import { Reducer } from 'redux';
import update from 'immutability-helper';
import { concat, get, isEqual, uniqWith } from 'lodash';
import { BankFileDescriptorsActionTypes, BankFileDescriptorsState } from './types';
import { BANK_FILE_DESCRIPTORS_PAGE } from '../../config/tableAndPageConstants';
import {
    bankFileDescriptorsSortByOptions,
} from '../../constants/bankFileDescriptorsSortAndFilters';

// Type-safe initialState!
export const initialState: BankFileDescriptorsState = {
    loading: false,
    data: [],
    pageData: {
        pageSize: BANK_FILE_DESCRIPTORS_PAGE.pageSize,
        currentPage: 0,
        hasNextPage: false,
    },
    saveBankFileDescriptorDataLoading: false,
    errorMessages: [],
    filters: {
        Customer: '',
        Descriptors: [],
        CompanyIds: undefined
    },
    sortBy: bankFileDescriptorsSortByOptions[1].value,
    sortAscending: false,
    tableFilter: undefined,
    activeData: {
        loading: false,
        selectedId: null,
        errorMessages: [],
        record: {}
    }
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<BankFileDescriptorsState> = (state = initialState, action) => {
    switch (action.type) {
        case BankFileDescriptorsActionTypes.GET_BANK_FILE_DESCRIPTORS_REQUEST: {
            return { ...state, loading: true };
        }
        case BankFileDescriptorsActionTypes.GET_BANK_FILE_DESCRIPTORS_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(state.data, action.payload.data),
                    isEqual
                );
            }
            return {
                ...state,
                loading: false,
                data: newDataState,
                pageData: action.payload.pageData,
                errorMessages: initialState.errorMessages,
            };
        }
        case BankFileDescriptorsActionTypes.GET_BANK_FILE_DESCRIPTORS_ERROR: {
            return {
                ...state,
                loading: false,
                data: initialState.data,
                errorMessages: action.payload,
            };
        }
        
        case BankFileDescriptorsActionTypes.UPDATE_BANK_FILE_DESCRIPTORS_FILTERS: {
            return { ...state, filters: action.payload };
        }

        case BankFileDescriptorsActionTypes.UPDATE_BANK_FILE_DESCRIPTORS_SORT_BY: {
            return {
                ...state,
                sortBy: action.payload.sortBy,
                sortAscending: action.payload.sortAscending
            };
        }

        case BankFileDescriptorsActionTypes.UPDATE_BANK_FILE_DESCRIPTORS_TABLE_FILTER_STATE: {
            return {
                ...state,
                tableFilter: action.payload
            };
        }

        case BankFileDescriptorsActionTypes.CLEAR_BANK_FILE_DESCRIPTORS_STATE_ALL_TABLE_FILTERS: {
            return {
                ...state,
                filters: initialState.filters,
                sortBy: initialState.sortBy,
                sortAscending: initialState.sortAscending,
                tableFilter: initialState.tableFilter
            };
        }
        //Single Record
        case BankFileDescriptorsActionTypes.SET_BANK_FILE_DESCRIPTOR_SELECTED_ID_REQUEST: {
            const newActiveData = update(state.activeData, {
                $merge: {
                    selectedId: initialState.activeData.selectedId
                }
            });

            return {
                ...state,
                activeData: newActiveData
            };
        }

        case BankFileDescriptorsActionTypes.SET_BANK_FILE_DESCRIPTOR_SELECTED_ID_SUCCESS: {
            const newActiveData = update(state.activeData, {
                $merge: {
                    selectedId: action.payload
                },
            });

            return {
                ...state,
                activeData: newActiveData
            };
        }

        case BankFileDescriptorsActionTypes.GET_BANK_FILE_DESCRIPTOR_DATA_REQUEST: {
            const newActiveData = update(state.activeData, {
                record: { $set: initialState.activeData.record },
                loading: { $set: true }
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case BankFileDescriptorsActionTypes.GET_BANK_FILE_DESCRIPTOR_DATA_SUCCESS: {
            const newActiveData = update(state.activeData, {
                record: { $set: action.payload.record },
                loading: { $set: false },
                errorMessages: { $set: initialState.activeData.errorMessages },
            });

            return {
                ...state,
                activeData: newActiveData
            };
        }

        case BankFileDescriptorsActionTypes.GET_BANK_FILE_DESCRIPTOR_DATA_ERROR: {
            const newActiveData = update(state.activeData, {
                record: { $set: initialState.activeData.record },
                loading: { $set: false },
                errorMessages: { $set: action.payload }
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case BankFileDescriptorsActionTypes.CLEAR_BANK_FILE_DESCRIPTOR_DATA_SUCCESS: {
            return {
                ...state,
                activeData: initialState.activeData
            };
        }
        
        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as bankFileDescriptorsReducer };
