/**
 * Component responsible for handling the layout of the entire app.
 */

import { Button, Layout, Modal, Spin } from 'antd';
import { capitalize, debounce, get, includes, isEmpty, some } from 'lodash';
import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    CompanyIdAttribute,
    IsOrganisationViewAttribute,
} from '../../constants/authUserAttributes';
import { accountingSystemOptions } from '../../constants/settings';
import ROLE_PERMISSIONS from '../../frontend-permissions.json';
import PaymentsManagementPage from '../../pages/banking/PaymentsManagementPage';
import PaymentPlansManagementPage from '../../pages/banking/PaymentPlansManagementPage';
import DashboardManagementPage from '../../pages/dashboard/DashboardManagementPage';
import ImportPageContainer from '../../pages/import/invoice/ImportPageContainer';
import ImportPaymentPlanPageContainer from '../../pages/import/payment_plan/ImportPaymentPlanPageContainer';
import CreditsManagementPage from '../../pages/sales/CreditsManagementPage';
import CustomersManagementPage from '../../pages/sales/CustomersManagementPage';
import InvoicesManagementPage from '../../pages/sales/InvoicesManagementPage';
import AccountingSystemManagementPage from '../../pages/settings/AccountingSystemManagementPage';
import CompanyPageContainer from '../../pages/settings/company/CompanyPageContainer';
import CustomerPaymentsManagementPage from '../../pages/settings/CustomerPaymentsManagementPage';
import DisplayManagementPage from '../../pages/settings/DisplayManagementPage';
import MultipleCurrenciesManagementPage from '../../pages/settings/organisation/MultipleCurrenciesManagementPage';
import PersonalAccessTokenManagementPage from '../../pages/settings/PersonalAccessTokenManagementPage';
import UsersPageContainer from '../../pages/settings/UsersPageContainer';
import ActiveTasksManagementPage from '../../pages/tasks/active_tasks/ActiveTasksManagementPage';
import CustomerAssistanceManagementPage from '../../pages/customer_enquiries/assistance/CustomerAssistanceManagementPage';
import TaskHistoryManagementPage from '../../pages/tasks/task_history/TaskHistoryManagementPage';
import RemittanceAdvicesManagementPage from '../../pages/banking/RemittanceAdvicesManagementPage';
import GenerateRoutes from '../../routes/GenerateRoutes';
import { ApplicationState } from '../../store';
import { getDemoOptionsConfig } from '../../store/common/sagas';
import {
    getCompanyWorkflowOptionsRequestAction,
    getUserCompaniesRequestAction
} from '../../store/companies/actions';
import { CompaniesState, CompanyUserRole } from '../../store/companies/types';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { getUserOrganisationsRequestAction } from '../../store/organisations/actions';
import {
    Organisation,
    OrganisationsState,
} from '../../store/organisations/types';
import {
    getAllRolesRequestAction,
    getRolePermissionsSuccessAction,
} from '../../store/roles/actions';
import { getRolePermissions } from '../../store/roles/sagas';
import { getUserPreferencesRequestAction } from '../../store/users/actions';
import { getCurrentUser } from '../../store/users/sagas';
import { getSelectedAccountingSystemBasedOnCompanyDetails, getTranslatedText } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withAnnouncementsHandler } from '../common/AnnouncementsHandler';
import { withAuthHandler } from '../common/AuthHandler';
import ErrorPageComponent from '../common/ErrorPageComponent';
import FontAwesome from '../common/FontAwesome';
import { withNotificationsHandler } from '../common/NotificationsHandler';
import PageHeader from './PageHeader';
import PageSidebar from './PageSidebar';
import CompaniesManagementPage from '../../pages/settings/organisation/CompaniesManagementPage';
import OrganisationUsersManagementPage from '../../pages/settings/organisation/OrganisationUsersManagementPage';
import CustomizationManagementPage from '../../pages/settings/CustomizationManagementPage';
import SupportTicketManagementPage from '../../pages/support/SupportTicketManagementPage';
import SupportTicketListsManagementPage from '../../pages/support/SupportTicketListsManagementPage';
import RACustomizationPageContainer from '../../pages/settings/ra_customization/RACustomizationPageContainer';
import ReportHistoryPage from '../../pages/report/ReportHistoryPage';
import WorkflowContentsManagementPage from '../../pages/contents/WorkflowContentsManagementPage';
import ManualContentsManagementPage from '../../pages/contents/ManualContentsManagementPage';
import OtherContentsManagementPage from '../../pages/contents/OtherContentsManagementPage';
import AppMfa from '../App/AppMfa';
import WorkflowManagementPage from '../../pages/workflows/WorkflowManagementPage';
import WorkflowCustomizationManagementPage from '../../pages/workflows/WorkflowCustomizationManagementPage';
import ReportHistoryOrganisationPage from '../../pages/report/ReportHistoryOrganisationPage';
import InvoicesManagementOrganisationPage from '../../pages/sales/OrganisationInvoicesManagementPage';
import OrganisationCustomersManagementPage from '../../pages/sales/OrganisationCustomersManagementPage';
import OrganisationCreditsManagementPage from '../../pages/sales/OrganisationCreditsManagementPage';
import OrganisationActiveTasksManagementPage from '../../pages/tasks/active_tasks/OrganisationActiveTasksManagementPage';
import ScheduledReportsManagementPage from '../../pages/report/ScheduledReportsManagementPage';
import BankFileDescriptorManagementPage from '../../pages/bankfile/BankFileDescriptorManagementPage';
import BankFileImportCustomizationPage from '../../pages/bankfile/BankFileImportCustomizationPage';
import ImportBankFileHistoryPage from '../../pages/bankfile/ImportBankFileHistoryPage';


const { Content } = Layout;

let initialAllRolesFetchCalled = false;
interface IProps {
    location: {
        pathname: string;
    };
    logoutUser: () => void;
    openNotificationsMenu: (count?: number) => void;
    showWhatsNewPanel: () => void;
}
const pathShowImportPage = [
    accountingSystemOptions.EXCEL,
    accountingSystemOptions.LEGACY_API,
];
const PageLayout: React.FC<IProps> = (props: IProps) => {
    
    const currentUser = useSelector(getCurrentUser);
    const demoOptionsConfig = useSelector(getDemoOptionsConfig);

    const isOrgView = get(currentUser, IsOrganisationViewAttribute) === '1';

    const companyIdCognito = get(currentUser, CompanyIdAttribute);

    const [windowHeight, setWindowHeight] = useState<number>(
        window.innerHeight
    );

    const customerLabel = useSelector(getCustomerUILabel);

    useEffect(() => {
        const rootElement = document.getElementById('root');
        if (rootElement) rootElement.style.height = '100%';
    }, []);

    const isMounted = useRef<boolean>(false);
    const dispatch = useDispatch();
    const companiesState: CompaniesState = useSelector(
        (state: ApplicationState) => state.companies
    );
    const organisationsState: OrganisationsState = useSelector(
        (state: ApplicationState) => state.organisations
    );
    /**
     * Boolean indicator to check if the invite linking is currently in progress.
     */
    const inviteLinkOngoing: boolean = useSelector(
        (state: ApplicationState) =>
            state.invites.companyInviteLinkingInProgress
    );

    const userCompanies: CompanyUserRole[] = companiesState.userCompanies;

    const selectedUserCompany: CompanyUserRole | undefined =
        companiesState.selectedUserCompany;

    const userRole = get(selectedUserCompany, 'Role.Name');

    const requiredMFASetup = some(companiesState.userCompanies, c => get(c, 'Company.EnableMultiFactorAuthentication'))
        && get(currentUser, 'preferredMFA') === 'NOMFA';

    const selectedUserOrganisation: Organisation | undefined =
        organisationsState.selectedUserOrganisation;

    const rolePermissions = useSelector(getRolePermissions);
    const SupportCashAllocation = useSelector((state: ApplicationState) =>
        get(state.companies.selectedUserCompany, 'Company.SupportCashAllocation')
    );
    const bankingRoutes = [
        {
            name: getTranslatedText('Payments'),
            route: '/banking/payments',
            component: PaymentsManagementPage,
        },

        // {
        //     name: 'Settlements',
        //     route: '/banking/settlements',
        // },
        // {
        //     name: 'Bank Account',
        //     route: '/banking/bank-account',
        // },
    ];

    if (
        includes(
            get(demoOptionsConfig, 'PAYMENT_PLAN'),
            get(selectedUserCompany, 'Company.CompanyId')
        ) ||
        get(selectedUserCompany, 'Company.CompanyPaymentPlan.IsEnabled')
    ) {
        bankingRoutes.push({
            name: getTranslatedText('Payment Plans'),
            route: '/banking/payment-plans',
            component: PaymentPlansManagementPage,
        });
    }

    if (
        includes(
            get(demoOptionsConfig, 'REMITTANCE_ADVICES'),
            get(selectedUserCompany, 'Company.CompanyId')
        ) ||
        get(selectedUserCompany, 'Company.SupportCashAllocation')
    ) {
        bankingRoutes.push({
            name: getTranslatedText('Remittance Advices'),
            route: '/banking/remittance-advices',
            component: RemittanceAdvicesManagementPage,
        });
    }

    const settingsRoute:any = [
        {
            name: getTranslatedText('Display'),
            route: '/settings/display',
            component: DisplayManagementPage,
            allowedRoles:
                rolePermissions.DISPLAY_SETTINGS_VIEW_LIST,
        },
        {
            name: getTranslatedText('Company'),
            route: '/settings/company',
            component: CompanyPageContainer,
        },
        {
            name: getTranslatedText(`${capitalize(customerLabel)} Payments`),
            route: '/settings/customer-payments',
            component: CustomerPaymentsManagementPage,
            allowedRoles:
                rolePermissions.CUSTOMER_PAYMENTS_VIEW_LIST,
        },
        {
            name: getTranslatedText('Users'),
            route: '/settings/users',
            allowedRoles: rolePermissions.USER_VIEW_LIST,
            component: UsersPageContainer,
        },
        {
            name: getTranslatedText('Access Tokens'),
            route: '/settings/personal-access-token',
            component: PersonalAccessTokenManagementPage,
        },
        {
            name: getTranslatedText('Accounting System'),
            route: '/settings/accounting-system',
            component: AccountingSystemManagementPage,
            allowedRoles:
                rolePermissions.DISPLAY_ACCOUNTING_SYSTEM_PAGE,
        },
        {
            name: getTranslatedText('Customisation'),
            route: '/settings/customization',
            component: CustomizationManagementPage,
            allowedRoles: rolePermissions.CUSTOMIZATION_VIEW,
        },
        {
            name: getTranslatedText('Workflow Customisation'),
            route: '/settings/workflow-customization',
            component: WorkflowCustomizationManagementPage,
            allowedRoles: rolePermissions.WORKFLOW_CUSTOMIZATION_UPDATE,
            style: { whiteSpace: 'normal', paddingTop: '7px', paddingBottom: '7px', lineHeight: 'normal', height: 'auto' }
        }
    ];
    
    if(SupportCashAllocation){
        settingsRoute.push(
            {
                name: getTranslatedText('Remittance Advice Customisation'),
                route: '/settings/ra_customization',
                component: RACustomizationPageContainer,
                allowedRoles: rolePermissions.RA_CUSTOMIZATION,
                style: { whiteSpace: 'normal', paddingTop: '7px', paddingBottom: '7px', lineHeight: 'normal', height: 'auto' }
            });
    }

    const selectedAccountingSystem = getSelectedAccountingSystemBasedOnCompanyDetails(selectedUserCompany);

    const importRoute = [];

    if (includes(pathShowImportPage, selectedAccountingSystem) && !isOrgView) {
        importRoute.push({
            name: getTranslatedText('Invoices'),
            route: '/import/invoice',
            component: ImportPageContainer,
            allowedRoles: rolePermissions.IMPORT_EXCEL_FILE,
        });
    }

    if (get(selectedUserCompany, 'Company.CompanyPaymentPlan.IsEnabled') && !isOrgView) {
        importRoute.push({
            name: getTranslatedText('Payment plans'),
            route: '/import/payment_plan',
            component: ImportPaymentPlanPageContainer,
            allowedRoles: rolePermissions.PAYMENT_PLAN_CREATE_NEW_PAYMENT_PLAN,
        });
    }

    /**
     * PageSidebar routes / menu items.
     */
    const menuItems: DynamicObject[] = isOrgView ? [
        {
            name: getTranslatedText('Dashboard'),
            icon: ['fas', 'tachometer-alt'],
            route: '',
            exact: true,
            component: DashboardManagementPage,
        },
        {
            name: getTranslatedText('Tasks'),
            icon: ['fas', 'tasks'],
            route: '/tasks',
            children: [
                {
                    name: getTranslatedText('Active tasks'),
                    route: '/tasks/active-tasks',
                    component: OrganisationActiveTasksManagementPage,
                }
            ],
        },         
        {
            name: getTranslatedText('Sales'),
            icon: ['fas', 'hand-holding-usd'],
            route: '/sales',
            allowedRoles: rolePermissions.ORGANISATION_REPORT_HISTORY,
            children: [
                {
                    name: getTranslatedText(`${capitalize(customerLabel)}s`),
                    route: '/sales/customers',
                    component: OrganisationCustomersManagementPage,
                },
                {
                    name: getTranslatedText('Invoices'),
                    route: '/sales/invoices',
                    component: InvoicesManagementOrganisationPage,
                },
                {
                    name: getTranslatedText('Credits'),
                    route: '/sales/credits',
                    component: OrganisationCreditsManagementPage,
                },
            ]
        },
        {
            name: getTranslatedText('Reports'),
            icon: ['fas', 'chart-line'],
            route: '/reports',
            allowedRoles: rolePermissions.ORGANISATION_REPORT_HISTORY,
            children: [
                {
                    name: getTranslatedText('History'),
                    route: '/reports/history',
                    component: ReportHistoryOrganisationPage
                }
            ]
        },
        {
            name: getTranslatedText('Settings'),
            icon: ['fas', 'cogs'],
            route: '/settings',
            children: [
                {
                    name: getTranslatedText('Companies'),
                    route: '/settings/companies',
                    component: CompaniesManagementPage,
                },
                {
                    name: getTranslatedText('Users'),
                    route: '/settings/users',
                    component: OrganisationUsersManagementPage,
                    allowedRoles: rolePermissions.ORGANISATION_VIEW_USERS,
                },
                {
                    name: getTranslatedText('Multiple currencies'),
                    route: '/settings/multiple-currencies',
                    component: MultipleCurrenciesManagementPage,
                },
            ],
        },
    ] :
    [
        {
            name: getTranslatedText('Dashboard'),
            icon: ['fas', 'tachometer-alt'],
            route: '',
            exact: true,
            component: DashboardManagementPage,
        },
        {
            name: getTranslatedText('Tasks'),
            icon: ['fas', 'tasks'],
            route: '/tasks',
            children: [
                {
                    name: getTranslatedText('Active tasks'),
                    route: '/tasks/active-tasks',
                    component: ActiveTasksManagementPage,
                },
                {
                    name: getTranslatedText('Task history'),
                    route: '/tasks/history',
                    component: TaskHistoryManagementPage,
                },
            ],
        },
        {
            name: getTranslatedText('Sales'),
            icon: ['fas', 'hand-holding-usd'],
            route: '/sales',
            children: [
                {
                    name: getTranslatedText(`${capitalize(customerLabel)}s`),
                    route: '/sales/customers',
                    component: CustomersManagementPage,
                },
                {
                    name: getTranslatedText('Invoices'),
                    route: '/sales/invoices',
                    component: InvoicesManagementPage,
                },
                {
                    name: getTranslatedText('Credits'),
                    route: '/sales/credits',
                    component: CreditsManagementPage,
                },
            ],
        },
        {
            name: getTranslatedText(`${capitalize(customerLabel)} Enquiries`),
            icon: ['fas', 'headset'],
            route: '/customer-enquiries',
            children: [
                {
                    name: getTranslatedText('Assistance/Tickets'),
                    route: '/customer-enquiries/customer-assistance',
                    component: CustomerAssistanceManagementPage
                }
            ]
        },
        // {
        //     name: 'Purchases',
        //     icon: ['fas', 'wallet'],
        //     route: '/purchases',
        //     children: [
        //         {
        //             name: 'Suppliers',
        //             route: '/purchases/suppliers',
        //         },
        //         {
        //             name: 'Purchase Orders',
        //             route: '/purchases/purchase-orders',
        //         },
        //     ],
        // },
        {
            name: getTranslatedText('Banking'),
            icon: ['fas', 'piggy-bank'],
            route: '/banking',
            children: bankingRoutes,
        },
        {
            name: getTranslatedText('Reports'),
            icon: ['fas', 'chart-line'],
            route: '/reports',
            children: [
                {
                    name: getTranslatedText('History'),
                    route: '/reports/history',
                    component: ReportHistoryPage
                },
                {
                    name: getTranslatedText('Schedules'),
                    route: '/reports/schedules',
                    component: ScheduledReportsManagementPage
                }
            ],
        },
        {
            name: getTranslatedText('Workflows'),
            icon: ['fas', 'project-diagram'],
            route: '/workflows',
            children: [
                {
                    name: getTranslatedText('Workflow Configurations'),
                    route: '/workflow/configurations',
                    component: WorkflowManagementPage,
                    style: { whiteSpace: 'normal', paddingTop: '7px', paddingBottom: '7px', lineHeight: 'normal', height: 'auto' }
                },
                {
                    name: getTranslatedText('Workflow Customisation'),
                    route: '/workflow/workflow-customization',
                    component: WorkflowCustomizationManagementPage,
                    allowedRoles: rolePermissions.WORKFLOW_CUSTOMIZATION_UPDATE,
                    style: { whiteSpace: 'normal', paddingTop: '7px', paddingBottom: '7px', lineHeight: 'normal', height: 'auto' }
                }
            ]
        },
        {
            name: getTranslatedText('Settings'),
            icon: ['fas', 'cogs'],
            route: '/settings',
            children: settingsRoute
        }
    ];


    if (get(selectedUserCompany, 'Company.SupportCashAllocation') && get(selectedUserCompany, 'Company.EnableBankFileImporting')
    ) {
        menuItems.push({
            name: getTranslatedText('Bank File'),
            icon: ['fa', 'receipt'],
            route: '/bank-file',
            children: [
                {
                    name: getTranslatedText('Bank File Descriptor'),
                    route: '/bank-file/bank-file-descriptor',
                    component: BankFileDescriptorManagementPage,
                    style: { whiteSpace: 'normal', paddingTop: '7px', paddingBottom: '7px', lineHeight: 'normal', height: 'auto' }
                },
                {
                    name: getTranslatedText('Bank File Customisation'),
                    route: '/bank-file/bank-file-customization',
                    component: BankFileImportCustomizationPage,
                    style: { whiteSpace: 'normal', paddingTop: '7px', paddingBottom: '7px', lineHeight: 'normal', height: 'auto' }
                },
                {
                    name: getTranslatedText('Bank File Process History'),
                    route: '/bank-file/bank-file-process-history',
                    component: ImportBankFileHistoryPage,
                    style: { whiteSpace: 'normal', paddingTop: '7px', paddingBottom: '7px', lineHeight: 'normal', height: 'auto' }
                }
            ]
        });
    }

    if (includes(rolePermissions.CONTENTS_CUSTOMIZATION, userRole) && !isOrgView) {
        menuItems.push({
            name: getTranslatedText('Contents'),
            icon: ['fas', 'file-invoice'],
            route: '/contents',
            children: [
                {
                    name: getTranslatedText('Workflow contents'),
                    route: '/contents/workflow-contents',
                    component: WorkflowContentsManagementPage,
                },
                {
                    name: getTranslatedText('Manual contents'),
                    route: '/contents/manual-contents',
                    component: ManualContentsManagementPage,
                },
                {
                    name: getTranslatedText('Others'),
                    route: '/contents/other-contents',
                    component: OtherContentsManagementPage,
                }
            ]
        });
    }
    
    if ((includes(pathShowImportPage, selectedAccountingSystem) || get(selectedUserCompany, 'Company.CompanyPaymentPlan.IsEnabled')) && !isOrgView) {
        menuItems.push({
            name: getTranslatedText('Import'),
            icon: ['fas', 'retweet'],
            route: '/import',
            children: importRoute
        });
    }

    menuItems.push({
        name: getTranslatedText("Create Support Ticket"),
        route: '/support/create/support-ticket',
        component: SupportTicketManagementPage,
        allowedRoles: rolePermissions.SUPPORT_TICKET,
        style: { whiteSpace: 'normal', paddingTop: '7px', paddingBottom: '7px', lineHeight: 'normal', height: 'auto' },
        areaType: 'header'
    })

    menuItems.push({
        name: getTranslatedText("Support Ticket Lists"),
        route: '/support/support-ticket/lists',
        component: SupportTicketListsManagementPage,
        allowedRoles: rolePermissions.SUPPORT_TICKET,
        style: { whiteSpace: 'normal', paddingTop: '7px', paddingBottom: '7px', lineHeight: 'normal', height: 'auto' },
        areaType: 'header'
    })

    /**
     * Function that is called upon window resize.
     */
    const checkWindowSize = () => {
        setWindowHeight(window.innerHeight);
    };

    /**
     * Callback function that will be called whenever a window resize is triggered.
     * Applies debounce to keep a succeeding function from being called when resize is trigger in
     * a short span of time.
     */
    const resizeWindowHandler = useCallback(debounce(checkWindowSize, 400), []);

    /**
     * Function that adds a listener for window resize and binds it to a function.
     */
    const resizeWindowInitializer = () => {
        window.addEventListener('resize', resizeWindowHandler);
    };
    useLayoutEffect(resizeWindowInitializer, []);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (inviteLinkOngoing === false) {
            dispatch(getUserCompaniesRequestAction());
        }
    }, [dispatch, inviteLinkOngoing]);

    useEffect(() => {
        if (!isEmpty(userCompanies)) {
            dispatch(getUserOrganisationsRequestAction());
        }
    }, [dispatch, userCompanies]);

    /**
     * Function that fetches role permissions from a JSON file and sets it in redux.
     */
    const getRolePermissionsData = useCallback(() => {
        dispatch(getRolePermissionsSuccessAction(ROLE_PERMISSIONS));
    }, [dispatch]);

    //on Unmount
    useEffect(() => {
        return () => {
            initialAllRolesFetchCalled = false;
        };
    }, []);

    const initializeWorkflowOptions = () => {
        if (selectedUserCompany) {
            dispatch(getCompanyWorkflowOptionsRequestAction());
        }
    };

    /**
     * Function for identifying if the page layout is loading or not.
     */
    const getPageLayoutLoading = () => {
        let layoutLoading =
            companiesState.loading || organisationsState.loading;

        if (!layoutLoading) {
            layoutLoading = isOrgView
                ? isEmpty(selectedUserOrganisation)
                : isEmpty(selectedUserCompany) || isEmpty(rolePermissions);
        }

        return layoutLoading;
    };

    /**
     * Function for getting the loading text.
     */
    const getPageLayoutLoadingText = () => {
        let loadingType = isOrgView ? 'organisation' : 'company';
        if (
            !isEmpty(organisationsState.userOrganisations) &&
            !isEmpty(companiesState.userCompanies)
        ) {
            if (
                isOrgView &&
                companiesState.loading &&
                !isEmpty(organisationsState.selectedUserOrganisation)
            ) {
                loadingType = 'company';
            } else if (
                !isOrgView &&
                organisationsState.loading &&
                !isEmpty(companiesState.selectedUserCompany)
            ) {
                loadingType = 'organisation';
            }
        }

        return `Loading ${loadingType} data`;
    };

    useEffect(initializeWorkflowOptions, [selectedUserCompany]);

    useEffect(() => {
        if (
            !isEmpty(companiesState.userCompanies) &&
            !initialAllRolesFetchCalled &&
            companyIdCognito
        ) {
            initialAllRolesFetchCalled = true;
            dispatch(getAllRolesRequestAction());
            getRolePermissionsData();
            if (!isOrgView) {
                dispatch(
                    getUserPreferencesRequestAction((res: DynamicObject) => {
                        if (!res.IsSuccess && !companyIdCognito) {
                            Modal.error({
                                title: getTranslatedText('Error'),
                                content:
                                    getTranslatedText(`${get(res, 'Messages.0') || 'Error encountered while fetching user preferences!'}`),
                                okText: getTranslatedText('OK'),
                            });
                        }
                    })
                );
            }
        }
    }, [
        dispatch,
        companiesState.userCompanies,
        getRolePermissionsData,
        companyIdCognito,
        isOrgView,
    ]);

    if (
        !companiesState.loading &&
        isEmpty(companiesState.userCompanies) &&
        !inviteLinkOngoing
    ) {
        return (
            <ErrorPageComponent
                errorTitle={getTranslatedText("Error")}
                errorFaIcon={['fas', 'exclamation-triangle']}
                errorDisplayComponent={
                    <div
                        style={{
                            fontSize: 20,
                        }}
                    >
                        {getTranslatedText('User does not belong in any company.')}
                        <p
                            style={{
                                fontSize: 14,
                            }}
                        >
                            {getTranslatedText('Please contact the system administrator')}
                        </p>
                        <div>
                            <Button
                                className="pa-0"
                                type="link"
                                onClick={props.logoutUser}
                            >
                                <FontAwesome
                                    icon={['fas', 'sign-out-alt']}
                                    className="mr-10"
                                />
                                {getTranslatedText('Sign Out')}
                            </Button>
                        </div>
                    </div>
                }
            />
        );
    }

    const pageLayoutLoading = getPageLayoutLoading();
    const loadingText = getPageLayoutLoadingText();

    const shouldGenerateRoutes = isOrgView
        ? !isEmpty(selectedUserOrganisation)
        : !isEmpty(selectedUserCompany) && !isEmpty(rolePermissions);

    if (requiredMFASetup) {
        return <AppMfa />;
    }

    return (
        <Spin
            spinning={pageLayoutLoading}
            tip={getTranslatedText(loadingText)}
            // size="large"
            style={{
                height: '80%',
            }}
        >
            <Layout
                className="h-100"
                style={{
                    maxHeight: windowHeight,
                }}
            >
                <PageHeader
                    logoutUser={props.logoutUser}
                    openNotificationsMenu={props.openNotificationsMenu}
                    pageLayoutLoading={pageLayoutLoading}
                    showWhatsNewPanel={props.showWhatsNewPanel}
                    isOrgView={isOrgView}
                />
                <Layout>
                    {/* Sidebar Here */}
                    <PageSidebar menuItems={menuItems} />
                    <Layout className="page-layout-container">
                        <Content className="page-layout-content">
                            {/* Routes Section */}
                            {shouldGenerateRoutes && (
                                <GenerateRoutes routeList={menuItems} />
                            )}
                            {/* end Routes Section */}
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        </Spin>
    );
};

export default withRouter(
    withAuthHandler(
        withAnnouncementsHandler(withNotificationsHandler(PageLayout))
    )
);
