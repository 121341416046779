import API, { graphqlOperation } from '@aws-amplify/api';
import { get, map, orderBy } from 'lodash';
import moment from 'moment-timezone';
import {
    all,
    call,
    delay,
    fork,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';
import { ApplicationState } from '..';
import {
    API_NAME,
    maxAPIRefetchCount,
    refetchAPIDelay,
} from '../../config/config';
import {
    DETAILS_TAB,
    PAYMENT_PLANS_PAGE,
} from '../../config/tableAndPageConstants';
import {
    FamilyNameAttribute,
    GivenNameAttribute,
} from '../../constants/authUserAttributes';
import { fetchPaymentPlanScheduleHandler } from '../../constants/paymentPlansSortAndFilters';
import queries from '../../graphql/queries.graphql';
import {
    checkShouldRequestRefetch,
    formatDateToDateObjectUTC,
    removeAppliedFiltersForApiRequest,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { PageData } from '../common/types';
import { getCurrentUser } from '../users/sagas';
import {
    fetchPaymentPlanCommunicationDetail,
    getPaymentPlanConversationErrorAction,
    getPaymentPlanConversationSuccessAction,
    getPaymentPlanDataErrorAction,
    getPaymentPlanDataScheduleErrorAction,
    getPaymentPlanDataScheduleSuccessAction,
    getPaymentPlanDataSuccessAction,
    getPaymentPlanInvoicesErrorAction,
    getPaymentPlanInvoicesSuccessAction,
    getPaymentPlansErrorAction,
    getPaymentPlansRequestAction,
    getPaymentPlansSuccessAction,
    setPaymentPlanSelectedIdSuccessAction,
} from './actions';
import {
    PaymentPlan,
    PaymentPlan as PaymentPlanType,
    PaymentPlansActionTypes,
} from './types';

export const getPaymentPlanData = (state: ApplicationState) =>
    state.paymentPlans.activeData;

export const getPaymentPlanSelectedId = (state: ApplicationState) =>
    state.paymentPlans.activeData.selectedId;

export const getPaymentPlansPageData = (state: ApplicationState) =>
    state.paymentPlans.pageData;

let refetchCount = 0;
/**
 * Function that calls the API for fetching the paymentPlan list.
 * @param param0
 */
function* handleGetPaymentPlansRequest({ payload }: any) {
    const errorMessage =
        'Error fetching payment plans list. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const {
            filters,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            isUsingCloudImportType,
            paymentPlanState,
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'paymentPlans'
        );

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_PAYMENT_PLANS_FOR_COMPANY, {
                ...cleanFilters,
                IsCloudImportType: isUsingCloudImportType,
                PaymentPlanState: paymentPlanState,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * PAYMENT_PLANS_PAGE.pageSize
            })
        );

        const { PaymentPlans } = get(res.data, 'GetPaymentPlansForCompany');

        const responsePayload = {
            data: PaymentPlans,
            pageData: {
                pageSize: pageSize,
                currentPage: currentPage,
                hasNextPage:
                    !(PaymentPlans.length < pageSize) &&
                    !(pageSize < PAYMENT_PLANS_PAGE.pageSize),
            },
        };

        refetchCount = 0;
        yield put(getPaymentPlansSuccessAction(responsePayload));
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getPaymentPlansRequestAction(payload));
        } else {
            yield put(getPaymentPlansErrorAction([errorMessage]));
        }
    }
}

/**
 * Function calling the API for fetching the paymentPlan data based on the given id.
 * @param param0
 */
function* handleGetPaymentPlanDataRequest({
    payload: { paymentPlanId, isUsingCloudImportType },
}: any) {
    const errorMessage =
        'Error fetching payment plan details. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_PAYMENT_PLAN_DETAILS_FOR_COMPANY, {
                PaymentPlanId: paymentPlanId,
                IsCloudImportType: isUsingCloudImportType,
            })
        );

        const PaymentPlan = get(res.data, 'GetPaymentPlanDetailsForCompany');

        if (PaymentPlan) {
            const responsePayload = {
                record: PaymentPlan,
            };

            yield put(getPaymentPlanDataSuccessAction(responsePayload));
        } else {
            yield put(getPaymentPlanDataErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getPaymentPlanDataErrorAction([errorMessage]));
    }
}

/**
 * Function that sets the selected paymentPlanId id for reference.
 * @param param0
 */
function* handleSetPaymentPlanSelectedIdRequest({ payload }: any) {
    const { paymentPlanId, callback } = payload;
    yield put(setPaymentPlanSelectedIdSuccessAction(paymentPlanId));
    if (callback) callback();
}

/**
 * Function that gets the invoices list for a certain paymentPlan.
 * @param param0
 */
function* handleGetPaymentPlanDataInvoicesRequest({ payload }: any) {
    const errorMessage = `Error fetching payment plan's invoice list. Please try again later.`;
    try {
        const {
            filters,
            invoiceState,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true);
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_INVOICES_FOR_COMPANY, {
                ...cleanFilters,
                InvoiceState: invoiceState,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * DETAILS_TAB.INVOICE_LIST_COMPLETE.pageSize,
            })
        );

        const { Invoices } = get(res.data, 'GetInvoicesForCompany');
        if (Invoices) {
            const responsePayload = {
                data: Invoices,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage: !(Invoices.length < pageSize),
                },
            };

            yield put(getPaymentPlanInvoicesSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getPaymentPlanInvoicesErrorAction([errorMessage]));
    }
}

/**
 * Payment Plan Data Schedule
 */
/**
 * Function that fetches the schedule for the selected payment plan.
 * @param param0
 */
function* handleGetPaymentPlanDataScheduleRequest({ payload }: any) {
    const errorMessage = `Error fetching payment plan's schedule list. Please try again later.`;
    try {
        const { filters, sortBy, sortAscending, pageSize, currentPage } =
            payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(filters);
        // To call async functions, use redux-saga's `call()`.
        // const res: DynamicObject = yield call(
        //     [API, 'graphql'],
        //     graphqlOperation(queries.GET_INVOICES_FOR_COMPANY, {
        //         ...cleanFilters,
        //         InvoiceState: invoiceState,
        //         SortField: sortBy,
        //         Ascending: sortAscending,
        //         PageSize: pageSize,
        //         Skip: currentPage * DETAILS_TAB.INVOICE_LIST.pageSize,
        //     })
        // );

        // const { Invoices: Schedule } = get(res.data, 'GetInvoicesForCompany');
        // const jsonResponse: DynamicObject = yield call(
        //     fetch,
        //     `${window.location.origin}/payment-plans-mock-data.json`
        // );
        // const PaymentPlans: PaymentPlanType[] = yield jsonResponse.json();

        // const PaymentPlan = get(filter(PaymentPlans, ['Id', paymentPlanId]), 0);
        const paymentPlanActiveData: PaymentPlanType = yield select(
            getPaymentPlanData
        );
        const paymentPlan = get(paymentPlanActiveData, 'record');
        const paymentPlanSchedules = get(
            paymentPlan,
            'PaymentPlanSchedules',
            []
        );
        const planSchedulesFiltered: any[] = fetchPaymentPlanScheduleHandler(
            paymentPlanSchedules,
            cleanFilters
        );

        if (paymentPlanSchedules) {
            const sortOrder = sortAscending ? 'asc' : 'desc';
            const paymentPlanSorted = orderBy(
                planSchedulesFiltered,
                [sortBy],
                [sortOrder]
            );

            const responsePayload = {
                data: paymentPlanSorted,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(paymentPlanSchedules.length < pageSize) &&
                        !(pageSize < DETAILS_TAB.INVOICE_LIST.pageSize),
                },
            };

            yield put(getPaymentPlanDataScheduleSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getPaymentPlanDataScheduleErrorAction([errorMessage]));
    }
}

/**
 * Function for fetching the conversation list of a given paymentPlan.
 * @param param0
 */
function* handleGetPaymentPlanConversationRequest({ payload }: any) {
    const errorMessage = `Error fetching paymentPlan's conversation list. Please try again later.`;
    try {
        const { filters, sortBy, sortAscending, pageSize, currentPage } =
            payload;

        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true);

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CONVERSATION_LINES_FOR_COMPANY, {
                ...cleanFilters,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * DETAILS_TAB.CONVERSATION_TIMELINE.pageSize,
            })
        );

        const { ConversationLines } = get(
            res.data,
            'GetConversationLinesForCompany'
        );
        const Conversation = ConversationLines;

        if (Conversation) {
            const responsePayload = {
                data: Conversation,
                pageData: {
                    pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(Conversation.length < pageSize) &&
                        !(
                            pageSize <
                            DETAILS_TAB.CONVERSATION_TIMELINE.pageSize
                        ),
                },
            };

            yield put(getPaymentPlanConversationSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getPaymentPlanConversationErrorAction([errorMessage]));
    }
}

/**
 * Function for adding comment to the paymentPlan's conversation list.
 * @param param0
 */
function* handlePaymentPlanAddCommentRequest({ payload: sagaPayload }: any) { 
    const { filter, paymentPlanIds, excludePaymentPlans, comment, callback } =
        sagaPayload;
    const cleanFilters = removeAppliedFiltersForApiRequest(
        filter,
        true,
        'paymentPlans'
    );

    if (cleanFilters.CustomFieldFilters) {
        cleanFilters.CustomFieldFilters = JSON.parse(
            cleanFilters.CustomFieldFilters
        );
    }
    const payload = {
        PaymentPlanManagementFilter: {
            ...cleanFilters,
            PaymentPlanIds: paymentPlanIds,
            ExcludePaymentPlans: excludePaymentPlans,
        },
        Comment: comment,
    };

    try {
        yield call(
            [API, 'post'],
            API_NAME,
            '/conversation/save/paymentplancomment',
            {
                body: payload,
            }
        );
        if (callback) {
            let RefetchList = true;
            if (paymentPlanIds.length === 1 && excludePaymentPlans === false) {
                RefetchList = false;
                const currentUser: DynamicObject = yield select(getCurrentUser);
                const paymentPlansUpdated: DynamicObject[] = map(
                    paymentPlanIds,
                    (uId: string) => {
                        return {
                            Id: uId,
                            ConversationLine: {
                                Content: comment,
                                CreatedDateTime: formatDateToDateObjectUTC(
                                    moment(),
                                    undefined,
                                    true
                                ),
                                User: {
                                    GivenName: get(
                                        currentUser,
                                        GivenNameAttribute
                                    ),
                                    FamilyName: get(
                                        currentUser,
                                        FamilyNameAttribute
                                    ),
                                },
                            },
                        };
                    }
                );
                const pageData: PageData = yield select(
                    getPaymentPlansPageData
                );
                yield put(
                    getPaymentPlansSuccessAction({
                        data: paymentPlansUpdated,
                        pageData,
                        mergeData: true,
                    })
                );
            }
            const response = {
                IsSuccess: true,
                RefetchList,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function for creating paymentplan.
 * @param param0
 */
function* handleCreatePaymentPlan({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;

    try {
        yield call([API, 'post'], API_NAME, '/paymentplan', {
            body: payload,
        });
        if (callback) {
            const response = {
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function for creating dummy paymentplan.
 * @param param0
 */
 function* handleAssignPaymentPlan({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;

    try {
        const paymentPlans: PaymentPlan[] = yield call([API, 'post'], API_NAME, '/paymentplan/assignconfig', {
            body: payload
        });
        if (callback) {
            callback({
                IsSuccess: true,
                PaymentPlans: paymentPlans || []
            });
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function for getting the data needed to populate the public Payment schedule details page from API.
 * @param param0
 */
function* handleFetchPaymentScheduleDetailsPublic({
    payload: sagaPayload,
}: any) {
    const { communicationId, applyDelay, callback } = sagaPayload;
    try {
        if (applyDelay) yield delay(3000); // delay for 3 seconds
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_PAYMENT_PLAN_COMMUNICATION_DETAIL, {
                CommunicationId: communicationId || null,
                AllInvoices: true,
            })
        );

        res.IsSuccess = true;
        refetchCount = 0;
        if (callback) callback(res);
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                fetchPaymentPlanCommunicationDetail(
                    communicationId,
                    applyDelay,
                    callback
                )
            );
        } else {
            if (callback) {
                const returnData = get(err.response, 'data')
                    ? err.response.data
                    : { Messages: [err.message] };
                returnData.IsSuccess = false;
                callback(returnData);
            }
        }
    }
}

/**
 * Function called when actioning a payment plan task.
 * @param param0
 */
function* handleActionPaymentPlanRequest({ payload: sagaPayload }: any) {
    const { callback, ...fields } = sagaPayload;

    const payload = {
        ...fields,
    };

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/task/action/paymentplan',
            {
                body: payload,
            }
        );
        const { TaskEditAction } = res;
        let successMessages: any = [];
        if (TaskEditAction) {
            successMessages.push(`Task has been actioned successfully!`);
        }
        if (callback) {
            const response = {
                IsSuccess: true,
                Messages: successMessages,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called when actioning a batch of multiple payment plan tasks.
 * @param param0
 */
function* handleActionPaymentPlansRequest({ payload: sagaPayload }: DynamicObject) {
    const { filter, taskIds, excludeTasks, callback, ...fields } = sagaPayload;
    const cleanFilters = removeAppliedFiltersForApiRequest(
        filter,
        true,
        'paymentPlans'
    );

    if (cleanFilters.CustomFieldFilters) {
        cleanFilters.CustomFieldFilters = JSON.parse(
            cleanFilters.CustomFieldFilters
        );
    }

    const payload = {
        TaskManagementFilter: {
            ...cleanFilters,
            TaskIds: taskIds,
            ExcludeTasks: excludeTasks,
        },
        ...fields
    };

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/task/action/paymentplans',
            {
                body: payload,
            }
        );
        const { TaskEditAction } = res;
        let successMessages: string[] = [];
        if (TaskEditAction) {
            successMessages.push(`Tasks has been actioned successfully!`);
        }
        if (callback) {
            const response = {
                IsSuccess: true,
                Messages: successMessages,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function for checking the state of payment plans selected to enable/disable the `Cancel` button in
 * Payment Plans page.
 * @param param0
 */
function* handleCheckPaymentPlansActionStateRequest({ payload }: any) {
    const {
        filters,
        sortBy,
        sortAscending,
        pageSize,
        currentPage,
        paymentPlanIds,
        excludePaymentPlans,
        callback,
    } = payload;
    // To call async functions, use redux-saga's `call()`.
    const cleanFilters = removeAppliedFiltersForApiRequest(
        filters,
        true,
        'paymentPlans'
    );
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.CHECK_PAYMENT_PLANS_ACTION_STATE, {
                ...cleanFilters,
                SortField: sortBy,
                PaymentPlanIds: paymentPlanIds,
                ExcludePaymentPlans: excludePaymentPlans,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * PAYMENT_PLANS_PAGE.pageSize,
            })
        );

        const paymentPlansActionState = get(res.data, 'PaymentPlansActionState');

        if (callback) callback(paymentPlansActionState);
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

    }
}

/**
 * Function called when cancelling a payment plan task.
 * @param param0
 */
function* handleCancelPaymentPlanRequest({ payload: sagaPayload }: any) {
    const { filter, paymentPlanIds, excludePaymentPlans, comment, callback, notifyCustomerCancellation } =
        sagaPayload;
    const cleanFilters = removeAppliedFiltersForApiRequest(
        filter,
        true,
        'paymentPlans'
    );

    if (cleanFilters.CustomFieldFilters) {
        cleanFilters.CustomFieldFilters = JSON.parse(
            cleanFilters.CustomFieldFilters
        );
    }
    const payload = {
        PaymentPlanManagementFilter: {
            ...cleanFilters,
            PaymentPlanIds: paymentPlanIds,
            ExcludePaymentPlans: excludePaymentPlans,
        },
        Comment: comment,
        NotifyCustomerCancellation: notifyCustomerCancellation
    };

    try {
        yield call(
            [API, 'post'],
            API_NAME,
            '/paymentplan/cancel',
            {
                body: payload,
            }
        );
        if (callback) {
            let RefetchList = true;
            if (paymentPlanIds.length === 1 && excludePaymentPlans === false) {
                RefetchList = false;
                const currentUser: DynamicObject = yield select(getCurrentUser);
                const paymentPlansUpdated: DynamicObject[] = map(
                    paymentPlanIds,
                    (uId: string) => {
                        return {
                            Id: uId,
                            ConversationLine: {
                                Content: comment,
                                CreatedDateTime: formatDateToDateObjectUTC(
                                    moment(),
                                    undefined,
                                    true
                                ),
                                User: {
                                    GivenName: get(
                                        currentUser,
                                        GivenNameAttribute
                                    ),
                                    FamilyName: get(
                                        currentUser,
                                        FamilyNameAttribute
                                    ),
                                },
                            },
                        };
                    }
                );
                const pageData: PageData = yield select(
                    getPaymentPlansPageData
                );
                yield put(
                    getPaymentPlansSuccessAction({
                        data: paymentPlansUpdated,
                        pageData,
                        mergeData: true,
                    })
                );
            }
            const response = {
                IsSuccess: true,
                RefetchList,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

function* handleResendPaymentPlanApprovalRequest({ payload: sagaPayload }: any) {
    const { filter, paymentPlanIds, excludePaymentPlans, callback } =
        sagaPayload;
    const cleanFilters = removeAppliedFiltersForApiRequest(
        filter,
        true,
        'paymentPlans'
    );

    if (cleanFilters.CustomFieldFilters) {
        cleanFilters.CustomFieldFilters = JSON.parse(
            cleanFilters.CustomFieldFilters
        );
    }
    const payload = {
        PaymentPlanManagementFilter: {
            ...cleanFilters,
            PaymentPlanIds: paymentPlanIds,
            ExcludePaymentPlans: excludePaymentPlans,
        }
    };

    try {
        yield call(
            [API, 'post'],
            API_NAME,
            '/paymentplan/resend-approval',
            {
                body: payload,
            }
        );
        if (callback) {
            const response = {
                IsSuccess: true
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called when action manual create payment plan tasks.
 * @param param0
 */
function* handleActionManualCreatePaymentPlansRequest({ payload: sagaPayload }: DynamicObject) {
    const { 
        InvoiceIds,
        InstalmentPayments,
        DepositPercentage,
        DepositAmount,
        DepositDueDate,
        callback,
    } = sagaPayload;

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/paymentplan/manual/create',
            {
                body: {
                    InvoiceIds,
                    InstalmentPayments,
                    DepositPercentage,
                    DepositAmount,
                    DepositDueDate,
                    callback,
                },
            }
        );
        const paymentPlan = res;
        let successMessages: any = [];
        if (paymentPlan) {
            successMessages.push(`The payment plan is created successfully!`);
        }
        if (callback) {
            const response = {
                IsSuccess: true,
                PaymentPlan: paymentPlan,
                Messages: successMessages,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function for edit the paymentPlan schedule.
 * @param param0
 */
function* handleUpdatePaymentPlanScheduleRequest({ payload: sagaPayload }: DynamicObject) { 
    const { payload, callback } =
        sagaPayload;

    try {
        const res: DynamicObject =  yield call(
            [API, 'post'],
            API_NAME,
            '/paymentplan/schedule/edit',
            {
                body: {
                    InstalmentPayments: payload
                }
            }
        );
       
        let successMessages: any = [];
        if (res) {
            successMessages.push(`The payment plan schedule is updated successfully!`);
        }
        if (callback) {
            const response = {
                IsSuccess: true,
                Messages: successMessages,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetPaymentPlansRequest() {
    yield takeLatest(
        PaymentPlansActionTypes.GET_PAYMENT_PLANS_REQUEST,
        handleGetPaymentPlansRequest
    );
}

function* watchGetPaymentPlanDataRequest() {
    yield takeLatest(
        PaymentPlansActionTypes.GET_PAYMENT_PLAN_DATA_REQUEST,
        handleGetPaymentPlanDataRequest
    );
}

function* watchSetPaymentPlanSelectedIdRequest() {
    yield takeLatest(
        PaymentPlansActionTypes.SET_PAYMENT_PLAN_SELECTED_ID_REQUEST,
        handleSetPaymentPlanSelectedIdRequest
    );
}

function* watchGetPaymentPlanDataInvoicesRequest() {
    yield takeLatest(
        PaymentPlansActionTypes.GET_PAYMENT_PLAN_INVOICES_REQUEST,
        handleGetPaymentPlanDataInvoicesRequest
    );
}

function* watchGetPaymentPlanDataScheduleRequest() {
    yield takeLatest(
        PaymentPlansActionTypes.GET_PAYMENT_PLAN_DATA_SCHEDULE_REQUEST,
        handleGetPaymentPlanDataScheduleRequest
    );
}

function* watchGetPaymentPlanConversationRequest() {
    yield takeLatest(
        PaymentPlansActionTypes.GET_PAYMENT_PLAN_CONVERSATION_REQUEST,
        handleGetPaymentPlanConversationRequest
    );
}

function* watchPaymentPlanAddCommentRequest() {
    yield takeLatest(
        PaymentPlansActionTypes.PAYMENT_PLAN_ADD_COMMENT_REQUEST,
        handlePaymentPlanAddCommentRequest
    );
}

function* watchCreatePaymentPlan() {
    yield takeLatest(
        PaymentPlansActionTypes.CREATE_PAYMENT_PLAN,
        handleCreatePaymentPlan
    );
}

function* watchAssignPaymentPlan() {
    yield takeLatest(
        PaymentPlansActionTypes.FETCH_PAYMENT_PLAN_CONFIGURATIONS,
        handleAssignPaymentPlan
    );
}

function* watchFetchPaymentScheduleDetailsPublic() {
    yield takeLatest(
        PaymentPlansActionTypes.FETCH_PAYMENT_PLAN_COMMUNICATION_DETAILS_PUBLIC,
        handleFetchPaymentScheduleDetailsPublic
    );
}

function* watchActionPaymentPlanRequest() {
    yield takeLatest(
        PaymentPlansActionTypes.ACTION_PAYMENT_PLAN_REQUEST,
        handleActionPaymentPlanRequest
    );
}

function* watchActionPaymentPlansRequest() {
    yield takeLatest(
        PaymentPlansActionTypes.ACTION_PAYMENT_PLANS_REQUEST,
        handleActionPaymentPlansRequest
    );
}

function* watchCheckPaymentPlansActionStateRequest() {
    yield takeLatest(
        PaymentPlansActionTypes.CHECK_PAYMENT_PLANS_ACTION_STATE,
        handleCheckPaymentPlansActionStateRequest
    );
}

function* watchCancelPaymentPlanRequest() {
    yield takeLatest(
        PaymentPlansActionTypes.CANCEL_PAYMENT_PLAN_REQUEST,
        handleCancelPaymentPlanRequest
    );
}

function* watchResendPaymentPlanApprovalRequest() {
    yield takeLatest(
        PaymentPlansActionTypes.RESEND_PAYMENT_PLAN_APPROVAL_REQUEST,
        handleResendPaymentPlanApprovalRequest
    );
}

function* watchActionManualCreatePaymentPlanRequest() {
    yield takeLatest(
        PaymentPlansActionTypes.ACTION_MANUAL_CREATE_NEW_PAYMENT_PLAN_REQUEST,
        handleActionManualCreatePaymentPlansRequest
    );
}

function* watchUpdatePaymentPlanScheduleRequest() {
    yield takeLatest(
        PaymentPlansActionTypes.UPDATE_PAYMENT_PLAN_SCHEDULE,
        handleUpdatePaymentPlanScheduleRequest
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* paymentPlansSaga() {
    yield all([
        fork(watchGetPaymentPlansRequest),
        fork(watchGetPaymentPlanDataRequest),
        fork(watchSetPaymentPlanSelectedIdRequest),
        fork(watchGetPaymentPlanDataInvoicesRequest),
        fork(watchGetPaymentPlanDataScheduleRequest),
        fork(watchGetPaymentPlanConversationRequest),
        fork(watchPaymentPlanAddCommentRequest),
        fork(watchCreatePaymentPlan),
        fork(watchFetchPaymentScheduleDetailsPublic),
        fork(watchActionPaymentPlanRequest),
        fork(watchCancelPaymentPlanRequest),
        fork(watchResendPaymentPlanApprovalRequest),
        fork(watchCheckPaymentPlansActionStateRequest),
        fork(watchAssignPaymentPlan),
        fork(watchActionPaymentPlansRequest),
        fork(watchActionManualCreatePaymentPlanRequest),
        fork(watchUpdatePaymentPlanScheduleRequest),
    ]);
}

export default paymentPlansSaga;
