import { Button, Checkbox, Col, Row, Tooltip } from 'antd';
import {
    capitalize,
    get,
    includes,
    isEmpty,
    isEqual,
    lowerCase,
} from 'lodash';
import React, { memo } from 'react';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import { ScheduledReport } from '../../store/scheduledReports/types';
import { getSelectedScheduledReportId } from '../../store/scheduledReports/sagas';
import { useSelector } from 'react-redux';
import AvatarNameInitialsComponent from '../common/AvatarNameInitialsComponent';
import { ReportStatus, ReportType} from '../../constants/reportSortAndFilters';
import { dateFormatDoMMMYYYYHHmmSpace } from '../../constants/dateFormats';
import FontAwesome from '../common/FontAwesome';
import { getCurrentUser } from '../../store/users/sagas';
import { IsOrganisationViewAttribute } from '../../constants/authUserAttributes';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { getTranslatedText } from '../../utils/commonFunctions';

interface IProps {
    readonly item: ScheduledReport;
    readonly style: {};
    readonly onRowClick: (item: DynamicObject, drawerType?: string) => void;
    readonly onCheckboxClick: (item: DynamicObject) => void;
    readonly selectedRowKeys: [];
    readonly formatDateLocal: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
    readonly formatDateUTCToLocal: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
    readonly downloadReportHandler: (report: ScheduledReport) => void
}

const ScheduledReportItemComponent: React.FC<IProps> = ({
    item,
    style,
    onRowClick,
    onCheckboxClick,
    selectedRowKeys,
    formatDateLocal,
    formatDateUTCToLocal,
    downloadReportHandler
}: IProps) => {
    const selectedId = useSelector(getSelectedScheduledReportId);
    const currentUser = useSelector(getCurrentUser);
    const isOrgView = get(currentUser, IsOrganisationViewAttribute) === '1';
    const {
        ScheduleId,
        LastReportLocalDateTime,
        LastReportDateTime,
        NextReportLocalDateTime,
        NextReportDateTime,
        Description,
        Type,
        User,
        Company,
        LastReportStatus
    } = item;

    const handleRowClick = () => {
        const itemInfo: DynamicObject = { ...item, key: ScheduleId };
        if (onRowClick) onRowClick(itemInfo);
    };
    const customerLabel = useSelector(getCustomerUILabel);

     /**
     * Function called when checkbox is ticked.
     * @param e
     */
     const handleCheckboxClick = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.stopPropagation();
        const itemInfo: DynamicObject = { ...item, key: ScheduleId };
        if (onCheckboxClick) onCheckboxClick(itemInfo);
    };

    const handleDownloadReport = (e: any) => {
        e.stopPropagation();
        downloadReportHandler(item);
    }

    const UserName = (() => {
        let name: string = '';
        if (get(User, 'GivenName')) {
            name += get(User, 'GivenName');
        }
        if (get(User, 'FamilyName')) {
            if (!isEmpty(name)) {
                name += ' ';
            }
            name += get(User, 'FamilyName');
        }
        return name;
    })();


    const ReportTitle = (() => {
        const parts = getTranslatedText(Description).split(' ');
        let typeDisplay: string | undefined;
        switch (Type) {
            case ReportType.Page: typeDisplay = getTranslatedText('Page'); break;
            case ReportType.Dashboard: typeDisplay = getTranslatedText('Dashboard'); break;
        }
        if (typeDisplay) {
            parts.push(typeDisplay);
        }
        parts.push(getTranslatedText('Report'));
        return parts.join(' ');
    })();

    const getStatusMessage = (status: string) => {
        if (status === "") 
            return '';
        else
            return getTranslatedText(`Report ${lowerCase(status)}`);
    };
    
    return (
        <Row
            className={`list-item-clickable ${selectedId === ScheduleId ? 'list-item-clicked' : ''
                }`}
            key={ScheduleId}
            style={style}
            onClick={handleRowClick}
        >
            <Col
                span={24}
                style={{ height: '100%', paddingTop: 12, paddingBottom: 12 }}
            >
                <Row type="flex" align="middle" style={{ height: '100%' }}>
                    <Col xl={1} lg={0} md={0} sm={0} xs={0} style={{paddingLeft: 7}}>
                    <div>
                            <Checkbox
                                checked={includes(selectedRowKeys, ScheduleId)}
                                onClick={handleCheckboxClick}
                            />
                        </div>
                    </Col>
                    <Col xl={4} lg={5} md={5} sm={5} xs={5} className="pl-10-i">
                    <Row>
                        {getTranslatedText("Next")}: {NextReportDateTime ? formatDateUTCToLocal(
                                NextReportDateTime,
                                undefined,
                                dateFormatDoMMMYYYYHHmmSpace
                                )  : '--'}
                    </Row>
                    <Row>
                    {getTranslatedText("Prev")}: {LastReportDateTime ? formatDateUTCToLocal(
                                LastReportDateTime,
                                undefined,
                                dateFormatDoMMMYYYYHHmmSpace
                                )  : '--'}
                    </Row>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                        {getTranslatedText(ReportTitle.includes('Customer') ? ReportTitle.replace('Customer', `${capitalize(customerLabel)}`): ReportTitle)}
                    </Col>
                    <Col xl={5} lg={6} md={6} sm={6} xs={6}>
                    {getStatusMessage(LastReportStatus)}
                        {LastReportStatus === ReportStatus.Complete &&
                            <Tooltip title={getTranslatedText('Download report')} placement="topRight">
                                <Button
                                    type="link"
                                    onClick={handleDownloadReport}
                                >
                                    <FontAwesome
                                        icon={['fas', 'cloud-download-alt']}
                                        size='lg'
                                    />
                                </Button>
                            </Tooltip>
                        }
                    </Col>
                    <Col
                        className="ta-right pr-10"
                        xl={4}
                        lg={3}
                        md={3}
                        sm={3}
                        xs={3}
                    >
                        {isOrgView && get(Company, 'Name')}
                    </Col>
                    <Col
                        xl={4}
                        lg={3}
                        md={3}
                        sm={3}
                        xs={3}
                        className="ta-right pr-10"
                    >
                        <span className='mr-10'>{UserName}</span>
                        <AvatarNameInitialsComponent
                            fullName={UserName}
                            size="large"
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

const arePropsEqual = (prevProps: any, nextProps: any) => {
    if (
        JSON.stringify(prevProps.item) === JSON.stringify(nextProps.item) &&
        prevProps.selectedId === nextProps.selectedId &&
        isEqual(prevProps.selectedRowKeys, nextProps.selectedRowKeys) &&
        JSON.stringify(prevProps.style) === JSON.stringify(nextProps.style)
    ) {
        return true;
    } else {
        return false;
    }
};

export default memo(
    withDateFormatHandler(withNumberFormatHandler(ScheduledReportItemComponent)),
    arePropsEqual
);
