import { action } from 'typesafe-actions';
import { DynamicObject } from '../../utils/commonInterfaces';
import {
    Conversation,
    GetConversationRequestPayload,
    PageData,
} from '../common/types';
import { GetInvoicesRequestPayload, Invoice } from '../invoices/types';
import {
    Credit,
    CreditAddCommentRequestPayload,
    CreditChanges,
    CreditsActionTypes,
    DeleteCreditManuallyRequestPayload,
    GetCreditChangesRequestPayload,
    GetCreditsRequestPayload,
    GetRebatesRequestPayload,
    RequestCreateCreditManuallyPayload,
    RequestEditCreditManuallyPayload
} from './types';

/**
 * Actions for fetching the credits list.
 * @param payload
 */
export const getCreditsRequestAction = (payload: GetCreditsRequestPayload) =>
    action(CreditsActionTypes.GET_CREDITS_REQUEST, payload);
export const getCreditsForOrganisationRequestAction = (payload: GetCreditsRequestPayload) =>
    action(CreditsActionTypes.GET_CREDITS_FOR_ORGANISATION_REQUEST, payload);
export const getCreditsSuccessAction = (payload: {
    data: Credit[];
    pageData: PageData;
}) => action(CreditsActionTypes.GET_CREDITS_SUCCESS, payload);
export const getCreditsErrorAction = (payload: string[]) =>
    action(CreditsActionTypes.GET_CREDITS_ERROR, payload);

    /**
 * Actions for fetching the rebates list.
 * @param payload
 */
export const getRebatesRequestAction = (payload: GetRebatesRequestPayload) =>
action(CreditsActionTypes.GET_REBATES_REQUEST, payload);
export const getRebatesSuccessAction = (payload: {
data: Credit[];
pageData: PageData;
}) => action(CreditsActionTypes.GET_REBATES_SUCCESS, payload);
export const getRebatesErrorAction = (payload: string[]) =>
action(CreditsActionTypes.GET_REBATES_ERROR, payload);

/**
 * Action called for updating the filters on Credits management page.
 * @param filters
 */
export const updateCreditsFiltersAction = (
    filters: GetCreditsRequestPayload['filters']
) => action(CreditsActionTypes.UPDATE_CREDITS_FILTERS, filters);

/**
 * Action called for changing the sort by, sort order, and state for Credits list.
 * @param tableSortAndState
 */
export const updateCreditsSortByAndStateAction = (tableSortAndState: {
    sortBy: GetCreditsRequestPayload['sortBy'];
    sortAscending: boolean;
    creditState: string;
}) =>
    action(
        CreditsActionTypes.UPDATE_CREDITS_SORT_BY_AND_STATE,
        tableSortAndState
    );

/**
 * Action called when changing the credits view filter.
 * @param creditState
 */
export const updateCreditsTableFilterAction = (creditState: string) =>
    action(CreditsActionTypes.UPDATE_CREDITS_TABLE_FILTER_STATE, creditState);

/**
 * Action for resetting table filters and sort values.
 */
export const clearCreditsStateAllTableFiltersAction = () =>
    action(CreditsActionTypes.CLEAR_CREDITS_STATE_ALL_TABLE_FILTERS);
/**
 * Action called for clearing the Credits state.
 */
export const clearCreditsStateDataAction = () =>
    action(CreditsActionTypes.CLEAR_CREDITS_STATE_DATA);

export const clearCreditsListData = () =>
    action(CreditsActionTypes.CLEAR_CREDITS_LIST_DATA);

// Single Record
/**
 * Action sfor setting the selected credit Id when clicking on a credit table row item.
 * @param creditId
 * @param callback
 */
export const setCreditSelectedIdRequestAction = (
    creditId: string | null,
    callback: () => void
) =>
    action(CreditsActionTypes.SET_CREDIT_SELECTED_ID_REQUEST, {
        creditId,
        callback,
    });
export const setCreditSelectedIdSuccessAction = (creditId: string | null) =>
    action(CreditsActionTypes.SET_CREDIT_SELECTED_ID_SUCCESS, creditId);

/**
 * Actions for getting the details for the selected single credit.
 */
export const getCreditDataRequestAction = (
    creditId: string,
    isUsingCloudImportType: boolean
) =>
    action(CreditsActionTypes.GET_CREDIT_DATA_REQUEST, {
        creditId,
        isUsingCloudImportType,
    });
export const getOrganisationCreditDataRequestAction = (
    creditId: string,
    region: string,
    isUsingCloudImportType: boolean
) =>
    action(CreditsActionTypes.GET_ORGANISATION_CREDIT_DATA_REQUEST, {
        creditId,
        region,
        isUsingCloudImportType,
    });
export const getCreditDataSuccessAction = (payload: { record: {} }) =>
    action(CreditsActionTypes.GET_CREDIT_DATA_SUCCESS, payload);
export const getCreditDataErrorAction = (payload: string[]) =>
    action(CreditsActionTypes.GET_CREDIT_DATA_ERROR, payload);

/**
 * Actions for fetching the credits list.
 * @param payload
 */
export const getCreditsByCreditCodesRequestAction = (
    payload: any,
    callback?: Function
) =>
    action(CreditsActionTypes.GET_CREDITS_BY_CREDIT_CODES_REQUEST, { payload, callback });
export const getCreditsByCreditCodesSuccessAction = (payload: {
    data: Credit[];
}) => action(CreditsActionTypes.GET_CREDITS_BY_CREDIT_CODES_SUCCESS, payload);
export const getCreditsByCreditCodesErrorAction = (payload: string[]) =>
    action(CreditsActionTypes.GET_CREDITS_BY_CREDIT_CODES_ERROR, payload);

/**
 * Action for clearing the details of a selected credit.
 */
export const clearCreditDataAction = () =>
    action(CreditsActionTypes.CLEAR_CREDIT_DATA_SUCCESS);

// Invoice List Actions
export const getCreditInvoicesRequestAction = (
    payload: GetInvoicesRequestPayload
) => action(CreditsActionTypes.GET_CREDIT_INVOICES_REQUEST, payload);
export const getCreditInvoicesSuccessAction = (payload: {
    data: Invoice[];
    pageData: PageData;
}) => action(CreditsActionTypes.GET_CREDIT_INVOICES_SUCCESS, payload);
export const getCreditInvoicesErrorAction = (payload: string[]) =>
    action(CreditsActionTypes.GET_CREDIT_INVOICES_ERROR, payload);

export const updateCreditInvoicesFiltersAction = (
    filters: GetInvoicesRequestPayload['filters']
) => action(CreditsActionTypes.UPDATE_CREDIT_INVOICES_FILTERS, filters);
export const updateCreditInvoicesSortByAndStateAction = (tableSortAndState: {
    sortBy: GetInvoicesRequestPayload['sortBy'];
    sortAscending: boolean;
    invoiceState: string;
}) =>
    action(
        CreditsActionTypes.UPDATE_CREDIT_INVOICES_SORT_BY_AND_STATE,
        tableSortAndState
    );
export const updateCreditInvoicesTableFilterAction = (invoiceState: string) =>
    action(
        CreditsActionTypes.UPDATE_CREDIT_INVOICES_TABLE_FILTER_STATE,
        invoiceState
    );

// Conversation actions
export const getCreditConversationRequestAction = (
    payload: GetConversationRequestPayload
) => action(CreditsActionTypes.GET_CREDIT_CONVERSATION_REQUEST, payload);
export const getCreditConversationSuccessAction = (payload: {
    data: Conversation[];
    pageData: PageData;
}) => action(CreditsActionTypes.GET_CREDIT_CONVERSATION_SUCCESS, payload);
export const getCreditConversationErrorAction = (payload: string[]) =>
    action(CreditsActionTypes.GET_CREDIT_CONVERSATION_ERROR, payload);

export const updateCreditConversationFiltersAction = (
    filters: GetConversationRequestPayload['filters']
) => action(CreditsActionTypes.UPDATE_CREDIT_CONVERSATION_FILTERS, filters);

export const updateCreditConversationTableFilterAction = (
    conversationTableFilter: string
) =>
    action(
        CreditsActionTypes.UPDATE_CREDIT_CONVERSATION_TABLE_FILTER,
        conversationTableFilter
    );
export const updateCreditConversationSortByAction = (tableSort: {
    sortBy: GetConversationRequestPayload['sortBy'];
    sortAscending: boolean;
}) => action(CreditsActionTypes.UPDATE_CREDIT_CONVERSATION_SORT_BY, tableSort);

export const creditAddCommentRequestAction = (
    payload: CreditAddCommentRequestPayload
) => action(CreditsActionTypes.CREDIT_ADD_COMMENT_REQUEST, payload);

export const deleteCreditManuallyRequestAction = (
    payload: DeleteCreditManuallyRequestPayload
) => action(CreditsActionTypes.DELETE_CREDIT_MANUALLY_REQUEST, payload);

// CreditChanges actions
/**
 * Action for getting the credit changes list.
 * @param payload
 */
export const getCreditChangesRequestAction = (
    payload: GetCreditChangesRequestPayload
) => action(CreditsActionTypes.GET_CREDIT_CREDIT_CHANGES_REQUEST, payload);
export const getOrganisationCreditChangesRequestAction = (
    payload: GetCreditChangesRequestPayload,
    region: string
) => action(CreditsActionTypes.GET_ORGANISATION_CREDIT_CREDIT_CHANGES_REQUEST, {
    payload,
    region
});
export const getCreditChangesSuccessAction = (payload: {
    data: CreditChanges[];
    pageData: PageData;
}) => action(CreditsActionTypes.GET_CREDIT_CREDIT_CHANGES_SUCCESS, payload);
export const getCreditChangesErrorAction = (payload: string[]) =>
    action(CreditsActionTypes.GET_CREDIT_CREDIT_CHANGES_ERROR, payload);

export const updateCreditChangesFiltersAction = (
    filters: GetCreditChangesRequestPayload['filters']
) => action(CreditsActionTypes.UPDATE_CREDIT_CREDIT_CHANGES_FILTERS, filters);

export const updateCreditChangesTableFilterAction = (
    creditChangesTableFilter: string
) =>
    action(
        CreditsActionTypes.UPDATE_CREDIT_CREDIT_CHANGES_TABLE_FILTER,
        creditChangesTableFilter
    );
export const updateCreditChangesSortByAction = (tableSort: {
    sortBy: GetCreditChangesRequestPayload['sortBy'];
    sortAscending: boolean;
}) =>
    action(CreditsActionTypes.UPDATE_CREDIT_CREDIT_CHANGES_SORT_BY, tableSort);

/**
 * Function that prepares the download file for credit reconciliation report
 * @param payload
 */
export const getCreditsReconciliationReportRequestAction = (
    payload: DynamicObject
) =>
    action(
        CreditsActionTypes.GET_CREDITS_RECONCILIATION_REPORT_REQUEST,
        payload
    );

/**
* Function for getting the Credits Custom Fields for a company.
* @param payload
*/
export const getCreditCustomFieldsForCompanyRequestAction = (
    companyId: string,
    callback?: (response: DynamicObject) => void
) =>
    action(CreditsActionTypes.GET_CREDIT_CUSTOM_FIELDS_FOR_COMPANY_REQUEST, {
        companyId,
        callback,
    });
/** Function for create credits or rebates manually
 * @param payload
 */
export const createCreditManuallyRequestAction = (
    payload: RequestCreateCreditManuallyPayload
) => action(CreditsActionTypes.CREATE_CREDIT_MANUALLY_REQUEST, payload);

/**
 * Function for edit credit or rebate manually
 * @param payload
 */
export const editCreditManuallyRequestAction = (
    payload: RequestEditCreditManuallyPayload
) => action(CreditsActionTypes.EDIT_CREDIT_MANUALLY_REQUEST, payload);

