/**
 * Component for populating the customer assistance row items.
 */

import { Checkbox, Col, Row } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import { get, includes, isEqual, isUndefined, map, isEmpty } from 'lodash';
import React, { Fragment, memo } from 'react';
import { useSelector } from 'react-redux';
import '../../../pages/sales/sales.less';
import { getNumberOfDaysBeforeRecentCommentTurnedOff } from '../../../store/companies/sagas';
import { getSelectedTicketId } from '../../../store/tickets/sagas';
import { Ticket } from '../../../store/tickets/types';
import { CustomField } from '../../../store/common/types';
import { getCompanyName, getIfIsLg, getTranslatedText } from '../../../utils/commonFunctions';
import { DynamicObject } from '../../../utils/commonInterfaces';
import { withDateFormatHandler } from '../../common/DateFormatHandler';
import { withNumberFormatHandler } from '../../common/NumberFormatHandler';
import {
    generateAttachmentsIcon,
    generateCommentItemWithType
} from '../../common/SharedExportComponents';
import { withTicketIconsHandler } from '../../common/TicketIconsHandler';
import AvatarNameInitialsComponent from '../../common/AvatarNameInitialsComponent';

interface IProps {
    readonly item: Ticket;
    readonly style: {};
    readonly onRowClick: (item: DynamicObject, activeTab?: string) => void;
    readonly onCheckboxClick: (item: DynamicObject) => void;
    readonly selectedRowKeys: [];
    readonly formatCurrency?: (amount: number) => JSX.Element;
    readonly formatDateUTCToLocal?: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
    readonly generateTicketIcon?: (
        type: string,
        iconColor: string,
        fontSizeProp?: number | string | null
    ) => JSX.Element | undefined;
}
const CustomerAssistanceItemComponent: React.FC<IProps> = ({
    item,
    style,
    onRowClick,
    onCheckboxClick,
    selectedRowKeys,
    formatDateUTCToLocal,
    generateTicketIcon,
}: IProps) => {
    const selectedId = useSelector(getSelectedTicketId);
    
    const numberOfDaysBeforeRecentCommentTurnedOff: number = useSelector(getNumberOfDaysBeforeRecentCommentTurnedOff);

    const {
        Id,
        TicketNumber,
        TicketOption,
        Customer,
        CreatedDateTime,
        ResolvedDateTime,
        State,
        Attachments,
        ConversationLine,
        ConversationChatLine
    } = item;

    const CustomerCode = get(Customer, 'CustomerCode');
    const CustomeFields = get(Customer, 'CustomFields');

    const RaisedDateTimeLocal = formatDateUTCToLocal
        ? formatDateUTCToLocal(CreatedDateTime)
        : CreatedDateTime;

    const ResolvedDateTimeLocal = formatDateUTCToLocal
        ? formatDateUTCToLocal(ResolvedDateTime)
        : ResolvedDateTime;

    const isClosed = State === 'Closed';

    /**
     * Function called when checkbox is ticked.
     * @param e
     */
    const handleCheckboxClick = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.stopPropagation();
        const itemInfo: DynamicObject = item;
        itemInfo.key = Id;
        if (onCheckboxClick) onCheckboxClick(itemInfo);
    };
    /**
         * Function that populates the custom fields section
         */
    const populateCustomFields = () => {
        const customFieldsDivElements = map(
            CustomeFields,
            ({ Name, Value }: CustomField) => (
                <Col key={`${Name}-${Value}`}>
                    <span className="mr-8">{Name}:</span>
                    <span>{Value}</span>
                </Col>
            )
        );
        return (
            <Row className="center-flex-i fw-w" gutter={30}>
                {customFieldsDivElements}
            </Row>
        );
    };
    /**
     * Common function that gets the row data and adds an identifier named `key`.
     */
    const getItemInfo = () => {
        const itemInfo: any = item;
        itemInfo.key = Id;

        return itemInfo;
    };

    /**
     * Function called when row item is clicked.
     */
    const handleRowClick = () => {
        const itemInfo = getItemInfo();
        if (onRowClick) onRowClick(itemInfo);
    };

    const givenName = get(item, 'AssignedUser.GivenName');
    const familyName = get(item, 'AssignedUser.FamilyName');
    const assignedEmail = get(item, 'AssignedEmailAddress');
    let name = '';
    if (givenName && familyName) {
        name = `${givenName} ${familyName}`;
    } else if (assignedEmail) {
        name = assignedEmail;
    }

    const assignedToUserSection = name && (
        <Row>
            <Col>{getTranslatedText('Assigned to')}: { name }</Col>
        </Row>
    );

    const populateAvatar = (
        size: number = 45
    ) => {
        return (
            <AvatarNameInitialsComponent
                fullName={name}
                size={size}
                showTooltip
                isDefault={!isEmpty(assignedEmail)}
                fontSize={18}
            />
        );
    };

    /**
     * Function called when clicking on the `latest comment` icon.
     * @param e
     */
    const viewCommentsSectionDrawer = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.stopPropagation();
        const itemInfo = getItemInfo();
        if (onRowClick) onRowClick(itemInfo, 'conversation');
    };

    /**
     * Function called for generating the ticket item icon if `generateTicketIcon` HOC function exists.
     */
    const generateItemIcon = () => {
        let iconColor = 'green';
        if (isClosed) iconColor = 'settled';

        if (generateTicketIcon) {
            return generateTicketIcon(get(TicketOption, 'Type'), iconColor);
        }
    };

    const isLg = getIfIsLg();

    const secondRowStyle: DynamicObject | undefined = isLg
        ? { height: 42, display: 'flex', alignItems: 'center' }
        : undefined;
    const secondRowStyleAvatar: DynamicObject | undefined = isLg
        ? { ...secondRowStyle, justifyContent: 'flex-end' }
        : undefined;

    return (
        <Row
            className={`list-item-clickable ${selectedId === Id ? 'list-item-clicked' : ''
                }`}
            key={Id}
            style={style}
            onClick={handleRowClick}
        >
            <Col
                span={24}
                style={{
                    height: '100%',
                    paddingTop: 10,
                    paddingBottom: 10,
                }}
            >
                <Row
                    type="flex"
                    align="middle"
                    className="pr-8 h-100"
                    gutter={{ xxl: 10, xl: 10, lg: 20 }}
                >
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="h-100">
                        <Row className="h-100 fw-w" type="flex" align="middle">
                            <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                                <div className="ta-center center-flex-all">
                                    <Checkbox
                                        checked={includes(selectedRowKeys, Id)}
                                        onClick={handleCheckboxClick}
                                    />
                                </div>
                            </Col>
                            <Col
                                xl={12}
                                lg={24}
                                md={24}
                                sm={24}
                                xs={24}
                                className="ta-center"
                            >
                                <div className="ta-center center-flex-all">
                                    {generateItemIcon()}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={22} lg={22} md={22} sm={22} xs={22}>
                        <Row type="flex">
                            <Col span={24}>
                                <div>
                                    <Row type="flex" align="middle">
                                        <Col
                                            xl={12}
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                        >
                                            <Row className="ticket-customer-container">
                                                <Col
                                                    lg={8}
                                                    md={8}
                                                    sm={8}
                                                    xs={8}
                                                >
                                                    <Row>
                                                        <Col>
                                                            <b>
                                                                #{TicketNumber}
                                                            </b>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col
                                                    lg={16}
                                                    md={16}
                                                    sm={16}
                                                    xs={16}
                                                >
                                                    <Row>
                                                        <Col>
                                                            <b>
                                                                {getTranslatedText(get(
                                                                    TicketOption,
                                                                    'Reason'
                                                                )) || (
                                                                        <div>
                                                                            &nbsp;
                                                                        </div>
                                                                    )}
                                                            </b>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {!isUndefined(CustomerCode) && (
                                                    <Col
                                                        lg={8}
                                                        md={8}
                                                        sm={8}
                                                        xs={8}
                                                    // style={secondRowStyle}
                                                    >
                                                        {CustomerCode}
                                                    </Col>
                                                )}
                                                <Col
                                                    lg={16}
                                                    md={16}
                                                    sm={16}
                                                    xs={16}
                                                >
                                                    <Col>
                                                        {getCompanyName(
                                                            Customer
                                                        )}
                                                    </Col>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col
                                                    xl={24}
                                                    lg={0}
                                                    md={0}
                                                    sm={0}
                                                    xs={0}
                                                >
                                                    {populateCustomFields()}
                                                </Col>

                                            </Row>
                                        </Col>
                                        <Col
                                            xl={12}
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            className="raised-with-avatar-container"
                                        >
                                            <Row
                                                className="ta-right"
                                                type="flex"
                                                justify="end"
                                                gutter={{
                                                    xl: 8,
                                                }}
                                            >
                                                <Col
                                                    xl={13}
                                                    lg={17}
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <Row type="flex" gutter={[10, 10]}>
                                                        <Col>
                                                            <div>
                                                                <ButtonGroup className="button-link-icons">
                                                                    {generateAttachmentsIcon(
                                                                        Attachments
                                                                    )}
                                                                    {ConversationChatLine &&
                                                                        generateCommentItemWithType(
                                                                            ConversationChatLine,
                                                                            'chat',
                                                                            numberOfDaysBeforeRecentCommentTurnedOff,
                                                                            viewCommentsSectionDrawer
                                                                        )}
                                                                    {ConversationLine &&
                                                                        generateCommentItemWithType(
                                                                            ConversationLine,
                                                                            'ticket',
                                                                            numberOfDaysBeforeRecentCommentTurnedOff,
                                                                            viewCommentsSectionDrawer
                                                                        )}
                                                                </ButtonGroup>
                                                            </div>
                                                        </Col>
                                                        <Col className="fx-1">
                                                            <Row gutter={[10, 10]}>
                                                                <Col
                                                                    xxl={12}
                                                                    xl={24}
                                                                    lg={24}
                                                                    md={24}
                                                                    sm={24}
                                                                    xs={24}
                                                                >
                                                                    <Row>
                                                                        {isClosed ? (
                                                                            <Col>
                                                                                <b>
                                                                                    {getTranslatedText('Resolved')}{' '}
                                                                                    {
                                                                                        ResolvedDateTimeLocal
                                                                                    }
                                                                                </b>
                                                                            </Col>
                                                                        ) : null}
                                                                    </Row>
                                                                </Col>
                                                                <Col
                                                                    xxl={12}
                                                                    xl={24}
                                                                    lg={24}
                                                                    md={24}
                                                                    sm={24}
                                                                    xs={24}
                                                                >
                                                                    <b>
                                                                        {getTranslatedText('Raised')}{' '}
                                                                        {
                                                                            RaisedDateTimeLocal
                                                                        }
                                                                    </b>
                                                                </Col>                                         
                                                                <Col
                                                                    className={`${
                                                                        isLg ? 'fx-1a' : ''
                                                                    } ta-right`}
                                                                >
                                                                    {assignedToUserSection}
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col
                                                            className="ta-right pr-8"
                                                            style={{ width: 60 }}
                                                        >
                                                            {populateAvatar()}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

const arePropsEqual = (prevProps: any, nextProps: any) => {
    if (
        JSON.stringify(prevProps.item) === JSON.stringify(nextProps.item) &&
        prevProps.selectedId === nextProps.selectedId &&
        isEqual(prevProps.selectedRowKeys, nextProps.selectedRowKeys) &&
        JSON.stringify(prevProps.style) === JSON.stringify(nextProps.style) &&
        prevProps.addCommentDrawerVisible === nextProps.addCommentDrawerVisible
    ) {
        return true;
    } else {
        return false;
    }
};

export default memo(
    withDateFormatHandler(
        withTicketIconsHandler(
            withNumberFormatHandler(CustomerAssistanceItemComponent)
        )
    ),
    arePropsEqual
);
