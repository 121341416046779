import { bankFileDescriptorsSortByOptions } from '../../constants/bankFileDescriptorsSortAndFilters';
import {
    DynamicObject,
    ResponseModalObject,
} from '../../utils/commonInterfaces';
import { PageData } from "../common/types";
import { Customer } from "../customers/types";

export enum BankFileDescriptorsActionTypes {
    GET_BANK_FILE_DESCRIPTORS_REQUEST = '@@bankFileDescriptors/GET_BANK_FILE_DESCRIPTORS_REQUEST',
    GET_BANK_FILE_DESCRIPTORS_SUCCESS = '@@bankFileDescriptors/GET_BANK_FILE_DESCRIPTORS_SUCCESS',
    GET_BANK_FILE_DESCRIPTORS_ERROR = '@@bankFileDescriptors/GET_BANK_FILE_DESCRIPTORS_ERROR',
    UPDATE_BANK_FILE_DESCRIPTORS_FILTERS = '@@bankFileDescriptors/UPDATE_BANK_FILE_DESCRIPTORS_FILTERS',
    UPDATE_BANK_FILE_DESCRIPTORS_SORT_BY = '@@bankFileDescriptors/UPDATE_BANK_FILE_DESCRIPTORS_SORT_BY',
    UPDATE_BANK_FILE_DESCRIPTORS_TABLE_FILTER_STATE = '@@bankFileDescriptors/UPDATE_BANK_FILE_DESCRIPTORS_TABLE_FILTER_STATE',
    CLEAR_BANK_FILE_DESCRIPTORS_STATE_ALL_TABLE_FILTERS = '@@bankFileDescriptors/CLEAR_BANK_FILE_DESCRIPTORS_STATE_ALL_TABLE_FILTERS',

    SET_BANK_FILE_DESCRIPTOR_SELECTED_ID_REQUEST = '@@bankFileDescriptors/SET_BANK_FILE_DESCRIPTOR_SELECTED_ID_REQUEST',
    SET_BANK_FILE_DESCRIPTOR_SELECTED_ID_SUCCESS = '@@bankFileDescriptors/SET_BANK_FILE_DESCRIPTOR_SELECTED_ID_SUCCESS',
    GET_BANK_FILE_DESCRIPTOR_DATA_REQUEST = '@@bankFileDescriptors/GET_BANK_FILE_DESCRIPTOR_DATA_REQUEST',
    GET_BANK_FILE_DESCRIPTOR_DATA_SUCCESS = '@@bankFileDescriptors/GET_BANK_FILE_DESCRIPTOR_DATA_SUCCESS',
    GET_BANK_FILE_DESCRIPTOR_DATA_ERROR = '@@bankFileDescriptors/GET_BANK_FILE_DESCRIPTOR_DATA_ERROR',
    CLEAR_BANK_FILE_DESCRIPTOR_DATA_SUCCESS = '@@bankFileDescriptors/CLEAR_BANK_FILE_DESCRIPTOR_DATA_SUCCESS',
    CLEAR_BANK_FILE_DESCRIPTORS_LIST_DATA = '@@bankFileDescriptors/CLEAR_BANK_FILE_DESCRIPTORS_LIST_DATA',

    CREATE_OR_EDIT_BANK_FILE_DESCRIPTORS_REQUEST = '@@bankFileDescriptors/CREATE_OR_EDIT_BANK_FILE_DESCRIPTORS_REQUEST',
    DELETE_BANK_FILE_DESCRIPTOR_REQUEST = '@@bankFileDescriptors/DELETE_BANK_FILE_DESCRIPTOR_REQUEST'
}

export interface BankFileDescriptorsState {
    readonly loading: boolean;
    readonly saveBankFileDescriptorDataLoading: boolean;
    readonly errorMessages: string[];
    readonly data: BankFileDescriptor[];
    readonly pageData: PageData;
    readonly filters: GetBankFileDescriptorsRequestPayload['filters'];
    readonly sortBy: GetBankFileDescriptorsRequestPayload['sortBy'];
    readonly sortAscending: boolean; 
    readonly tableFilter: string | undefined;
    readonly activeData: {
        loading: boolean;
        record: {};
        selectedId: string | null;
        errorMessages: string[];
    };
}

export interface BankFileDescriptorVM {
    Id: string;
    Customer: Partial<Customer>;
    OldCustomer: Partial<Customer>;
    Descriptors: Descriptor[];
}

export interface Descriptor {
    Id: string;
    Descriptor: string;
}

export interface BankFileDescriptor {
    Id: string;
    Customer: Partial<Customer>;
    OldCustomer: Partial<Customer>;
    Descriptors: string[];
}

export interface GetBankFileDescriptorsRequestPayload {
    filters: {
        Customer: string;
        Descriptors: string[];
        CompanyIds?: string[];
    };
    tableFilter?: string;
    sortBy: typeof bankFileDescriptorsSortByOptions[number]['value'];
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
}

export interface DeleteBankFileDescriptorRequestPayload {
    filter: {};
    customerIds: string[] | number[];
    excludeCustomerIds: boolean;
    callback?: (response: ResponseModalObject) => void;
}

export interface RequestCreateOrEditBankFileDescriptorPayload {
    BankFileDescriptors: Partial<BankFileDescriptor>[];
    callback?: (response: any) => void;
}

export interface GetBankFileDescriptorChangesRequestPayload {
    filters: {};
    sortBy: string;
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
    bankFileDescriptorChangesTableFilter: string;
}

export interface BankFileDescriptorChangesState {
    readonly loading: boolean;
    readonly errorMessages: string[];
    readonly data: BankFileDescriptorVM[];
    readonly pageData: PageData;
    readonly filters: GetBankFileDescriptorChangesRequestPayload['filters'];
    readonly bankFileDescriptorChangesTableFilter: string;
    readonly sortBy: GetBankFileDescriptorChangesRequestPayload['sortBy'];
    readonly sortAscending: boolean;
}

export interface BankFileDescriptorChanges { }