/**
 * Component for populating the customer vip status editing panel.
 */

import { Button, Col, Row, Select } from 'antd';
import { get, isObject, isUndefined } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getSupportSendNewInvoice } from '../../store/companies/sagas';
import { getPopoverContainer, getTranslatedText } from '../../utils/commonFunctions';
import { newInvoiceSendModes } from '../../constants/common';


const { Option } = Select;
interface FormState {
    vip: number | undefined;
    newInvoiceSendMode?: string;
}
interface IProps {
    readonly onCancelClick: () => void;
    readonly onSaveClick: (formState: FormState) => void;
    readonly containerRef?: any;
    readonly visible?: boolean;
    readonly visibleSettings?: {
        showVip?: boolean;
        showSendMode?: boolean;
    }
}

const CustomerEditDrawerContent: React.FC<IProps> = ({
    onCancelClick,
    onSaveClick,
    containerRef,
    visible,
    visibleSettings
}: IProps) => {
    
    const initialState: FormState = {
        vip: undefined,
        newInvoiceSendMode: undefined
    };
    const [formState, setFormState] = useState<FormState>(initialState);
    const supportSendNewInvoice = useSelector(getSupportSendNewInvoice);

    /**
     * Function listener when panel is closed.
     * Clears the state and sets the initial state values.
     */
    const clearStateWhenClosedListener = () => {
        if (visible === false) {
            setFormState(initialState);
        }
    };

    useEffect(clearStateWhenClosedListener, [visible]);

    /**
     * Function called when `Cancel` button is clicked.
     */
    const onCancel = () => {
        onCancelClick();
    };

    /**
     * Function called when `Save` button is clicked.
     */
    const onSave = () => {
        onSaveClick(formState);
    };

    /**
     * Function called when updating the form state.
     * @param field - form field name
     */
    const updateFormState = (field: string) => {
        return (e: any) => {
            const value = isObject(e) ? get(e, 'target.value') : e;
            setFormState({
                ...formState,
                [field]: value,
            });
        };
    };

    /**
     * Function that gets the container for this component.
     * To make sure that the select dropdown will not be overlayed by some other component.
     */
    const getContainerRef = () => {
        return containerRef
            ? () => getPopoverContainer(containerRef)
            : undefined;
    };

    /**
     * Function for validating form before saving
     */
    const isFormValidAndChanged = () => {
        return !isUndefined(formState.vip)
            || !isUndefined(formState.newInvoiceSendMode);
    };

    return (
        <div>
            {
                (!visibleSettings || visibleSettings.showVip) &&
                <div>
                    <Row className="mb-10" type="flex" align="middle">
                        <Col className="ta-right pr-8" md={8}>
                            {getTranslatedText('VIP')}
                        </Col>
                        <Col md={16}>
                                <Select
                                    value={formState.vip}
                                    style={{ width: '100%' }}
                                    onChange={updateFormState('vip')}
                                    getPopupContainer={getContainerRef()}
                                    placeholder={getTranslatedText("Unchanged")}
                            >
                                <Option value={1}>Yes</Option>
                                <Option value={0}>No</Option>
                            </Select>
                        </Col>
                    </Row>
                </div>

            }
            {
                supportSendNewInvoice &&
                (!visibleSettings || visibleSettings.showSendMode) &&
                <div>
                    <Row className="mb-10" type="flex" align="middle">
                        <Col className="ta-right pr-8" md={8}>
                                {getTranslatedText('New invoice send mode')}
                        </Col>
                        <Col md={16}>
                            <Select
                                value={formState.newInvoiceSendMode}
                                style={{ width: '100%' }}
                                onChange={updateFormState('newInvoiceSendMode')}
                                getPopupContainer={getContainerRef()}
                                    placeholder={getTranslatedText("Unchanged")}
                            >
                                {
                                    Object.entries(newInvoiceSendModes)
                                        .map(([key, config]) => <Option key={key} value={key}>{getTranslatedText(config.display)}</Option>)
                                }
                            </Select>
                        </Col>
                    </Row>
                </div>
            }
            <div className="mt-20 ta-right">
                <Button
                    className="mr-10"
                    onClick={onSave}
                    disabled={!isFormValidAndChanged()}
                    type="primary"
                >
                    {getTranslatedText('Save')}
                </Button>
                <Button className="buttonGrey" onClick={onCancel}>
                    {getTranslatedText('Cancel')}
                </Button>
            </div>
        </div>
    );
};

export default CustomerEditDrawerContent;
