import { Button, Col, Modal, Row, Select, Typography } from "antd";
import {
    difference, filter, forEach, get, includes, isEmpty, isEqual, isUndefined, map, remove,
} from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, {
    lazy,
    RefObject,
    Suspense,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withPageViewHandler } from "../../components/common/PageViewHandler";
import VirtualizedList from "../../components/common/VirtualizedList";
import { CompanyUserRole } from "../../store/companies/types";
import BankFileDescriptorItemComponent from "../../components/bankfile/BankFileDescriptorItemComponent";
import { getRolePermissions } from '../../store/roles/sagas';
import { BANK_FILE_DESCRIPTORS_PAGE, PAGE_NAMES_FOR_VIEW } from "../../config/tableAndPageConstants";
import { CompanyIdAttribute } from '../../constants/authUserAttributes';
import {
    bankFileDescriptorsSortByOptions,
    getBankFileDescriptorsFilterBarFilters,
} from '../../constants/bankFileDescriptorsSortAndFilters';
import {
    bankFileDescriptorsSummaryPageQuery,
    bankFileDescriptorsSummaryQueryName,
    
    defaultPageSizeForReport,
} from '../../constants/downloadToExcel';
import { ApplicationState } from "../../store";
import {
    downloadToExcelAction,
} from '../../store/common/actions';
import {
    getBankFileDescriptorsRequestAction,
    setBankFileDescriptorSelectedIdRequestAction,
    updateBankFileDescriptorsFiltersAction,
    updateBankFileDescriptorsSortByAction,
    updateBankFileDescriptorsTableFilterAction,
    createOrEditBankFileDescriptorRequestAction,
    deleteBankFileDescriptorRequestAction,
    getBankFileDescriptorDataRequestAction,
} from '../../store/bankFileDescriptors/actions';
import { initialState } from '../../store/bankFileDescriptors/reducer';
import { getBankFileDescriptorSelectedId } from '../../store/bankFileDescriptors/sagas';
import {
    BankFileDescriptorsState,
    GetBankFileDescriptorsRequestPayload,
} from '../../store/bankFileDescriptors/types';
import { getCurrentUser } from '../../store/users/sagas';
import {
    computeTableScroll,
    downloadToExcelModalResponseHandler,
    emptyPredefinedFilterOnAppliedFilters,
    getTranslatedText,
    getUsedSortByOptionsIfCloud,
    populateDownloadToExcelModalDisplayMessage,
    populateDownloadToExcelModalTitle,
    removeAppliedFiltersForApiRequest,
    replaceInstancesOfCustomerString,
} from '../../utils/commonFunctions';
import {
    DynamicObject,
    ResponseModalObject,
} from '../../utils/commonInterfaces';
import CreateBankFileDescriptorPanel from '../../components/bankfile/CreateBankFileDescriptorPanel';
import CreateBankFileDescriptorDrawerContentWrapper from '../../components/bankfile/CreateBankFileDescriptorDrawerContent';
import { confirmModalCancelText, confirmModalOkText } from '../../config/config';
import FontAwesome from "../../components/common/FontAwesome";
import { getCustomerUILabel } from "../../store/customers/sagas";
import ActionBar from "../../components/common/ActionBar";
import _ from "lodash";
import './bankfile.less';

const BankFileDescriptorItemDetailsDrawerComponent = lazy(
    () => import('../../components/bankfile/BankFileDescriptorItemDetailsDrawerComponent')
);
const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);
const FilterBar = lazy(() => import('../../components/common/FilterBar'));

const { Title } = Typography;
const { Option } = Select;

let lastSelectedCompanyId: string | null = null;
let resetTableScroll = false;
let isRefetching = false;
let fetchBankFileDescriptorsRequired = false;
let skipListenToPreconfiguredFilter = false;
const tablePageSize = BANK_FILE_DESCRIPTORS_PAGE.pageSize;
const pageName = PAGE_NAMES_FOR_VIEW.BANK_FILE_DESCRIPTORS_PAGE;
let initialTableFilter: undefined | string = undefined;

interface IProps {
    readonly isUsingCloudImportType: boolean;
    readonly handlePageViewSelection: (
        tableFilterValue: string | undefined,
        applyFiltersFunction: Function,
        actionBarRefCurrent?: any,
        pageName?: string
    ) => void;
}

const BankFileDescriptorManagementPage: React.FC<IProps> = ({
    isUsingCloudImportType,
    handlePageViewSelection,
}: IProps) => {
    const customerLabel = useSelector(getCustomerUILabel);
    const dispatch = useDispatch();

    const selectedId = useSelector(getBankFileDescriptorSelectedId);
    const currentUser = useSelector(getCurrentUser);
    const rolePermissions = useSelector(getRolePermissions);

    const actionBarRef: RefObject<DynamicObject | null | undefined> = useRef();

    const [bankFileDescriptorDetailsDrawer, setBankFileDescriptorDetailsDrawer] = useState<{
        visible: boolean;
        activeTab: undefined | string;
    }>({
        visible: false,
        activeTab: undefined,
    });

    const bankFileDescriptorsState: BankFileDescriptorsState = useSelector(
        (state: ApplicationState) => state.bankFileDescriptors
    );

    const [bankFileDescriptorsTableFilter, setBankFileDescriptorsTableFilter] = useState<
        string | undefined
    >(
        isEqual(bankFileDescriptorsState.filters, initialState.filters)
            ? initialState.tableFilter
            : bankFileDescriptorsState.tableFilter
    );

    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );

    const selectedUserCompanyLoading: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.loading
    );

    const [tableFilters, setTableFilters] = useState<any>(bankFileDescriptorsState.filters);

    const [tableRowSelection, setTableRowSelection] = useState<{
        selectedRowKeys: string[];
        unselectedRowKeys: string[];
    }>({
        selectedRowKeys: [],
        unselectedRowKeys: [],
    });

    const [actionBarItemsState, setActionBarItemsState] = useState<{
        editButtonLoading: boolean;
    }>({
        editButtonLoading: false,
    });

    const [editAllowed, setEditAllowed] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

    /**
     * Function to get used sort options
     */
    const getBankFileDescriptorsSortByOptions = () => {
        return getUsedSortByOptionsIfCloud(
            bankFileDescriptorsSortByOptions,
            isUsingCloudImportType
        );
    };

    /**
     * Function for checking if sort value is in options and set the first one if not.
     */
    const checkSortBySelectedIfAvailable = () => {
        if (!selectedUserCompany) return;
        const usedSortByOptions = getBankFileDescriptorsSortByOptions();
        const usedSortValues = map(
            usedSortByOptions,
            (sortOpt) => sortOpt.value
        );
        if (!includes(usedSortValues, bankFileDescriptorsState.sortBy)) {
            setTimeout(() => {
                const newSortValues = {
                    ...tableSortState,
                    sortBy: initialState.sortBy,
                };

                updateTableSortStateObject(newSortValues);
                dispatch(updateBankFileDescriptorsSortByAction(newSortValues));
            }, 200);
        }
    };

    useEffect(checkSortBySelectedIfAvailable, [
        selectedUserCompany,
        bankFileDescriptorsState.sortBy,
    ]);

    /**
     * Function that checks if there are filters from redux for this page.
     * Returns boolean.
     */
    const getHasFiltersOnRedux = () => {
        let hasFiltersOnRedux = false;
        forEach(bankFileDescriptorsState.filters, (filterValue: any) => {
            if (!isEmpty(filterValue)) {
                hasFiltersOnRedux = true;
                return false; // terminates the foreach
            }
        });

        return hasFiltersOnRedux;
    };

    const [showConditions, setShowConditions] = useState<{
        filterBar: boolean;
        filterEllipsis: boolean;
        allSelected: boolean;
        editDrawer: boolean;
        downloadToExcel: boolean;
        createBankFileDescriptorDrawer: boolean;
        editBankFileDescriptorDrawer: boolean;
        deleteBankFileDescriptorDrawer: boolean;
    }>({
        filterBar: getHasFiltersOnRedux(),
        filterEllipsis: false,
        allSelected: false,
        editDrawer: false,
        downloadToExcel: false,
        createBankFileDescriptorDrawer: false,
        editBankFileDescriptorDrawer: false,
        deleteBankFileDescriptorDrawer: false,
    });

    const [tableSortState, setTableSortState] = useState<{
        sortBy: string;
        sortAscending: boolean;
    }>({
        sortBy: bankFileDescriptorsState.sortBy,
        sortAscending: bankFileDescriptorsState.sortAscending,
    });

    const [tableCurrentPage, setTableCurrentPage] = useState<number>(
        get(bankFileDescriptorsState, 'pageData.currentPage', 0)
    );

    /**
     * Function that prepares the payload for the fetch request (either in table or excel report).
     * @param currentPage
     * @param pageSize
     */
    const generatePayloadForRequest = (
        currentPage: number,
        pageSize: number
    ) => {
        const payload: GetBankFileDescriptorsRequestPayload = {
            filters: readyAllFiltersSelected(),
            sortBy: bankFileDescriptorsState.sortBy,
            sortAscending: bankFileDescriptorsState.sortAscending,
            pageSize,
            currentPage: currentPage,
            tableFilter: bankFileDescriptorsTableFilter,
        };

        return payload;
    };

    /**
     * Function that handles fetching of bank file descriptors (calls an action that triggers an API call).
     * @param currentPage - page dependent on scroll
     * @param pageSize - number of items in page
     */
    const fetchBankFileDescriptors = (
        currentPage = tableCurrentPage,
        pageSize = tablePageSize
    ) => {
        if (isEmpty(selectedUserCompany)) return;
        const payload = generatePayloadForRequest(currentPage, pageSize);

        if (!isRefetching) resetTableScroll = false;
        dispatch(getBankFileDescriptorsRequestAction(payload));
    };

    /**
     * Function called when more data from list is requested
     * by scrolling down.
     */
    const handleFetch = () => {
        if (
            isUndefined(initialTableFilter) ||
            isEmpty(selectedUserCompany) ||
            bankFileDescriptorsState.loading
        )
            return;

        if (!bankFileDescriptorsState.pageData.hasNextPage) return;

        const nextPage = tableCurrentPage + 1;
        setTableCurrentPage(nextPage);
        fetchBankFileDescriptors(nextPage);
    };
    
    /**
     * Function called when page loads, changes in filters, and sort options,
     * or company is changed.
     */
    const callFetchBankFileDescriptors = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');

        if (companyIdCognito === selectedCompanyId) {
            if (lastSelectedCompanyId !== selectedCompanyId) {
                lastSelectedCompanyId = selectedCompanyId;
                resetAllSelectedRowKeys();
                checkAllTableFiltersOnCompanySwitch();
                resetTableScrollAndPageData();
            }
            if (!selectedUserCompanyLoading) fetchBankFileDescriptors(0);
        }
    };

    /**
     * Function for comparing component and redux state then setting the correct values.
     */
    const checkAllTableFiltersOnCompanySwitch = () => {
        const { filters, sortBy, sortAscending } = bankFileDescriptorsState;

        if (!isEqual(filters, tableFilters)) {
            setTableFilters(filters);
        }

        if (
            sortBy !== tableSortState.sortBy ||
            sortAscending !== tableSortState.sortAscending
        ) {
            updateTableSortStateObject({
                sortBy,
                sortAscending
            });
        }
    };

    useEffect(callFetchBankFileDescriptors, [
        bankFileDescriptorsState.sortBy,
        bankFileDescriptorsState.sortAscending,
        bankFileDescriptorsState.filters,
        selectedUserCompany,
    ]);

    // on Unmount
    useEffect(() => {
        return () => {
            lastSelectedCompanyId = null;
            skipListenToPreconfiguredFilter = true;
        };
    }, []);

    const selectedKeysName = 'Id';

    /**
     * Function that checks if the row is selected.
     * Specifically if `Select all` is clicked and the bank file descriptor data is updated,
     * it marks all the checkbox depending on the selected value.
     */
    const checkRowSelectionState = () => {
        if (showConditions.allSelected) {
            const selectedKeys = difference(
                map(bankFileDescriptorsState.data, selectedKeysName),
                tableRowSelection.unselectedRowKeys
            );

            updateTableRowSelection({
                selectedRowKeys: selectedKeys,
            });
        } else if (tableRowSelection.selectedRowKeys) {
            const allCurrentKeys = map(bankFileDescriptorsState.data, selectedKeysName);
            const selectedKeys = _.intersection(tableRowSelection.selectedRowKeys, allCurrentKeys);
            
            if (!selectedKeys || selectedKeys.length == 0) setEditAllowed(false);
            updateTableRowSelection({
                selectedRowKeys: selectedKeys,
            });
        }
    };

    useEffect(checkRowSelectionState, [bankFileDescriptorsState.data]);

    /**
     * Action Bar Functions
     */
    /**
     * Function that updates the redux table filters and state table filters.
     * @param filter - dropdown view value
     * @param refetch - boolean indicator if fetching of items is to be called
     */
    const changeBankFileDescriptorsTableFilter = (
        filter: string,
        refetch: boolean = true
    ) => {
        if (!initialTableFilter) {
            initialTableFilter = filter;
        } else {
            if (filter !== initialTableFilter) {
                updateShowConditionsObject({
                    allSelected: false,
                    filterBar: true,
                });
            }
        }

        if (filter !== bankFileDescriptorsState.tableFilter) {
            skipListenToPreconfiguredFilter = false;
        }

        setBankFileDescriptorsTableFilter(filter);
        resetTableScrollAndPageData();
        if (refetch && filter === bankFileDescriptorsState.tableFilter) {
            handleBankFileDescriptorFilterRefresh();
        } else {
            dispatch(updateBankFileDescriptorsTableFilterAction(filter));
        }
    };

    /**
     * Function responsible for refetching bank file descriptors data after an update or when clicking the refresh button.
     */
    const refetchListAndResetScroll = () => {
        isRefetching = true;
        resetTableScrollAndPageData();
        fetchBankFileDescriptors(0);
    };

    const getBankFileDescriptorsFilterPayload = () => {
        const { allExcept, keysToUse, filterObject } =
            getSelectedBankFileDescriptorsValues();
        const payload = {
            filter: filterObject,
            customerIds: keysToUse,
            excludeCustomerIds: allExcept,
        };

        return payload;
    };

    const deleteBankFileDescriptorResponseModal = ({
        IsSuccess,
        Messages
    }: ResponseModalObject) => {
        setDeleteLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: getTranslatedText('Success'),
                content: map(
                    Messages || [],
                    (message: string, index: number) => (
                        <div key={index}>{getTranslatedText(message)}</div>
                    )
                ),
                onOk: () => {
                    refetchListAndResetScroll();
                },
                okText: getTranslatedText('OK')
            });
        } else {
            let errorMessageContent: any = getTranslatedText(`An error occurred while attempting to delete customer bank file descriptors!`);
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{getTranslatedText(error)}</div>
                    )
                );
            }
            Modal.error({
                title: getTranslatedText('Error'),
                content: errorMessageContent,
                okText: getTranslatedText('OK')
            });
        }
    };

    const onDeleteClick = () => {
        Modal.confirm({
            className: 'modal-swapped-buttons',
            title: getTranslatedText('Confirm'),
            content: getTranslatedText('Are you certain you wish to delete the selected customer bank file descriptors?'),
            okText: getTranslatedText(confirmModalOkText),
            cancelText: getTranslatedText(confirmModalCancelText),
            onOk: () => {
                setDeleteLoading(true);
                const payload = getBankFileDescriptorsFilterPayload();
                dispatch(deleteBankFileDescriptorRequestAction({
                    ...payload,
                    callback: deleteBankFileDescriptorResponseModal,
                }));
            }
        });
    };

    /**
     * Function called when refresh button is clicked (upper left near table filter dropdown).
     */
    const handleBankFileDescriptorFilterRefresh = () => {
        fetchBankFileDescriptorsRequired = true;
        skipListenToPreconfiguredFilter = false;
        resetAllSelectedRowKeys();
        refetchListAndResetScroll();
    };

    /**
     * Listener function called when the table filter value for the dropdown at the upper left has been changed.
     */
    const listenToPreConfiguredFilter = () => {
        if (skipListenToPreconfiguredFilter) return;
        const { tableFilter } = bankFileDescriptorsState;
        if (tableFilter === initialTableFilter) {
            closeFilterBar();

            if (fetchBankFileDescriptorsRequired) {
                fetchBankFileDescriptors(0);
                fetchBankFileDescriptorsRequired = false;
            }
        } else {
            handlePageViewSelection(
                tableFilter,
                applyFilters,
                actionBarRef.current,
                pageName
            );
        }
    };

    useEffect(listenToPreConfiguredFilter, [bankFileDescriptorsState.tableFilter]);

    /**
     * Function that populates the bank file descriptor table filter (upper left).
     * @param menu
     */
    const populateBankFileDescriptorFilterSelectDropdownRender = (
        menu: React.Component
    ) => (
        <div>
            {menu}
        </div>
    );

    /**
     * Function that populates the label for button (Select all or Deselect all).
     */
    const populateSelectDeselectAllLabel = () => {
        const allDataLoaded = bankFileDescriptorsState.pageData.hasNextPage === false;

        let selectDeselectLabel = '';
        if (
            !isEmpty(bankFileDescriptorsState.data) &&
            ((tableRowSelection.unselectedRowKeys.length === 0 &&
                showConditions.allSelected) ||
                (allDataLoaded &&
                bankFileDescriptorsState.data.length ===
                    tableRowSelection.selectedRowKeys.length))
        ) {
            selectDeselectLabel = 'Deselect';
        } else {
            selectDeselectLabel = 'Select';
        }

        return getTranslatedText(`${selectDeselectLabel} all`);
    };

    /**
     * Function called when `Select all` button is clicked.
     */
    const selectAllRows = () => {
        const selectAllCondition =
            isEmpty(tableRowSelection.selectedRowKeys) ||
            tableRowSelection.selectedRowKeys.length < bankFileDescriptorsState.data.length;

        updateShowConditionsObject({
            allSelected: selectAllCondition,
        });

        if (selectAllCondition) {
            const idKeys: string[] = map(bankFileDescriptorsState.data, selectedKeysName);

            updateTableRowSelection({
                selectedRowKeys: idKeys,
                unselectedRowKeys: [],
            });
        } else {
            updateTableRowSelection({
                selectedRowKeys: [],
            });
        }

        setEditAllowed(false);
    };

    /**
     * Function called when sort order button is clicked (ascending or descending).
     * @param sortAscending - boolean - true for ascending and false for descending
     */
    const changeSortOrder = async (sortAscending: boolean) => {
        await resetTableScrollAndPageData();
        dispatch(
            updateBankFileDescriptorsSortByAction({
                ...tableSortState,
                sortAscending,
            })
        );
    };

    const downloadDisabled = bankFileDescriptorsState.loading || isEmpty(bankFileDescriptorsState.data);

    /**
     * Function for populating the upper section for the ellipsis popover.
     */
    const populateFilterEllipsisPopoverTitle = () => (
        <div className="pop-action-title">
            <Button
                type="link"
                disabled={bankFileDescriptorsState.loading}
                onClick={() => {
                    setShowConditions((prevState: { filterBar: boolean }) => ({
                        ...showConditions,
                        filterEllipsis: false,
                        filterBar: !prevState.filterBar,
                    }));
                }}
            >
                <FontAwesome icon={['fas', 'filter']} className="mr-10" />
                {getTranslatedText('Filter')}
            </Button>
            <br />
            <Button
                type="link"
                disabled={bankFileDescriptorsState.loading}
                onClick={() => {
                    setTableSortState((prevState: any) => {
                        const sortOrder = !prevState.sortAscending;
                        changeSortOrder(sortOrder);
                        return {
                            ...prevState,
                            sortAscending: sortOrder,
                        };
                    });
                }}
            >
                <FontAwesome
                    icon={[
                        'fas',
                        `sort-amount-${bankFileDescriptorsState.sortAscending ? 'down' : 'up-alt'
                        }`,
                    ]}
                    className="mr-10"
                />
                {getTranslatedText('Change sort order')}
            </Button>
            <br />
            <Button
                type="link"
                onClick={() => downloadToExcelHandler()}
                disabled={downloadDisabled}
            >
                <FontAwesome
                    icon={['fas', 'cloud-download-alt']}
                    className="mr-10"
                />
                {getTranslatedText('Download to Excel')}
            </Button>
        </div>
    );

    /**
     * Function called when Download to Excel button is clicked.
     */
    const downloadToExcelHandler = (specializedReportType?: string) => {
        updateShowConditionsObject({
            downloadToExcel: true,
            filterEllipsis: false,
        });
        const variables = getDownloadReportVariables();

        const payload = {
            Query: bankFileDescriptorsSummaryPageQuery,
            OperationName: bankFileDescriptorsSummaryQueryName,
            Variables: JSON.stringify(variables),
            PageName: PAGE_NAMES_FOR_VIEW.BANK_FILE_DESCRIPTORS_PAGE
        };
        dispatch(downloadToExcelAction(payload, downloadToExcelModalResponse));
    };

    /**
     * Callback function after calling the download to excel api.
     * @param param0 - response with type `ResponseModalObject`
     */
    const downloadToExcelModalResponse = ({
        IsSuccess,
        Messages,
    }: ResponseModalObject) => {
        updateShowConditionsObject({
            downloadToExcel: false,
            filterEllipsis: false,
        });
        downloadToExcelModalResponseHandler(IsSuccess, Messages);
    };

    /**
     * Function that populates the lower section of the ellipsis popover.
     */
    const populateFilterEllipsisPopoverContent = () => {
        return (
            <div className="pop-action-content">
                <div className="mb-10">
                    <span>{getTranslatedText('Sort by')}</span>
                </div>
                <div>
                    <Select
                        onChange={(sortBySelected: string) =>
                            updateTableSortStateObject({
                                sortBy: sortBySelected,
                            })
                        }
                        value={tableSortState.sortBy}
                    >
                        {map(
                            getBankFileDescriptorsSortByOptions(),
                            ({
                                label,
                                value,
                            }: {
                                label: string;
                                value: string;
                            }) => {
                                const labelUsed =
                                    replaceInstancesOfCustomerString(
                                        label,
                                        customerLabel
                                    );

                                return (
                                    <Option key={value} value={value}>
                                        {getTranslatedText(labelUsed)}
                                    </Option>
                                );
                            }
                        )}
                    </Select>
                </div>
                <br />
                <div className="ta-right">
                    <Button
                        type="primary"
                        disabled={
                            bankFileDescriptorsState.loading ||
                            (tableSortState.sortBy === bankFileDescriptorsState.sortBy)
                        }
                        onClick={applySortedBy}
                    >
                        {getTranslatedText('Apply')}
                    </Button>
                </div>
            </div>
        );
    };

    /**
     * Filter Bar Functions
     */
    /**
     * Function that updates the table filters state, also the table filters redux state.
     * Called when `Apply filters` button is clicked.
     * @param filters
     * @param fromFilterBar - boolean indicator if called from Apply filters button in FilterBar component
     */
    const applyFilters = async (
        filters?: GetBankFileDescriptorsRequestPayload['filters'],
        fromFilterBar?: boolean
    ) => {
        if (!filters) {
            setTableFilterToInitialState();
        } else {
            if (fromFilterBar) {
                emptyPredefinedFilterOnAppliedFilters(
                    filters,
                    tableFilters,
                    bankFileDescriptorsTableFilter,
                    fromFilterBar,
                    () => {
                        changeBankFileDescriptorsTableFilter('');
                    }
                );
            }
        }

        let appliedFilters: any = filters || initialState.filters;
        const savedSortState = get(appliedFilters, 'tableSortState');
        if (savedSortState) {
            setTableSortState(savedSortState);
            applySortedBy(savedSortState);
            delete appliedFilters.tableSortState;
        }

        await resetTableScrollAndPageData();
        await setTableFilters(appliedFilters);
        await dispatch(updateBankFileDescriptorsFiltersAction(appliedFilters));

        await resetAllSelectedRowKeys();
    };

    /**
     * Function that sets the value of the dropdown filter next to refresh button to it's initial state.
     */
    const setTableFilterToInitialState = () => {
        if (bankFileDescriptorsState.tableFilter !== initialTableFilter) {
            setBankFileDescriptorsTableFilter(initialTableFilter);

            if (!isUndefined(initialTableFilter))
                changeBankFileDescriptorsTableFilter(initialTableFilter);

            resetAllSelectedRowKeys();
        }
    };

    /**
     * Function called when `Apply` button is clicked inside the ellipsis popover,
     * also hides the ellipsis popover after `Save` is clicked.
     * @param savedValues - optional param - used when a specific tableSortState value is passed.
     */
    const applySortedBy = async (savedValues?: any) => {
        await resetTableScrollAndPageData();
        const usedValues = get(savedValues, 'sortBy')
            ? savedValues
            : tableSortState;
        await dispatch(updateBankFileDescriptorsSortByAction(usedValues));
        await updateShowConditionsObject({
            filterEllipsis: false,
        });
    };

    /**
     * Common function that manipulates the state object for showConditions state.
     */
    const updateShowConditionsObject = (showConditionObject: {}) => {
        setShowConditions((prevState) => ({
            ...prevState,
            ...showConditionObject
        }));
    };

    /**
     * Common function that updates the tableRowSelection state.
     * @param selectionObject - must conform to tableRowSelection object
     */
    const updateTableRowSelection = (selectionObject: {}) => {
        setTableRowSelection({
            ...tableRowSelection,
            ...selectionObject,
        });
    };

    /**
     * Common function for updating the action bar items state.
     * @param itemStateObject - must conform to actionBarItemsState object
     */
    const updateActionBarItemsState = (itemStateObject: {}) => {
        setActionBarItemsState({
            ...actionBarItemsState,
            ...itemStateObject,
        });
    };

    /**
     * Bank File Descriptor Details Functions
     */
    /**
     * Function called when bank file descriptor details drawer is closed.
     */
    const closeBankFileDescriptorDetailsDrawer = () => {
        setBankFileDescriptorDetailsDrawer({
            visible: false,
            activeTab: undefined,
        });
    };

    /**
     * Table Functions
     */
    /**
     * Common function called for updating table sort state.
     * @param tableSortStateObject  - must conform to tableSortState object
     */
    const updateTableSortStateObject = (tableSortStateObject: {}) => {
        setTableSortState({
            ...tableSortState,
            ...tableSortStateObject,
        });
    };

    /**
     * Function called when checkbox is clicked (either checked/unchecked) - will call onRowSelect.
     * @param record - the data for each row (with additional `key` property to identify the row)
     */
    const onCheckboxClick = (record: DynamicObject) => {
        const selectedRowKeys = [...tableRowSelection.selectedRowKeys];
        const isCurrentlySelected = includes(selectedRowKeys, record.key);
        const newSelectedRowKeys = !isCurrentlySelected
            ? [...selectedRowKeys, record.key]
            : remove(selectedRowKeys, (key: string) => key !== record.key);

        onRowSelect(record, !isCurrentlySelected, newSelectedRowKeys, true);
    };

    /**
     * Function called when row is clicked. Responsible for showing the drawer.
     * @param record - data for each row
     * @param activeTab - the tab key to be shown upon showing the drawer
     */
    const onRowClick = (record: DynamicObject, activeTab?: string) => {
        dispatch(
            setBankFileDescriptorSelectedIdRequestAction(get(record, 'Id'), () => {
                setBankFileDescriptorDetailsDrawer({
                    visible: true,
                    activeTab,
                });
            })
        );
    };

    /**
     * Function triggered when after checkbox is clicked. Responsible for setting the state of selected
     * or unselected rows.
     * @param record - table row data record
     * @param selected - boolean if checked/unchecked
     * @param selectedRows - list of keys selected previously
     * @param nativeEvent
     */
    const onRowSelect = (
        record: DynamicObject,
        selected: boolean,
        selectedRows: string[],
        nativeEvent: Event | boolean
    ) => {
        // nativeEvent overridden
        const selectedRowKeys =
            nativeEvent === true ? [...selectedRows] : map(selectedRows, 'key');

        let unselectedRowKeys = [];
        if (selected) {
            unselectedRowKeys = filter(
                tableRowSelection.unselectedRowKeys,
                (unselectedKey: string) => unselectedKey !== record.key
            );
        } else {
            unselectedRowKeys = showConditions.allSelected
                ? [...tableRowSelection.unselectedRowKeys, record.key]
                : [];
        }

        updateTableRowSelection({
            selectedRowKeys,
            unselectedRowKeys,
        });

        let isEditAllowed = false;

        if (selectedRowKeys.length === 1) {
            isEditAllowed = true;
        }

        setEditAllowed(isEditAllowed);
    };

    /**
     * Function using for get selected bank file descriptor
     */
    const getSelectedBankFileDescriptor = () => {
        let bankFileDescriptorVM = undefined;

        if (tableRowSelection.selectedRowKeys.length === 1) {
            const bankFileDescriptor = bankFileDescriptorsState.data.find(item => item.Id === tableRowSelection.selectedRowKeys[0]); 

            if(bankFileDescriptor) {
                bankFileDescriptorVM = {
                    Id: bankFileDescriptor.Id,
                    Customer: bankFileDescriptor.Customer,
                    Descriptors: bankFileDescriptor.Descriptors.map((item, index) => {
                        return {
                            Id: index.toString(),
                            Descriptor: item
                        }
                    }),
                }; 
            }
        }

        return bankFileDescriptorVM;
    };

    /**
     * Function triggered when action bar items with drawer/popover is clicked.
     * @param name - name of popover item
     * @param condition - optional condition whether to show the popover/drawer
     */
    const popoverOnVisibleChange = (name: string, condition?: boolean) => {
        return (visible: boolean) => {
            if (condition === undefined || condition === true) {
                let visibilityCondition = visible;
                if (
                    name === 'editDrawer' ||
                    name === 'createBankFileDescriptorDrawer' ||
                    name === 'editBankFileDescriptorDrawer'
                ) {
                    visibilityCondition = !showConditions[name];
                    updateShowConditionsObject({
                        [name]: visibilityCondition,
                    });
                } else {
                    updateShowConditionsObject({
                        [name]: visible,
                    });
                }
            }
        };
    };

    /**
     * Reset Functions
     */
    /**
     * Function for resetting the table scroll and sets current page to initial state.
     */
    const resetTableScrollAndPageData = async () => {
        resetTableScroll = true;
        await setTableCurrentPage(0);
    };

    /**
     * Function for resetting all selected and unselected row keys in the state.
     * Also resets some of the action bar items state
     * and the allselected indicator for `Select all` button.
     */
    const resetAllSelectedRowKeys = () => {
        // reset Selected Row Keys after change role success
        updateTableRowSelection({
            selectedRowKeys: [],
            unselectedRowKeys: [],
        });
        updateActionBarItemsState({
            editButtonDisabled: true,
        });
        updateShowConditionsObject({
            allSelected: false,
        });
    };

    /**
     * Function called when closing the filter bar.
     * Responsible for hiding the filter bar.
     * Also resets all the table state filters applied to initial state.
     */
    const closeFilterBar = async () => {
        await applyFilters();
        updateShowConditionsObject({
            filterBar: false,
        });
    };

    /**
     * Function that manipulates all the table filter selected.
     * Like adding additional filters required in the payload before sending it to API.
     */
    const readyAllFiltersSelected = () => {
        return {
            ...bankFileDescriptorsState.filters,
        };
    };

    /**
     * Function for getting the values needed when connecting to API.
     * Like the selected keys, filter object, and boolean indicator if
     * the selected keys are to be excluded from search or included.
     */
    const getSelectedBankFileDescriptorsValues = () => {
        let allExcept = false;
        let keysToUse = [...tableRowSelection.selectedRowKeys];

        if (showConditions.allSelected) {
            allExcept = true;
            keysToUse = [...tableRowSelection.unselectedRowKeys];
        }
        const filters = readyAllFiltersSelected();
        const filterObject = {
            ...filters,            
        };

        return {
            allExcept,
            keysToUse,
            filterObject,
        };
    };

    const deleteAllowed = (() => {
        const { allExcept } = getSelectedBankFileDescriptorsValues();
        if (allExcept) {
            return true;
        }
        const bankFileDescriptors = filter(bankFileDescriptorsState.data, c => includes(tableRowSelection.selectedRowKeys, c.Id));
        return !isEmpty(bankFileDescriptors);
    })();

    /**
     * Function for populating the loading text of table.
     */
    const populateTableLoadingText = () => {
        const loadingText = `Fetching ${tableCurrentPage === 0 || isRefetching ? 'list of' : 'more' } customer bank file descriptors`;

        isRefetching = false;

        return getTranslatedText(loadingText);
    };

    /**
     * Function for populating the modal title when saving the bank file descriptor data.
     */
    const populateBankFileDescriptorBatchSaveRequestModalTitle = () => {
        const { keysToUse } = getSelectedBankFileDescriptorsValues();

        return getTranslatedText(`Saving customer bank file descriptor${keysToUse.length > 1 ? 's' : ''} data`);
    };

    /**
     * Function for populating the modal body when saving the bank file descriptor data.
     */
    const populateBankFileDescriptorBatchSaveRequestModalDisplayMessage = () => {
        const { keysToUse } = getSelectedBankFileDescriptorsValues();

        return getTranslatedText(`Please wait while saving the customer bank file descriptor${keysToUse.length > 1 ? 's' : ''} classification status. . .`);
    };

    const populateDeleteBankFileDescriptorsModalTitle = () => {
        const { keysToUse } = getSelectedBankFileDescriptorsValues();

        return getTranslatedText(`Deleting customer bank file descriptor${keysToUse.length > 1 ? 's' : ''}`);
    };

    const populateDeleteBankFileDescriptorsModalDisplayMessage = () => {
        const { keysToUse } = getSelectedBankFileDescriptorsValues();

        return getTranslatedText(`Please wait while deleting the customer bank file descriptor${keysToUse.length > 1 ? 's' : ''} . . .`);
    };

    /**
     * Function called when panel is closed.
     * @param panelType - type of panel to be closed. Must be the same with showConditions state props
     */
    const closePanel = (panelType: string) => {
        return (refreshList?: boolean) => {
            updateShowConditionsObject({
                [panelType]: false,
            });

            if (refreshList) {
                refetchListAndResetScroll();
            }
        };
    };

    /**
     * Function that checks if the Name of the page view to be saved already exists.
     * @param name - name of page view
     */
    const doesViewNameExist = (name: string) => {
        if (actionBarRef.current)
            return actionBarRef.current.doesViewNameExist(name);
    };

    /**
     * Function that fetches report variables
     */
    const getDownloadReportVariables = () => {
        const filterAndPageProps = generatePayloadForRequest(
            0,
            defaultPageSizeForReport
        );
        const { filters, sortBy, sortAscending } =
            filterAndPageProps;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'bankFileDescriptor'
        );

        const variables = {
            ...cleanFilters,
            SortField: sortBy,
            Ascending: sortAscending,
        };

        return variables;
    };

    return (
        <Col span={24}>
            <QueueAnim type={['right', 'left']} leaveReverse>
                <Row key="title-container" type="flex" justify="space-between">
                    <Col>
                        <Title level={3}>{getTranslatedText('Customer Bank File Descriptor')}</Title>
                    </Col>
                </Row>
                <div className="spacer-15"/>
                {/* Filter Bar */}
                <QueueAnim type="top" leaveReverse duration={300}>
                    {showConditions.filterBar && (
                        <div key="filter-bar-container">
                            <Suspense fallback={null}>
                                <FilterBar
                                    pageName={pageName}
                                    loading={bankFileDescriptorsState.loading}
                                    applyFilters={applyFilters}
                                    filters={getBankFileDescriptorsFilterBarFilters()}
                                    filterValues={bankFileDescriptorsState.filters}
                                    colDivision={4}
                                    closeAllPopovers={
                                        bankFileDescriptorDetailsDrawer.visible
                                    }
                                    closeFilterBar={closeFilterBar}
                                    appliedView={bankFileDescriptorsTableFilter}
                                    doesViewNameExist={doesViewNameExist}
                                    tableSortState={{
                                        sortBy: bankFileDescriptorsState.sortBy,
                                        sortAscending:
                                            bankFileDescriptorsState.sortAscending,
                                    }}
                                />
                            </Suspense>
                        </div>
                    )}
                </QueueAnim>
                <div key="action-bar-container">
                    <ActionBar
                        ref={actionBarRef}
                        pageName={pageName}
                        loading={bankFileDescriptorsState.loading}
                        filterBarOpen={showConditions.filterBar}
                        actionItems={[
                            {
                                actionKey: 'bank-file-descriptor-filter',
                                actionType: 'select-with-button',
                                selectValue: bankFileDescriptorsTableFilter,
                                selectDropdownRender:
                                    populateBankFileDescriptorFilterSelectDropdownRender,
                                onSelectChange: changeBankFileDescriptorsTableFilter,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fa', 'sync']}
                                            className="mr-8"
                                        />
                                        <span>{getTranslatedText('Refresh')}</span>
                                    </>
                                ),
                                buttonDisabled: bankFileDescriptorsState.loading,
                                onButtonClick: handleBankFileDescriptorFilterRefresh,
                            },
                            {
                                actionKey: 'bank-file-descriptor-select-all',
                                actionType: 'protected-button',
                                buttonDisabled: downloadDisabled,
                                onButtonClick: selectAllRows,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fas', 'check-double']}
                                        />
                                        <span>
                                            {populateSelectDeselectAllLabel()}
                                        </span>
                                    </>
                                ),
                            },
                            {
                                actionKey: 'create-bank-file-descriptor',
                                actionType: 'protected-drawer-button',
                                allowedRoles: rolePermissions.CREATE_BANK_FILE_DESCRIPTOR,
                                popoverVisible:
                                    showConditions.createBankFileDescriptorDrawer,
                                drawerCloseable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'createBankFileDescriptorDrawer'
                                ),
                                popoverTitle: getTranslatedText('Create customer bank file descriptors'),
                                popoverContent: (
                                    <div>
                                        <CreateBankFileDescriptorPanel
                                            visible={
                                                showConditions.createBankFileDescriptorDrawer
                                            }
                                            closePanel={closePanel(
                                                'createBankFileDescriptorDrawer'
                                            )}
                                            dispatchAction={
                                                createOrEditBankFileDescriptorRequestAction
                                            }
                                        />
                                    </div>
                                ),
                                buttonDisabled: bankFileDescriptorsState.loading,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fa', 'plus-circle']}
                                        />
                                        <span>{getTranslatedText('Create descriptors')}</span>
                                    </>
                                ),
                                drawerWidth: 700,
                            },
                            {
                                actionKey: 'edit-bank-file-descriptor',
                                actionType: 'protected-drawer-button',
                                allowedRoles: rolePermissions.EDIT_BANK_FILE_DESCRIPTOR,
                                popoverVisible:
                                    showConditions.editBankFileDescriptorDrawer,
                                drawerCloseable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'editBankFileDescriptorDrawer'
                                ),
                                popoverTitle: getTranslatedText('Edit customer bank file descriptor'),
                                popoverContent: (
                                    <div>
                                        <CreateBankFileDescriptorDrawerContentWrapper
                                            visible={showConditions.editBankFileDescriptorDrawer}
                                            isEditMode={true}
                                            isEditViaManagementPage={true}
                                            closePanel={closePanel('editBankFileDescriptorDrawer')}
                                            editBankFileDescriptor={getSelectedBankFileDescriptor()}
                                        />
                                    </div>
                                ),
                                buttonDisabled: !editAllowed,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fas', 'edit']}
                                        />
                                        <span>{getTranslatedText('Edit descriptor')}</span>
                                    </>
                                ),
                                drawerWidth: 700,
                            },
                            {
                                actionKey: 'delete-bank-file-descriptor',
                                actionType: 'protected-button',
                                allowedRoles: rolePermissions.DELETE_BANK_FILE_DESCRIPTOR,
                                buttonDisabled: !deleteAllowed,
                                onButtonClick: onDeleteClick,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fa', 'trash']}
                                        />
                                        <span>{getTranslatedText('Delete')}</span>
                                    </>
                                )
                            }
                        ]}
                        actionEllipsis={{
                            popoverVisible: showConditions.filterEllipsis,
                            popoverOnVisibleChange:
                                popoverOnVisibleChange('filterEllipsis'),
                            popoverTitle: populateFilterEllipsisPopoverTitle(),
                            popoverContent:
                                populateFilterEllipsisPopoverContent(),
                            buttonContent: (
                                <FontAwesome icon={['fas', 'ellipsis-h']} />
                            ),
                        }}
                    />
                </div>
                <div className="spacer-15" />
                {/* Table Section */}
                <Row key="table-container">
                    <Col span={24}>
                        <VirtualizedList
                            dataSource={bankFileDescriptorsState.data}
                            fetchMore={handleFetch}
                            scroll={computeTableScroll(
                                window.innerHeight - 185,
                                tablePageSize,
                                BANK_FILE_DESCRIPTORS_PAGE.rowHeight
                            )}
                            resetTableScroll={resetTableScroll}
                            selectedRowKeys={tableRowSelection.selectedRowKeys}
                            rerenderTrigger={tableRowSelection.selectedRowKeys}
                            onRowClick={onRowClick}
                            onCheckboxClick={onCheckboxClick}
                            loading={bankFileDescriptorsState.loading}
                            loadingText={populateTableLoadingText()}
                            emptyText={
                                !isEmpty(bankFileDescriptorsState.errorMessages)
                                    ? get(bankFileDescriptorsState, 'errorMessages.0')
                                    : getTranslatedText('No customer bank file descriptors found')
                            }
                            hasNextPage={bankFileDescriptorsState.pageData.hasNextPage}
                            itemComponent={BankFileDescriptorItemComponent}
                            itemHeight={BANK_FILE_DESCRIPTORS_PAGE.rowHeight}
                            selectedId={selectedId}
                        />
                    </Col>
                    <Suspense fallback={null}>
                        <BankFileDescriptorItemDetailsDrawerComponent
                            visible={bankFileDescriptorDetailsDrawer.visible}
                            activeTab={bankFileDescriptorDetailsDrawer.activeTab}
                            closeDrawer={closeBankFileDescriptorDetailsDrawer}
                            refetchList={refetchListAndResetScroll}
                            getBankFileDescriptorDataRequestAction={getBankFileDescriptorDataRequestAction}
                        />
                    </Suspense>
                    {bankFileDescriptorsState.saveBankFileDescriptorDataLoading && (
                        <Suspense fallback={null}>
                            <ModalWithSpinner
                                modalTitle={populateBankFileDescriptorBatchSaveRequestModalTitle()}
                                modalVisible={
                                    bankFileDescriptorsState.saveBankFileDescriptorDataLoading
                                }
                                displayMessage={populateBankFileDescriptorBatchSaveRequestModalDisplayMessage()}
                            />
                        </Suspense>
                    )}
                    {showConditions.downloadToExcel && (
                        <Suspense fallback={null}>
                            <ModalWithSpinner
                                modalTitle={populateDownloadToExcelModalTitle()}
                                modalVisible={showConditions.downloadToExcel}
                                displayMessage={populateDownloadToExcelModalDisplayMessage()}
                            />
                        </Suspense>
                    )}
                    {deleteLoading && (
                        <Suspense fallback={null}>
                            <ModalWithSpinner
                                modalTitle={populateDeleteBankFileDescriptorsModalTitle()}
                                modalVisible={deleteLoading}
                                displayMessage={populateDeleteBankFileDescriptorsModalDisplayMessage()}
                            />
                        </Suspense>
                    )}
                </Row>
            </QueueAnim>
        </Col>
    );
};

export default withPageViewHandler(
    BankFileDescriptorManagementPage
);