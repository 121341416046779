import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';

import { getCurrentEntityType, getSelectedImportHistoryId } from '../../store/import/sagas';
import AvatarNameInitialsComponent from '../../components/common/AvatarNameInitialsComponent';
import { EntityType, ImportHistory } from '../../store/import/types';
import { withDateFormatHandler } from '../../components/common/DateFormatHandler';
import { dateFormatDoMMMYYYYHHmmSpace } from '../../constants/dateFormats';
import { get, isEmpty } from 'lodash';
import {
    PENDING,
    IN_PROGRESS,
    SUCCESSFUL,
    FAILED,
    SUCCESSFUL_WITH_WARNINGS,
} from '../../constants/importHistoryStatuses';
import { DynamicObject } from '../../utils/commonInterfaces';
import { getTranslatedText } from '../../utils/commonFunctions';

interface IProps {
    readonly item: ImportHistory;
    readonly style: {};
    readonly onRowClick: (item: DynamicObject) => void;
    readonly formatDateUTCToLocal?: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
}
const ImportHistoryItemComponent: React.FC<IProps> = ({
    item,
    style,
    onRowClick,
    formatDateUTCToLocal,
}: IProps) => {
    const selectedId = useSelector(getSelectedImportHistoryId);
    const entityType = useSelector(getCurrentEntityType);
    
    const { Id, User, StartDateTime, Status } = item;

    const StartDateTimeLocal = formatDateUTCToLocal
        ? formatDateUTCToLocal(
              StartDateTime,
              undefined,
              dateFormatDoMMMYYYYHHmmSpace
          )
        : StartDateTime;

    let Name: string = '';
    if (get(User, 'GivenName')) {
        Name += get(User, 'GivenName');
    }
    if (get(User, 'FamilyName')) {
        if (!isEmpty(Name)) {
            Name += ' ';
        }
        Name += get(User, 'FamilyName');
    }
    
    /**
     * Function called when clicking the row
     * sets a key based on the Id
     * to be used as an Identity on which row has been clicked.
     */
    const handleRowClick = () => {
        const itemInfo: DynamicObject = item;
        itemInfo.key = Id;
        if (onRowClick) onRowClick(itemInfo);
    };

    /**
     * Function for getting the correct status message based on UI.
     * @param status - string from API
     */
    const getStatusMessage = (status: string) => {
        let StatusMessage = entityType === EntityType.BankFile ? 'Bank file process' : 'Import';
        switch (status) {
            case PENDING.name:
                StatusMessage += ` ${PENDING.display}`;
                break;
            case IN_PROGRESS.name:
                StatusMessage += ` ${IN_PROGRESS.display}`;
                break;
            case SUCCESSFUL.name:
                StatusMessage += ` ${SUCCESSFUL.display}`;
                break;
            case FAILED.name:
                StatusMessage += ` ${FAILED.display}`;
                break;
            case SUCCESSFUL_WITH_WARNINGS.name:
                StatusMessage += ` ${SUCCESSFUL_WITH_WARNINGS.display}`;
                break;
            default:
                StatusMessage = '';
                break;
        }

        return StatusMessage !== ''
            ? getTranslatedText(StatusMessage)
            : StatusMessage;
    };

    return (
        <Row
            className={`list-item-clickable ${
                selectedId === Id ? 'list-item-clicked' : ''
            }`}
            key={Id}
            style={style}
            onClick={handleRowClick}
        >
            <Col
                span={24}
                style={{ height: '100%', paddingTop: 12, paddingBottom: 12 }}
            >
                <Row type="flex" align="middle" style={{ height: '100%' }}>
                    <Col xl={1} lg={0} md={0} sm={0} xs={0}></Col>
                    <Col xl={4} lg={5} md={5} sm={5} xs={5} className="pl-10-i">
                        {StartDateTimeLocal}
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                        {Name}
                    </Col>
                    <Col xl={9} lg={9} md={9} sm={9} xs={9}>
                        {getStatusMessage(Status)}
                    </Col>
                    <Col
                        xl={4}
                        lg={3}
                        md={3}
                        sm={3}
                        xs={3}
                        className="ta-center"
                    >
                        <AvatarNameInitialsComponent
                            fullName={Name}
                            size="large"
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

// export default withDateFormatHandler(ImportHistoryItemComponent);
const arePropsEqual = (prevProps: any, nextProps: any) => {
    if (
        JSON.stringify(prevProps.item) === JSON.stringify(nextProps.item) &&
        prevProps.selectedId === nextProps.selectedId &&
        JSON.stringify(prevProps.style) === JSON.stringify(nextProps.style)
    ) {
        return true;
    } else {
        return false;
    }
};

export default memo(
    withDateFormatHandler(ImportHistoryItemComponent),
    arePropsEqual
);
