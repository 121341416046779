import { action } from 'typesafe-actions';
import {
    EntityType,
    ImportActionTypes,
    ImportHistory,
    ImportStagingMessage,
} from './types';
import { PageData } from '../common/types';
import { DynamicObject } from '../../utils/commonInterfaces';

/**
 * Action called when uploading an excel file - request (to control loading modal).
 * Getting the presigned URL for upload.
 * @param callback - function to be called after
 */
export const uploadTemplateFileRequestAction = (
    type: string | undefined,
    callback: (response: DynamicObject) => void
) => action(ImportActionTypes.UPLOAD_TEMPLATE_FILE_REQUEST, {
    type,
    callback
});

/**
 * Action called when downloading an excel file.
 * Getting the presigned url for download.
 * @param callback
 */
export const downloadTemplateFileRequestAction = (
    fileName: string,
    callback: (response: DynamicObject) => void
) => action(ImportActionTypes.DOWNLOAD_TEMPLATE_FILE_REQUEST, {
    fileName,
    callback
});

/**
 * Actions for setting the import history entity type.
 */
export const setImportHistoryEntityTypeAction = (entityType: EntityType) =>
    action(ImportActionTypes.SET_IMPORT_HISTORY_ENTITY_TYPE, entityType);

/**
 * Actions for getting the import history list.
 */
export const getImportHistoryRequestAction = (entityType: string) =>
    action(ImportActionTypes.GET_IMPORT_HISTORY_REQUEST, { entityType });
export const getImportHistorySuccessAction = (payload: {
    data: ImportHistory[];
}) => action(ImportActionTypes.GET_IMPORT_HISTORY_SUCCESS, payload);
export const getImportHistoryErrorAction = (payload: string[]) =>
    action(ImportActionTypes.GET_IMPORT_HISTORY_ERROR, payload);

// Single Record
/**
 * Actions called when setting the import history Id upon clicking a row - request.
 */
export const setImportHistorySelectedIdRequestAction = (
    importHistoryId: string | null,
    callback: () => void
) =>
    action(ImportActionTypes.SET_IMPORT_HISTORY_SELECTED_ID_REQUEST, {
        importHistoryId,
        callback,
    });
export const setImportHistorySelectedIdSuccessAction = (
    importHistoryId: string | null
) =>
    action(
        ImportActionTypes.SET_IMPORT_HISTORY_SELECTED_ID_SUCCESS,
        importHistoryId
    );

/**
 * Actions for getting the single data (details) for import history.
 * @param importHistoryId - Id of the import history
 */
export const getImportHistoryDataRequestAction = (importHistoryId: string) =>
    action(ImportActionTypes.GET_IMPORT_HISTORY_DATA_REQUEST, importHistoryId);
export const getImportHistoryDataSuccessAction = (payload: { record: {} }) =>
    action(ImportActionTypes.GET_IMPORT_HISTORY_DATA_SUCCESS, payload);
export const getImportHistoryDataErrorAction = (payload: string[]) =>
    action(ImportActionTypes.GET_IMPORT_HISTORY_DATA_ERROR, payload);

/**
 * Actions for checking import job status.
 */
export const getCheckImportJobStatusRequestAction = (callback: (response: DynamicObject) => void) =>
    action(ImportActionTypes.CHECK_IMPORT_JOB_STATUS_REQUEST, { callback });

/**
 * Actions required for getting the import history data for Errors and warnings section inside import history detail.
 * @param importJobId - the Job Id for import - from db
 * @param currentPage - current page based on scroll
 * @param pageSize - number of items in page
 */
export const getImportHistoryDataErrorsAndWarningsRequestAction = (
    importJobId: string,
    currentPage: number,
    pageSize: number,
    entityType: string
) =>
    action(
        ImportActionTypes.GET_IMPORT_HISTORY_DATA_ERRORS_AND_WARNINGS_REQUEST,
        {
            importJobId,
            currentPage,
            pageSize,
            entityType
        }
    );
export const getImportHistoryDataErrorsAndWarningsSuccessAction = (payload: {
    data: ImportStagingMessage[];
    pageData: PageData;
}) =>
    action(
        ImportActionTypes.GET_IMPORT_HISTORY_DATA_ERRORS_AND_WARNINGS_SUCCESS,
        payload
    );
export const getImportHistoryDataErrorsAndWarningsErrorAction = (
    payload: string[]
) =>
    action(
        ImportActionTypes.GET_IMPORT_HISTORY_DATA_ERRORS_AND_WARNINGS_ERROR,
        payload
    );

/**
 * Clearing of Import history redux state.
 */
export const clearImportHistoryDataAction = () =>
    action(ImportActionTypes.CLEAR_IMPORT_HISTORY_DATA_SUCCESS);

// Xero actions

/**
 * Actions called for getting xero configuration.
 */
export const getXeroConfigurationRequestAction = (
    callback: (response: DynamicObject) => void
) => action(ImportActionTypes.GET_XERO_CONFIGURATION_REQUEST, callback);

/**
 * Action for connecting to xero api.
 */
export const xeroConnectRequestAction = (
    callback: (response: DynamicObject) => void
) => action(ImportActionTypes.XERO_CONNECT_REQUEST, callback);

/**
 * Action for disconnecting from xero api.
 */
export const xeroDisconnectRequestAction = (
    connectionId: string,
    callback: (response: DynamicObject) => void
) =>
    action(ImportActionTypes.XERO_DISCONNECT_REQUEST, {
        connectionId,
        callback,
    });

/**
 * Action for xero manual syncing.
 */
export const xeroManualSyncRequestAction = (
    callback: (response: DynamicObject) => void
) =>
    action(ImportActionTypes.XERO_MANUAL_SYNC_REQUEST, {
        callback,
    });
