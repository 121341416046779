/**
 * File responsible for all the UI and actions for Settings>Remittance Advice Customization page container - `/app/settings/ra_customization`.
 */

import { Col, Row, Tabs, Typography,Button, Form,Modal } from 'antd';
import { History as IHistory } from 'history';
import { get, map,debounce } from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, {
    RefObject,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ApplicationState } from '../../../store';
import {
    CompaniesState,
    CompanyUserRole,
} from '../../../store/companies/types';
import { getTranslatedText } from '../../../utils/commonFunctions';
import { DynamicObject } from '../../../utils/commonInterfaces';
import '../settings.less';
import RACustomizationProcessingSettingsPage from './process-settings/RACustomizationProcessingSettingsPage';
import RACustomizationRebateMappingsPage from './rebate-mappings/RACustomizationRebateMappingsPage';

const { TabPane } = Tabs;
const { Title } = Typography;
const { confirm } = Modal;

interface IProps {
    location: Location;
    history: typeof IHistory;
    match: {
        path: string;
    };
    form?: any
}

const routeTabs = [
    { name: getTranslatedText("Process Settings"), routeName: "RACustomizationProcessingSettingsPage", component: RACustomizationProcessingSettingsPage },
    { name: getTranslatedText("Rebate Mappings"), routeName: "RACustomizationRebateMappingsPage", component: RACustomizationRebateMappingsPage }
];


const raCustomizationRoute = '/app/settings/ra_customization';
const RACustomizationPageContainer: React.FC<IProps> = (props: IProps) => {
    const companiesState: CompaniesState = useSelector(
        (state: ApplicationState) => state.companies
    );

    const selectedUserCompany: CompanyUserRole | undefined =
        companiesState.selectedUserCompany;

    const childRef: RefObject<DynamicObject | null | undefined> = useRef();
    const [currentTab, setCurrentTab] = useState<string>('');
    const [formHasChanges, setFormHasChanges] = useState<boolean>(false);
    const [saveLoading, setSaveLoading] = useState<boolean>(false);

    useEffect(() => {
        // Translate the tabs name
        routeTabs.map((routeTab: any) => {
            routeTab.name = routeTab.name || routeTab.name;

            return routeTab;
        });

        const currentTabPath = props.location.pathname
            .replace(raCustomizationRoute, '')
            .replace('/', '');

        const selectedTab = currentTabPath || routeTabs[0].routeName.toLowerCase();
        
        setCurrentTab(selectedTab);
    }, [props.location.pathname]);

    /**
     * Function that is executed when a tab is selected (change - different from the previously selected tab).
     * @param tabKey - key for the tab (required to set the tab identity)
     */
    const onTabChange = (tabKey: string) => {
        const tabPath =
            tabKey === routeTabs[0].name.toLowerCase() ? '' : `/${tabKey}`;

        props.history.push(`${raCustomizationRoute}${tabPath}`);
    };

    /**
     * Function called when common `Save` button is clicked
     * calling the save function for the child (General, Contact, Branding).
     */
    const onSaveButtonClick = async () => {
        if (childRef.current) {
            try {
                // Perform save operation
                childRef.current.save();
            } catch (error) {
                console.error("Error occurred:", error);
            }
            
        }
    };

    /**
     * Function called when common `Cancel` button is clicked
     * calling the cancel function for the child tab items.
     */
    const onCancelButtonClick = () => {
        confirm({
            className: 'modal-swapped-buttons',
            title: getTranslatedText("Continue"),
            content: (
                <span dangerouslySetInnerHTML={{ __html: getTranslatedText(`When you click the cancel button, all the data will be reverted to the last saved values.`) }} />
            ),
            onOk() {
                if (childRef.current) childRef.current.cancel();
            },
            onCancel() {},
            okText: getTranslatedText("Ok"),
            cancelText: getTranslatedText("Cancel"),
        });
    };
    /**
     * A wrapper function (using debounce) for setting if form has changes.
     * Wrapped in debounce to prevent being called a couple of times in a short span of time.
     */
    const setHasChangesDebounced = debounce(() => {
        if (childRef.current) {
            const hasChanges = childRef.current.checkForChanges();
            setFormHasChanges(hasChanges);
        }
    }, 500);
    return (
        <div className="h-100">
            <Col span={24}>
                <QueueAnim type={['right', 'left']} leaveReverse>
                    <Row key="title-container" type="flex" align="middle">
                        <Col span={12}>
                            <Title level={3}>{getTranslatedText("Remittance Advice Customisation")}</Title>
                        </Col>
                        <Col span={12} className="ta-right">
                            <Button
                                className="mr-10 w-100px"
                                type="primary"
                                onClick={onSaveButtonClick}
                                disabled={!formHasChanges}
                                loading={saveLoading}
                            >
                                {getTranslatedText("Save")}
                            </Button>
                            <Button
                                className="buttonGrey w-100px"
                                onClick={onCancelButtonClick}
                                disabled={!formHasChanges}
                            >
                                {getTranslatedText("Cancel")}
                            </Button>
                        </Col>
                    </Row>
                    <br />
                    <Row key="tabs-container">
                        <Col span={24}>
                            <Tabs
                                className="left-aligned-tab h-100"
                                tabPosition="left"
                                activeKey={currentTab}
                                onChange={onTabChange}
                            >
                                {map(
                                    routeTabs,
                                    ({
                                        name: tabName,
                                        routeName: routePath,
                                        component: TabComponent,
                                    }: {
                                        name: string;
                                        routeName: string;
                                        component: any;
                                    }) => {
                                        const lowerCaseTabName =
                                            routePath.toLowerCase();
                                        
                                        return (
                                            <TabPane
                                                tab={getTranslatedText(tabName)}
                                                key={lowerCaseTabName}
                                            >
                                                {lowerCaseTabName ===
                                                currentTab ? (
                                                    <div
                                                        className="custom-tab-container"
                                                        style={{
                                                            height:
                                                                window.innerHeight -
                                                                150,
                                                        }}
                                                    >
                                                        <TabComponent
                                                            ref={childRef}
                                                            history={
                                                                props.history
                                                            }
                                                            selectedUserCompany={get(
                                                                selectedUserCompany,
                                                                'Company',
                                                                {}
                                                            )}
                                                            form={props.form}
                                                            setFormHasChanges={setHasChangesDebounced}
                                                            setSaveLoading={setSaveLoading}
                                                            saveLoading={saveLoading}
                                                        />
                                                    </div>
                                                ) : null}
                                            </TabPane>
                                        );
                                    }
                                )}
                            </Tabs>
                        </Col>
                    </Row>
                </QueueAnim>
            </Col>
        </div>
    );
};
const RACustomizationPageForm = Form.create({
    name: 'ra-customization-form',
})(RACustomizationPageContainer);
export default withRouter(RACustomizationPageForm);
