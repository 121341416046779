/**
 * Component for the Sales > PaymentPlans page. `/app/banking/paymentPlans`
 */

import { Button, Col, Row, Select, Typography } from 'antd';
import {
    difference,
    filter,
    forEach,
    get,
    includes,
    isEmpty,
    isEqual,
    isUndefined,
    map,
    remove,
} from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, {
    lazy,
    RefObject,
    Suspense,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PaymentPlanItemComponent from '../../components/banking/PaymentPlanItemComponent';
import { withAccountingSystemHandler } from '../../components/common/AccountingSystemHandler';
import ActionBar from '../../components/common/ActionBar';
import AddCommentPanel from '../../components/common/AddCommentPanel';
import CreateNewPaymentPlanPanelWrapper from '../../components/banking/CreateNewPaymentPlanPanel';
import PaymentPlanCancelPanel from '../../components/banking/PaymentPlanCancelPanel';
import { customFieldIndicator } from '../../components/common/FilterBar';
import FontAwesome from '../../components/common/FontAwesome';
import { withPageViewHandler } from '../../components/common/PageViewHandler';
import VirtualizedList from '../../components/common/VirtualizedList';
import {
    CUSTOM_FIELD_TYPES,
    PAGE_NAMES_FOR_VIEW,
    PAYMENT_PLANS_PAGE,
} from '../../config/tableAndPageConstants';
import { CompanyIdAttribute } from '../../constants/authUserAttributes';
import {
    CUSTOM_PLAN_STATE_FILTER_OPTION,
    getPaymentPlansFilterBarFilters,
    paymentPlansSortByOptions,
    paymentPlansStateFilterOptions,
} from '../../constants/paymentPlansSortAndFilters';
import { ApplicationState } from '../../store';
import { downloadToExcelAction, getCustomFieldsFilterListByTypes } from '../../store/common/actions';
import {
    CompanyCustomFieldConfigure,
    CompanyUserRole,
} from '../../store/companies/types';
import {
    getPaymentPlansRequestAction,
    paymentPlanAddCommentRequestAction,
    setPaymentPlanSelectedIdRequestAction,
    updatePaymentPlansFiltersAction,
    updatePaymentPlansSortByAndStateAction,
    updatePaymentPlansTableFilterAction,
    cancelPaymentPlanRequestAction,
    checkPaymentPlansActionStateAction,
    manualCreateNewPaymentPlanRequestAction,
    updatePaymenPlanScheduleRequestAction,
    resendPaymentPlanApprovalRequestAction
} from '../../store/paymentPlans/actions';
import { initialState } from '../../store/paymentPlans/reducer';
import { getPaymentPlanSelectedId } from '../../store/paymentPlans/sagas';
import {
    GetPaymentPlansRequestPayload,
    PaymentPlansState,
    CheckPaymentPlansActionStateRequestPayload,
    PaymentPlan
} from '../../store/paymentPlans/types';
import { getCurrentUser } from '../../store/users/sagas';
import { getRolePermissions } from '../../store/roles/sagas';
import {
    computeTableScroll,
    downloadToExcelModalResponseHandler,
    emptyPredefinedFilterOnAppliedFilters,
    getUsedSortByOptionsIfCloud,
    populateDownloadToExcelModalDisplayMessage,
    populateDownloadToExcelModalTitle,
    removeAppliedFiltersForApiRequest,
    getTranslatedText
} from '../../utils/commonFunctions';
import { DynamicObject, ResponseModalObject } from '../../utils/commonInterfaces';
import { defaultPageSizeForReport, paymentPlansSummaryPageQuery, paymentPlansSummaryQueryName } from '../../constants/downloadToExcel';
import EditPaymentPlanPanel from '../../components/banking/EditPaymentPlanPanel';
import ResendPaymentPlanApprovalPanel from '../../components/banking/ResendPaymentPlanApprovalPanel';
import CreateScheduledReportDrawer from '../../components/common/CreateScheduledReportDrawer';


const PaymentPlanItemDetailsDrawerComponent = lazy(
    () =>
        import('../../components/banking/PaymentPlanItemDetailsDrawerComponent')
);
const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);
const FilterBar = lazy(() => import('../../components/common/FilterBar'));

const { Title } = Typography;
const { Option } = Select;

interface IProps {
    readonly handlePageViewSelection: (
        tableFilterValue: string | undefined,
        applyFiltersFunction: Function,
        actionBarRefCurrent?: any,
        pageName?: string
    ) => void;
}
let lastSelectedCompanyId: string | null = null;
let resetTableScroll = false;
let isRefetching = false;
let fetchPaymentPlansRequired = false;
let skipListenToPreconfiguredFilter = false;
const tablePageSize = PAYMENT_PLANS_PAGE.pageSize;
const pageName = PAGE_NAMES_FOR_VIEW.PAYMENT_PLANS_PAGE;
let initialTableFilter: undefined | string = undefined;
const PaymentPlansManagementPage: React.FC<IProps> = ({
    handlePageViewSelection,
}: IProps) => {
    const dispatch = useDispatch();

    const actionBarRef: RefObject<DynamicObject | null | undefined> = useRef();
    
    const currentUser = useSelector(getCurrentUser);
    const rolePermissions = useSelector(getRolePermissions);
    const selectedId = useSelector(getPaymentPlanSelectedId);

    const [customFieldsFilterList, setCustomFieldsFilterList] = useState<[]>(
        []
    );

    const [paymentPlanDetailsDrawer, setPaymentPlanDetailsDrawer] = useState<{
        visible: boolean;
        activeTab: undefined | string;
    }>({
        visible: false,
        activeTab: undefined,
    });

    const paymentPlansState: PaymentPlansState = useSelector(
        (state: ApplicationState) => state.paymentPlans
    );

    const [paymentPlansTableFilter, setPaymentPlansTableFilter] = useState<
        string | undefined
    >(
        isEqual(paymentPlansState.filters, initialState.filters)
            ? initialState.tableFilter
            : paymentPlansState.tableFilter
    );

    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );

    let isCreateNewPaymentPlanAllowed = false;
    if (
        isEmpty(rolePermissions.PAYMENT_PLAN_CREATE_NEW_PAYMENT_PLAN) ||
        includes(
            rolePermissions.PAYMENT_PLAN_CREATE_NEW_PAYMENT_PLAN,
            selectedUserCompany.Role.Name
        )
    ) {
        isCreateNewPaymentPlanAllowed = true;
    }

    const editButtonDisabled = () => {
        const paymentPlanStatus: string[] = ["Open", "Pending"];
        let paymentPlan: PaymentPlan | undefined;

        if (tableRowSelection.selectedRowKeys && tableRowSelection.selectedRowKeys.length === 1) {
            paymentPlan = paymentPlansState.data.find(p => p.Id === tableRowSelection.selectedRowKeys[0]);
        }

        let disabled = !isEmpty(rolePermissions.PAYMENT_PLAN_EDIT_PAYMENT_PLAN) &&
            !includes(
                rolePermissions.PAYMENT_PLAN_EDIT_PAYMENT_PLAN,
                selectedUserCompany.Role.Name
            );

        if (!disabled) {
            disabled = isEmpty(tableRowSelection.selectedRowKeys)
                || !paymentPlan
                || !includes(
                    paymentPlanStatus,
                    paymentPlan.State
                );
        }

        return disabled;
    }

    const [createScheduleReportPayload, setCreateScheduleReportPayload] = useState<any>();

    const [tableFilters, setTableFilters] = useState<any>(
        paymentPlansState.filters
    );

    const [tableRowSelection, setTableRowSelection] = useState<{
        selectedRowKeys: string[];
        unselectedRowKeys: string[];
    }>({
        selectedRowKeys: [],
        unselectedRowKeys: [],
    });

    const [actionBarItemsState, setActionBarItemsState] = useState<{
        cancelButtonLoading: boolean;
        resendApprovalBtnLoading: boolean;
        resendApprovalBtnDisabled: boolean;
    }>({
        cancelButtonLoading: true,
        resendApprovalBtnDisabled: true,
        resendApprovalBtnLoading: false
    });

    let isResendApprovalAllowed = false;
    if (
        isEmpty(rolePermissions.RESEND_PAYMENT_PLAN_APPROVAL_CONTENT) ||
        includes(
            rolePermissions.RESEND_PAYMENT_PLAN_APPROVAL_CONTENT,
            selectedUserCompany.Role.Name
        )
    ) {
        isResendApprovalAllowed = true;
    }

    /**
     * Function that checks if there are filters applied and saved in redux.
     * Returns a boolean.
     */
    const getHasFiltersOnRedux = () => {
        let hasFiltersOnRedux = false;
        forEach(paymentPlansState.filters, (filterValue: any) => {
            if (!isEmpty(filterValue)) {
                hasFiltersOnRedux = true;
                return false; // terminates the foreach
            }
        });

        return hasFiltersOnRedux;
    };

    const [showConditions, setShowConditions] = useState<{
        filterBar: boolean;
        filterEllipsis: boolean;
        addCommentDrawer: boolean;
        createNewPaymentPlanDrawer: boolean;
        resendApprovalDrawer: boolean;
        editPaymentPlanDrawer: boolean;
        cancelDrawer: boolean;
        allSelected: boolean;
        downloadToExcel: boolean;
        createScheduledReport: boolean;
    }>({
        filterBar: getHasFiltersOnRedux(),
        filterEllipsis: false,
        addCommentDrawer: false,
        createNewPaymentPlanDrawer: false,
        resendApprovalDrawer: false,
        editPaymentPlanDrawer: false,
        cancelDrawer: false,
        allSelected: false,
        downloadToExcel: false,
        createScheduledReport: false
    });

    const selectedPaymentPlanState = get(paymentPlansState, ['paymentPlanState']);
    const [tableSortState, setTableSortState] = useState<{
        sortBy: string;
        sortAscending: boolean;
        paymentPlanState: string;
    }>({
        sortBy: paymentPlansState.sortBy,
        sortAscending: paymentPlansState.sortAscending,
        paymentPlanState: selectedPaymentPlanState,
    });

    const [tableCurrentPage, setTableCurrentPage] = useState<number>(
        get(paymentPlansState, 'pageData.currentPage', 0)
    );

    /**
     * Function that prepares the payload for the fetch request (either in table or excel report).
     * @param currentPage
     * @param pageSize
     */
    const generatePayloadForRequest = (
        currentPage: number,
        pageSize: number
    ) => {
        const payload: GetPaymentPlansRequestPayload = {
            filters: paymentPlansState.filters,
            sortBy: tableSortState.sortBy,
            sortAscending: tableSortState.sortAscending,
            pageSize,
            currentPage: currentPage,
            paymentPlanState: paymentPlansState.paymentPlanState
        };

        return payload;
    };

    /**
    * Function for preparing the filters to be used as payload for API.
    */
    const readyAllFiltersSelected = () => {
        let allFilters: DynamicObject = {
            ...paymentPlansState.filters,
        };
        return allFilters;
    };

    /**
     * Function to get used sort options
     */
    const getPaymentPlansSortByOptions = () => {
        return getUsedSortByOptionsIfCloud(paymentPlansSortByOptions, false);
    };

    /**
     * Function for checking if sort value is in options and set the first one if not.
     */
    const checkSortBySelectedIfAvailable = () => {
        if (!selectedUserCompany) return;
        const usedSortByOptions = getPaymentPlansSortByOptions();
        const usedSortValues = map(
            usedSortByOptions,
            (sortOpt) => sortOpt.value
        );
        forEach(
            customFieldsFilterList,
            ({ Type, FieldName }: CompanyCustomFieldConfigure) => {
                usedSortValues.push(
                    `${customFieldIndicator}${Type}--${FieldName}`
                );
            }
        );
        if (!includes(usedSortValues, paymentPlansState.sortBy)) {
            setTimeout(() => {
                const newSortValues = {
                    ...tableSortState,
                    sortBy: initialState.sortBy,
                };

                updateTableSortStateObject(newSortValues);
                dispatch(updatePaymentPlansSortByAndStateAction(newSortValues));
            }, 200);
        }
    };

    useEffect(checkSortBySelectedIfAvailable, [
        selectedUserCompany,
        paymentPlansState.sortBy,
    ]);

    /**
     * Function that calls an action which triggers a saga for fetching the PaymentPlans data.
     * @param currentPage - current page to fetch data from
     * @param pageSize - number of items in page
     */
    const fetchPaymentPlans = (
        currentPage = tableCurrentPage,
        pageSize = tablePageSize
    ) => {
        if (isEmpty(selectedUserCompany)) return;

        const payload = generatePayloadForRequest(currentPage, pageSize);

        if (!isRefetching) resetTableScroll = false;
        dispatch(getPaymentPlansRequestAction(payload));
    };

    /**
     * Function called in preparation for fetching the paymentPlans data.
     * Manages the page to be called.
     */
    const handleFetch = () => {
        if (
            isUndefined(initialTableFilter) ||
            isEmpty(selectedUserCompany) ||
            paymentPlansState.loading
        )
            return;

        if (!paymentPlansState.pageData.hasNextPage) return;

        const nextPage = tableCurrentPage + 1;
        setTableCurrentPage(nextPage);
        fetchPaymentPlans(nextPage);
    };

    /**
     * Function that fetches the custom fields filter list
     */
    const getCustomFieldsFilterList = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');

        if (companyIdCognito === selectedCompanyId) {
            dispatch(
                getCustomFieldsFilterListByTypes(
                    [CUSTOM_FIELD_TYPES.CUSTOMER], // Filters are sorted based on the orders of types here and Number property
                    (response: any) => {
                        if (response.IsSuccess) {
                            setCustomFieldsFilterList(response.CustomFields);
                        }
                    }
                )
            );
        }
    };

    useEffect(getCustomFieldsFilterList, [selectedUserCompany]);

    /**
     * Listener function for calling the fetch paymentPlans function.
     * Can be triggered upon changes on selected user company, filter values, sort values, and paymentPlan state filter update.
     */
    const callFetchPaymentPlans = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');

        if (companyIdCognito === selectedCompanyId) {
            if (lastSelectedCompanyId !== selectedCompanyId) {
                lastSelectedCompanyId = selectedCompanyId;
                resetAllSelectedRowKeys();
                checkAllTableFiltersOnCompanySwitch();
                resetTableScrollAndPageData();
            }
            fetchPaymentPlans(0);
        }
    };

    /**
     * Function for comparing component and redux state then setting the correct values.
     */
    const checkAllTableFiltersOnCompanySwitch = () => {
        const { filters, sortBy, sortAscending } = paymentPlansState;
        if (!isEqual(filters, tableFilters)) {
            setTableFilters(filters);
        }

        if (
            sortBy !== tableSortState.sortBy ||
            sortAscending !== tableSortState.sortAscending ||
            selectedPaymentPlanState !== tableSortState.paymentPlanState
        ) {
            updateTableSortStateObject({
                sortBy,
                sortAscending,
                PaymentPlanState: selectedPaymentPlanState
            });
        }
    };

    useEffect(callFetchPaymentPlans, [
        paymentPlansState.sortBy,
        paymentPlansState.sortAscending,
        paymentPlansState.filters,
        selectedPaymentPlanState,
        selectedUserCompany,
    ]);

    /**
     * Listener function called when the table filter value for the dropdown at the upper left has been changed.
     */
    const listenToPreConfiguredFilter = () => {
        if (skipListenToPreconfiguredFilter) return;
        const { tableFilter } = paymentPlansState;
        if (tableFilter === initialTableFilter) {
            closeFilterBar();

            if (fetchPaymentPlansRequired) {
                fetchPaymentPlans(0);
                fetchPaymentPlansRequired = false;
            }
        } else {
            handlePageViewSelection(
                tableFilter,
                applyFilters,
                actionBarRef.current,
                pageName
            );
        }
    };

    useEffect(listenToPreConfiguredFilter, [paymentPlansState.tableFilter]);

    // on Unmount
    useEffect(() => {
        return () => {
            lastSelectedCompanyId = null;
            skipListenToPreconfiguredFilter = true;
        };
    }, []);

    const selectedKeysName = 'Id';

    /**
     * Listener function responsible for showing the state of checkboxes (checked/unchecked) upon data update.
     */
    const checkRowSelectionState = () => {
        if (showConditions.allSelected) {
            const selectedKeys = difference(
                map(paymentPlansState.data, selectedKeysName),
                tableRowSelection.unselectedRowKeys
            );

            updateTableRowSelection({
                selectedRowKeys: selectedKeys,
            });
        }
    };

    useEffect(checkRowSelectionState, [paymentPlansState.data]);

    /**
     * Action Bar Functions
     */
    /**
     * Function called when paymentPlan table filter (upper-left dropdown next to refresh button) is updated.
     * @param filter - dropdown view value
     * @param refetch - boolean indicator if fetching of items is to be called
     */
    const changePaymentPlansTableFilter = (
        filter: string,
        refetch: boolean = true
    ) => {
        if (!initialTableFilter) {
            initialTableFilter = filter;
        } else {
            if (filter !== initialTableFilter) {
                updateShowConditionsObject({
                    allSelected: false,
                    filterBar: true,
                });
            }
        }

        if (filter !== paymentPlansState.tableFilter) {
            skipListenToPreconfiguredFilter = false;
        }

        setPaymentPlansTableFilter(filter);
        resetTableScrollAndPageData();
        if (refetch && filter === paymentPlansState.tableFilter) {
            handlePaymentPlanFilterRefresh();
        } else {
            dispatch(updatePaymentPlansTableFilterAction(filter));
        }
    };

    /**
     * Function called when the refresh button is clicked.
     */
    const handlePaymentPlanFilterRefresh = () => {
        fetchPaymentPlansRequired = true;
        skipListenToPreconfiguredFilter = false;
        resetAllSelectedRowKeys();
        refetchListAndResetScroll();
    };

    /**
     * Function responsible for refetching tasks data after an update or when clicking the refresh button.
     */
    const refetchListAndResetScroll = () => {
        isRefetching = true;
        resetTableScrollAndPageData();
        fetchPaymentPlans(0);
    };

    /**
 * Function that checks the ready state of the selected payment plans.
 * To be used to enable/disable the `Cancel` button.
 * @param selectedRowKeys - array of selected keys
 * @param unselectedRowKeys - array of unselected keys
 */
    const checkPaymentPlansActionStateForSelectedPaymentPlans = (
        selectedRowKeys?: string[],
        unselectedRowKeys?: string[]
    ) => {
        updateActionBarItemsState({
            cancelButtonLoading: true,
            resendApprovalBtnLoading: isResendApprovalAllowed
        });

        const { allExcept, keysToUse, filterObject } = getSelectedPaymentPlansValues(
            selectedRowKeys,
            unselectedRowKeys
        );

        const payload: CheckPaymentPlansActionStateRequestPayload = {
            filters: filterObject,
            sortBy: tableSortState.sortBy,
            sortAscending: tableSortState.sortAscending,
            pageSize: tablePageSize,
            currentPage: 0,
            paymentPlanIds: keysToUse,
            excludePaymentPlans: allExcept,
            callback: (paymentPlansActionState: any) => {
                updateActionBarItemsState({
                    cancelButtonLoading: !paymentPlansActionState.ActionEnabled,
                    resendApprovalBtnDisabled: !isResendApprovalAllowed || !paymentPlansActionState.ActionEnabled,
                    resendApprovalBtnLoading: false,
                });
            },
        };

        dispatch(checkPaymentPlansActionStateAction(payload));
    };

    /**
    * Common function for updating the action bar items state.
    * @param itemStateObject - must conform to actionBarItemsState object
    */
    const updateActionBarItemsState = (itemStateObject: {}) => {
        setActionBarItemsState({
            ...actionBarItemsState,
            ...itemStateObject,
        });
    };

    /**
     * Function for getting the selected paymentPlans values.
     * @param selectedRowKeys
     * @param unselectedRowKeys
     */
    const getSelectedPaymentPlansValues = (
        selectedRowKeys?: string[],
        unselectedRowKeys?: string[]
    ) => {
        let allExcept = false;
        let keysToUse = selectedRowKeys || [
            ...tableRowSelection.selectedRowKeys,
        ];

        if (showConditions.allSelected) {
            allExcept = true;
            keysToUse = unselectedRowKeys || [
                ...tableRowSelection.unselectedRowKeys,
            ];
        }
        const filters = readyAllFiltersSelected();
        const filterObject = {
            ...filters,
        };

        return {
            allExcept,
            keysToUse,
            filterObject,
        };
    };

    /**
     * Function for populating the dropdown render of the table filter (upper-left).
     * @param menu - menu items component
     */
    const populatePaymentPlanFilterSelectDropdownRender = (menu: any) => (
        <div>
            {menu}
            {/* <Divider className="action-bar-divider" />
             <div
                 className="pa-12 cursor-p"
                 onMouseDown={() => console.log('Add Custom View Clicked')}
             >
                 Add custom view
             </div> */}
        </div>
    );

    /**
     * Function for populating the `Select/Deselect all` button label.
     */
    const populateSelectDeselectAllLabel = () => {
        const allDataLoaded = paymentPlansState.pageData.hasNextPage === false;

        let selectDeselectLabel = '';
        if (
            !isEmpty(paymentPlansState.data) &&
            ((tableRowSelection.unselectedRowKeys.length === 0 &&
                showConditions.allSelected) ||
                (allDataLoaded &&
                    paymentPlansState.data.length ===
                    tableRowSelection.selectedRowKeys.length))
        ) {
            selectDeselectLabel = 'Deselect';
        } else {
            selectDeselectLabel = 'Select';
        }

        return `${selectDeselectLabel} all`;
    };

    /**
     * Function triggered when the select/deselect all button is clicked.
     */
    const selectAllRows = () => {
        const selectAllCondition =
            isEmpty(tableRowSelection.selectedRowKeys) ||
            tableRowSelection.selectedRowKeys.length <
            paymentPlansState.data.length;

        updateShowConditionsObject({
            allSelected: selectAllCondition,
        });

        if (selectAllCondition) {
            checkPaymentPlansActionStateForSelectedPaymentPlans([], []);
            const idKeys: string[] = map(
                paymentPlansState.data,
                selectedKeysName
            );
            updateTableRowSelection({
                selectedRowKeys: idKeys,
                unselectedRowKeys: [],
            });
        } else {
            updateTableRowSelection({
                selectedRowKeys: [],
            });
        }
    };

    /**
     * Function called when the sort order button link is clicked (inside the ellipsis popover).
     * @param sortAscending - boolean indicator if sort order is ascending
     */
    const changeSortOrder = async (sortAscending: boolean) => {
        await resetTableScrollAndPageData();
        dispatch(
            updatePaymentPlansSortByAndStateAction({
                ...tableSortState,
                sortAscending,
            })
        );
    };

    const downloadDisabled =
        paymentPlansState.loading || isEmpty(paymentPlansState.data);

    /**
     * Function for populating the upper section of ellipsis popover.
     */
    const populateFilterEllipsisPopoverTitle = () => (
        <div className="pop-action-title">
            <Button
                type="link"
                disabled={downloadDisabled}
                onClick={() => {
                    setShowConditions((prevState: { filterBar: boolean }) => ({
                        ...showConditions,
                        filterEllipsis: false,
                        filterBar: !prevState.filterBar,
                    }));
                }}
            >
                <FontAwesome icon={['fas', 'filter']} className="mr-10" />
                {getTranslatedText("Filter")}
            </Button>
            <br />
            <Button
                type="link"
                disabled={downloadDisabled}
                onClick={() => {
                    setTableSortState((prevState: any) => {
                        const sortOrder = !prevState.sortAscending;
                        changeSortOrder(sortOrder);
                        return {
                            ...prevState,
                            sortAscending: sortOrder,
                        };
                    });
                }}
            >
                <FontAwesome
                    icon={[
                        'fas',
                        `sort-amount-${paymentPlansState.sortAscending ? 'down' : 'up-alt'
                        }`,
                    ]}
                    className="mr-10"
                />
                {getTranslatedText("Change sort order")}
            </Button>
            <br />
            <Button
                type="link"
                onClick={downloadToExcelHandler}
                disabled={downloadDisabled}
            >
                <FontAwesome
                    icon={['fas', 'cloud-download-alt']}
                    className="mr-10"
                />
                {getTranslatedText("Download to Excel")}
            </Button>
            <br /> 
            <Button
                type="link"
                onClick={createScheduledReportHandler}
                // disabled={downloadDisabled}
            >
                    <FontAwesome
                        icon={['far', 'calendar']}
                        className={`icon calendar`}
                    />
                    <FontAwesome
                        icon={['fas', 'clock']}
                        className={`icon clock`}
                    />
                <span className='icon-button-wrapper'>{getTranslatedText('Create Scheduled Report')}</span>
            </Button>
        </div>
    );

    /**
     * Function called when Download to Excel button is clicked.
     */
    const downloadToExcelHandler = () => {
        updateShowConditionsObject({
            downloadToExcel: true,
            filterEllipsis: false,
        });

        const filterAndPageProps = generatePayloadForRequest(
            0,
            defaultPageSizeForReport
        );

        const { filters, sortBy, sortAscending } = filterAndPageProps;

        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true, 'paymentPlans', true);

        const variables = {
            ...cleanFilters,
            SortField: sortBy,
            Ascending: sortAscending
        };

        const payload = {
            Query: paymentPlansSummaryPageQuery,
            OperationName: paymentPlansSummaryQueryName,
            Variables: JSON.stringify(variables),
            PageName: PAGE_NAMES_FOR_VIEW.PAYMENT_PLANS_PAGE,
        };

        dispatch(downloadToExcelAction(payload, downloadToExcelModalResponse));
    };

      /**
     * Function called when create schedule report is closed.
     * @param resetChanges
     * @param saveChanges
     * @param containerRef
     */
      const onCreateScheduledReportEnd = (
        containerRef?: any
    ) => {
        updateShowConditionsObject({
            createScheduledReport: false,
        });
    };

    /**
     * Function called when Create Scheduled report button is clicked.
     */
    const createScheduledReportHandler = () => {
        updateShowConditionsObject({
            createScheduledReport: true,
            filterEllipsis: false,
        });

        const filterAndPageProps = generatePayloadForRequest(
            0,
            defaultPageSizeForReport
        );

        const { filters, sortBy, sortAscending } = filterAndPageProps;

        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true, 'paymentPlans');

        const variables = {
            ...cleanFilters,
            SortField: sortBy,
            Ascending: sortAscending
        };

        const payload = {
            Query: paymentPlansSummaryPageQuery,
            OperationName: paymentPlansSummaryQueryName,
            Variables: JSON.stringify(variables),
            PageName: PAGE_NAMES_FOR_VIEW.PAYMENT_PLANS_PAGE,
            title: "Payment plans",
            Type: 1
        };

        setCreateScheduleReportPayload(payload);
    };

    /**
     * Callback function after calling the download to excel api.
     * @param param0 - response with type `ResponseModalObject`
     */
    const downloadToExcelModalResponse = ({
        IsSuccess,
        Messages,
    }: ResponseModalObject) => {
        updateShowConditionsObject({
            downloadToExcel: false,
            filterEllipsis: false,
        });
        downloadToExcelModalResponseHandler(IsSuccess, Messages);
    };

    /**
     * Function for populating the lower section of ellipsis popover.
     */
    const populateFilterEllipsisPopoverContent = () => (
        <div className="pop-action-content">
            <div className="mb-10">
                <span>{getTranslatedText("Sort by")}</span>
            </div>
            <div>
                <Select
                    onChange={(sortBySelected: string) =>
                        updateTableSortStateObject({
                            sortBy: sortBySelected,
                        })
                    }
                    value={tableSortState.sortBy}
                >
                    {map(
                        getPaymentPlansSortByOptions(),
                        ({
                            label,
                            value,
                        }: {
                            label: string;
                            value: string;
                        }) => (
                            <Option key={value} value={value}>
                                {getTranslatedText(label)}
                            </Option>
                        )
                    )}
                </Select>
            </div>
            <br />
            <div className="mb-10">
                <span>{getTranslatedText("State of payment plans")}</span>
            </div>
            <div>
                <Select
                    onChange={(stateSelected: string) =>
                        updateTableSortStateObject({
                            paymentPlanState: stateSelected,
                        })
                    }
                    value={tableSortState.paymentPlanState}
                >
                    {map(
                        paymentPlansStateFilterOptions,
                        ({ label, value }: any) => (
                            <Option key={value} value={value}>
                                {getTranslatedText(label)}
                            </Option>
                        )
                    )}
                </Select>
            </div>
            <br />
            <div className="ta-right">
                <Button
                    type="primary"
                    disabled={
                        paymentPlansState.loading ||
                        (tableSortState.sortBy === paymentPlansState.sortBy
                            && tableSortState.paymentPlanState === selectedPaymentPlanState)
                    }
                    onClick={applySortedByAndState}
                >
                    {getTranslatedText("Apply")}
                </Button>
            </div>
        </div>
    );

    /**
     * Function that sets the value of the dropdown filter next to refresh button to it's initial state.
     */
    const setTableFilterToInitialState = () => {
        if (paymentPlansState.tableFilter !== initialTableFilter) {
            setPaymentPlansTableFilter(initialTableFilter);

            if (!isUndefined(initialTableFilter))
                changePaymentPlansTableFilter(initialTableFilter);

            resetAllSelectedRowKeys();
        }
    };

    /**
     * Filter Bar Functions
     */
    /**
     * Function called when applying the filters.
     * Set the filters in state and redux.
     * @param filters
     * @param fromFilterBar - boolean indicator if called from Apply filters button in FilterBar component
     */
    const applyFilters = async (
        filters?: GetPaymentPlansRequestPayload['filters'],
        fromFilterBar?: boolean
    ) => {
        if (!filters) {
            setTableFilterToInitialState();
        } else {
            if (fromFilterBar) {
                emptyPredefinedFilterOnAppliedFilters(
                    filters,
                    tableFilters,
                    paymentPlansTableFilter,
                    fromFilterBar,
                    () => {
                        changePaymentPlansTableFilter('');
                    }
                );
            }
        }

        let appliedFilters: any = filters || initialState.filters;
        let savedSortState = get(appliedFilters, 'tableSortState');
        const statusType = appliedFilters['StatusType'];
        if (!isEmpty(statusType)) {
            savedSortState = savedSortState || tableSortState;
            savedSortState.PaymentPlanState = CUSTOM_PLAN_STATE_FILTER_OPTION;
        }
        if (savedSortState) {
            setTableSortState(savedSortState);
            applySortedByAndState(savedSortState);
            delete appliedFilters.tableSortState;
        }

        await resetTableScrollAndPageData();
        await setTableFilters(appliedFilters);
        await dispatch(updatePaymentPlansFiltersAction(appliedFilters));

        await resetAllSelectedRowKeys();
    };

    /**
     * Function called when `Apply` button is clicked inside the ellipsis popover,
     * also hides the ellipsis popover after `Save` is clicked.
     * @param savedValues - optional param - used when a specific tableSortState value is passed.
     */
    const applySortedByAndState = async (savedValues?: any) => {
        await resetTableScrollAndPageData();
        const usedValues = get(savedValues, 'sortBy')
            ? savedValues
            : tableSortState;
        await dispatch(updatePaymentPlansSortByAndStateAction(usedValues));
        await updateShowConditionsObject({
            filterEllipsis: false,
        });
    };

    /**
     * Manipulate State Objects
     */
    /**
     * Function for updating the `showConditions` state.
     * @param showConditionObject - must conform to `showConditions` state
     */
    const updateShowConditionsObject = (showConditionObject: {}) => {
        setShowConditions({
            ...showConditions,
            ...showConditionObject,
        });
    };

    /**
     * Function for updating the `tableRowSelection` state.
     * @param selectionObject - must conform to `tableRowSelection` state
     */
    const updateTableRowSelection = (selectionObject: {}) => {
        setTableRowSelection({
            ...tableRowSelection,
            ...selectionObject,
        });
    };

    /**
     * Function called to close the paymentPlan details drawer.
     * Clears the data saved during drawer close.
     */
    const closePaymentPlanDetailsDrawer = () => {
        setPaymentPlanDetailsDrawer({
            visible: false,
            activeTab: undefined,
        });
    };

    /**
     * Function to update the `tableSortState` state.
     * @param tableSortStateObject - must conform to `tableSortState`
     */
    const updateTableSortStateObject = (tableSortStateObject: {}) => {
        setTableSortState({
            ...tableSortState,
            ...tableSortStateObject,
        });
    };

    /**
     * Function called when clicking on checkbox.
     * @param record - record for the row where the ticked checkbox is in
     */
    const onCheckboxClick = (record: DynamicObject) => {
        const selectedRowKeys = [...tableRowSelection.selectedRowKeys];
        const isCurrentlySelected = includes(selectedRowKeys, record.key);
        const newSelectedRowKeys = !isCurrentlySelected
            ? [...selectedRowKeys, record.key]
            : remove(selectedRowKeys, (key: string) => key !== record.key);

        onRowSelect(record, !isCurrentlySelected, newSelectedRowKeys, true);
    };

    /**
     * Function called when row is clicked.
     * @param record - data where the row is clicked
     * @param activeTab - kind of panel to show based on the row clicked
     */
    const onRowClick = (record: DynamicObject, activeTab?: string) => {
        dispatch(
            setPaymentPlanSelectedIdRequestAction(get(record, 'Id'), () => {
                setPaymentPlanDetailsDrawer({
                    visible: true,
                    activeTab,
                });
            })
        );
    };

    /**
     * Function for populating the table loading text.
     */
    const populateTableLoadingText = () => {
        const loadingText = `Fetching ${tableCurrentPage === 0 || isRefetching ? 'list of' : 'more' } payment plans`;

        isRefetching = false;

        return loadingText;
    };

    /**
     * Function called when the row is selected (left side checkbox is ticked).
     * @param record - data for the selected row
     * @param selected - boolean indicator if the row is selected/unselected
     * @param selectedRows - selected row keys
     * @param nativeEvent - event fired when the row is selected
     */
    const onRowSelect = (
        record: DynamicObject,
        selected: boolean,
        selectedRows: any,
        nativeEvent: any
    ) => {
        // nativeEvent overridden
        const selectedRowKeys =
            nativeEvent === true ? [...selectedRows] : map(selectedRows, 'key');

        let unselectedRowKeys = [];
        if (selected) {
            unselectedRowKeys = filter(
                tableRowSelection.unselectedRowKeys,
                (unselectedKey: string) => unselectedKey !== record.key
            );
        } else {
            unselectedRowKeys = showConditions.allSelected
                ? [...tableRowSelection.unselectedRowKeys, record.key]
                : [];
        }

        checkPaymentPlansActionStateForSelectedPaymentPlans(selectedRowKeys, unselectedRowKeys);

        const customerIds: string[] = [];
        forEach(paymentPlansState.data, (paymentPlanData) => {
            if (includes(selectedRowKeys, paymentPlanData.Id)) {
                const customerId = get(paymentPlanData, 'Customer.Id');
                if (!includes(customerIds, customerId))
                    customerIds.push(customerId);
            }
        });

        updateTableRowSelection({
            selectedRowKeys,
            unselectedRowKeys,
        });
    };

    /**
     * Function to control the visibility of popovers.
     * @param name - name of popover to change the visibility
     * @param condition - boolean indicator to apply the condition
     */
    const popoverOnVisibleChange = (name: string, condition?: boolean) => {
        return (visible: boolean) => {
            if (condition === undefined || condition === true) {
                let visibilityCondition = visible;
                if (name === 'addCommentDrawer' || name === 'cancelDrawer' || name === 'createNewPaymentPlanDrawer' || name === 'editPaymentPlanDrawer') {
                    visibilityCondition = !showConditions[name];
                    updateShowConditionsObject({
                        [name]: visibilityCondition,
                    });
                } else {
                    updateShowConditionsObject({
                        [name]: visible,
                    });
                }
            }
        };
    };

    /**
     * Function for resetting the table scroll and current page.
     */
    const resetTableScrollAndPageData = async () => {
        resetTableScroll = true;
        await setTableCurrentPage(0);
    };

    /**
     * Function for resetting all the selected/unselected row keys.
     */
    const resetAllSelectedRowKeys = () => {
        // reset Selected Row Keys after change role success
        updateTableRowSelection({
            selectedRowKeys: [],
            unselectedRowKeys: [],
        });
        updateShowConditionsObject({
            allSelected: false,
        });
    };

    /**
     * Function for closing the filter bar section.
     * Resets the applied filters to initial state.
     */
    const closeFilterBar = async () => {
        await applyFilters();
        updateShowConditionsObject({
            filterBar: false,
        });
    };

    // /**
    //  * Function called to refresh the paymentPlan list after an update.
    //  */
    // const refetchPaymentPlansListAfterEditing = () => {
    //     isRefetching = true;
    //     const pageSizeUsed =
    //         paymentPlansState.data.length < tablePageSize
    //             ? paymentPlansState.data.length
    //             : tablePageSize;
    //     fetchPaymentPlans(0, pageSizeUsed);
    // };

    /**
     * Function called to close the add comment panel
     * @param refreshList
     */
    const closePaymentPlanPanel =
        (conditionBool: string) => (refreshList?: boolean) => {
            updateShowConditionsObject({
                [conditionBool]: false,
            });

            if (refreshList) {
                refetchListAndResetScroll();
            }
        };

    /**
     * Function for populating the payload needed for paymentPlan add comment.
     */
    const getPaymentPlanFilterPayload = () => {
        const { allExcept, keysToUse, filterObject } =
            getSelectedPaymentPlansValues();

        return {
            filter: filterObject,
            paymentPlanIds: keysToUse,
            excludePaymentPlans: allExcept,
        };
    };

    /**
     * Function that checks if the Name of the page view to be saved already exists.
     * @param name - name of page view
     */
    const doesViewNameExist = (name: string) => {
        if (actionBarRef.current)
            return actionBarRef.current.doesViewNameExist(name);
    };

    return (
        <Col span={24}>
            <QueueAnim type={['right', 'left']} leaveReverse>
                <Row key="title-container">
                    <Col span={24}>
                        <Title level={3}>{getTranslatedText("Payment Plans")}</Title>
                    </Col>
                </Row>
                <div className="spacer-15" />
                {/* Filter Bar */}
                <QueueAnim type="top" leaveReverse duration={300}>
                    {showConditions.filterBar && (
                        <div key="filter-bar-container">
                            <Suspense fallback={null}>
                                <FilterBar
                                    pageName={pageName}
                                    loading={paymentPlansState.loading}
                                    applyFilters={applyFilters}
                                    filters={getPaymentPlansFilterBarFilters()}
                                    filterValues={paymentPlansState.filters}
                                    colDivision={4}
                                    closeAllPopovers={
                                        paymentPlanDetailsDrawer.visible
                                    }
                                    closeFilterBar={closeFilterBar}
                                    appliedView={paymentPlansTableFilter}
                                    doesViewNameExist={doesViewNameExist}
                                    // customFieldsFilters={customFieldsFilterList}
                                    tableSortState={{
                                        sortBy: paymentPlansState.sortBy,
                                        sortAscending: paymentPlansState.sortAscending,
                                        PaymentPlanState: selectedPaymentPlanState,
                                    }}
                                />
                            </Suspense>
                        </div>
                    )}
                    <CreateScheduledReportDrawer
                        visible={showConditions.createScheduledReport}
                        onClose={onCreateScheduledReportEnd}
                        widgetQuery={createScheduleReportPayload}
                    />
                </QueueAnim>
                <div key="action-bar-container">
                    <ActionBar
                        ref={actionBarRef}
                        pageName={pageName}
                        loading={paymentPlansState.loading}
                        filterBarOpen={showConditions.filterBar}
                        actionItems={[
                            {
                                actionKey: 'paymentPlan-filter',
                                actionType: 'select-with-button',
                                selectValue:
                                    paymentPlansTableFilter ||
                                    'All payment plans',
                                selectDropdownRender:
                                    populatePaymentPlanFilterSelectDropdownRender,
                                onSelectChange: changePaymentPlansTableFilter,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fa', 'sync']}
                                            className="mr-8"
                                        />
                                        <span>{getTranslatedText("Refresh")}</span>
                                    </>
                                ),
                                buttonDisabled: paymentPlansState.loading,
                                onButtonClick: handlePaymentPlanFilterRefresh,
                            },
                            {
                                actionKey: 'paymentPlan-select-all',
                                actionType: 'protected-button',
                                buttonDisabled: downloadDisabled,
                                onButtonClick: selectAllRows,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fas', 'check-double']}
                                        />
                                        <span>
                                            {getTranslatedText(populateSelectDeselectAllLabel())}
                                        </span>
                                    </>
                                ),
                            },
                            {
                                actionKey: 'paymentPlan-cancel',
                                actionType: 'protected-drawer-button',
                                popoverVisible: showConditions.cancelDrawer,
                                drawerCloseable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'cancelDrawer',
                                    !isEmpty(tableRowSelection.selectedRowKeys)
                                ),
                                popoverTitle: getTranslatedText("Cancel payment plans"),
                                popoverContent: (
                                    <PaymentPlanCancelPanel
                                        visible={showConditions.cancelDrawer}
                                        closePanel={closePaymentPlanPanel(
                                            'cancelDrawer'
                                        )}
                                        filterPayload={getPaymentPlanFilterPayload()}
                                        dispatchAction={
                                            cancelPaymentPlanRequestAction
                                        }
                                    />
                                ),
                                buttonDisabled:
                                    isEmpty(
                                        tableRowSelection.selectedRowKeys
                                    ) ||
                                    actionBarItemsState.cancelButtonLoading
                                ,
                                buttonContent: (
                                    <>
                                        <FontAwesome icon={['fas', 'ban']} />
                                        <span>{getTranslatedText("Cancel plan")}</span>
                                    </>
                                ),
                                drawerWidth: 500,
                            },
                            {
                                actionKey: 'paymentPlan-add-comment',
                                actionType: 'protected-drawer-button',
                                popoverVisible: showConditions.addCommentDrawer,
                                drawerCloseable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'addCommentDrawer',
                                    !isEmpty(tableRowSelection.selectedRowKeys)
                                ),
                                popoverTitle: getTranslatedText("Add a comment"),
                                popoverContent: (
                                    <AddCommentPanel
                                        visible={
                                            showConditions.addCommentDrawer
                                        }
                                        closePanel={closePaymentPlanPanel(
                                            'addCommentDrawer'
                                        )}
                                        filterPayload={getPaymentPlanFilterPayload()}
                                        dispatchAction={
                                            paymentPlanAddCommentRequestAction
                                        }
                                    />
                                ),
                                buttonDisabled: isEmpty(
                                    tableRowSelection.selectedRowKeys
                                ),
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fas', 'comment']}
                                        />
                                        <span>{getTranslatedText("Add comment")}</span>
                                    </>
                                ),
                                drawerWidth: 500,
                            },
                            {
                                actionKey: 'paymentPlan-create-new-payment-plan',
                                actionType: 'protected-drawer-button',
                                popoverVisible: showConditions.createNewPaymentPlanDrawer,
                                drawerCloseable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'createNewPaymentPlanDrawer',
                                    true
                                ),
                                buttonDisabled: !isCreateNewPaymentPlanAllowed,
                                popoverTitle: getTranslatedText("Create new payment plan"),
                                popoverContent: (
                                    <CreateNewPaymentPlanPanelWrapper
                                        visible={
                                            showConditions.createNewPaymentPlanDrawer
                                        }
                                        closePanel={closePaymentPlanPanel(
                                            'createNewPaymentPlanDrawer'
                                        )}
                                        dispatchAction={
                                            manualCreateNewPaymentPlanRequestAction
                                        }
                                    />
                                ),
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fas', 'plus-circle']}
                                        />
                                        <span>{getTranslatedText("Create new payment plan")}</span>
                                    </>
                                ),
                                drawerWidth: 800,
                            },
                            {
                                actionKey: 'payment-plan-resend-approval',
                                actionType: 'protected-drawer-button',
                                popoverVisible: showConditions.resendApprovalDrawer,
                                drawerCloseable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'resendApprovalDrawer',
                                    true
                                ),
                                buttonDisabled: isEmpty(tableRowSelection.selectedRowKeys)
                                    || actionBarItemsState.resendApprovalBtnDisabled
                                    || actionBarItemsState.resendApprovalBtnLoading,
                                popoverTitle: getTranslatedText("Resend approval content"),
                                popoverContent: (
                                    <ResendPaymentPlanApprovalPanel
                                        closePanel={closePaymentPlanPanel(
                                            'resendApprovalDrawer'
                                        )}
                                        filterPayload={getPaymentPlanFilterPayload()}
                                        dispatchAction={
                                            resendPaymentPlanApprovalRequestAction
                                        }
                                    />
                                ),
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fas', 'paper-plane']}
                                        />
                                        <span>{getTranslatedText("Resend approval content")}</span>
                                    </>
                                )
                            },
                            {
                                actionKey: 'paymentPlan-edit-payment-plan',
                                actionType: 'protected-drawer-button',
                                popoverVisible: showConditions.editPaymentPlanDrawer,
                                drawerCloseable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'editPaymentPlanDrawer',
                                    !isEmpty(tableRowSelection.selectedRowKeys)
                                ),
                                buttonDisabled: editButtonDisabled(),
                                popoverTitle: getTranslatedText("Edit"),
                                popoverContent: (
                                    <EditPaymentPlanPanel
                                        visible={
                                            showConditions.editPaymentPlanDrawer
                                        }
                                        closePanel={closePaymentPlanPanel(
                                            'editPaymentPlanDrawer'
                                        )}
                                        dispatchAction={
                                            updatePaymenPlanScheduleRequestAction
                                        }
                                        paymentPlanId={tableRowSelection.selectedRowKeys[0]}
                                    />
                                ),
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fas', 'edit']}
                                        />
                                        <span>{getTranslatedText("Edit")}</span>
                                    </>
                                ),
                                drawerWidth: 500,
                            }
                        ]}
                        actionEllipsis={{
                            popoverVisible: showConditions.filterEllipsis,
                            popoverOnVisibleChange:
                                popoverOnVisibleChange('filterEllipsis'),
                            popoverTitle: populateFilterEllipsisPopoverTitle(),
                            popoverContent:
                                populateFilterEllipsisPopoverContent(),
                            buttonContent: (
                                <FontAwesome icon={['fas', 'ellipsis-h']} />
                            ),
                        }}
                    />
                </div>
                <div className="spacer-15" />
                {/* Table Section */}
                <Row key="table-container">
                    <Col span={24}>
                        <VirtualizedList
                            dataSource={paymentPlansState.data}
                            fetchMore={handleFetch}
                            scroll={computeTableScroll(
                                window.innerHeight - 185,
                                tablePageSize,
                                PAYMENT_PLANS_PAGE.rowHeight
                            )}
                            resetTableScroll={resetTableScroll}
                            selectedRowKeys={tableRowSelection.selectedRowKeys}
                            rerenderTrigger={tableRowSelection.selectedRowKeys}
                            onRowClick={onRowClick}
                            onCheckboxClick={onCheckboxClick}
                            loading={paymentPlansState.loading}
                            loadingText={populateTableLoadingText()}
                            emptyText={
                                !isEmpty(paymentPlansState.errorMessages)
                                    ? get(paymentPlansState, 'errorMessages.0')
                                    : getTranslatedText("No payment plans found")
                            }
                            hasNextPage={paymentPlansState.pageData.hasNextPage}
                            itemComponent={PaymentPlanItemComponent}
                            itemHeight={PAYMENT_PLANS_PAGE.rowHeight}
                            selectedId={selectedId}
                            extraData={{
                                addCommentDrawerVisible:
                                    showConditions.addCommentDrawer,
                            }}
                        />
                    </Col>
                    <Suspense fallback={null}>
                        <PaymentPlanItemDetailsDrawerComponent
                            visible={paymentPlanDetailsDrawer.visible}
                            activeTab={paymentPlanDetailsDrawer.activeTab}
                            closeDrawer={closePaymentPlanDetailsDrawer}
                            refetchList={refetchListAndResetScroll}
                        />
                    </Suspense>
                    {showConditions.downloadToExcel && (
                        <Suspense fallback={null}>
                            <ModalWithSpinner
                                modalTitle={getTranslatedText(populateDownloadToExcelModalTitle())}
                                modalVisible={showConditions.downloadToExcel}
                                displayMessage={getTranslatedText(populateDownloadToExcelModalDisplayMessage())}
                            />
                        </Suspense>
                    )}
                </Row>
            </QueueAnim>
        </Col>
    );
};

export default withPageViewHandler(
    withAccountingSystemHandler(PaymentPlansManagementPage)
);
