import { action } from 'typesafe-actions';
import { PageData,SupportTicketActionTypes } from './types';
import { ResponseModalObject,DynamicObject } from '../../utils/commonInterfaces';

/**
 * Function for submitting support ticket.
 */
export const submitSupportTicketRequestAction = (
    payload: DynamicObject,    
    callback?: (response: DynamicObject) => void

) => action(SupportTicketActionTypes.SUBMIT_SUPPORT_TICKET_REQUEST, {
        payload,
        callback
    });
export const submitSupportTicketResponseAction = () =>
    action(SupportTicketActionTypes.SUBMIT_SUPPORT_TICKET_RESPONSE);

/**
 * Function for attaching file to a support ticket.
 */
export const attachSupportTicketRequestAction = (
    payload: DynamicObject,    
    callback?: (response: DynamicObject) => void

) => action(SupportTicketActionTypes.ATTACH_SUPPORT_TICKET_REQUEST, {
        payload,
        callback
    });

    /**
 * Function for fetching support ticket lists.
 */
export const getSupportTicketListsRequestAction = (   
    payload: DynamicObject,    
    callback?: (response: DynamicObject) => void,
    refetchCount: number = 1
) => action(SupportTicketActionTypes.GET_SUPPORT_TICKET_LISTS_REQUEST, {
        payload,
        callback,
        refetchCount
    });

export const getSupportTicketListsSuccessAction = (payload: {
    data: DynamicObject[];
    pageData: PageData;
    mergeData?: boolean;
}) => action(SupportTicketActionTypes.GET_SUPPORT_TICKET_LISTS_SUCCESS, payload);

// Single Record
export const setSelectedSupportTicketIdRequestAction = (
    supportTicketId: string | null,
    callback: () => void
) =>
    action(SupportTicketActionTypes.SET_SUPPORT_TICKET_SELECTED_ID_REQUEST, {
        supportTicketId,
        callback,
    });

export const setSelectedSupportTicketIdSuccessAction = (supportTicketId: string | null) =>
    action(SupportTicketActionTypes.SET_SUPPORT_TICKET_SELECTED_ID_SUCCESS, supportTicketId);

/**
 * Actions for getting support ticket data.
 */
export const getSupportTicketDataRequestAction = (
    supportTicketId: string
) =>
    action(SupportTicketActionTypes.GET_SUPPORT_TICKET_DATA_REQUEST, {
        supportTicketId
    });

export const getSupportTicketDataSuccessAction = (payload: { record: {} }) =>
    action(SupportTicketActionTypes.GET_SUPPORT_TICKET_DATA_SUCCESS, payload);
export const getSupportTicketDataErrorAction = (payload: string[]) =>
    action(SupportTicketActionTypes.GET_SUPPORT_TICKET_DATA_ERROR, payload);

export const clearSupportTicketDetailsDataAction = () =>
    action(SupportTicketActionTypes.CLEAR_SUPPORT_TICKET_DETAILS_DATA_SUCCESS);
    


