import { Button, Col, Modal, Row } from 'antd';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import WorkflowConfigurationForm from './WorkflowConfigurationForm';
import { ContentStateMapping, UpdateWorkflowRequestPayload, WorkflowConfiguration, WorkflowDefinitionUpdateModel } from '../../store/workflow/types';
import { getPopoverContainer, getTranslatedText } from '../../utils/commonFunctions';
import { confirmModalCancelText, confirmModalOkText } from '../../config/config';
import WorkflowDefinitionForm from './WorkflowDefinitionFormComponent';
import { find, get, isEmpty, map } from 'lodash';
import { updateWorkflowRequestAction } from '../../store/workflow/actions';
import { useDispatch, useSelector } from 'react-redux';
import ModalWithSpinner from '../common/ModalWithSpinner';
import { workflowTemplateOptions } from '../../constants/workflowsSortAndFilters';
import { DANGER_COLOR } from '../../constants/common';
import { getTaskWorkflowsRequestAction } from '../../store/tasks/actions';
import { TasksState } from '../../store/tasks/types';
import { getTaskWorkflows } from '../../store/tasks/sagas';

interface IProps {
    readonly containerRef?: any;
    readonly closePanel?: (refresh?: boolean) => void;
    readonly isCreateNew: boolean;
    readonly workflowConfiguration?: WorkflowConfiguration;
    readonly initialPayload?: UpdateWorkflowRequestPayload;
}

const WorkflowDetailsPanelContent: React.FC<IProps> = ({
    containerRef, closePanel, isCreateNew, workflowConfiguration, initialPayload
}) => {
    const functionRef = useMemo(() => ({
        onSubmit: [] as (() => void)[]
    }), []);
    const dispatch = useDispatch();
    const {
        errorMessages: taskWorkflowsErrors,
        loading: taskWorkflowsLoading
    }: TasksState['taskWorkflows'] = useSelector(getTaskWorkflows);
    const stepDataChanged = useMemo<boolean[]>(() => [false, false], []);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [nextButtonLoading, setNextButtonLoading] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<string | JSX.Element | undefined>();
    const [updateWorkflowPayload, setWorkflowUpdatePayload] = useState<UpdateWorkflowRequestPayload | undefined>(initialPayload);
    const [step, setStep] = useState(0);
    const workflowTemplateName = get(updateWorkflowPayload, ['WorkflowDefinition', 'TemplateOption']);
    const workflowTemplate = useMemo(() => {
        if (workflowTemplateName) return find(workflowTemplateOptions, opt => opt.Name === workflowTemplateName)
    }, [workflowTemplateName]);

    const handleSubmit = ({ workflowModel, contentStateMappings, errorMessage }: {
        workflowModel?: WorkflowDefinitionUpdateModel,
        contentStateMappings?: ContentStateMapping[],
        errorMessage?: string
    }) => {
        if (!updateWorkflowPayload) return;
        setSubmitError(errorMessage);
        if (!errorMessage && workflowModel) {
            setSubmitLoading(true);
            dispatch(updateWorkflowRequestAction({
                ...updateWorkflowPayload,
                WorkflowDefinition: workflowModel,
                ContentStateMappings: contentStateMappings
            }, isCreateNew, submitResponseModal))
        }
    }

    const submitResponseModal = ({ IsSuccess, Messages, WorkflowStepNames }: {
        IsSuccess: boolean, Messages?: (JSX.Element | string)[], WorkflowStepNames?: string[]
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: getTranslatedText('Success'),
                content: getTranslatedText('Submitted workflow successfully'),
                onOk: () => {
                    if (closePanel) closePanel(true);
                },
                getContainer: () => getPopoverContainer(containerRef),
            });
        } else {
            Messages = Messages || [`${getTranslatedText('Failed to submit workflow')}`];
            if (WorkflowStepNames) {
                Messages.push(<>
                    {getTranslatedText('The following step(s) cannot be deleted')}:
                    <ul>
                        {map(WorkflowStepNames, (stepName, idx) => <li key={idx}>{stepName}</li>)}
                    </ul>
                </>);
            }

            let errorMessageContent = map(
                Messages,
                (error: string, index: number) => (
                    <div key={index}>{error}</div>
                )
            );

            Modal.error({
                title: getTranslatedText('Error'),
                content: errorMessageContent,
                getContainer: () => getPopoverContainer(containerRef),
            });
        }
    };

    useEffect(() => {
        if (!isCreateNew && initialPayload && initialPayload.WorkflowDefinition.WorkflowId) {
            dispatch(getTaskWorkflowsRequestAction([{
                WorkflowId: initialPayload.WorkflowDefinition.WorkflowId
            }]))
        }
    }, [initialPayload, isCreateNew]);

    useEffect(() => {
        if (!isCreateNew) {
            setSubmitError(<>{map(taskWorkflowsErrors, (error, idx) => <div key={idx}>{error}</div>)}</>);
        }
    }, [taskWorkflowsErrors, isCreateNew]);

    const steps = [
        {
            nextButtonLabel: getTranslatedText('Next'),
            populateContent: () =>
                <WorkflowConfigurationForm
                    workflowConfiguration={workflowConfiguration}
                    isCreateNew={isCreateNew}
                    updatePayload={updateWorkflowPayload}
                    setOnSubmit={(handler) => functionRef.onSubmit[0] = handler}
                    submitCallback={(updatePayload) => {
                        setWorkflowUpdatePayload(updatePayload);
                        setStep(step => step + 1);
                    }}
                    setDataHasChanges={(changed) => stepDataChanged[0] = changed}
                />
        },
        {
            nextButtonLabel: getTranslatedText('Submit'),
            backButtonLabel: getTranslatedText('Previous'),
            populateContent: () => updateWorkflowPayload && workflowTemplate && <WorkflowDefinitionForm
                inputWorkflow={get(updateWorkflowPayload, 'WorkflowDefinition')}
                workflowTemplateOption={workflowTemplate}
                isCreateNew={isCreateNew}
                submitCallback={handleSubmit}
                setOnSubmit={(handler) => functionRef.onSubmit[1] = handler}
                setDataHasChanges={(changed) => stepDataChanged[1] = changed}
                setNextButtonLoading={setNextButtonLoading}
            />
        }
    ];
    const currentStep = steps[step];

    const handleClosePanel = () => {
        if (!stepDataChanged.some(changed => changed)) {
            closePanel && closePanel();
            return;
        }
        Modal.confirm({
            className: 'modal-swapped-buttons',
            title: getTranslatedText('Are you sure you want to cancel'),
            onOk: () => closePanel && closePanel(),
            okText: getTranslatedText(confirmModalOkText),
            cancelText: getTranslatedText(confirmModalCancelText),
            getContainer: () => getPopoverContainer(containerRef),
        });
    };

    const nextButtonDisabled = (!isCreateNew && !isEmpty(taskWorkflowsErrors));

    return (<>
        <Row className="mb-10">
            <Col span={24} className="ta-right">
                <span><b>{getTranslatedText('Step')} {step + 1}/{steps.length}</b></span>
                <Button className="ml-10 buttonGrey" onClick={handleClosePanel}>
                    {getTranslatedText('Cancel')}
                </Button>
                {currentStep.backButtonLabel &&
                    <Button className="ml-10" onClick={() => setStep(step => step - 1)}>
                        {currentStep.backButtonLabel}
                    </Button>
                }
                <Button
                    loading={nextButtonLoading || taskWorkflowsLoading}
                    disabled={nextButtonDisabled}
                    className="ml-10" type="primary"
                    onClick={() => functionRef.onSubmit[step]()}
                >
                    {currentStep.nextButtonLabel}
                </Button>
                {submitError && <div style={{ color: DANGER_COLOR }}>{submitError}</div>}
            </Col>
        </Row>
        {map(steps, (step, idx) => (
            <div key={idx} style={{ display: step === currentStep ? undefined : 'none' }}>
                {step.populateContent()}
            </div>
        ))}
        {submitLoading && (
            <Suspense fallback={null}>
                <ModalWithSpinner
                    modalTitle={getTranslatedText('Submitting workflow definition')}
                    modalVisible={submitLoading}
                    displayMessage={getTranslatedText('Please wait while submitting workflow definition')}
                    containerRef={containerRef}
                />
            </Suspense>
        )}
    </>);
};

export default WorkflowDetailsPanelContent

