/**
 * Component for populating the Tasks > Customer assistance page. `/app/tasks/customer-assistance`
 */

import { Button, Col, Row, Select, Typography } from 'antd';
import {
    capitalize,
    difference,
    filter,
    forEach,
    get,
    includes,
    isEmpty,
    isEqual,
    isUndefined,
    map,
    remove,
    toLower,
} from 'lodash';
import moment from 'moment-timezone';
import QueueAnim from 'rc-queue-anim';
import React, {
    lazy,
    RefObject,
    Suspense,
    useEffect,
    useRef,
    useState,
} from 'react';
import { selectUserFilterMapping } from '../../../constants/common';
import { useDispatch, useSelector } from 'react-redux';
import { withAccountingSystemHandler } from '../../../components/common/AccountingSystemHandler';
import ActionBar from '../../../components/common/ActionBar';
import AddCommentPanel from '../../../components/common/AddCommentPanel';
import AssignUserPanel from '../../../components/common/AssignUserPanel';
import { withDateFormatHandler } from '../../../components/common/DateFormatHandler';
import FontAwesome from '../../../components/common/FontAwesome';
import { withPageViewHandler } from '../../../components/common/PageViewHandler';
import VirtualizedList from '../../../components/common/VirtualizedList';
import CustomerAssistanceItemComponent from '../../../components/tasks/customer_assistance/CustomerAssistanceItemComponent';
import {
    CUSTOMER_ASSISTANCE_PAGE,
    CUSTOM_FIELD_TYPES,
    PAGE_NAMES_FOR_VIEW,
} from '../../../config/tableAndPageConstants';
import { CompanyIdAttribute } from '../../../constants/authUserAttributes';
import {
    ticketActionsOptions,
    ticketRaisedOptions,
    ticketResolvedOptions,
    ticketsSortByOptions,
    ticketsStatusFilterOptions,
    ticketsTypeOptions,
} from '../../../constants/customerAssistanceSortAndFilters';
import {
    customerAssistanceSummaryPageQuery,
    customerAssistanceSummaryQueryName,
    defaultPageSizeForReport,
} from '../../../constants/downloadToExcel';
import { ApplicationState } from '../../../store';
import { downloadToExcelAction, getCustomFieldsFilterListByTypes } from '../../../store/common/actions';
import { CompanyUserRole } from '../../../store/companies/types';
import { getCustomerUILabel } from '../../../store/customers/sagas';
import {
    getTicketsRequestAction,
    setSelectedTicketIdRequestAction,
    ticketAddCommentRequestAction,
    updateTicketsFiltersAction,
    updateTicketsSortByAndStatusAction,
    updateTicketsTableFilterAction,
    getTicketCustomFieldsForCompanyRequestAction
} from '../../../store/tickets/actions';
import { initialState } from '../../../store/tickets/reducer';
import {
    getSelectedTicketId,
    populateSortFieldsParamSortParams,
} from '../../../store/tickets/sagas';
import {
    GetTicketsRequestPayload,
    TicketsState,
} from '../../../store/tickets/types';
import { getCurrentUser } from '../../../store/users/sagas';
import {
    checkIfEmailIsValid,
    computeTableScroll,
    downloadToExcelModalResponseHandler,
    emptyPredefinedFilterOnAppliedFilters,
    getUsedSortByOptionsIfCloud,
    populateDownloadToExcelModalDisplayMessage,
    populateDownloadToExcelModalTitle,
    removeAppliedFiltersForApiRequest,
    stringToHex,
} from '../../../utils/commonFunctions';
import {
    DynamicObject,
    ResponseModalObject,
} from '../../../utils/commonInterfaces';
import { getTicketOptionsRequestAction } from '../../../store/notifications/actions';
import { getCustomerFirstLetterValuesRequestAction } from '../../../store/customers/actions';
import { getTranslatedText } from '../../../utils/commonFunctions';
import CreateScheduledReportDrawer from '../../../components/common/CreateScheduledReportDrawer';

const CustomerAssistanceItemDetailsDrawerComponent = lazy(
    () =>
        import(
            '../../../components/tasks/customer_assistance/CustomerAssistanceItemDetailsDrawerComponent'
        )
);
const ModalWithSpinner = lazy(
    () => import('../../../components/common/ModalWithSpinner')
);
const FilterBar = lazy(() => import('../../../components/common/FilterBar'));

const { Title } = Typography;
const { Option } = Select;

interface IProps {
    readonly isUsingCloudImportType: boolean;
    readonly handlePageViewSelection: (
        tableFilterValue: string | undefined,
        applyFiltersFunction: Function,
        actionBarRefCurrent?: any,
        pageName?: string
    ) => void;
    readonly customerId?: undefined | string;
}
let lastSelectedCompanyId: string | null = null;
let resetTableScroll = false;
let fetchTicketsRequired = false;
let isRefetching = false;
let skipListenToPreconfiguredFilter = false;
const tablePageSize = CUSTOMER_ASSISTANCE_PAGE.pageSize;
const pageName = PAGE_NAMES_FOR_VIEW.CUSTOMER_ASSISTANCE_PAGE;
let initialTableFilter: undefined | string = undefined;
const CustomerAssistanceManagementPage: React.FC<IProps> = ({
    isUsingCloudImportType,
    handlePageViewSelection,
    customerId,
}: IProps) => {
    const dispatch = useDispatch();
    const customerLabel = useSelector(getCustomerUILabel);

    const actionBarRef: RefObject<DynamicObject | null | undefined> = useRef();
    
    const selectedId = useSelector(getSelectedTicketId);
    const currentUser = useSelector(getCurrentUser);

    const [ticketOptions, setTicketOptions] = useState<{
        options: any[];
        loading: boolean;
        customerId: string;
        companyId: string;
    }>({
        options: [],
        loading: false,
        customerId: '',
        companyId: '',
    });

    const [ticketDetailsDrawer, setTicketDetailsDrawer] = useState<{
        visible: boolean;
        activeTab: undefined | string;
    }>({
        visible: false,
        activeTab: undefined,
    });

    const ticketsState: TicketsState = useSelector(
        (state: ApplicationState) => state.tickets
    );

    const [ticketReasons, setTicketReasons] = useState(
        [
            {
                label:'',
                value:''
            }
        ]
    );

    const [customFieldsFilterList, setCustomFieldsFilterList] = useState<[]>(
        []
    );

    const [customFieldValuesList, setCustomFieldValuesList] = useState<DynamicObject[]>(
        []
    );

    const [customerValuesOption, setCustomerValuesList] = useState<DynamicObject[]>(
        []
    );

    const [ticketsTableFilter, setTicketsTableFilter] = useState<
        string | undefined
    >(
        isEqual(ticketsState.filters, initialState.filters)
            ? initialState.ticketTableFilter
            : ticketsState.ticketTableFilter
    );

    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );
    
    const selectedUserCompanyLoading: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.loading
    );

    const IsSearchCustomFieldsByCheckBox: boolean = get(
        selectedUserCompany,
        'Company.IsSearchCustomFieldsByCheckbox'
    );
    const [createScheduleReportPayload, setCreateScheduleReportPayload] = useState<any>();

    const [tableFilters, setTableFilters] = useState<any>(ticketsState.filters);

    const [tableRowSelection, setTableRowSelection] = useState<{
        selectedRowKeys: any[];
        unselectedRowKeys: any[];
        selectedUnreadyKeys: any[];
    }>({
        selectedRowKeys: [],
        unselectedRowKeys: [],
        selectedUnreadyKeys: [],
    });

    /**
     * Function for getting the company Id of the currently selected user company.
     */
    const companyId = useSelector((state: ApplicationState) =>
        get(state.companies.selectedUserCompany, 'Company.CompanyId')
    );

    /**
     * Function to get used sort options
     */
    const getTicketsSortByOptions = () => {
        return getUsedSortByOptionsIfCloud(
            ticketsSortByOptions,
            isUsingCloudImportType
        );
    };

    /**
     * Function for checking if sort value is in options and set the first one if not.
     */
    const checkSortBySelectedIfAvailable = () => {
        if (!selectedUserCompany) return;
        const usedSortByOptions = getTicketsSortByOptions();
        const usedSortValues = map(
            usedSortByOptions,
            (sortOpt) => sortOpt.value
        );
        if (!includes(usedSortValues, ticketsState.sortBy)) {
            setTimeout(() => {
                const newSortValues = {
                    ...tableSortStatus,
                    sortBy: initialState.sortBy,
                };
                updateTableSortStatusObject(newSortValues);
                dispatch(updateTicketsSortByAndStatusAction(newSortValues));
            }, 200);
        }
    };

    useEffect(checkSortBySelectedIfAvailable, [
        selectedUserCompany,
        ticketsState.sortBy,
    ]);

    /**
     * Function for checking if there are applied filters currently save on redux.
     */
    const getHasFiltersOnRedux = () => {
        let hasFiltersOnRedux = false;
        forEach(ticketsState.filters, (filterValue: any) => {
            if (!isEmpty(filterValue)) {
                hasFiltersOnRedux = true;
                return false; // terminates the foreach
            }
        });

        return hasFiltersOnRedux;
    };

    const [showConditions, setShowConditions] = useState<{
        filterBar: boolean;
        filterEllipsis: boolean;
        actionDrawer: boolean;
        editDrawer: boolean;
        addCommentDrawer: boolean;
        allSelected: boolean;
        certifyNotPaid: boolean;
        downloadToExcel: boolean;
        assignUserDrawer: boolean;
        createScheduledReport: boolean;
    }>({
        filterBar: getHasFiltersOnRedux(),
        filterEllipsis: false,
        actionDrawer: false,
        editDrawer: false,
        addCommentDrawer: false,
        allSelected: false,
        certifyNotPaid: false,
        downloadToExcel: false,
        assignUserDrawer: false,
        createScheduledReport: false
    });

    const [tableSortStatus, setTableSortStatus] = useState<{
        sortBy: string;
        ticketStatus: any;
        sortAscending: boolean;
    }>({
        sortBy: ticketsState.sortBy,
        ticketStatus: ticketsState.ticketStatus,
        sortAscending: ticketsState.sortAscending,
    });

    const [tableCurrentPage, setTableCurrentPage] = useState<number>(
        get(ticketsState, 'pageData.currentPage', 0)
    );

    /**
     * Function for preparing the filters to be used as payload for API.
     */
    const readyAllFiltersSelected = () => {
        return {
            ...ticketsState.filters,
            Status: tableSortStatus.ticketStatus,
        };
    };

    /**
     * Function that prepares the payload for the fetch request (either in table or excel report).
     * @param currentPage
     * @param pageSize
     */
    const generatePayloadForRequest = (
        currentPage: number,
        pageSize: number
    ) => {
        const payload: GetTicketsRequestPayload = {
            filters: readyAllFiltersSelected(),
            sortBy: ticketsState.sortBy,
            sortAscending: ticketsState.sortAscending,
            pageSize,
            currentPage: currentPage,
            ticketTableFilter: ticketsTableFilter,
            isUsingCloudImportType,
        };

        return payload;
    };

    /**
     * Function that calls an action which triggers a saga for calling the fetch tickets API.
     * @param currentPage - current page for data to be fetched
     * @param pageSize - number of items in page
     */
    const fetchTickets = (
        currentPage = tableCurrentPage,
        pageSize = tablePageSize
    ) => {
        if (isEmpty(selectedUserCompany)) return;
        const payload = generatePayloadForRequest(currentPage, pageSize);

        if (!isRefetching) resetTableScroll = false;
        dispatch(getTicketsRequestAction(payload));
    };

    /**
     * Function called in preparation to fetchTickets.
     * Manages the page to be used when calling the API.
     */
    const handleFetch = () => {
        if (
            isUndefined(initialTableFilter) ||
            isEmpty(selectedUserCompany) ||
            ticketsState.loading
        )
            return;

        if (!ticketsState.pageData.hasNextPage) return;

        const nextPage = tableCurrentPage + 1;
        setTableCurrentPage(nextPage);
        fetchTickets(nextPage);
    };
    /**
         * Function that fetches the custom fields filter list
         */
    const getCustomFieldsFilterList = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');

        if (companyIdCognito === selectedCompanyId) {
            dispatch(
                getCustomFieldsFilterListByTypes(
                    [CUSTOM_FIELD_TYPES.CUSTOMER],
                    (response: any) => {
                        if (response.IsSuccess) {
                            setCustomFieldsFilterList(response.CustomFields);
                        }
                    }
                )
            );
        }
    };

    /**
     * Function that fetches the custom fields values
     */
    const getCustomFieldsValueList = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');
        let customFieldsFilterOptions: DynamicObject[] = [];

        if (companyIdCognito === selectedCompanyId) {
            dispatch(
                getTicketCustomFieldsForCompanyRequestAction(
                    selectedCompanyId,
                    async (response: any) => {
                        if(response){
                            if (response.IsSuccess) {
                                customFieldsFilterOptions = await getCustomFieldsOptionsByKey(customFieldsFilterOptions,response.CustomFieldValues);
                                setCustomFieldValuesList(customFieldsFilterOptions)
                            }
                        }
                        
                    }
                    
                )
            ); 
        }
          
    };

    /**
     * Function for populating the custom field options by key used (affected when UsingCustomerWorkflow is changed).
     * @param workflowKey
     * @param actionFilterOptions
     */
    const getCustomFieldsOptionsByKey = async (
        customFieldsFilterOptions: DynamicObject[],
        customFieldValuesList: DynamicObject[]
    ) => {
        forEach(
            customFieldsFilterList,
            (customFieldsOption,index) => {                
                const customFieldName = get(customFieldsOption, 'FieldName');
                const customFieldLists = customFieldValuesList.filter(x => x.Name === customFieldName && x.Value != null);

                const childCustomFieldValues = map(customFieldLists, (cfl,index) => ({
                    label: cfl.Value,
                    value: cfl.Value,
                }));

                customFieldsFilterOptions.push({
                    Number:index,
                    Type: get(customFieldsOption, 'Type'),
                    FieldName: get(customFieldsOption, 'FieldName'),
                    children: childCustomFieldValues,
                    filterLoading: ticketsState.loading
                });
            }
        );

        return customFieldsFilterOptions;
    };

    useEffect(getCustomFieldsFilterList, [selectedUserCompany]);
    useEffect(getCustomFieldsValueList, [customFieldsFilterList]);

    /**
        * Function that fetches the customer first letter values
        */
    const getCustomerFirstLetterValuesList = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');
        let options: DynamicObject[] = [];

        if (companyIdCognito === selectedCompanyId) {
            dispatch(
                getCustomerFirstLetterValuesRequestAction(
                    selectedCompanyId,
                    async (response: any) => {
                        if (response.IsSuccess) {
                            options = await getCustomerFirstLatterValuesOptions(options, response.Values);
                            setCustomerValuesList(options)
                        }
                    }
                )
            );
        }
    };
    const getCustomerFirstLatterValuesOptions = async (
        options: DynamicObject[],
        valuesList: DynamicObject[]
    ) => {
        forEach(
            valuesList,
            (value, index) => {
                options.push({
                    label: value,
                    value: value
                });
            }
        );
        return options;
    };

    useEffect(getCustomerFirstLetterValuesList, [selectedUserCompany]);
    /**
     * Listener function called when changes on selected user company, filter values, sort values, and ticket status happens.
     */
    const callFetchTickets = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');

        if (companyIdCognito === selectedCompanyId) {
            if (lastSelectedCompanyId !== selectedCompanyId) {
                lastSelectedCompanyId = selectedCompanyId;
                resetAllSelectedRowKeys();
                checkAllTableFiltersOnCompanySwitch();
                resetTableScrollAndPageData();
            }
            if (!selectedUserCompanyLoading) fetchTickets(0);
        }
    };

    /**
     * Function for comparing component and redux state then setting the correct values.
     */
    const checkAllTableFiltersOnCompanySwitch = () => {
        const { filters, sortBy, sortAscending, ticketStatus } = ticketsState;
        if (!isEqual(filters, tableFilters)) {
            setTableFilters(filters);
        }

        if (
            sortBy !== tableSortStatus.sortBy ||
            sortAscending !== tableSortStatus.sortAscending ||
            ticketStatus !== tableSortStatus.ticketStatus
        ) {
            updateTableSortStatusObject({
                sortBy,
                sortAscending,
                ticketStatus,
            });
        }
    };

    useEffect(callFetchTickets, [
        ticketsState.sortBy,
        ticketsState.sortAscending,
        ticketsState.filters,
        ticketsState.ticketStatus,
        selectedUserCompany,
    ]);

    // on Unmount
    useEffect(() => {
        return () => {
            lastSelectedCompanyId = null;
            skipListenToPreconfiguredFilter = true;
        };
    }, []);

    const selectedKeysName = 'Id';

    /**
     * Function called on data update to check the selected keys if Select all button has been clicked.
     */
    const checkRowSelectionState = () => {
        if (showConditions.allSelected) {
            const selectedKeys = difference(
                map(ticketsState.data, selectedKeysName),
                tableRowSelection.unselectedRowKeys
            );

            updateTableRowSelection({
                selectedRowKeys: selectedKeys,
            });
        }
    };

    useEffect(checkRowSelectionState, [ticketsState.data]);

    /**
     * Function called when the filter value on upper left dropdown (next to refresh button) has been updated.
     * @param filter - dropdown view value
     * @param refetch - boolean indicator if fetching of items is to be called
     */
    const changeTicketsTableFilter = (
        filter: string,
        refetch: boolean = true
    ) => {
        if (!initialTableFilter) {
            initialTableFilter = filter;
        } else {
            if (filter !== initialTableFilter) {
                updateShowConditionsObject({
                    allSelected: false,
                    filterBar: true,
                });
            }
        }

        if (filter !== ticketsState.ticketTableFilter) {
            skipListenToPreconfiguredFilter = false;
        }
        setTicketsTableFilter(filter);
        resetTableScrollAndPageData();
        if (refetch && filter === ticketsState.ticketTableFilter) {
            handleTicketFilterRefresh();
        } else {
            dispatch(updateTicketsTableFilterAction(filter));
        }
    };

    /**
     * Function called to get the ticket reasons needed for the dropdown inside the drawer.
     */
    const updateTicketReasons = (ticketReasonsObject: any[]) => {
        setTicketReasons(
            ticketReasonsObject
        );
    };

    /**
     * Function for setting the `ticket reasons` values and display value
     */
    const getTicketReasons = () => {
        dispatch(
            getTicketOptionsRequestAction(companyId, (res: any) => {
                const filteredRes = filter(res, (r: DynamicObject) => {
                    return !r.Hidden;
                });
                const reasons: any[] = [];

                filteredRes.forEach(ticketReason => {
                    const ticketReasonResult = {
                        label: ticketReason.Reason,
                        value: ticketReason.Reason
                    };
                    reasons.push(ticketReasonResult);
                });
                updateTicketReasons(reasons);
            })
        );
    };

    useEffect(getTicketReasons, [companyId]);

    /**
     * Function responsible for refetching the data after an update or when clicking the refresh button.
     */
    const refetchListAndResetScroll = () => {
        isRefetching = true;
        resetTableScrollAndPageData();
        fetchTickets(0);
    };

    /**
     * Function called when `Refresh` button is clicked or the upper left dropdown filter has been set to initial state.
     */
    const handleTicketFilterRefresh = () => {
        fetchTicketsRequired = true;
        skipListenToPreconfiguredFilter = false;
        resetAllSelectedRowKeys();
        refetchListAndResetScroll();

        // skipListenToPreconfiguredFilter = false;
        // listenToPreConfiguredFilter();
    };

    /**
     * Function for populating the menu items for the dropdown.
     * @param menu
     */
    const populateTicketFilterSelectDropdownRender = (menu: any) => (
        <div>
            {menu}
        </div>
    );

    /**
     * Function that checks if `Select all` is in effect.
     * Returns a boolean.
     */
    const checkIfSelectAll = () => {
        const allDataLoaded = ticketsState.pageData.hasNextPage === false;

        return (
            !isEmpty(ticketsState.data) &&
            ((tableRowSelection.unselectedRowKeys.length === 0 &&
                showConditions.allSelected) ||
                (allDataLoaded &&
                    ticketsState.data.length ===
                    tableRowSelection.selectedRowKeys.length))
        );
    };

    /**
     * Function for populating the `Select/Deselect all` label.
     */
    const populateSelectDeselectAllLabel = () => {
        let selectDeselectLabel = '';
        if (checkIfSelectAll()) {
            selectDeselectLabel = 'Deselect';
        } else {
            selectDeselectLabel = 'Select';
        }

        return getTranslatedText(`${selectDeselectLabel} all`);
    };

    /**
     * Function called when `Select/Deselect all` button has been clicked.
     */
    const selectAllRows = () => {
        const selectAllCondition =
            isEmpty(tableRowSelection.selectedRowKeys) ||
            tableRowSelection.selectedRowKeys.length < ticketsState.data.length;

        updateShowConditionsObject({
            allSelected: selectAllCondition,
        });

        if (selectAllCondition) {
            const idKeys: string[] = map(ticketsState.data, selectedKeysName);
            updateTableRowSelection({
                selectedRowKeys: idKeys,
                unselectedRowKeys: [],
            });
        } else {
            updateTableRowSelection({
                selectedRowKeys: [],
                selectedUnreadyKeys: [],
            });
        }
    };

    /**
     * Function for updating the sort order of the items in table.
     * @param sortAscending - boolean indicator if the sort order is ascending or not
     */
    const changeSortOrder = async (sortAscending: boolean) => {
        await resetTableScrollAndPageData();
        dispatch(
            updateTicketsSortByAndStatusAction({
                ...tableSortStatus,
                sortAscending,
            })
        );
    };

    const downloadDisabled = ticketsState.loading || isEmpty(ticketsState.data);

    /**
     * Function for populating the ellipsis popover upper section.
     */
    const populateFilterEllipsisPopoverTitle = () => (
        <div className="pop-action-title">
            <Button
                type="link"
                disabled={downloadDisabled}
                onClick={() => {
                    setShowConditions((prevState: { filterBar: boolean }) => ({
                        ...showConditions,
                        filterEllipsis: false,
                        filterBar: !prevState.filterBar,
                    }));
                }}
            >
                <FontAwesome icon={['fas', 'filter']} className="mr-10" />
                {getTranslatedText("Filter")}
            </Button>
            <br />
            <Button
                type="link"
                disabled={downloadDisabled}
                onClick={() => {
                    setTableSortStatus((prevState: any) => {
                        const sortOrder = !prevState.sortAscending;
                        changeSortOrder(sortOrder);
                        return {
                            ...prevState,
                            sortAscending: sortOrder,
                        };
                    });
                }}
            >
                <FontAwesome
                    icon={[
                        'fas',
                        `sort-amount-${ticketsState.sortAscending ? 'down' : 'up-alt'
                        }`,
                    ]}
                    className="mr-10"
                />
                {getTranslatedText("Change sort order")}
            </Button>
            <br />
            <Button
                type="link"
                onClick={downloadToExcelHandler}
                disabled={downloadDisabled}
            >
                <FontAwesome
                    icon={['fas', 'cloud-download-alt']}
                    className="mr-10"
                />
                {getTranslatedText("Download to Excel")}
            </Button>
            <br />
            <Button
                type="link"
                onClick={createScheduledReportHandler}
                // disabled={downloadDisabled}
            >
                    <FontAwesome
                        icon={['far', 'calendar']}
                        className={`icon calendar`}
                    />
                    <FontAwesome
                        icon={['fas', 'clock']}
                        className={`icon clock`}
                    />
                <span className='icon-button-wrapper'>{getTranslatedText('Create Scheduled Report')}</span>
            </Button>
        </div>
        
    );

    /**
     * Function called when Download to Excel button is clicked.
     */
    const downloadToExcelHandler = () => {
        updateShowConditionsObject({
            downloadToExcel: true,
            filterEllipsis: false,
        });

        const filterAndPageProps = generatePayloadForRequest(
            0,
            defaultPageSizeForReport
        );

        const { filters, sortBy, sortAscending } = filterAndPageProps;

        const sortFields = populateSortFieldsParamSortParams(sortBy);

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'ticket'
        );
        const variables = {
            ...cleanFilters,
            ...sortFields,
            Ascending: sortAscending,
        };

        const payload = {
            Query: customerAssistanceSummaryPageQuery,
            OperationName: customerAssistanceSummaryQueryName,
            Variables: JSON.stringify(variables),
            PageName: PAGE_NAMES_FOR_VIEW.CUSTOMER_ASSISTANCE_PAGE,
        };
        dispatch(downloadToExcelAction(payload, downloadToExcelModalResponse));
    };

      /**
     * Function called when create schedule report is closed.
     * @param resetChanges
     * @param saveChanges
     * @param containerRef
     */
      const onCreateScheduledReportEnd = (
        containerRef?: any
    ) => {
        updateShowConditionsObject({
            createScheduledReport: false,
        });
    };

    /**
     * Function called when Create Scheduled report button is clicked.
     */
    const createScheduledReportHandler = () => {
        updateShowConditionsObject({
            createScheduledReport: true,
            filterEllipsis: false,
        });

        const filterAndPageProps = generatePayloadForRequest(
            0,
            defaultPageSizeForReport
        );

        const { filters, sortBy, sortAscending } = filterAndPageProps;

        const sortFields = populateSortFieldsParamSortParams(sortBy);

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'ticket'
        );
        const variables = {
            ...cleanFilters,
            ...sortFields,
            Ascending: sortAscending,
        };

        const payload = {
            Query: customerAssistanceSummaryPageQuery,
            OperationName: customerAssistanceSummaryQueryName,
            Variables: JSON.stringify(variables),
            PageName: PAGE_NAMES_FOR_VIEW.CUSTOMER_ASSISTANCE_PAGE,
            title: "Tickets",
            Type: 1
        };

        setCreateScheduleReportPayload(payload);
    };

    /**
     * Callback function after calling the download to excel api.
     * @param param0 - response with type `ResponseModalObject`
     */
    const downloadToExcelModalResponse = ({
        IsSuccess,
        Messages,
    }: ResponseModalObject) => {
        updateShowConditionsObject({
            downloadToExcel: false,
            filterEllipsis: false,
        });
        downloadToExcelModalResponseHandler(IsSuccess, Messages);
    };

    /**
     * Function for populating the ellipsis popover lower section.
     */
    const populateFilterEllipsisPopoverContent = () => (
        <div className="pop-action-content">
            <div className="mb-10">
                <span>{getTranslatedText("Sort by")}</span>
            </div>
            <div>
                <Select
                    onChange={(sortBySelected: string) =>
                        updateTableSortStatusObject({
                            sortBy: sortBySelected,
                        })
                    }
                    value={tableSortStatus.sortBy}
                >
                    {map(
                        getTicketsSortByOptions(),
                        ({
                            label,
                            value,
                        }: {
                            label: string;
                            value: string;
                        }) => {
                            const labelUsed = label
                                .replace('Customer', capitalize(customerLabel))
                                .replace('customer', toLower(customerLabel));
                            return (
                                <Option key={value} value={value}>
                                    {getTranslatedText(labelUsed)}
                                </Option>
                            );
                        }
                    )}
                </Select>
            </div>
            <br />
            <div className="mb-10">
                <span>{getTranslatedText("Ticket status")}</span>
            </div>
            <div>
                <Select
                    onChange={(statusSelected: string) =>
                        updateTableSortStatusObject({
                            ticketStatus: statusSelected,
                        })
                    }
                    value={tableSortStatus.ticketStatus}
                >
                    {map(
                        ticketsStatusFilterOptions,
                        ({
                            label,
                            value,
                        }: {
                            label: string;
                            value: number;
                        }) => (
                            <Option key={value} value={value}>
                                {getTranslatedText(label)}
                            </Option>
                        )
                    )}
                </Select>
            </div>
            <br />
            <div className="ta-right">
                <Button
                    type="primary"
                    disabled={
                        ticketsState.loading ||
                        (tableSortStatus.sortBy === ticketsState.sortBy &&
                            tableSortStatus.ticketStatus ===
                            get(ticketsState, 'ticketStatus'))
                    }
                    onClick={applySortedByAndState}
                >
                    {getTranslatedText("Apply")}
                </Button>
            </div>
        </div>
    );

    /**
     * Listener function called when the table filter value for the dropdown at the upper left has been changed.
     */
    const listenToPreConfiguredFilter = () => {
        if (skipListenToPreconfiguredFilter) return;
        const { ticketTableFilter } = ticketsState;
        if (ticketTableFilter === initialTableFilter) {
            closeFilterBar();

            if (fetchTicketsRequired) {
                fetchTickets(0);
                fetchTicketsRequired = false;
            }
        } else {
            handlePageViewSelection(
                ticketTableFilter,
                applyFilters,
                actionBarRef.current,
                pageName
            );
        }
    };

    useEffect(listenToPreConfiguredFilter, [ticketsState.ticketTableFilter]);

    /**
     * Function that sets the value of the dropdown filter next to refresh button to it's initial state.
     */
    const setTableFilterToInitialState = () => {
        if (ticketsState.ticketTableFilter !== initialTableFilter) {
            setTicketsTableFilter(initialTableFilter);

            if (!isUndefined(initialTableFilter))
                changeTicketsTableFilter(initialTableFilter);

            resetAllSelectedRowKeys();
        }
    };

    /**
     * Filter Bar Functions
     */
    /**
     * Function called for applying the filters selected.
     * @param filters
     * @param fromFilterBar - boolean indicator if called from Apply filters button in FilterBar component
     */
    const applyFilters = async (
        filters?: GetTicketsRequestPayload['filters'],
        fromFilterBar?: boolean
    ) => {
        if (!filters) {
            setTableFilterToInitialState();
        } else {
            if (fromFilterBar) {
                emptyPredefinedFilterOnAppliedFilters(
                    filters,
                    tableFilters,
                    ticketsTableFilter,
                    fromFilterBar,
                    () => {
                        changeTicketsTableFilter('');
                    }
                );
            }
        }

        let appliedFilters: any = filters || initialState.filters;
        const savedSortState = get(appliedFilters, 'tableSortState');
        if (savedSortState) {
            setTableSortStatus(savedSortState);
            applySortedByAndState(savedSortState);
            delete appliedFilters.tableSortState;
        }

        await resetTableScrollAndPageData();
        await setTableFilters(appliedFilters);
        await dispatch(updateTicketsFiltersAction(appliedFilters));

        await resetAllSelectedRowKeys();
    };

    /**
     * Function called for applying the sort values selected.
     * @param savedValues - optional param - used when a specific tableSortState value is passed.
     */
    const applySortedByAndState = async (savedValues?: any) => {
        await resetTableScrollAndPageData();
        const usedValues = get(savedValues, 'sortBy')
            ? savedValues
            : tableSortStatus;
        await dispatch(updateTicketsSortByAndStatusAction(usedValues));
        await updateShowConditionsObject({
            filterEllipsis: false,
        });
    };

    /**
     * Manipulate State Objects
     */
    /**
     * Function for updating the `showConditions` state.
     * @param showConditionObject - must conform to `showConditions` state
     */
    const updateShowConditionsObject = (showConditionObject: {}) => {
        setShowConditions({
            ...showConditions,
            ...showConditionObject,
        });
    };

    /**
     * Function for updating the `tableRowSelection` state.
     * @param selectionObject - must conform to `tableRowSelection` state
     */
    const updateTableRowSelection = (selectionObject: {}) => {
        setTableRowSelection({
            ...tableRowSelection,
            ...selectionObject,
        });
    };

    /**
     * Function called for closing the ticket details drawer.
     * Resets all the active data for the previously selected item in redux state.
     */
    const closeTicketDetailsDrawer = () => {
        setTicketDetailsDrawer({
            visible: false,
            activeTab: undefined,
        });
    };

    /**
     * Function for updating the `tableSortStatus` state.
     * @param tableSortStatusObject - must conform to `tableSortStatus` state
     */
    const updateTableSortStatusObject = (tableSortStatusObject: {}) => {
        setTableSortStatus({
            ...tableSortStatus,
            ...tableSortStatusObject,
        });
    };

    /**
     * Function called when the checkbox is clicked.
     * @param record - data for the row item where the ticked checkbox is in
     */
    const onCheckboxClick = (record: DynamicObject) => {
        const selectedRowKeys = [...tableRowSelection.selectedRowKeys];
        const isCurrentlySelected = includes(selectedRowKeys, record.key);
        const newSelectedRowKeys = !isCurrentlySelected
            ? [...selectedRowKeys, record.key]
            : remove(selectedRowKeys, (key: string) => key !== record.key);

        onRowSelect(record, !isCurrentlySelected, newSelectedRowKeys, true);
    };

    /**
     * Function called when a row item is clicked.
     * @param record - data for the clicked row item
     * @param activeTab - kind of panel to show based on the row clicked
     */
    const onRowClick = (record: DynamicObject, activeTab?: string) => {
        dispatch(
            setSelectedTicketIdRequestAction(get(record, 'Id'), () => {
                setTicketDetailsDrawer({
                    visible: true,
                    activeTab,
                });
            })
        );
    };

    /**
     * Function to populate the loading text for the table.
     */
    const populateTableLoadingText = () => {
        const loadingText = `Fetching ${tableCurrentPage === 0 || isRefetching ? 'list of' : 'more' } tickets`;

        isRefetching = false;

        return loadingText;
    };

    /**
     * Function called when the row is selected/deselected.
     * @param record - data item for the selected/deselected row
     * @param selected - indicator if the row is selected or deselected
     * @param selectedRows
     * @param nativeEvent
     */
    const onRowSelect = (
        record: DynamicObject,
        selected: boolean,
        selectedRows: any,
        nativeEvent: any
    ) => {
        const selectedRowKeys =
            nativeEvent === true ? [...selectedRows] : map(selectedRows, 'key');

        let unselectedRowKeys = [];

        const selectedUnreadyKeys = [...tableRowSelection.selectedUnreadyKeys];

        if (selected) {
            unselectedRowKeys = filter(
                tableRowSelection.unselectedRowKeys,
                (unselectedKey: string) => unselectedKey !== record.key
            );
            if (!record.isReady) {
                selectedUnreadyKeys.push(record.key);
            }
        } else {
            unselectedRowKeys = showConditions.allSelected
                ? [...tableRowSelection.unselectedRowKeys, record.key]
                : [];

            if (!record.isReady) {
                remove(
                    selectedUnreadyKeys,
                    (unReadyKey) => unReadyKey === record.key
                );
            }
        }

        updateTableRowSelection({
            selectedRowKeys,
            unselectedRowKeys,
            selectedUnreadyKeys,
        });
    };

    /**
     * Function for updating the visibility of popovers.
     * @param name - name of popover
     * @param condition - optional condition if the change should be applied
     */
    const popoverOnVisibleChange = (name: string, condition?: boolean) => {
        return (visible: boolean) => {
            if (condition === undefined || condition === true) {
                let visibilityCondition = visible;
                if (
                    name === 'actionDrawer' ||
                    name === 'editDrawer' ||
                    name === 'addCommentDrawer' ||
                    name === 'assignUserDrawer'
                ) {
                    visibilityCondition = !showConditions[name];
                    updateShowConditionsObject({
                        [name]: visibilityCondition,
                    });
                } else {
                    updateShowConditionsObject({
                        [name]: visible,
                    });
                }
            }
        };
    };

    /**
     * Function for resetting the scroll and current page.
     */
    const resetTableScrollAndPageData = async () => {
        resetTableScroll = true;
        await setTableCurrentPage(0);
    };

    /**
     * Function for resetting the selected, unselected, and selected unread keys.
     * @param excludeShowConditions - boolean indicator that checks if allSelected property in
     * `showConditions` state be excluded in resetting.
     */
    const resetAllSelectedRowKeys = (excludeShowConditions = false) => {
        updateTableRowSelection({
            selectedRowKeys: [],
            unselectedRowKeys: [],
            selectedUnreadyKeys: [],
        });
        if (!excludeShowConditions) {
            updateShowConditionsObject({
                allSelected: false,
            });
        }
    };

    /**
     * Function called for closing the filter bar section.
     * Resets all the filters to initial state.
     */
    const closeFilterBar = async () => {
        await applyFilters();
        updateShowConditionsObject({
            filterBar: false,
        });
    };

    // const populateEditProtectedPopoverButtonContent = () => (
    //     <>
    //         <FontAwesome icon={['fas', 'edit']} />
    //         <span>Edit</span>
    //     </>
    // );

    const populateAddCommentProtectedPopoverButtonContent = () => (
        <>
            <FontAwesome icon={['fas', 'comment']} />
            <span>{getTranslatedText("Add Comment")}</span>
        </>
    );

    const checkingForAssignedUser = (inputValue: string | undefined | null) => {

        if (checkIfEmailIsValid(inputValue)) return true;

        return false;
    };

    /**
     * Function for getting the filter values to be used in FilterBar component.
     */
    const populateTicketsFilterBarFilters = () => {
        const ticketsFilterBarFilters = [
            /* Commenting out as the Priority functionality for Tickets is currently out of scope
            {
                filterName: 'Priority',
                filterStateName: 'Priority',
                filterElement: 'checkbox-group',
                filterType: 'array',
                filterOptions: [
                    {
                        label: 'High',
                        value: 'High',
                    },
                    {
                        label: 'Medium',
                        value: 'Medium',
                    },
                    {
                        label: 'Low',
                        value: 'Low',
                    },
                ],
            }, */
            {
                filterName: getTranslatedText("Reason"),
                filterStateName: 'TicketOptionReasons',
                filterElement: 'checkbox-group',
                filterType: 'array',
                filterOptions: ticketReasons,
            },
            {
                filterName: getTranslatedText(capitalize(customerLabel)),
                filterStateName: 'Customer',
                filterElement: 'input-autocomplete',
                filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
                filterSort: 'Company name',
                filterResponse: 'GetCustomersForCompany.Customers',
                filterLabelField: 'DisplayName',
            },
            {
                filterName: getTranslatedText(capitalize(customerLabel) + ' A-Z'),
                filterStateName: 'CustomerFirstLetter',
                filterElement: 'checkbox-group',
                filterType: 'array',
                filterOptions: customerValuesOption
            },
            {
                filterName: getTranslatedText("Assigned user"),
                filterStateName: 'AssignedUserId',
                filterNameQuery: 'Name',
                filterElement: 'select-with-search',
                filterQuery: 'GET_USERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
                filterResponse: 'GetUsersForCompany.CompanyUserRoles',
                filterLabelField: ['User.GivenName', 'User.FamilyName'],
                filterSort: 'Name',
                filterNameQuerySub: 'Email',
                filterSubChecking: checkIfEmailIsValid,
                filterJSONValueFieldSub: 'User.Email',
                tagClass: 'wb-bw',
                filterMappingUsed: selectUserFilterMapping,
            },
            {
                filterName: getTranslatedText("Raised"),
                filterStateName: 'CreatedDate',
                filterElement: 'select',
                filterType: 'text',
                filterOptions: ticketRaisedOptions,
                filterMaxDate: moment(),
                filterOptionsCustomTypes: {
                    'Custom date range': 'date-range',
                    'Custom days range': 'all-days-range-from-to-last',
                },
            },
            {
                filterName: getTranslatedText("Resolved"),
                filterStateName: 'ClosedDate',
                filterElement: 'select',
                filterType: 'text',
                filterOptions: ticketResolvedOptions,
                filterMaxDate: moment(),
                filterOptionsCustomTypes: {
                    'Custom date range': 'date-range',
                    'Custom days range': 'all-days-range-from-to-last',
                },
            },
            {
                filterName: getTranslatedText("Country"),
                filterStateName: 'CustomerCountry',
                filterElement: 'input-autocomplete',
                filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
                filterSort: 'CustomerCountry',
                filterResponse: 'GetCustomersForCompany.Customers',
                filterLabelField: 'Country',
            },
            {
                filterName: getTranslatedText("Ticket Number"),
                filterStateName: 'TicketNumber',
                filterElement: 'input',
                filterType: 'number',
                filterLabelField: 'TicketNumber',
            },
            {
                filterName: getTranslatedText("Postal State"),
                filterStateName: 'CustomerState',
                filterElement: 'input-autocomplete',
                filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
                filterSort: 'CustomerState',
                filterResponse: 'GetCustomersForCompany.Customers',
                filterLabelField: 'State',
            },
            {
                filterName: getTranslatedText("Last Chat Date"),
                filterStateName: 'LastChatDate',
                filterElement: 'select',
                filterType: 'text',
                filterOptions: ticketRaisedOptions,
                filterMaxDate: moment(),
                filterOptionsCustomTypes: {
                    'Custom date range': 'date-range',
                    'Custom days range': 'all-days-range-from-to-last',
                },
            },
            {
                filterName: getTranslatedText("Actions"),
                filterStateName: 'Action',
                filterElement: 'checkbox-group',
                filterType: 'array',
                filterOptions: ticketActionsOptions.map((elm) => (
                    getTranslatedText(elm.label),
                    elm.value
                ))
            }
        ];

        return ticketsFilterBarFilters;
    };

    /**
     * Function for getting the values needed when connecting to API.
     * Like the selected keys, filter object, and boolean indicator if
     * the selected keys are to be excluded from search or included.
     */
    const getSelectedTicketsValues = () => {
        let allExcept = false;
        let keysToUse = [...tableRowSelection.selectedRowKeys];

        if (showConditions.allSelected) {
            allExcept = true;
            keysToUse = [...tableRowSelection.unselectedRowKeys];
        }

        const filterObject = {
            ...tableFilters,
            Status: tableSortStatus.ticketStatus,
        };

        return {
            allExcept,
            keysToUse,
            filterObject
        };
    };

    /**
     * Function for getting the payload for adding comment.
     */
    const getAddCommentFilterPayload = () => {
        const { allExcept, keysToUse, filterObject } =
            getSelectedTicketsValues();

        return {
            filter: filterObject,
            ticketIds: keysToUse,
            excludeTickets: allExcept,
        };
    };

    /**
     * Function called when add comment panel is closed.
     * @param refreshList - optional boolean indicator whether to refresh the table list or not
     */
    const closeAddCommentPanel = (refreshList?: boolean) => {
        updateShowConditionsObject({
            addCommentDrawer: false,
        });

        if (refreshList) {
            refetchListAndResetScroll();
        }
    };

    /**
     * Function that checks if the Name of the page view to be saved already exists.
     * @param name - name of page view
     */
    const doesViewNameExist = (name: string) => {
        if (actionBarRef.current)
            return actionBarRef.current.doesViewNameExist(name);
    };

    const populateAssigUsertProtectedPopoverButtonContent = () => (
        <>
            <FontAwesome icon={['fas', 'user']} />
            <span>{getTranslatedText("Assign to")}</span>
        </>
    );

    /**
     * Function called when assign to changes has been saved successfully.
     * @param values
     */
    const onSaveSuccessAssignToUserChanges = (values: DynamicObject) => {
        handleTicketFilterRefresh();

        updateShowConditionsObject({
            assignUserDrawer: false
        });
    };

    /**
     * Function for populating the assign to drawer content.
     */
    const populateAssignToDrawerContent = () => {
        return (
            <AssignUserPanel
                onCancelClick={() => {
                    updateShowConditionsObject({
                        assignUserDrawer: false
                    });
                }}
                onSaveSuccess={onSaveSuccessAssignToUserChanges}
                visible={showConditions.assignUserDrawer}
                getSelectedTicketsValues={getSelectedTicketsValues}
                companyId={get(selectedUserCompany, 'Company.CompanyId')}
            />
        );
    };

    return (
        <Col span={24}>
            <QueueAnim type={['right', 'left']} leaveReverse>
                <Row key="title-container">
                    <Col span={24}>
                        <Title level={3}>{getTranslatedText("Assistance/Tickets")}</Title>
                    </Col>
                </Row>
                <div className="spacer-15" />
                {/* Filter Bar */}
                <QueueAnim type="top" leaveReverse duration={300}>
                    {showConditions.filterBar && (
                        <div key="filter-bar-container">
                            <Suspense fallback={null}>
                                <FilterBar
                                    pageName={pageName}
                                    loading={ticketsState.loading}
                                    applyFilters={applyFilters}
                                    filters={populateTicketsFilterBarFilters()}
                                    filterValues={ticketsState.filters}
                                    colDivision={4}
                                    closeFilterBar={closeFilterBar}
                                    appliedView={ticketsTableFilter}
                                    doesViewNameExist={doesViewNameExist}
                                    customFieldsFilters={IsSearchCustomFieldsByCheckBox ? customFieldValuesList : customFieldsFilterList}
                                    checkingForAssignedUser={
                                        checkingForAssignedUser
                                    }
                                    tableSortState={{
                                        sortBy: ticketsState.sortBy,
                                        ticketStatus: ticketsState.ticketStatus,
                                        sortAscending:
                                            ticketsState.sortAscending,
                                    }}
                                />
                            </Suspense>
                        </div>
                    )}
                    <CreateScheduledReportDrawer
                        visible={showConditions.createScheduledReport}
                        onClose={onCreateScheduledReportEnd}
                        widgetQuery={createScheduleReportPayload}
                    />
                </QueueAnim>
                <div key="action-bar-container">
                    <ActionBar
                        ref={actionBarRef}
                        pageName={pageName}
                        loading={ticketsState.loading}
                        filterBarOpen={showConditions.filterBar}
                        actionItems={[
                            {
                                actionKey: 'ticket-filter',
                                actionType: 'select-with-button',
                                selectValue: ticketsTableFilter,
                                selectDropdownRender:
                                    populateTicketFilterSelectDropdownRender,
                                onSelectChange: changeTicketsTableFilter,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fa', 'sync']}
                                            className="mr-8"
                                        />
                                        <span>{getTranslatedText("Refresh")}</span>
                                    </>
                                ),
                                buttonDisabled: ticketsState.loading,
                                onButtonClick: handleTicketFilterRefresh,
                            },
                            {
                                actionKey: 'ticket-select-all',
                                actionType: 'protected-button',
                                buttonDisabled: downloadDisabled,
                                onButtonClick: selectAllRows,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fas', 'check-double']}
                                        />
                                        <span>
                                            {populateSelectDeselectAllLabel()}
                                        </span>
                                    </>
                                ),
                            },
                            // {
                            //     actionKey: 'ticket-assign-to',
                            //     actionType: 'protected-button',
                            //     buttonDisabled: isEmpty(
                            //         tableRowSelection.selectedRowKeys
                            //     ),
                            //     onButtonClick: () => console.log('Assign to'),
                            //     buttonContent: (
                            //         <>
                            //             <FontAwesome
                            //                 icon={['fas', 'user-tag']}
                            //             />
                            //             <span>Assign to</span>
                            //         </>
                            //     ),
                            // },
                            // {
                            //     actionKey: 'ticket-escalate',
                            //     actionType: 'protected-button',
                            //     buttonDisabled: isEmpty(
                            //         tableRowSelection.selectedRowKeys
                            //     ),
                            //     onButtonClick: () =>
                            //         console.log('Escalate clicked!'),
                            //     buttonContent: (
                            //         <>
                            //             <FontAwesome icon={['fas', 'signal']} />
                            //             <span>Escalate</span>
                            //         </>
                            //     ),
                            // },
                            // {
                            //     actionKey: 'ticket-edit',
                            //     actionType: 'protected-button',
                            //     buttonDisabled: isEmpty(
                            //         tableRowSelection.selectedRowKeys
                            //     ),
                            //     buttonContent: populateEditProtectedPopoverButtonContent(),
                            // },
                            {
                                actionKey: 'ticket-add-comment',
                                actionType: 'protected-drawer-button',
                                buttonDisabled: isEmpty(
                                    tableRowSelection.selectedRowKeys
                                ),
                                buttonContent:
                                    populateAddCommentProtectedPopoverButtonContent(),
                                popoverVisible: showConditions.addCommentDrawer,
                                drawerCloseable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'addCommentDrawer',
                                    !isEmpty(tableRowSelection.selectedRowKeys)
                                ),
                                popoverTitle: getTranslatedText("Add a comment"),
                                popoverContent: (
                                    <AddCommentPanel
                                        visible={
                                            showConditions.addCommentDrawer
                                        }
                                        closePanel={closeAddCommentPanel}
                                        filterPayload={getAddCommentFilterPayload()}
                                        dispatchAction={
                                            ticketAddCommentRequestAction
                                        }
                                    />
                                ),
                                drawerWidth: 500,
                            },
                            {
                                actionKey: 'ticket-assign-user',
                                actionType: 'protected-drawer-button',
                                buttonDisabled: isEmpty(
                                    tableRowSelection.selectedRowKeys
                                ),
                                buttonContent:
                                    populateAssigUsertProtectedPopoverButtonContent(),
                                popoverVisible: showConditions.assignUserDrawer,
                                drawerCloseable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'assignUserDrawer',
                                    !isEmpty(tableRowSelection.selectedRowKeys)
                                ),
                                popoverTitle: getTranslatedText("Assign to User"),
                                popoverContent: populateAssignToDrawerContent(),
                                drawerWidth: 500,
                            },
                        ]}
                        actionEllipsis={{
                            popoverVisible: showConditions.filterEllipsis,
                            popoverOnVisibleChange:
                                popoverOnVisibleChange('filterEllipsis'),
                            popoverTitle: populateFilterEllipsisPopoverTitle(),
                            popoverContent:
                                populateFilterEllipsisPopoverContent(),
                            buttonContent: (
                                <FontAwesome icon={['fas', 'ellipsis-h']} />
                            ),
                        }}
                    />
                </div>
                <div className="spacer-15" />
                {/* Table Section */}
                <Row key="table-container">
                    <Col span={24}>
                        <VirtualizedList
                            dataSource={ticketsState.data}
                            fetchMore={handleFetch}
                            scroll={computeTableScroll(
                                window.innerHeight - 185,
                                tablePageSize,
                                CUSTOMER_ASSISTANCE_PAGE.rowHeight
                            )}
                            resetTableScroll={resetTableScroll}
                            selectedRowKeys={tableRowSelection.selectedRowKeys}
                            rerenderTrigger={tableRowSelection.selectedRowKeys}
                            onRowClick={onRowClick}
                            onCheckboxClick={onCheckboxClick}
                            loading={ticketsState.loading}
                            loadingText={populateTableLoadingText()}
                            emptyText={
                                !isEmpty(ticketsState.errorMessages)
                                    ? get(ticketsState, 'errorMessages.0')
                                    : getTranslatedText("No tickets found")
                            }
                            hasNextPage={ticketsState.pageData.hasNextPage}
                            itemComponent={CustomerAssistanceItemComponent}
                            itemHeight={CUSTOMER_ASSISTANCE_PAGE.rowHeight}
                            selectedId={selectedId}
                            extraData={{
                                addCommentDrawerVisible:
                                    showConditions.addCommentDrawer,
                            }}

                        />

                    </Col>
                    <Suspense fallback={null}>
                        <CustomerAssistanceItemDetailsDrawerComponent
                            visible={ticketDetailsDrawer.visible}
                            closeDrawer={closeTicketDetailsDrawer}
                            refetchCallback={handleTicketFilterRefresh}
                            activeTab={ticketDetailsDrawer.activeTab}
                        />
                    </Suspense>
                    {showConditions.downloadToExcel && (
                        <Suspense fallback={null}>
                            <ModalWithSpinner
                                modalTitle={getTranslatedText(populateDownloadToExcelModalTitle())}
                                modalVisible={showConditions.downloadToExcel}
                                displayMessage={getTranslatedText(populateDownloadToExcelModalDisplayMessage())}
                            />
                        </Suspense>
                    )}
                </Row>
            </QueueAnim>
        </Col>
    );
};

export default withPageViewHandler(
    withAccountingSystemHandler(
        withDateFormatHandler(CustomerAssistanceManagementPage)
    )
);
