/**
 * Component for populating the Invoice details drawer.
 */

import React, { useEffect, Suspense, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withDateFormatHandler } from "../common/DateFormatHandler";
import ModalWithSpinner from "../../components/common/ModalWithSpinner";
import { get, groupBy, map } from "lodash";
import { Button, Card, Col, Modal, Row } from "antd";
import {
    convertCamelToSentence,
    downloadFileFromPresignedUrl,
    openURLNewTab,
    getTranslatedText
} from "../../utils/commonFunctions";
import FontAwesome from "../common/FontAwesome";
import { dateFormatDoMMMYYYYHHmmssSpace, dateFormatMMDDYYYYhhmmssASpaceSlash } from "../../constants/dateFormats";
import { ReportStatus } from "../../constants/reportSortAndFilters";
import { withNumberFormatHandler } from "../common/NumberFormatHandler";
import { getPresignedUrlDownloadReportReadyAction } from "../../store/common/actions";
import { DynamicObject } from "../../utils/commonInterfaces";
import { itemDrawerTabItemCardClassName } from "../../constants/common";
import AvatarNameInitialsComponent from "../common/AvatarNameInitialsComponent";
import SheetViewer from "../common/SheetViewer";
import { ReportHistory } from "../../store/report/types";
import { getCurrentUser } from "../../store/users/sagas";
import { IsOrganisationViewAttribute } from "../../constants/authUserAttributes";


interface IProps {
    record: ReportHistory;
    user: any;
    region?: string;
    readonly formatDateUTCToLocal: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
}

export const itemDrawerTabItemContainerMinusHeight = 108;

const ReportDetailsContent: React.FC<IProps> = ({
    record,
    user,
    region,
    formatDateUTCToLocal,
}: IProps) => {
    const dispatch = useDispatch();
    
    const currentUser = useSelector(getCurrentUser);
    const isOrgView = get(currentUser, IsOrganisationViewAttribute) === '1';
    const [downloading, setDownloading] = useState(false);
    const [excelUrl, setExcelUrl] = useState("");
    const RA_SHEET_VIEWER_ID = "app-ra-sheet-viewer";

    useEffect(() => {
        if (
            record &&
            record.Status === ReportStatus.Complete &&
            record.ReportJobId !== null
        ) {
            dispatch(
                getPresignedUrlDownloadReportReadyAction(
                    {
                        EntityKey: get(record, "ReportJobId"),
                        Region: region
                    },
                    (res: DynamicObject) => {
                        if (res.IsSuccess) {
                            setExcelUrl(res.ReportGetURL);
                            setDownloading(false);
                        } else {
                            setDownloading(false);
                            Modal.error({
                                title: getTranslatedText("Error"),
                                content: (
                                    <div>{getTranslatedText("Failed to download the report file")}</div>
                                ),
                                okText: getTranslatedText("OK"),
                            });
                        }
                    }
                )
            );
        }
    }, [record]);

    const downloadReportHandler = () => {
        setDownloading(true);
        dispatch(
            getPresignedUrlDownloadReportReadyAction(
                {
                    EntityKey: get(record, "ReportJobId"),
                    Region: region
                },
                (res: DynamicObject) => {
                    if (res.IsSuccess) {
                        downloadFileFromPresignedUrl(res.ReportGetURL, () => {
                            setDownloading(false);
                        });
                    } else {
                        setDownloading(false);
                        Modal.error({
                            title: getTranslatedText("Error"),
                            content: (
                                <div>{getTranslatedText("Failed to download the report file")}</div>
                            ),
                            okText: getTranslatedText("OK"),
                        });
                    }
                }
            )
        );
    };

    const handleDownload = () => {
        openURLNewTab(excelUrl);
    };

    const Company = get(record, 'Company');

    return (
        <>
            <div
                className="details-item-container"
                style={{
                    height: window.innerHeight - itemDrawerTabItemContainerMinusHeight,
                }}
            >
                <Row style={{ height: "100%" }} gutter={10}>
                <Col style={{ height: "5%" }} md={24}></Col>
                    <Col style={{ height: "100%" }} md={16}>
                        <Card
                            style={{
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                            }}
                            bodyStyle={{
                                flexGrow: 1,
                                display: "flex",
                                flexDirection: "column",
                                height: 0,
                            }}
                            className={itemDrawerTabItemCardClassName}
                            title={getTranslatedText("History Report Excel")}
                        >
                            {excelUrl && (
                                <div style={{ textAlign: "right" }}>
                                    <Button
                                        type="link"
                                        onClick={handleDownload}
                                        style={{ padding: 0 }}
                                    >
                                        <FontAwesome icon={["fa", "file-download"]} />
                                        &nbsp;
                                        <span>{getTranslatedText("Download")}</span>
                                    </Button>
                                </div>
                            )}
                            {record.Status === ReportStatus.Complete && <SheetViewer
                                id={RA_SHEET_VIEWER_ID}
                                url={excelUrl}
                                style={{
                                    height: 700,
                                    width: "100%",
                                }}
                                isCsv={false}
                            />}
                        </Card>
                    </Col>

                    <Col md={8}>
                        <Card title={getTranslatedText("Report details")}>
                            <Row>
                                {isOrgView && Company && <Col>Company: {get(Company, 'Name')}</Col>}
                                    <Col>{getTranslatedText("Type")}: {getTranslatedText(record.Type)}</Col>
                                <Col>
                                    {getTranslatedText("Start Time")}:{" "}
                                    {formatDateUTCToLocal(
                                        get(record, "CreatedDateTime"),
                                        undefined,
                                        dateFormatDoMMMYYYYHHmmssSpace
                                    )}
                                </Col>
                                <Col>
                                    {getTranslatedText("End Time")}:{" "}
                                    {get(record, "CompletedDateTime") && formatDateUTCToLocal(
                                        get(record, "CompletedDateTime"),
                                        undefined,
                                        dateFormatDoMMMYYYYHHmmssSpace
                                    )}
                                </Col>
                                    <Col>{getTranslatedText("State")}: {getTranslatedText(record.Status)}</Col>

                <Col>
                  {record.Status === ReportStatus.Complete && (
                    <Button
                      style={{ paddingLeft: "0px" }}
                      type="link"
                      onClick={downloadReportHandler}
                      title="Download to Excel"
                    >
                      <FontAwesome
                        icon={["fas", "cloud-download-alt"]}
                        size="lg"
                      />
                      <span style={{ padding: "5px" }}>
                        {" "}
                        {getTranslatedText("Download to Excel")}
                      </span>
                    </Button>
                  )}
                </Col>
              </Row>
            </Card>
            <div className="spacer-15" />
            {record.ReportAppliedFilters && (
              <Card title={getTranslatedText("Filters applied")}>
                <Row>
                  {map(groupBy(record.ReportAppliedFilters, e => e.Name), (records, name) => (
                    !name.includes("ResourceScopes") && (
                    <Col key={name}>
                      {getTranslatedText(convertCamelToSentence(name))}:{' '}
                      {
                        records.length === 1
                          ? <>
                                {  
                                  (convertCamelToSentence(name) === "Created Date Max" || convertCamelToSentence(name) === "Created Date Min") || 
                                   convertCamelToSentence(name) === "Due Date Min" || convertCamelToSentence(name) === "Due Date Max" ? 
                                  formatDateUTCToLocal(
                                    records[0].Value,
                                    dateFormatMMDDYYYYhhmmssASpaceSlash,
                                    dateFormatDoMMMYYYYHHmmssSpace
                                  )
                                  : getTranslatedText(records[0].Value)
                                }
                            </>
                          : <ul>
                            {map(records, (record, i) => <li key={i}>{getTranslatedText(record.Value)}</li>)}
                          </ul>
                      }
                    </Col>
                  )))}
                </Row>
              </Card>
            )}
            <div className="spacer-15" />
            {user && (
              <Card>
                <Row>
                  <Col className="ta-right">
                    <div
                      className="mb-10"
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <AvatarNameInitialsComponent
                        fullName={user.GivenName}
                        size={90}
                        fontSize={24}
                        showTooltip
                      />
                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                            justifyContent: "flex-start",
                                        }}
                                    >
                                        <div className="mr-10">
                                            <b>{getTranslatedText("Created By")}:</b>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                justifyContent: "flex-start",
                                            }}
                                        >
                                            <Col>{user.GivenName} </Col>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    )}
                    </Col>
                </Row>
            </div>
            {downloading && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle={getTranslatedText("Downloading report")}
                        modalVisible={downloading}
                        displayMessage={getTranslatedText("Please wait while we get the file from storage")}
                    />
                </Suspense>
            )}
        </>
    );
};

export default withDateFormatHandler(
  withNumberFormatHandler(ReportDetailsContent)
);