/**
 * Component for populating each item in users management page.
 */

import React, { memo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { get, includes, isEqual } from 'lodash';
import { Row, Col, Checkbox, Tooltip } from 'antd';
import { WorkflowConfiguration } from '../../store/workflow/types';
import { getCurrentUser } from '../../store/users/sagas';
import { SubAttribute } from '../../constants/authUserAttributes';
import { DynamicObject } from '../../utils/commonInterfaces';
import { getWorkflowSelectedId } from '../../store/workflow/sagas';
import FontAwesome from '../common/FontAwesome';

interface IProps {
    readonly item: WorkflowConfiguration;
    readonly style: {};
    readonly onRowClick: (item: DynamicObject, drawerType?: string) => void;
    readonly onCheckboxClick: (item: DynamicObject) => void;
    readonly selectedRowKeys: [];
}
const WorkflowItemComponent: React.FC<IProps> = ({
    item,
    style,
    onRowClick,
    onCheckboxClick,
    selectedRowKeys,
}: IProps) => {
    const firstRowContainerRef = useRef<any>(null);
    const {
        WorkflowId,
        WorkflowType,
        State,
        InvoiceNumber,
        CustomerCode,
        CountryCode,
        WorkflowName,
        CustomField
    } = item;

    const selectedId = useSelector(getWorkflowSelectedId);

    /**
     * Function called for adding the key prop - identifier for the row.
     */
    const getItemInfo = () => {
        const itemInfo: DynamicObject = item;
        itemInfo.key = WorkflowId;

        return itemInfo;
    };

    /**
     * Function triggered when checkbox is toggled.
     * @param e
     */
    const handleCheckboxClick = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.stopPropagation();
        const itemInfo = getItemInfo();
        if (onCheckboxClick) onCheckboxClick(itemInfo);
    };

    /**
     * Function triggered when row is clicked.
     */
    const handleRowClick = () => {
        const itemInfo = getItemInfo();
        if (onRowClick) onRowClick(itemInfo);
    };

    const currentUser = useSelector(getCurrentUser);
    const currentUserId: string = get(currentUser, SubAttribute);

    const keyValue = get(item, 'key');
    let iconCircleColor = 'green';
    let iconColor = 'green';
    
    /**
     * Indicator if the checkbox on the left side (if present) will be disabled.
     * The checkbox for the currently logged in user account is disabled.
     */
    const rowDisabled = currentUserId === keyValue;
    return (
        <Row
            className={`list-item-clickable ${
                rowDisabled ? 'click-disabled' : ''
            }`}
            key={keyValue}
            style={style}
            onClick={handleRowClick}
        >
            <Col span={24} style={{ height: '100%' }}>
                <Row type="flex" align="middle" style={{ height: '100%' }}>
                    <Col className="h-100" xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                        <Row className="h-100" type="flex" align="middle">
                            <Col xxl={8} xl={8} lg={24} md={24} sm={24} xs={24}>
                                <div style={{ padding: '0px 20px' }}>
                                    <Checkbox
                                        checked={includes(
                                            selectedRowKeys,
                                            keyValue
                                        )}
                                        disabled={rowDisabled}
                                        onClick={handleCheckboxClick}
                                    />
                                </div>
                            </Col>
                            <Col xxl={16} xl={16} lg={24} md={24} sm={24} xs={24} className="ta-center">
                                <div
                                    className="workflow-icon-container"
                                >
                                    <div className="fa-layers fa-fw fa-2x w-100">
                                        <Tooltip
                                            title={WorkflowType}
                                            placement="topRight">
                                            <FontAwesome
                                                icon={['far', 'circle']}
                                                className={
                                                    iconCircleColor || iconColor
                                                }
                                                size="2x"
                                                transform="shrink-2"
                                            />
                                            <FontAwesome
                                                icon={['fas', 'file']}
                                                className={iconColor}
                                                size="2x"
                                                transform="shrink-8"
                                                />
                                        </Tooltip>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xxl={22} xl={22} lg={22} md={22} sm={22} xs={22}>
                        <Row className="first-row-container" type="flex">
                            <Col md={9} sm={24} xs={24}>
                                {get(item, 'WorkflowName')}
                            </Col>
                            <Col md={3} sm={24} xs={24}>
                                {get(item, 'CustomerCode')}
                            </Col>
                            <Col md={4} sm={24} xs={24} className="ta-center">
                                {get(item, 'CountryCode')}
                            </Col>
                            <Col md={4} sm={24} xs={24} className="ta-center">
                                {get(item, 'InvoiceNumber')}
                            </Col>
                        </Row>
                     
                        <Row>
                            <Col md={4} sm={24} xs={24} className="mr-8">
                                {get(item, 'WorkflowType')}
                            </Col>
                        </Row>
                        <Row>
                            { item.CustomField && 
                                <Col key={`${item.CustomField.Name}-${item.CustomField.Value}`}>
                                    <span className="mr-8">{item.CustomField.Name}:</span>
                                    <span>{item.CustomField.Value}</span>
                                </Col>
                            }
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

const arePropsEqual = (prevProps: any, nextProps: any) => {
    if (
        JSON.stringify(prevProps.item) === JSON.stringify(nextProps.item) &&
        prevProps.selectedId === nextProps.selectedId &&
        isEqual(prevProps.selectedRowKeys, nextProps.selectedRowKeys) &&
        JSON.stringify(prevProps.style) === JSON.stringify(nextProps.style)
    ) {
        return true;
    } else {
        return false;
    }
};

export default memo(WorkflowItemComponent, arePropsEqual);
