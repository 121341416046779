import { Reducer } from 'redux';
import { BanksActionTypes, BanksState } from './types';

// Type-safe initialState!
export const initialState: BanksState = {
    loading: false,
    bankFileCustomization: undefined,
    saveLoading: false
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<BanksState> = (state = initialState, action) => {
    switch (action.type) {
        case BanksActionTypes.SAVE_BANK_FILE_REQUEST: {
            return { ...state, saveLoading: true };
        }
        case BanksActionTypes.SAVE_BANK_FILE_RESPONSE: {
            return { ...state, saveLoading: false };
        }
        case BanksActionTypes.GET_BANK_FILE: {
            return { ...state, loading: true };
        }
        case BanksActionTypes.GET_BANK_FILE_SUCCESS: {
            return { ...state, loading: false, bankFileCustomization: action.payload };
        }
        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as banksReducer };
