/**
 * File responsible for the content when clicking `Create descriptor` in bank file descriptors management pages.
 */

import { Button, Col, Form, Modal, Row, Table } from 'antd';
import { isEmpty, map, findIndex, capitalize } from 'lodash';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPopoverContainer, getTranslatedText } from '../../utils/commonFunctions';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { BankFileDescriptorVM, BankFileDescriptor, RequestCreateOrEditBankFileDescriptorPayload } from '../../store/bankFileDescriptors/types';
import FontAwesome from '../common/FontAwesome';
import CreateBankFileDescriptorItemPanelWrapper from './CreateBankFileDescriptorItemPanel';
import { withRouter } from 'react-router-dom';

const ModalWithSpinner = lazy(() => import('../common/ModalWithSpinner'));

export interface IProps {
    readonly containerRef?: any;
    readonly visible: boolean;
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly form?: any;
    readonly dispatchAction: (requestCreateBankFileDescriptorPayload: RequestCreateOrEditBankFileDescriptorPayload) => void;
    readonly onCreateSuccess?: (bankFileDescriptors: Partial<BankFileDescriptorVM>[]) => void;
}

const CreateBankFileDescriptorPanel: React.FC<IProps> = ({
    containerRef,
    visible,
    closePanel,
    form,
    dispatchAction,
    onCreateSuccess
}: IProps) => {
    const dispatch = useDispatch();
    const customerLabel = useSelector(getCustomerUILabel);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [bankFileDescriptorList, setBankFileDescriptorList] = useState<Partial<BankFileDescriptorVM>[]>([]);
    const [bankFileDescriptor, setBankFileDescriptor] = useState<Partial<BankFileDescriptorVM>>({});
    const [isCreateBankFileDescriptorItemPanelVisible, setIsCreateBankFileDescriptorItemPanelVisible] = useState<boolean>(false);

    const bankFileDescriptorsTableColumns = [
        {
            title: getTranslatedText(`${capitalize(customerLabel)}`),
            dataIndex: 'Customer',
            width: '30%'
        },
        {
            title: getTranslatedText('Descriptors'),
            dataIndex: 'Descriptors',
            width: '55%'
        },
        {
            title: '',
            dataIndex: 'Controls',
            width: '15%'
        }
    ];

    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const {
        validateFields,
        resetFields,
    } = form;

    const isCreateBtnDisabled = submitLoading || bankFileDescriptorList.length === 0;

    /**
     * Function called when `Cancel` button is clicked inside create bank file descriptor panel.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    /**
    * Function for calling when the CreateCreditItemManuallyPanel is closed.
    * @param refreshList
    */
    const onCreateBankFileDescriptorItemPanelClose = (isBankFileDescriptorCreatedSuccessfully?: boolean) => {
        setIsCreateBankFileDescriptorItemPanelVisible(false);
        setIsEditMode(false);

        if (isBankFileDescriptorCreatedSuccessfully) {
            if (closePanel) {
                closePanel(true);
            };
        }
    };

    /**
     * Function that listens if panel is closed.
     * If closed, the form fields and values will reset.
     */
    const listenForClosingPanel = () => {
        if (!visible) {
            resetFields();
        }
    };

    useEffect(listenForClosingPanel, [visible]);

    /**
     * Function called when submitting the form.
     */
    const handleSubmitForm = () => {
        validateFields((err: any, values: any) => {
            if (!err) {
                setSubmitLoading(true);

                const bankFileDescriptors: Partial<BankFileDescriptor>[] = bankFileDescriptorList.map(bankFileDescriptor => {
                    return {
                        Customer: bankFileDescriptor.Customer,
                        Descriptors: bankFileDescriptor && bankFileDescriptor.Descriptors ? bankFileDescriptor.Descriptors.map(b => b.Descriptor) : [],
                    }
                });

                const payload: RequestCreateOrEditBankFileDescriptorPayload = {
                    BankFileDescriptors: bankFileDescriptors,
                    callback: createOrEditBankFileDescriptorResponseModal
                }

                dispatch(
                    dispatchAction(payload)
                );
            }
        });
    };

    /**
     * Function responsible for showing the response modal after bank file descriptor created.
     * @param param0 - object with success indicator and error message from api (if there's any)
     */
    const createOrEditBankFileDescriptorResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: getTranslatedText('Success'),
                content:
                    getTranslatedText('Successfully created customer bank file descriptors!'),
                onOk: () => {
                    if (closePanel) closePanel(true);
                },
                getContainer: () => getPopoverContainer(containerRef),
            });
            if (onCreateSuccess) {
                onCreateSuccess(bankFileDescriptorList);
            }
        } else {
            let errorMessageContent: any = getTranslatedText(`Error: Unable to create customer bank file descriptors.!`);
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{getTranslatedText(error)}</div>
                    )
                );
            }

            Modal.error({
                title: getTranslatedText('Error'),
                content: errorMessageContent,
                getContainer: () => getPopoverContainer(containerRef),
            });
        }
    };

    const populateBankFileDescriptorTableDataSourceItem = (bankFileDescriptor: BankFileDescriptorVM, index: number) => {
        return {
            key: index,
            Customer: bankFileDescriptor.Customer.DisplayName,
            Descriptors: bankFileDescriptor.Descriptors.map(d => d.Descriptor).join(' | '),
            Controls: (<>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="fs-16 cursor-p-div mr-5" onClick={handleEditBankFileDescriptor(bankFileDescriptor)}>
                        <FontAwesome icon={['fas', 'edit']} />
                    </div>{' '}
                    <div className="fs-16 cursor-p-div" onClick={handleDeleteBankFileDescriptor(bankFileDescriptor)}>
                        <FontAwesome icon={['fas', 'trash']} />
                    </div>
                </div>
            </>)
        }
    };

    const handleAddBankFileDescriptor = () => {
        setIsCreateBankFileDescriptorItemPanelVisible(true);
    };

    const handleAddBankFileDescriptorSuccessfully = (bankFileDescriptor: BankFileDescriptorVM) => {
        const bankFileDescriptors = bankFileDescriptorList.map((descriptor) => {
            if (descriptor.Customer && descriptor.Customer.CustomerCode === bankFileDescriptor.Customer.CustomerCode &&
                descriptor.Descriptors && descriptor.Descriptors.length > 0) {
                // Merge descriptors associated with the same customer
                return {
                    ...descriptor,
                    Descriptors: [...descriptor.Descriptors, ...bankFileDescriptor.Descriptors]
                };
            }

            return descriptor;
        });

        if (!bankFileDescriptors.find(b => b.Customer && b.Customer.CustomerCode === bankFileDescriptor.Customer.CustomerCode)) {
            setBankFileDescriptorList([...bankFileDescriptors, bankFileDescriptor]);
        }
        else {
            setBankFileDescriptorList(bankFileDescriptors);
        }
    };

    const handleEditBankFileDescriptor = (bankFileDescriptor: BankFileDescriptorVM) => () => {
        setIsEditMode(true);
        setBankFileDescriptor(bankFileDescriptor);
        setIsCreateBankFileDescriptorItemPanelVisible(true);
    };

    const handleEditBankFileDescriptorSuccessfully = (bankFileDescriptor: BankFileDescriptorVM) => {
        setIsEditMode(false);

        const bankFileDescriptorIdx = findIndex(bankFileDescriptorList, bfd => bfd.Id === bankFileDescriptor.Id);

        if (bankFileDescriptorIdx !== -1) {
            const bankFileDescriptors: Partial<BankFileDescriptorVM>[] = [...bankFileDescriptorList];
            bankFileDescriptors.splice(bankFileDescriptorIdx, 1, bankFileDescriptor);

            setBankFileDescriptorList(bankFileDescriptors);
        }
    };

    const handleDeleteBankFileDescriptor = (bankFileDescriptor: BankFileDescriptorVM) => () => {
        const bankFileDescriptorIdx = findIndex(bankFileDescriptorList, bfd => bfd === bankFileDescriptor);

        if (bankFileDescriptorIdx !== -1) {
            const bankFileDescriptors: Partial<BankFileDescriptorVM>[] = [...bankFileDescriptorList];
            bankFileDescriptors.splice(bankFileDescriptorIdx, 1);

            setBankFileDescriptorList(bankFileDescriptors);
        }
    };

    /**
     * Function responsible for populating the panel content.
     * Form fields.
     */
    const populatePanelContent = () => {
        return (
            <Form labelAlign='left' className="form-inline-mb-0" labelCol={{ span: 8 }}>
                <Row gutter={[16, 16]} type="flex" align="middle">
                    <Col span={24}>
                        <Table
                            className="app-bank-file-descriptors-table"
                            columns={bankFileDescriptorsTableColumns}
                            dataSource={map(bankFileDescriptorList, populateBankFileDescriptorTableDataSourceItem)}
                            pagination={false}
                            bordered={true}
                            locale={{ emptyText: getTranslatedText('No Data') }}
                        />
                    </Col>
                    <Col span={24} className="ta-right">
                        <Button
                            type="link"
                            onClick={handleAddBankFileDescriptor}
                            style={{ padding: 0, cursor: 'pointer' }}
                        >
                            <FontAwesome
                                icon={['fa', 'plus']}
                                className="mr-8"
                            />
                            <span>{getTranslatedText("Add descriptor")}</span>
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <Row>
            <Col>
                <div>{populatePanelContent()}</div>
                <br />
                <Row>
                    <Col className="ta-right" span={24}>
                        <Button
                            className="mr-8"
                            type="primary"
                            onClick={handleSubmitForm}
                            disabled={isCreateBtnDisabled}
                        >
                            {getTranslatedText("Create")}
                        </Button>
                        <Button onClick={handleClosePanel}>{getTranslatedText("Cancel")}</Button>
                    </Col>
                </Row>
            </Col>
            {submitLoading && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle={getTranslatedText("Creating new customer bank file descriptor")}
                        modalVisible={submitLoading}
                        displayMessage={getTranslatedText("Please wait while creating new customer bank file descriptor . . .")}
                        containerRef={containerRef}
                    />
                </Suspense>
            )}
            <Suspense fallback={null}>
                <div ref={null}>
                    <CreateBankFileDescriptorItemPanelWrapper
                        visible={isCreateBankFileDescriptorItemPanelVisible}
                        isEditMode={isEditMode}
                        editBankFileDescriptor={bankFileDescriptor}
                        closePanel={onCreateBankFileDescriptorItemPanelClose}
                        handleAddBankFileDescriptor={handleAddBankFileDescriptorSuccessfully}
                        handleEditBankFileDescriptor={handleEditBankFileDescriptorSuccessfully}
                        existingBankFileDescriptorList={ bankFileDescriptorList }
                    />
                </div>
            </Suspense>
        </Row>
    );
};

const CreateBankFileDescriptorPanelForm = Form.create({
    name: 'create-bank-file-descriptor-panel-form',
})(CreateBankFileDescriptorPanel);

export default withRouter(CreateBankFileDescriptorPanelForm)