/**
 * File responsible for all the UI and actions for Settings>Customer Payments page - `/app/settings/customer-payments`.
 */

 import {
    Alert,
    Button,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    Modal,
    Row,
    Select,
    Switch,
    Tabs,
    Typography,
} from 'antd';
import {
    clone,
    get,
    includes,
    isEmpty,
    isUndefined,
    map,
    toLower,
} from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import corpayLogo from '../../assets/corpayLogo.png';
import converaLogo from '../../assets/convera-logo.png';
import FontAwesome from '../../components/common/FontAwesome';
import ModalWithSpinner from '../../components/common/ModalWithSpinner';
import SelectReadonly from '../../components/FormComponents/SelectReadonly';
import {
    confirmModalCancelText,
    confirmModalOkText,
    DEFAULT_REGION_NAME,
    initialPrimaryColor,
} from '../../config/config';
import {
    auOnlyPaymentOptions,
    cardPaymentSystemOptions,
    cardPaymentSystems,
    creditCardOptionsList,
    defaultCreditCardsAvailable,
    overseasCardPaymentSystemOptions,
    paymentOptionsDataMapping,
} from '../../constants/notifications';
import { ApplicationState } from '../../store';
import {
    getUserCompaniesRequestAction,
    saveUserCompanyRequestAction,
} from '../../store/companies/actions';
import { CompaniesState } from '../../store/companies/types';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { getRolePermissions } from '../../store/roles/sagas';
import {
    checkIfValidURL,
    urlifyURLsOutsideAnchorTags,
    getTranslatedText
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import './settings.less';


const { TabPane } = Tabs;
const { Title } = Typography;
const { confirm } = Modal;
const { Item: FormItem } = Form;
const { Option } = Select;
interface IProps {
    form: DynamicObject;
    history: {
        push: (path: string) => void;
    };
}
const passwordDisplay = '*******';
const formFieldNames: DynamicObject = {
    CardPaymentSystem: 'CardPaymentSystem',
    CardPaymentClientReference: 'CardPaymentClientReference',
    CardPaymentSelfManagedDescription: 'CardPaymentSelfManagedDescription',
    CardPaymentURL: 'CardPaymentURL',
    CardPaymentSelectedCreditCards: 'CardPaymentSelectedCreditCards',
    CardPaymentEziDebitPaymentURL: 'CardPaymentEziDebitPaymentURL',
    CardPaymentEziDebitEDDRURL: 'CardPaymentEziDebitEDDRURL',
    CardPaymentEziDebitDigitalKey: 'CardPaymentEziDebitDigitalKey',
    CardPaymentEziDebitPublicKey: 'CardPaymentEziDebitPublicKey',
    CardPaymentIntegraPayUsername: 'CardPaymentIntegraPayUsername',
    CardPaymentIntegraPayPassword: 'CardPaymentIntegraPayPassword',
    CardPaymentADVAMAccountNumber: 'CardPaymentADVAMAccountNumber',
    CardPaymentADVAMUsername: 'CardPaymentADVAMUsername',
    CardPaymentADVAMPassword: 'CardPaymentADVAMPassword',
    OverseasCardPaymentSystem: 'OverseasCardPaymentSystem',
    OverseasCardPaymentSelfManagedDescription:
        'OverseasCardPaymentSelfManagedDescription',
    OverseasCardPaymentURL: 'OverseasCardPaymentURL',
    HasWesternUnionPaymentDetails: 'HasWesternUnionPaymentDetails',
    WesternUnionClientId: 'WesternUnionClientId',
    WesternUnionServiceId: 'WesternUnionServiceId',
    WesternUnionClientReference: 'WesternUnionClientReference',
    HasCorpayPaymentDetails: 'HasCorpayPaymentDetails',
    CorpayIsEnabled: 'CorpayIsEnabled',
    CorpayStatus: 'CorpayStatus',
    HasBankAccountDetails: 'HasBankAccountDetails',
    BankAccountName: 'BankAccountName',
    BankAccountBSBNumber: 'BankAccountBSBNumber',
    BankAccountNumber: 'BankAccountNumber',
    BankSwiftCode: 'BankSwiftCode',
    ReferenceNumber: 'ReferenceNumber',
    HasChequeMailingDetails: 'HasChequeMailingDetails',
    EnablePartialPayment: 'EnablePartialPayment'
};

const reactQuillSettings = {
    modules: {
        toolbar: [['bold', 'italic', 'underline', 'link']],
    },

    formats: ['bold', 'italic', 'underline', 'link'],
};

export const defaultCardPaymentClientRef = '[InvoiceList]';

const CustomerPaymentsManagementPage: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const customerLabel = useSelector(getCustomerUILabel);
    const rolePermissions = useSelector(getRolePermissions);
    
    const userRole = useSelector((state: ApplicationState) =>
        get(state.companies.selectedUserCompany, 'Role.Name')
    );

    const companiesState: CompaniesState = useSelector(
        (state: ApplicationState) => state.companies
    );

    const [showIncompleteMailingInfo, setShowIncompleteMailingInfo] =
        useState<boolean>(false);
    const [isOnlinePayment, setIsOnlinePayment] = useState<boolean>(false);

    const languagePack = useSelector((state: ApplicationState) =>
        get(state.companies.selectedUserCompany, 'Company.LanguagePackage')
    );

    const { selectedUserCompany, saveLoading } = companiesState;

    const companyData = get(selectedUserCompany, 'Company', {});

    const paymentOptionsData = get(companyData, 'PaymentOptions', {});
    const {
        getFieldDecorator,
        getFieldValue,
        validateFields,
        setFieldsValue,
        resetFields,
    } = props.form;
    
    /**
     * Function called when `Cancel` button is clicked inside Customer payment options.
     * Pops up a confirmation modal asking to revert all changes made.
     */
    const onCancelButtonClick = () => {
        confirm({
            className: 'modal-swapped-buttons',
            title: getTranslatedText("Continue"),
            content: (
                <div>
                    {getTranslatedText(`When you click the ${confirmModalOkText} button, all the data will be reverted to the last saved values`).split(/(<b>[^<]+<\/b>)/g) // Split by the <b>...</b> tags
                        .map((part, index) =>
                            part.startsWith('<b>') ? (
                                <b key={index}>{part.replace(/<\/?b>/g, '')}</b> // Render bold part
                            ) : (
                                part // Render regular text
                            )
                        )}
                </div>
            ),
            onOk() {
                resetFields();
            },
            onCancel() { },
            okText: getTranslatedText(confirmModalOkText),
            cancelText: getTranslatedText(confirmModalCancelText),
        });
    };
    
    

    /**
     * Function responsible for showing the success/error modal after saving the changes made.
     * @param IsSuccess - if the saving of data is successful
     * @param lastSavedPayload - latest saved values
     */
    const handleModalSaveResponse = (
        IsSuccess: boolean,
        lastSavedPayload: DynamicObject
    ) => {
        if (IsSuccess) {
            Modal.success({
                title: getTranslatedText("Success"),
                content: getTranslatedText("Company data saved successfully"),
                onOk: () => {
                    /**
                     * Calls the user companies api after clicking ok on successful update.
                     * The reason is, all this payment options data is attached to the Company
                     * and to get the latest Company details, this function must be called since we
                     * don't have a separate function for getting a single Company details.
                     */
                    dispatch(getUserCompaniesRequestAction());
                    const urlifiedSelfManagedDescription = get(
                        lastSavedPayload,
                        paymentOptionsDataMapping.CardPaymentSelfManagedDescription
                    );

                    if (urlifiedSelfManagedDescription) {
                        setFieldsValue({
                            [formFieldNames.CardPaymentSelfManagedDescription]:
                                urlifiedSelfManagedDescription,
                        });
                    }

                    // resetFields();
                },
                okText: getTranslatedText("OK")
            });
        } else {
            Modal.error({
                title: getTranslatedText("Error"),
                content: getTranslatedText("Failed to save company data"),
                okText: getTranslatedText("OK")
            });
        }
    };

    const maskedStringValuesCheck = (
        value: string,
        maskedValue?: string | undefined
    ) => {
        return includes(value, '**') && !isUndefined(maskedValue)
            ? undefined
            : value;
    };

    /**
     * Function called when `Save` button is clicked and will send all the changes to API.
     */
    const onSaveButtonClick = () => {
        validateFields((err: DynamicObject, values: DynamicObject) => {
            if (!err) {
                const selfManageDescriptionValue = get(
                    values,
                    formFieldNames.CardPaymentSelfManagedDescription
                );

                if (selfManageDescriptionValue) {
                    const urlifyHtmlString = urlifyURLsOutsideAnchorTags(
                        get(
                            values,
                            formFieldNames.CardPaymentSelfManagedDescription
                        )
                    );

                    values[formFieldNames.CardPaymentSelfManagedDescription] =
                        urlifyHtmlString;
                }

                const hasBankAccountDetails = get(
                    values,
                    formFieldNames.HasBankAccountDetails
                );
                const hasWesternUnionPaymentOption = get(
                    values,
                    formFieldNames.HasWesternUnionPaymentDetails
                );
                const hasCorpayPaymentOption = get(
                    values,
                    formFieldNames.HasCorpayPaymentDetails
                );
                const paymentOptionsPayload: {
                    EnablePartialPayment: boolean,
                    CardPaymentOption: {
                        Name: string;
                        SelectedCreditCards: string[];
                        SelfManagedPaymentOption: {
                            Description: string;
                        };
                        CreditCardPaymentOption: {
                            CreditCardPaymentUrl: string;
                        };
                        EziDebitPaymentOption: {
                            PaymentUrl: string | undefined;
                            EDDRUrl: string | undefined;
                            DigitalKey: string | undefined;
                            PublicKey: string | undefined;
                        };
                        IntegraPayPaymentOption: {
                            Username: string | undefined;
                            Password: string | undefined;
                        };
                        AdvamPaymentOption: {
                            AccountNumber: string | undefined;
                            Username: string | undefined;
                            Password: string | undefined;
                        };
                        ClientReference: string;
                    };
                    OverseasCardPaymentOption: {
                        Name: string;
                        SelfManagedPaymentOption: {
                            Description: string;
                        };
                        CreditCardPaymentOption: {
                            CreditCardPaymentUrl: string;
                        };
                    };
                    WesternUnionPaymentOption: {
                        IsEnabled: boolean;
                        ClientId: string;
                        ServiceId: string;
                        ClientReference: string;
                    };
                    CorpayPaymentOption: {
                        IsEnabled: boolean;
                        Status: string;
                    };
                    BankPaymentOption: {
                        IsEnabled: boolean;
                        AccountName: string;
                        AccountBSBNumber: string;
                        AccountNumber: string;
                        SwiftCode: string;
                        ReferenceNumber: string;
                    };
                    MailingChequePaymentOption: {
                        IsEnabled: boolean;
                    };
                } = {
                    EnablePartialPayment: get(values, formFieldNames.EnablePartialPayment),
                    CardPaymentOption: {
                        Name: get(values, formFieldNames.CardPaymentSystem),
                        SelectedCreditCards: get(
                            values,
                            formFieldNames.CardPaymentSelectedCreditCards
                        ),
                        SelfManagedPaymentOption: {
                            Description: get(
                                values,
                                formFieldNames.CardPaymentSelfManagedDescription
                            ),
                        },
                        CreditCardPaymentOption: {
                            CreditCardPaymentUrl: get(
                                values,
                                formFieldNames.CardPaymentURL
                            ),
                        },
                        EziDebitPaymentOption: {
                            PaymentUrl: get(
                                values,
                                formFieldNames.CardPaymentEziDebitPaymentURL,
                                ''
                            ),
                            EDDRUrl: get(
                                values,
                                formFieldNames.CardPaymentEziDebitEDDRURL,
                                ''
                            ),
                            DigitalKey: maskedStringValuesCheck(
                                get(
                                    values,
                                    formFieldNames.CardPaymentEziDebitDigitalKey,
                                    ''
                                ),
                                get(
                                    paymentOptionsData,
                                    paymentOptionsDataMapping[
                                    'CardPaymentEziDebitDigitalKey'
                                    ]
                                )
                            ),
                            PublicKey: maskedStringValuesCheck(
                                get(
                                    values,
                                    formFieldNames.CardPaymentEziDebitPublicKey,
                                    ''
                                ),
                                get(
                                    paymentOptionsData,
                                    paymentOptionsDataMapping[
                                    'CardPaymentEziDebitPublicKey'
                                    ]
                                )
                            ),
                        },
                        IntegraPayPaymentOption: {
                            Username: get(
                                values,
                                formFieldNames.CardPaymentIntegraPayUsername,
                                ''
                            ),
                            Password: maskedStringValuesCheck(
                                get(
                                    values,
                                    formFieldNames.CardPaymentIntegraPayPassword,
                                    ''
                                ),
                                get(
                                    paymentOptionsData,
                                    paymentOptionsDataMapping[
                                    'CardPaymentIntegraPayUsername' // masked value checking used is Username as it will indicate if password should be present or not
                                    ]
                                )
                            ),
                        },
                        AdvamPaymentOption: {
                            AccountNumber: get(
                                values,
                                formFieldNames.CardPaymentADVAMAccountNumber,
                                ''
                            ),
                            Username: get(
                                values,
                                formFieldNames.CardPaymentADVAMUsername,
                                ''
                            ),
                            Password: maskedStringValuesCheck(
                                get(
                                    values,
                                    formFieldNames.CardPaymentADVAMPassword,
                                    ''
                                ),
                                get(
                                    paymentOptionsData,
                                    paymentOptionsDataMapping[
                                    'CardPaymentADVAMUsername'
                                    ]
                                )
                            ),
                        },
                        ClientReference:
                            get(
                                values,
                                formFieldNames.CardPaymentClientReference
                            ) || defaultCardPaymentClientRef,
                    },
                    OverseasCardPaymentOption: {
                        Name: get(
                            values,
                            formFieldNames.OverseasCardPaymentSystem
                        ),
                        SelfManagedPaymentOption: {
                            Description: get(
                                values,
                                formFieldNames.OverseasCardPaymentSelfManagedDescription
                            ),
                        },
                        CreditCardPaymentOption: {
                            CreditCardPaymentUrl: get(
                                values,
                                formFieldNames.OverseasCardPaymentURL
                            ),
                        },
                    },
                    WesternUnionPaymentOption: {
                        IsEnabled: hasWesternUnionPaymentOption,
                        ClientId: get(
                            values,
                            formFieldNames.WesternUnionClientId
                        ),
                        ServiceId: get(
                            values,
                            formFieldNames.WesternUnionServiceId
                        ),
                        ClientReference: get(
                            values,
                            formFieldNames.WesternUnionClientReference
                        ),
                    },
                    CorpayPaymentOption: {
                        IsEnabled: hasCorpayPaymentOption,
                        Status: get(
                            paymentOptionsData,
                            paymentOptionsDataMapping.CorpayPaymentOptionStatus,
                            false
                        )
                    },
                    BankPaymentOption: {
                        IsEnabled: hasBankAccountDetails,
                        AccountName: get(
                            values,
                            formFieldNames.BankAccountName
                        ),
                        AccountBSBNumber: get(
                            values,
                            formFieldNames.BankAccountBSBNumber
                        ),
                        AccountNumber: get(
                            values,
                            formFieldNames.BankAccountNumber
                        ),
                        SwiftCode: get(values, formFieldNames.BankSwiftCode),
                        ReferenceNumber: get(
                            values,
                            formFieldNames.ReferenceNumber
                        ),
                    },
                    MailingChequePaymentOption: {
                        IsEnabled: get(
                            values,
                            formFieldNames.HasChequeMailingDetails
                        ),
                    },
                };

                const payload = {
                    PaymentOptions: paymentOptionsPayload,
                    DetailType: 'PaymentOptions',
                };

                dispatch(
                    saveUserCompanyRequestAction(
                        payload,
                        ({ IsSuccess }: { IsSuccess: boolean }) =>
                            handleModalSaveResponse(
                                IsSuccess,
                                paymentOptionsPayload
                            )
                    )
                );
            }
        });
    };

    /**
     * Function responsible for checking if Cheques switch button is turned `ON`.
     */
    const isMailingOptionsEnabled = getFieldValue(
        formFieldNames.HasChequeMailingDetails
    );

    /**
     * Function called when cheque switch button value from redux is updated.
     * This also checks if the Company has the minimum mailing info required
     * and displays an alert message if not.
     */
    const listenerForMailOption = () => {
        const usedMailingOptionsChecker = isUndefined(isMailingOptionsEnabled)
            ? get(
                paymentOptionsData,
                paymentOptionsDataMapping.MailingChequePaymentEnabled,
                false
            )
            : isMailingOptionsEnabled;

        // if mailing information is not complete
        if (
            usedMailingOptionsChecker &&
            !(
                get(companyData, 'Name') &&
                get(companyData, 'AddressLine1') &&
                get(companyData, 'Suburb')
            )
        ) {
            setShowIncompleteMailingInfo(true);
        } else {
            setShowIncompleteMailingInfo(false);
        }
    };

    useEffect(listenerForMailOption, [isMailingOptionsEnabled]);

    const clearOnCompanyChange = () => {
        resetFields();
    };

    useEffect(clearOnCompanyChange, [companyData]);

    /**
     * Function that updates the value for Digital key and Public key set after saving (to apply the obscured value).
     */
    const checkPaymentOptionsDataUpdate = () => {
        const cardPaymentSystemSelected = getFieldValue(
            formFieldNames['CardPaymentSystem']
        );

        if (cardPaymentSystemSelected === cardPaymentSystems.EZIDEBIT) {
            const digitalKey = get(
                paymentOptionsData,
                paymentOptionsDataMapping['CardPaymentEziDebitDigitalKey']
            );
            const publicKey = get(
                paymentOptionsData,
                paymentOptionsDataMapping['CardPaymentEziDebitPublicKey']
            );
            if (digitalKey || publicKey) {
                setFieldsValue({
                    [formFieldNames.CardPaymentEziDebitDigitalKey]: digitalKey,
                    [formFieldNames.CardPaymentEziDebitPublicKey]: publicKey,
                });
            }
        } else if (
            cardPaymentSystemSelected === cardPaymentSystems.INTEGRAPAY
        ) {
            const username = get(
                paymentOptionsData,
                paymentOptionsDataMapping['CardPaymentIntegraPayUsername']
            );
            const password = get(
                paymentOptionsData,
                paymentOptionsDataMapping['CardPaymentIntegraPayPassword'],
                passwordDisplay
            );
            if (username) {
                setFieldsValue({
                    [formFieldNames.CardPaymentIntegraPayUsername]: username,
                    [formFieldNames.CardPaymentIntegraPayPassword]: password,
                });
            }
        } else if (cardPaymentSystemSelected === cardPaymentSystems.ADVAM) {
            const password = get(
                paymentOptionsData,
                paymentOptionsDataMapping['CardPaymentADVAMPassword'],
                passwordDisplay
            );
            setFieldsValue({
                [formFieldNames.CardPaymentADVAMPassword]: password,
            });
        }

        const overseasCardPaymentSystemSelected = getFieldValue(
            formFieldNames['OverseasCardPaymentSystem']
        );
        if (cardPaymentSystemSelected || overseasCardPaymentSystemSelected) {
            const selectedCardsValue = getSelectedCardsValue();
            setFieldsValue({
                [formFieldNames.CardPaymentSelectedCreditCards]:
                    selectedCardsValue,
            });
        }
    };

    useEffect(checkPaymentOptionsDataUpdate, [paymentOptionsData]);
    const companyRegion = get(companyData, 'Region');
    const isDefaultRegion =
        companyRegion && companyRegion === DEFAULT_REGION_NAME;

    /**
     * Function that fetches the correct payment options for the company region.
     */
    const getCardPaymentSystemOptionsUsed = () => {
        let cardPaymentSystemOptionsByRegion = [];
        const cardPaymentSystemOptionsCopy = clone(cardPaymentSystemOptions);
        if (isDefaultRegion) {
            cardPaymentSystemOptionsByRegion = cardPaymentSystemOptionsCopy;
        } else {
            cardPaymentSystemOptionsByRegion =
                cardPaymentSystemOptionsCopy.filter(
                    (cpoc) => !includes(auOnlyPaymentOptions, cpoc.value)
                );
        }

        return cardPaymentSystemOptionsByRegion;
    };

    /**
     * Function that populates the dropdown options for card payment system.
     */
    const populateCardPaymentSystemOptions = (isOverseas: boolean) => {
        const cardPaymentSystemOptionsByRegion =
            getCardPaymentSystemOptionsUsed();

        const usedCardPaymentSystemOptions = isOverseas
            ? overseasCardPaymentSystemOptions
            : cardPaymentSystemOptionsByRegion;
        return map(
            usedCardPaymentSystemOptions,
            ({ label, value }: { label: string; value: string }) => (
                <Option key={value} value={value}>
                    {label}
                </Option>
            )
        );
    };

    /**
     * Function that validates the payment url entered in input.
     */
    const validatePaymentURL = (
        _rule: any,
        value: string,
        callback: (error?: any) => void
    ) => {
        if (value) {
            if (checkIfValidURL(value)) {
                if (
                    value.indexOf('http://') === 0 ||
                    value.indexOf('https://') === 0
                ) {
                    callback();
                } else {
                    callback(
                        <span>
                            Payment URL must start with <b>http://</b> or{' '}
                            <b>https://</b>!
                        </span>
                    );
                }
            } else {
                callback(getTranslatedText("Please enter a valid url"));
            }
        } else {
            callback(); // apply the default error for required field.
        }
    };

    /**
     * Function that validates the public key or digital key for ezidebit.
     */
    const validateEziDebitKey = (
        _rule: any,
        value: string,
        callback: (error?: any) => void
    ) => {
        if (value) {
            if (value.length === 36) {
                callback();
            } else {
                callback(getTranslatedText("A valid key should have 36 characters"));
            }
        } else {
            callback(); // apply the default error for required field.
        }
    };

    /**
     * Function that navigates the user to EziDebit apply account.
     */
    // const navigateToEziDebitApplyAccount = () => {
    //     openURLNewTab(EZIDEBIT_APPLY_ACCOUNT_URL);
    // };

    /**
     * Function that populates the card payment details section based on
     * the selected value for card payment system dropdown.
     */
    const populateCardPaymentOptionDetailsSection = (prefixText: string) => {
        const cardPaymentSystemSelected = getFieldValue(
            formFieldNames[`${prefixText}CardPaymentSystem`]
        );

        if (cardPaymentSystemSelected === cardPaymentSystems.SELF_MANAGED) {
            return (
                <Row>
                    <Col className="start-flex-i">
                        <div className="mt-9 pr-8 ant-form-item-required">
                            {getTranslatedText("Description")}:
                        </div>
                        <FormItem className="f-ta-100">
                            {getFieldDecorator(
                                formFieldNames[
                                `${prefixText}CardPaymentSelfManagedDescription`
                                ],
                                {
                                    initialValue: get(
                                        paymentOptionsData,
                                        paymentOptionsDataMapping[
                                        `${prefixText}CardPaymentSelfManagedDescription`
                                        ],
                                        ''
                                    ),
                                    rules: [
                                        {
                                            required: true,
                                            message: getTranslatedText("Description is required"),
                                        },
                                    ],
                                }
                            )(
                                <ReactQuill
                                    theme="snow"
                                    {...reactQuillSettings}
                                    readOnly={formDisabled}
                                />
                            )}
                        </FormItem>
                    </Col>
                </Row>
            );
        } else if (
            cardPaymentSystemSelected === cardPaymentSystems.PAYMENT_URL
        ) {
            return (
                <Row>
                    <Col className="flex-i">
                        <div className="mt-9 pr-8 ant-form-item-required">
                            {getTranslatedText("Your Payment URL")}:
                        </div>
                        <FormItem className="f-ta-100">
                            {getFieldDecorator(
                                formFieldNames[`${prefixText}CardPaymentURL`],
                                {
                                    initialValue: get(
                                        paymentOptionsData,
                                        paymentOptionsDataMapping[
                                        `${prefixText}CardPaymentPaymentURL`
                                        ],
                                        ''
                                    ),
                                    rules: [
                                        {
                                            required: true,
                                            message: getTranslatedText("Payment URL is required"),
                                        },
                                        {
                                            validator: validatePaymentURL,
                                        },
                                    ],
                                }
                            )(
                                <Input
                                    placeholder={getTranslatedText("Must start with https:// or http://")}
                                    readOnly={formDisabled}
                                />
                            )}
                        </FormItem>
                    </Col>
                </Row>
            );
        } else if (cardPaymentSystemSelected === cardPaymentSystems.EZIDEBIT) {
            const cardPaymentSystemOptionsByRegion =
                getCardPaymentSystemOptionsUsed();
            if (
                isEmpty(
                    cardPaymentSystemOptionsByRegion.filter(
                        (cps: DynamicObject) =>
                            cps.value === cardPaymentSystemSelected
                    )
                )
            ) {
                return null;
            }
            return (
                <Row>
                    <Col>
                        {/* <Row>
                            <Col className="flex-i">
                                <div className="mt-6 ezidebit-option-label">
                                    <b>EziDebit</b>
                                </div>
                                <div>
                                    <Button
                                        type="primary"
                                        onClick={navigateToEziDebitApplyAccount}
                                    >
                                        Apply for EziDebit Account
                                    </Button>
                                </div>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col className="mb-10">
                                {getTranslatedText("Please complete the details below once you have confirmation of your account being created, or use details from your existing EziDebit account")}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="flex-i">
                                <div className="mt-9 ezidebit-option-label ant-form-item-required">
                                    {getTranslatedText("Payment URL")}:
                                </div>
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(
                                        formFieldNames[
                                        `${prefixText}CardPaymentEziDebitPaymentURL`
                                        ],
                                        {
                                            initialValue: get(
                                                paymentOptionsData,
                                                paymentOptionsDataMapping[
                                                `${prefixText}CardPaymentEziDebitPaymentURL`
                                                ],
                                                ''
                                            ),
                                            rules: [
                                                {
                                                    required: true,
                                                    message: getTranslatedText("Payment URL is required")
                                                },
                                                {
                                                    validator:
                                                        validatePaymentURL,
                                                },
                                            ],
                                        }
                                    )(
                                        <Input
                                            placeholder={getTranslatedText("Must start with https:// or http://")}
                                            readOnly={formDisabled}
                                        />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="flex-i">
                                <div className="mt-9 ezidebit-option-label">
                                    {getTranslatedText("Electronic DDR URL")}:
                                </div>
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(
                                        formFieldNames[
                                        `${prefixText}CardPaymentEziDebitEDDRURL`
                                        ],
                                        {
                                            initialValue: get(
                                                paymentOptionsData,
                                                paymentOptionsDataMapping[
                                                `${prefixText}CardPaymentEziDebitEDDRURL`
                                                ],
                                                ''
                                            ),
                                            rules: [
                                                {
                                                    validator:
                                                        validatePaymentURL,
                                                },
                                            ],
                                        }
                                    )(
                                        <Input
                                            placeholder={getTranslatedText("Must start with https:// or http://")}
                                            readOnly={formDisabled}
                                        />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="flex-i">
                                <div className="mt-9 ezidebit-option-label ant-form-item-required">
                                    {getTranslatedText("Digital Key")}:
                                </div>
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(
                                        formFieldNames[
                                        `${prefixText}CardPaymentEziDebitDigitalKey`
                                        ],
                                        {
                                            initialValue: get(
                                                paymentOptionsData,
                                                paymentOptionsDataMapping[
                                                `${prefixText}CardPaymentEziDebitDigitalKey`
                                                ],
                                                ''
                                            ),
                                            rules: [
                                                {
                                                    required: true,
                                                    message: getTranslatedText("Digital Key is required")
                                                },
                                                {
                                                    validator:
                                                        validateEziDebitKey,
                                                },
                                            ],
                                        }
                                    )(<Input readOnly={formDisabled} />)}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="flex-i">
                                <div className="mt-9 ezidebit-option-label ant-form-item-required">
                                    {getTranslatedText("Public Key")}:
                                </div>
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(
                                        formFieldNames[
                                        `${prefixText}CardPaymentEziDebitPublicKey`
                                        ],
                                        {
                                            initialValue: get(
                                                paymentOptionsData,
                                                paymentOptionsDataMapping[
                                                `${prefixText}CardPaymentEziDebitPublicKey`
                                                ],
                                                ''
                                            ),
                                            rules: [
                                                {
                                                    required: true,
                                                    message: getTranslatedText("Public Key is required")
                                                },
                                                {
                                                    validator:
                                                        validateEziDebitKey,
                                                },
                                            ],
                                        }
                                    )(<Input readOnly={formDisabled} />)}
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            );
        } else if (
            cardPaymentSystemSelected === cardPaymentSystems.INTEGRAPAY
        ) {
            const cardPaymentSystemOptionsByRegion =
                getCardPaymentSystemOptionsUsed();
            if (
                isEmpty(
                    cardPaymentSystemOptionsByRegion.filter(
                        (cps: DynamicObject) =>
                            cps.value === cardPaymentSystemSelected
                    )
                )
            ) {
                return null;
            }
            return (
                <Row>
                    <Col>
                        <Row>
                            <Col className="mb-10">
                                {getTranslatedText("Please complete the details below from your existing Payrix account")}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="flex-i">
                                <div className="mt-9 ezidebit-option-label ant-form-item-required">
                                    {getTranslatedText("Username")}:
                                </div>
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(
                                        formFieldNames[
                                        `${prefixText}CardPaymentIntegraPayUsername`
                                        ],
                                        {
                                            initialValue: get(
                                                paymentOptionsData,
                                                paymentOptionsDataMapping[
                                                `${prefixText}CardPaymentIntegraPayUsername`
                                                ],
                                                ''
                                            ),
                                            rules: [
                                                {
                                                    required: true,
                                                    message: getTranslatedText("Username is required"),
                                                },
                                            ],
                                        }
                                    )(<Input readOnly={formDisabled} />)}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="flex-i">
                                <div className="mt-9 ezidebit-option-label ant-form-item-required">
                                    {getTranslatedText("Password")}:
                                </div>
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(
                                        formFieldNames[
                                        `${prefixText}CardPaymentIntegraPayPassword`
                                        ],
                                        {
                                            initialValue: get(
                                                paymentOptionsData,
                                                paymentOptionsDataMapping[
                                                `${prefixText}CardPaymentIntegraPayUsername`
                                                ]
                                            )
                                                ? passwordDisplay
                                                : get(
                                                    paymentOptionsData,
                                                    paymentOptionsDataMapping[
                                                    `${prefixText}CardPaymentIntegraPayPassword`
                                                    ],
                                                    ''
                                                ),
                                            rules: [
                                                {
                                                    required: true,
                                                    message: getTranslatedText("Password is required"),
                                                },
                                            ],
                                        }
                                    )(<Input readOnly={formDisabled} />)}
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            );
        } else if (cardPaymentSystemSelected === cardPaymentSystems.ADVAM) {
            const cardPaymentSystemOptionsByRegion =
                getCardPaymentSystemOptionsUsed();
            if (
                isEmpty(
                    cardPaymentSystemOptionsByRegion.filter(
                        (cps: DynamicObject) =>
                            cps.value === cardPaymentSystemSelected
                    )
                )
            ) {
                return null;
            }
            return (
                <Row>
                    <Col>
                        <Row>
                            <Col className="mb-10">
                                {getTranslatedText("Please complete the details below once you have confirmation of your account being created, or use details from your existing ADVAM account")}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="flex-i">
                                <div className="mt-9 advam-option-label ant-form-item-required">
                                    {getTranslatedText("Account number")}:
                                </div>
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(
                                        formFieldNames[
                                        `${prefixText}CardPaymentADVAMAccountNumber`
                                        ],
                                        {
                                            initialValue: get(
                                                paymentOptionsData,
                                                paymentOptionsDataMapping[
                                                `${prefixText}CardPaymentADVAMAccountNumber`
                                                ],
                                                ''
                                            ),
                                            rules: [
                                                {
                                                    required: true,
                                                    message: getTranslatedText("Account number is required"),
                                                },
                                            ],
                                        }
                                    )(<Input readOnly={formDisabled} />)}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="flex-i">
                                <div className="mt-9 advam-option-label ant-form-item-required">
                                    {getTranslatedText("Username")}:
                                </div>
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(
                                        formFieldNames[
                                        `${prefixText}CardPaymentADVAMUsername`
                                        ],
                                        {
                                            initialValue: get(
                                                paymentOptionsData,
                                                paymentOptionsDataMapping[
                                                `${prefixText}CardPaymentADVAMUsername`
                                                ],
                                                ''
                                            ),
                                            rules: [
                                                {
                                                    required: true,
                                                    message: getTranslatedText("Username is required"),
                                                },
                                            ],
                                        }
                                    )(<Input readOnly={formDisabled} />)}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="flex-i">
                                <div className="mt-9 advam-option-label ant-form-item-required">
                                    {getTranslatedText("Password")}:
                                </div>
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(
                                        formFieldNames[
                                        `${prefixText}CardPaymentADVAMPassword`
                                        ],
                                        {
                                            initialValue: get(
                                                paymentOptionsData,
                                                paymentOptionsDataMapping[
                                                `${prefixText}CardPaymentADVAMAccountNumber`
                                                ]
                                            )
                                                ? passwordDisplay
                                                : get(
                                                    paymentOptionsData,
                                                    paymentOptionsDataMapping[
                                                    `${prefixText}CardPaymentADVAMPassword`
                                                    ],
                                                    ''
                                                ),
                                            rules: [
                                                {
                                                    required: true,
                                                    message: getTranslatedText("Password is required"),
                                                },
                                            ],
                                        }
                                    )(<Input readOnly={formDisabled} />)}
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            );
        }
    };

    /**
     * Function to go to Company contact page for updating mailing option.
     */
    const goToCompanyContactPage = () => {
        props.history.push('/app/settings/company/contact');
    };

    let formDisabled = true;
    const allowedRoles = rolePermissions.CUSTOMER_PAYMENTS_UPDATE;
    if (isEmpty(allowedRoles) || includes(allowedRoles, userRole)) {
        formDisabled = false;
    }

    const formHasChanges = true;
    /**
     * Function responsible for getting the reference instructions based on logic provided.
     */
    const referenceNumber =
        getFieldValue(formFieldNames.HasBankAccountDetails) === true &&
            get(
                paymentOptionsData,
                paymentOptionsDataMapping.BankPaymentOptionEnabled,
                false
            ) === false
            ? 'Your invoice number'
            : get(
                paymentOptionsData,
                paymentOptionsDataMapping.BankPaymentReferenceInstructions,
                'Your invoice number'
            );

    /**
     * Common function to generate the card payment option section
     * @param isOverseas
     */
    const populateCardPaymentOptionSection = (isOverseas: boolean = false) => {
        const prefixText = isOverseas ? 'Overseas' : '';
        const cardPaymentSystem = getFieldValue(
            formFieldNames.CardPaymentSystem
        );

        let showClientReference = false;
        if (!isOverseas && cardPaymentSystem) {
            if (
                cardPaymentSystem === cardPaymentSystems.ADVAM ||
                cardPaymentSystem === cardPaymentSystems.EZIDEBIT ||
                cardPaymentSystem === cardPaymentSystems.INTEGRAPAY
            ) {
                showClientReference = true;
            }
        }

        return (
            <Row>
                <Col span={24} className="center-flex-i mb-10">
                    {getTranslatedText("Select the card payment system")} &nbsp;&nbsp;
                    <FormItem className="fx-1">
                        {getFieldDecorator(
                            formFieldNames[`${prefixText}CardPaymentSystem`],
                            {
                                initialValue:
                                    get(
                                        paymentOptionsData,
                                        paymentOptionsDataMapping[
                                        `${prefixText}CardPaymentSystem`
                                        ]
                                    ) || '',
                            }
                        )(
                            <SelectReadonly readOnly={formDisabled}>
                                {populateCardPaymentSystemOptions(isOverseas)}
                            </SelectReadonly>
                        )}
                    </FormItem>
                </Col>

                <Col span={24}>
                    {populateCardPaymentOptionDetailsSection(prefixText)}
                </Col>
                {showClientReference && (
                    <Col span={24}>
                        <Row>
                            <Col className="flex-i">
                                <div className="mt-9 ezidebit-option-label">
                                    {getTranslatedText("Payment reference")}:
                                </div>
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(
                                        formFieldNames.CardPaymentClientReference,
                                        {
                                            initialValue:
                                                cardPaymentSystem ===
                                                    get(
                                                        paymentOptionsData,
                                                        paymentOptionsDataMapping.CardPaymentSystem
                                                    ) &&
                                                    !isEmpty(
                                                        get(
                                                            paymentOptionsData,
                                                            paymentOptionsDataMapping.CardPaymentClientReference
                                                        )
                                                    )
                                                    ? get(
                                                        paymentOptionsData,
                                                        paymentOptionsDataMapping.CardPaymentClientReference
                                                    )
                                                    : defaultCardPaymentClientRef,
                                        }
                                    )(<Input readOnly={formDisabled} />)}
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                )}
            </Row>
        );
    };

    /**
    * Common function to generate the Western union section
    * @param isWesternUnion
    */
    const populateWesternUnionSection = (isWesternUnion: boolean = false) => {

        return (
            <Row>
                {isWesternUnion &&
                    <>
                        <Col className="mb-8">
                            <Row type="flex" align="middle" gutter={20}>
                                <Col>
                                    <img
                                        className="western-union-logo"
                                        src={converaLogo}
                                        alt="Convera"
                                    />
                                </Col>
                                <Col>
                                    <h4 className="mb-0">Convera</h4>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} className="center-flex-i">
                            <span
                                className="mb-8"
                                style={{ maxWidth: 700 }}
                            >
                                {getTranslatedText("Partner with Convera to accept invoice payments in a variety of currencies via credit card, bank transfer and a number of popular online payment options")}   
                            </span>
                            &nbsp;&nbsp;
                            <FormItem>
                            {getFieldDecorator(
                                formFieldNames.HasWesternUnionPaymentDetails,
                                {
                                    valuePropName: 'checked',
                                    initialValue: get(
                                        paymentOptionsData,
                                        paymentOptionsDataMapping.WesternUnionPaymentOptionEnabled,
                                        false
                                    ),
                                }
                            )(
                                <Switch
                                    checkedChildren={getTranslatedText("ON")}
                                    unCheckedChildren={getTranslatedText("OFF")}
                                        disabled={formDisabled}
                                    />
                                )}
                            </FormItem>
                        </Col>
                        {getFieldValue(
                            formFieldNames.HasWesternUnionPaymentDetails
                        ) && (
                                <Col
                                    xxl={24}
                                    xl={18}
                                    lg={22}
                                    md={24}
                                    sm={24}
                                    xs={24}
                                >
                                    <Row>
                                        <Col>
                                            <FormItem
                                                label={getTranslatedText("Client ID")}
                                                labelCol={{ span: 5 }}
                                            >
                                                {getFieldDecorator(
                                                    formFieldNames.WesternUnionClientId,
                                                    {
                                                        initialValue: get(
                                                            paymentOptionsData,
                                                            paymentOptionsDataMapping.WesternUnionClientId,
                                                            ''
                                                        ),
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: getTranslatedText("Client ID is required"),
                                                            },
                                                        ],
                                                    }
                                                )(
                                                    <Input
                                                        readOnly={formDisabled}
                                                    />
                                                )}
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormItem
                                                label={getTranslatedText("Service ID")}
                                                labelCol={{ span: 5 }}
                                            >
                                                {getFieldDecorator(
                                                    formFieldNames.WesternUnionServiceId,
                                                    {
                                                        initialValue: get(
                                                            paymentOptionsData,
                                                            paymentOptionsDataMapping.WesternUnionServiceId,
                                                            ''
                                                        ),
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: getTranslatedText("Service ID is required"),
                                                            },
                                                        ],
                                                    }
                                                )(
                                                    <Input
                                                        readOnly={formDisabled}
                                                    />
                                                )}
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormItem
                                                label={getTranslatedText("Payment reference")}
                                                labelCol={{ span: 5 }}
                                            >
                                                {getFieldDecorator(
                                                    formFieldNames.WesternUnionClientReference,
                                                    {
                                                        initialValue:
                                                            get(
                                                                paymentOptionsData,
                                                                paymentOptionsDataMapping.WesternUnionClientReference
                                                            ) ||
                                                            defaultCardPaymentClientRef,
                                                    }
                                                )(
                                                    <Input
                                                        readOnly={formDisabled}
                                                    />
                                                )}
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                    </>

                }

            </Row>
        );
    }
    /**
     * Common function to generate the corpay section
     * @param isCorpay
     */
    const populateCorpaySection = (isCorpay: boolean = false) => {
        var corpayStatus = get(
            paymentOptionsData,
            paymentOptionsDataMapping.CorpayPaymentOptionStatus,
            false
        );
        
        let corpayStatusColor = '';

        if (corpayStatus === "Completed") {
            corpayStatusColor = 'green';
        } 
        else if(corpayStatus === "Configuration in progress") {
            corpayStatusColor = 'orange';
        }
        else {
            corpayStatusColor = 'red';
            corpayStatus = 'Not configured';
        } 

        return (
            <Row>
                {isCorpay &&
                    <>
                        <Row type="flex" align="middle" gutter={[16, 35]}>
                            <Col>
                                <img
                                    className="corpay-logo"
                                    src={corpayLogo}
                                    alt="Corpay"
                                />
                            </Col>
                            <Col >
                                <h4 className="mb-0">Corpay</h4>
                            </Col>
                        </Row>
                        <Row type="flex" align="middle" gutter={[16, 35]}>
                            <Col span={24} className="center-flex-i">
                                <span
                                    className="mb-8"
                                    style={{ maxWidth: 700 }}
                                >
                                    {getTranslatedText("Partner with Corpay to accept invoice payments in a variety of currencies")}
                                </span>
                                &nbsp;&nbsp;
                                <FormItem>
                                    {getFieldDecorator(
                                        formFieldNames.HasCorpayPaymentDetails,
                                        {
                                            valuePropName: 'checked',
                                            initialValue: get(
                                                paymentOptionsData,
                                                paymentOptionsDataMapping.CorpayPaymentOptionEnabled,
                                                false
                                            ),
                                        }
                                    )(
                                        <Switch
                                            checkedChildren={getTranslatedText("ON")}
                                            unCheckedChildren={getTranslatedText("OFF")}
                                            disabled={formDisabled}
                                        />
                                    )}
                                </FormItem>

                            </Col>
                        </Row>


                        {getFieldValue(
                            formFieldNames.HasCorpayPaymentDetails
                        ) && (
                                <>
                                    <Row type="flex" gutter={[16, 25]}>
                                        <Col>
                                            <h4 className="mb-2">{getTranslatedText("Configuration Status")}:</h4>
                                        </Col>
                                        <Col>
                                            <span
                                                className={corpayStatusColor}
                                                style={{ maxWidth: 700 }}
                                            >
                                                {corpayStatus}
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row type="flex" gutter={[16, 35]}>
                                        <Col>
                                            <span
                                                className="mb-8"
                                                style={{ maxWidth: 700 }}
                                            >
                                                {getTranslatedText("If you, or your IT team, would like to configure your account to use Corpay, please contact us via support@iodmconnect.com")}
                                            </span>
                                        </Col>
                                    </Row>
                                </>
                            )}
                    </>
                }
            </Row>
        );
    };

    /**
    * Common function to generate the Bank depositn section
    * @param isBankDeposit
    */
    const populateBankDepositSection = (isBankDeposit: boolean = false) => {

        return (
            <Row>
                {isBankDeposit &&
                    <>
                        <Col span={24}>
                        <h4>{getTranslatedText("Bank deposit")}</h4>
                        </Col>
                        <Col span={24} className="center-flex-i">
                            {getTranslatedText("Provide your bank account details to receive payments via EFT")} &nbsp;&nbsp;
                            <FormItem>
                                {getFieldDecorator(
                                    formFieldNames.HasBankAccountDetails,
                                    {
                                        valuePropName: 'checked',
                                        initialValue: get(
                                            paymentOptionsData,
                                            paymentOptionsDataMapping.BankPaymentOptionEnabled,
                                            false
                                        ),
                                    }
                                )(
                                    <Switch
                                        checkedChildren={getTranslatedText("ON")}
                                        unCheckedChildren={getTranslatedText("OFF")}
                                        disabled={formDisabled}
                                    />
                                )}
                            </FormItem>
                        </Col>
                        {getFieldValue(
                            formFieldNames.HasBankAccountDetails
                        ) && (
                                <Col
                                    xxl={20}
                                    xl={18}
                                    lg={22}
                                    md={24}
                                    sm={24}
                                    xs={24}
                                >
                                    <Row>
                                        <Col>
                                            <FormItem label={getTranslatedText("Bank account name")}>
                                                {getFieldDecorator(
                                                    formFieldNames.BankAccountName,
                                                    {
                                                        initialValue: get(
                                                            paymentOptionsData,
                                                            paymentOptionsDataMapping.BankPaymentAccountName,
                                                            ''
                                                        ),
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: getTranslatedText("Bank account name is required"),
                                                            },
                                                        ],
                                                    }
                                                )(
                                                    <Input
                                                        readOnly={formDisabled}
                                                    />
                                                )}
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormItem
                                                label={getTranslatedText(get(languagePack, 'BankBSB', 'BSB'))}
                                            >
                                                {getFieldDecorator(
                                                    formFieldNames.BankAccountBSBNumber,
                                                    {
                                                        initialValue: get(
                                                            paymentOptionsData,
                                                            paymentOptionsDataMapping.BankPaymentBSBNumber,
                                                            ''
                                                        ),
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: getTranslatedText(`${get(languagePack, 'BankBSB', 'BSB')} is required!`),
                                                            },
                                                        ],
                                                    }
                                                )(
                                                    <Input
                                                        readOnly={formDisabled}
                                                    />
                                                )}
                                            </FormItem>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <FormItem
                                                label={getTranslatedText(get(languagePack, 'BankAccountNumber', 'Bank account number'))}
                                            >
                                                {getFieldDecorator(
                                                    formFieldNames.BankAccountNumber,
                                                    {
                                                        initialValue: get(
                                                            paymentOptionsData,
                                                            paymentOptionsDataMapping.BankPaymentAccountNumber,
                                                            ''
                                                        ),
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: getTranslatedText(`${get(languagePack, 'BankAccountNumber', 'Bank account number')} is required!`),
                                                            },
                                                        ],
                                                    }
                                                )(
                                                    <Input
                                                        readOnly={formDisabled}
                                                    />
                                                )}
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormItem label={getTranslatedText("SWIFT code")}>
                                                {getFieldDecorator(
                                                    formFieldNames.BankSwiftCode,
                                                    {
                                                        initialValue: get(
                                                            paymentOptionsData,
                                                            paymentOptionsDataMapping.BankPaymentSwiftCode,
                                                            ''
                                                        ),
                                                    }
                                                )(
                                                    <Input
                                                        readOnly={formDisabled}
                                                    />
                                                )}
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormItem label={getTranslatedText("Reference instructions")}>
                                                {getFieldDecorator(
                                                    formFieldNames.ReferenceNumber,
                                                    {
                                                        initialValue:
                                                            referenceNumber,
                                                    }
                                                )(
                                                    <Input
                                                        readOnly={formDisabled}
                                                    />
                                                )}
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                    </>
                }
            </Row>
        );
    }

    /**
    * Common function to generate the Bank depositn section
    * @param isBankDeposit
    */
    const populateChequesSection = (isCheques: boolean = false) => {

        return (
            <Row>
                {isCheques &&
                    <>
                        <Col span={24}>
                            <h4>{getTranslatedText("Cheques")}</h4>
                        </Col>
                        <Col span={24} className="center-flex-i">
                            {getTranslatedText("Provide your mailing details to receive cheques")} &nbsp;&nbsp;
                            <FormItem>
                                {getFieldDecorator(
                                    formFieldNames.HasChequeMailingDetails,
                                    {
                                        valuePropName: 'checked',
                                        initialValue: get(
                                            paymentOptionsData,
                                            paymentOptionsDataMapping.MailingChequePaymentEnabled,
                                            false
                                        ),
                                    }
                                )(
                                    <Switch
                                        checkedChildren={getTranslatedText("ON")}
                                        unCheckedChildren={getTranslatedText("OFF")}
                                        disabled={formDisabled}
                                    />
                                )}
                            </FormItem>
                        </Col>
                    </>
                }

            </Row>
        );
    }
    /**
     * Function that populates card payment option section.
     */
    const populatePaymentGatewaySection = () => {
        return (
            <>
                <Tabs defaultActiveKey="localpayments" type="card">
                    <TabPane tab={getTranslatedText("Local payments")} key="localpayments">
                        {populateCardPaymentOptionSection(false)}
                    </TabPane>
                    <TabPane tab={getTranslatedText("Overseas payments")} key="overseaspayments">
                        {populateCardPaymentOptionSection(true)}
                    </TabPane>
                </Tabs>
                <Divider />
                <h4>{getTranslatedText("Online payments")}</h4>
                <Tabs defaultActiveKey="westernunion" type="card">
                    <TabPane tab="Convera" key="westernunion">
                        {populateWesternUnionSection(true)}
                    </TabPane>
                    <TabPane tab="Corpay" key="corpay">
                        {populateCorpaySection(true)}
                    </TabPane>
                </Tabs>
                <Divider />
                <h4>{getTranslatedText("Other Payments")}</h4>
                <Tabs defaultActiveKey="bankdeposit" type="card">
                    <TabPane tab={getTranslatedText("Bank Deposit")} key="bankdeposit">
                        {populateBankDepositSection(true)}
                    </TabPane>
                    <TabPane tab={getTranslatedText("Cheques")} key="cheques">
                        {populateChequesSection(true)}
                    </TabPane>
                </Tabs>
            </>
        );
    };

    const getCardOptions = () => {
        const iconStyle = {
            color: initialPrimaryColor,
        };
        const cardsSelected = getFieldValue(
            formFieldNames.CardPaymentSelectedCreditCards
        );
        return map(creditCardOptionsList, (ccol: DynamicObject) => {
            return {
                label: (
                    <div className="cc-ic">
                        <FontAwesome
                            icon={['fab', ccol.icon]}
                            className="fs-30 primary mr-5"
                            style={
                                includes(cardsSelected, ccol.value)
                                    ? iconStyle
                                    : undefined
                            }
                        />
                        <span>{ccol.label}</span>
                    </div>
                ),
                value: ccol.value,
            };
        });
    };

    /**
     * Function for getting the selected cards value
     */
    const getSelectedCardsValue = () => {
        let defaultCardsAvailable = defaultCreditCardsAvailable;
        const creditcardPaymentSelected = get(
            paymentOptionsData,
            paymentOptionsDataMapping.CardPaymentSelectedCreditCards,
            []
        );

        if (!isEmpty(creditcardPaymentSelected)) {
            defaultCardsAvailable = creditcardPaymentSelected;
        }

        return defaultCardsAvailable;
    };

    const populateCardOptionsSection = () => {
        const cardPaymentSystem = getFieldValue(
            formFieldNames.CardPaymentSystem
        );
        const overseasCardPaymentSystem = getFieldValue(
            formFieldNames.OverseasCardPaymentSystem
        );

        if (!cardPaymentSystem && !overseasCardPaymentSystem) {
            return null;
        }
        const cardOptions = getCardOptions();
        const defaultCardsSelected = getSelectedCardsValue();

        return (
            <Row className="mt-20">
                <Col>
                    <h4>{getTranslatedText("Credit card options")}</h4>
                </Col>
                <Col span={24}>
                    <FormItem>
                        {getFieldDecorator(
                            formFieldNames.CardPaymentSelectedCreditCards,
                            {
                                rules: [{ type: 'array' }],
                                initialValue: defaultCardsSelected,
                            }
                        )(
                            <Checkbox.Group
                                className="cb-w-icon"
                                options={cardOptions}
                                disabled={formDisabled}
                            />
                        )}
                    </FormItem>
                </Col>
            </Row>
        );
    };

    return (
        <div className="h-100">
            <Col span={24}>
                <Form
                    className="form-inline-mb-0"
                    labelCol={{
                        xxl: { span: 'auto' },
                        xl: { span: 7 },
                        lg: { span: 8 },
                        md: { span: 6 },
                        sm: { span: 6 },
                        xs: { span: 6 },
                    }}
                    wrapperCol={{
                        xxl: { span: 16 },
                        xl: { span: 17 },
                        lg: { span: 16 },
                        md: { span: 18 },
                        sm: { span: 18 },
                        xs: { span: 18 },
                    }}
                >
                    <QueueAnim type={['right', 'left']} leaveReverse>
                        <Row key="title-container" type="flex" align="middle">
                            <Col span={12}>
                                <Row>
                                    <Col>
                                        <Title level={3}>{getTranslatedText("Payment options")}</Title>
                                    </Col>
                                    <Col>
                                        {getTranslatedText(`Select the payment options to provide to your ${toLower(customerLabel)}s`)}
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={12} className="ta-right">
                                <Button
                                    className="mr-10 w-100px"
                                    type="primary"
                                    onClick={onSaveButtonClick}
                                    disabled={formDisabled || !formHasChanges}
                                    loading={saveLoading}
                                >
                                    {getTranslatedText("Save")}
                                </Button>
                                <Button
                                    className="buttonGrey w-100px"
                                    onClick={onCancelButtonClick}
                                    disabled={formDisabled || !formHasChanges}
                                >
                                    {getTranslatedText("Cancel")}
                                </Button>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={24}>
                                <h4>{getTranslatedText("Allow Partial Payments")}</h4>
                            </Col>
                            <Col className="center-flex-i"
                                xxl={10}
                                xl={15}
                                lg={18}
                                md={18}
                                sm={18}
                                xs={18}
                            >
                                {getTranslatedText("Allow your customers to make part-payments. This applies to the online card and Convera payments")} &nbsp;&nbsp;
                                <FormItem>
                                    {getFieldDecorator(
                                        formFieldNames.EnablePartialPayment,
                                        {
                                            valuePropName: 'checked',
                                            initialValue: get(
                                                paymentOptionsData,
                                                paymentOptionsDataMapping.EnablePartialPayment,
                                                false
                                            ),
                                        }
                                    )(
                                        <Switch
                                            checkedChildren={getTranslatedText("ON")}
                                            unCheckedChildren={getTranslatedText("OFF")}
                                            disabled={formDisabled}
                                        />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={24}>
                                <h4>{getTranslatedText("Online card payments")}</h4>
                            </Col>
                            <Col
                                xxl={10}
                                xl={15}
                                lg={18}
                                md={18}
                                sm={18}
                                xs={18}
                            >
                                {populatePaymentGatewaySection()}
                            </Col>
                        </Row>

                        {showIncompleteMailingInfo && (
                            <Row>
                                <Col
                                    xxl={10}
                                    xl={15}
                                    lg={18}
                                    md={18}
                                    sm={18}
                                    xs={18}
                                >
                                    <Alert
                                        type="error"
                                        message={
                                            <span>
                                                {getTranslatedText(`Please note your mailing information is not complete and shall not appear correctly on ${toLower(customerLabel)} notifications. Click`)}{' '}
                                                <Button
                                                    className="pa-0 h-20px"
                                                    type="link"
                                                    onClick={
                                                        goToCompanyContactPage
                                                    }
                                                >
                                                    here
                                                </Button>{' '}
                                                {getTranslatedText("to update your contact information")}
                                            </span>
                                        }
                                    />
                                </Col>
                            </Row>
                        )}
                        <Divider />
                    </QueueAnim>
                </Form>
                {saveLoading && (
                    <ModalWithSpinner
                        modalTitle={getTranslatedText("Saving company data")}
                        modalVisible={saveLoading}
                        displayMessage={getTranslatedText("Please wait while saving company data")}
                    />
                )}
            </Col>
        </div>
    );
};

const CustomerPaymentsManagementPageForm = Form.create({
    name: 'customer-payments-form',
})(CustomerPaymentsManagementPage);
export default withRouter(CustomerPaymentsManagementPageForm);
