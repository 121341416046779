/**
 * Component that populates the item for each row in remittance advices page.
 */

import { Button, Checkbox, Col, Row, Tooltip } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import {
    capitalize,
    compact,
    concat,
    get,
    includes,
    isEmpty,
    isEqual,
    isUndefined,
    map,
} from 'lodash';
import React, { memo, useRef } from 'react';
import { useSelector } from 'react-redux';
import '../../pages/sales/sales.less';
import { ApplicationState } from '../../store';
import { getNumberOfDaysBeforeRecentCommentTurnedOff } from '../../store/companies/sagas'
import { getRemittanceAdviceSelectedId } from '../../store/remittanceAdvices/sagas';
import { RemittanceAdvice } from '../../store/remittanceAdvices/types';
import { getCompanyName, getIfIsLg, getTranslatedText } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import FontAwesome from '../common/FontAwesome';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import { generateCommentItemWithType } from '../common/SharedExportComponents';
import { getCustomerUILabel } from '../../store/customers/sagas';

interface IProps {
    readonly item: RemittanceAdvice;
    readonly style: {};
    readonly onRowClick: (item: DynamicObject, drawerType?: string) => void;
    readonly onCheckboxClick: (item: DynamicObject) => void;
    readonly selectedRowKeys: [];
    readonly formatCurrency?: (amount: number) => JSX.Element;
    readonly formatDateLocal: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
    readonly daysFromToday?: (date: any, dateFormat?: string) => number | null;
}
const RemittanceAdviceItemComponent: React.FC<IProps> = ({
    item,
    style,
    onRowClick,
    onCheckboxClick,
    selectedRowKeys,
    formatCurrency,
    formatDateLocal,
}: IProps) => {
    const firstRowContainerRef = useRef<any>(null);
    const selectedId = useSelector(getRemittanceAdviceSelectedId);
    const numberOfDaysBeforeRecentCommentTurnedOff: number = useSelector(getNumberOfDaysBeforeRecentCommentTurnedOff);
    const SupportCashAllocation = useSelector((state: ApplicationState) =>
        get(state.companies.selectedUserCompany, 'Company.SupportCashAllocation')
    );

    const {
        Id,
        CompanyId,
        Status,
        ReceivedDate,
        LocalReceivedDate,
        PaidDate,
        LocalPaidDate,
        Amount,
        MultipleCustomer,
        AllocatedAmount,
        Customer,
        Attachments,
        InvoicesAllocated,
        ConversationLine,
        CreditsAllocated, 
        RebatesAllocated
    } = item;

    const customerLabel = useSelector(getCustomerUILabel);
    /**
     * Common function that gets the row data and adds an identifier named `key`.
     */
    const getItemInfo = () => {
        const itemInfo: any = item;
        itemInfo.key = Id;
        return itemInfo;
    };

    /**
     * Function called when checkbox is ticked.
     * @param e
     */
    const handleCheckboxClick = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.stopPropagation();
        const itemInfo = getItemInfo();
        if (onCheckboxClick) onCheckboxClick(itemInfo);
    };

    /**
     * Function called when the row is clicked.
     */
    const handleRowClick = () => {
        const itemInfo = getItemInfo();
        if (onRowClick) onRowClick(itemInfo);
    };

    /**
     * Function called when clicking on the `latest comment` icon.
     * @param e
     */
    const viewCommentsSectionDrawer = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.stopPropagation();
        const itemInfo = getItemInfo();
        if (onRowClick) onRowClick(itemInfo, 'conversation');
    };

    /**
     * Function called when clicking on the `invoices paid` icon.
     * @param e
     */
    const viewPaidInvoicesSectionDrawer = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        e.stopPropagation();
        const itemInfo = getItemInfo();
        if (onRowClick) onRowClick(itemInfo, 'invoices');
    };


    /**
     * Function called for formatting an amount if formatCurrency HOC function exists.
     * @param amount - number for format
     */
    const handleFormatCurrency = (amount: number) => {
        return formatCurrency ? formatCurrency(amount) : null;
    };

    let itemColor = 'green';

    if (Status === 'Unprocessed') {
        itemColor = 'red';
        // itemIcon = ['far', 'circle'];
    } else if (Status === 'Processed') {
        itemColor = 'orange';
    } else if (Status === 'Completed') {
        itemColor = 'blue';
    } else if (Status === 'Disregarded') {
        itemColor = '#b3b3b3'
    }

    let UnallocatedAmount: number | undefined = undefined;
    let remittanceAdviceColor: string | undefined = undefined;

    if (SupportCashAllocation) {
        UnallocatedAmount = Amount - (AllocatedAmount || 0);
        remittanceAdviceColor = UnallocatedAmount > 0 ? 'red' : 'green';
    }

    const isLg = getIfIsLg();

    // let secondRowContainerHeight: any = 'auto';
    let firstRowContainerHeight: any = 'auto';

    /**
     * Generate item icon based on remittance advice type - default for now
     */
    const generateItemIcon = () => {
        let iconUsed = (
            <FontAwesome
            icon={['fas', 'search-dollar']}
            className={itemColor}
            size="2x"
            transform="shrink-9 left-3"
        />
        );
       
        return (
            <div className="fa-layers fa-fw fa-2x">
                <FontAwesome
                    icon={['far', 'circle']}
                    className={itemColor}
                    size="2x"
                    transform="left-3 shrink-2"
                />
                {iconUsed}
            </div>
        );
    };

    if (isLg) {

        firstRowContainerHeight = get(
            firstRowContainerRef,
            'current.clientHeight'
        )
            ? get(firstRowContainerRef, 'current.clientHeight') - 2
            : 40;
    }

    /**
     * Function for populating the icon group from row item.
     * Defined to easily use in different screen size layouts.
     */
    const iconGroupComponent = (
        <ButtonGroup className="button-link-icons">
            {!isEmpty(Attachments) && (
                <Tooltip
                    title={getTranslatedText(`[amount] attachment[s] available`).replace("[amount]", (Attachments.length).toString()).replace("[s]", Attachments.length > 1 ? 's' : '')}
                    placement="topRight"
                >
                    <Button type="link" className="row-icons-btn pr-5">
                        <div className="fs-20">
                            <FontAwesome
                                icon={['fas', 'file-alt']}
                                className="blue"
                            />
                        </div>
                    </Button>
                </Tooltip>
            )}
            {ConversationLine &&
                generateCommentItemWithType(
                    ConversationLine,
                    'remittanceAdvice',
                    numberOfDaysBeforeRecentCommentTurnedOff,
                    viewCommentsSectionDrawer
                )}

            {(!(InvoicesAllocated == 0) || !(CreditsAllocated == 0) || !(RebatesAllocated == 0)) && (
                
                <Tooltip
                    title={ <>
                        {!(InvoicesAllocated === 0) ? getTranslatedText(`Pays [amount] invoice[s]`).replace("[amount]",InvoicesAllocated).replace("[s]", InvoicesAllocated > 1 ? 's' : ''): ''}
                        <br />
                        {!(CreditsAllocated === 0) ? getTranslatedText(`Contains [amount] credit[s]`).replace("[amount]",CreditsAllocated).replace("[s]", CreditsAllocated > 1 ? 's' : ''): ''}
                        <br />
                        {!(RebatesAllocated === 0) ? getTranslatedText(`Contains [amount] rebate[s]`).replace("[amount]",RebatesAllocated).replace("[s]", RebatesAllocated > 1 ? 's' : ''): ''}
                        </>
                        }
                    placement="topRight"
                >
                    <Button
                        type="link"
                        className="row-icons-btn pa-0"
                        onClick={viewPaidInvoicesSectionDrawer}
                    >
                        <div className="fa-layers fa-fw fa fs-20">
                            <FontAwesome
                                className="blue"
                                icon={['fa', 'file']}
                            />
                            <FontAwesome
                                className="white"
                                icon={['fa', 'plus']}
                                transform="shrink-8 down-2"
                            />
                        </div>
                    </Button>
                </Tooltip>
            )} 
        </ButtonGroup>
    );

    /**
     * Function to populate `Paid on` and `Settled on` components.
     * Defined to easily be used in different screen size layouts.
     */
    const paidAndSettledDatesComponent = (
        <Row type="flex" gutter={60}>
            <Col>{getTranslatedText("Payment on")} {LocalPaidDate && formatDateLocal(LocalPaidDate)}</Col>
            <Col>{getTranslatedText("Received on")} {LocalReceivedDate && formatDateLocal(LocalReceivedDate)}</Col>
        </Row>
    );

    const AllCustomFields = concat(compact(get(Customer, 'CustomFields')));

    const isMultipleCustomer = MultipleCustomer;
    /**
     * Function that populates the custom fields section
     */
    const populateCustomFields = () => {
        const customFieldsDivElements = map(
            AllCustomFields,
            ({ Name, Value }: { Name: String; Value: String }) => (
                <Col key={`${Name}-${Value}`}>
                    <span className="mr-8">{getTranslatedText(Name.toString())}:</span>
                    <span>{Value}</span>
                </Col>
            )
        );
        return (
            <Row className="center-flex-i fw-w" gutter={30}>
                {customFieldsDivElements}
            </Row>
        );
    };

    return (
        <Row
            className={`list-item-clickable ${
                selectedId === Id ? 'list-item-clicked' : ''
            }`}
            key={Id}
            style={style}
            onClick={handleRowClick}
        >
            <Col
                span={24}
                style={{
                    height: '100%',
                    paddingTop: 10,
                    paddingBottom: 10,
                }}
            >
                <Row
                    className="fx-center-xl center-flex-i pr-8 h-100"
                    gutter={{ xxl: 10, xl: 10, lg: 20 }}
                >
                    <Col
                        className="h-100"
                        xxl={2}
                        xl={3}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                    >
                        <Row
                            className="h-100"
                            type="flex"
                            align={isLg ? 'top' : 'middle'}
                        >
                            <Col
                                xl={12}
                                // xl={24}
                                lg={24}
                                md={24}
                                sm={24}
                                xs={24}
                            >
                                <div
                                    className="ta-center center-flex-all"
                                    style={{
                                        height: firstRowContainerHeight,
                                    }}
                                >
                                    <Checkbox
                                        checked={includes(selectedRowKeys, Id)}
                                        onClick={handleCheckboxClick}
                                    />
                                </div>
                            </Col>
                            <Col
                                xl={12}
                                lg={24}
                                md={24}
                                sm={24}
                                xs={24}
                                className="ta-center"
                            >
                                <div className="ta-center center-flex-all">
                                    {generateItemIcon()}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col
                        xxl={22}
                        xl={21}
                        lg={22}
                        md={22}
                        sm={22}
                        xs={22}
                        className="h-100"
                    >
                        <Row className="h-100" type="flex">
                            <Col span={24} className="h-100">
                                <div ref={firstRowContainerRef}>
                                    <Row className="h-100">
                                        <Col span={14} className="h-100">
                                            <Row type="flex" gutter={60}>
                                                {!isUndefined(
                                                    get(
                                                        Customer,
                                                        'CustomerCode'
                                                    )
                                                ) && (
                                                    <Col
                                                        // xxl={4}
                                                        className={`flx-3 mxw-100`}
                                                    >
                                                        <b className="ws-nw va-top">
                                                            {get(
                                                                Customer,
                                                                'CustomerCode'
                                                            )}
                                                        </b>
                                                    </Col>
                                                ) 
                                                }
                                                { (isMultipleCustomer) ? 
                                                     <Col
                                                     // xxl={4}
                                                     className={`flx-7 mxw-100`}
                                                 >
                                                     <b className="ws-nw va-top">
                                                         Multiple {capitalize(customerLabel).concat('s')}
                                                     </b>
                                                 </Col>
                                                 :
                                                 <Col
                                                        className={`flx-7 mxw-100`}
                                                >   
                                                    <b className="ws-nw va-top">
                                                        {getCompanyName(
                                                            Customer
                                                        )}
                                                    </b>
                                                </Col>
                                                }
                                            </Row>
                                        </Col>
                                        <Col span={10} className="ta-right">
                                            <Row
                                                gutter={30}
                                                type="flex"
                                                justify="end"
                                            >
                                                <Col
                                                    className={`contact-icons-container ta-right`}
                                                >
                                                    {iconGroupComponent}
                                                </Col>
                                                <Col
                                                    className={
                                                        isLg
                                                            ? 'flx-a-200'
                                                            : 'flx-a-250'
                                                    }
                                                >
                                                    <div>
                                                        <b className={itemColor}>
                                                            {getTranslatedText("Amount paid")}{' '}
                                                            {handleFormatCurrency(
                                                                Amount
                                                            )}
                                                        </b>
                                                    </div>
                                                    {
                                                        SupportCashAllocation &&
                                                        Status != 'Completed' &&
                                                        <div>
                                                            <b className={remittanceAdviceColor}>
                                                                {getTranslatedText("Amount unallocated")}{' '}
                                                                {handleFormatCurrency(
                                                                    UnallocatedAmount || 0
                                                                )}
                                                            </b>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                    <Row>
                                        <Col span={14}>
                                            <Row>
                                                <Col xxl={8} xl={8} lg={24}>
                                                    {getTranslatedText("Status")}: {getTranslatedText(Status)}
                                                </Col>
                                                {!isLg && (
                                                    <Col>
                                                        {
                                                            paidAndSettledDatesComponent
                                                        }
                                                    </Col>
                                                )}
                                            </Row>
                                            {isLg && (
                                                <Row>
                                                    <Col>
                                                        {
                                                            paidAndSettledDatesComponent
                                                        }
                                                    </Col>
                                                </Row>
                                            )}
                                        </Col>
                                    </Row>
                                    {populateCustomFields()}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    {/* End of payment item */}
                </Row>
            </Col>
        </Row>
    );
};

// export default withDateFormatHandler(
//     withNumberFormatHandler(PaymentItemComponent)
// );

const arePropsEqual = (prevProps: any, nextProps: any) => {
    if (
        JSON.stringify(prevProps.item) === JSON.stringify(nextProps.item) &&
        prevProps.selectedId === nextProps.selectedId &&
        isEqual(prevProps.selectedRowKeys, nextProps.selectedRowKeys) &&
        JSON.stringify(prevProps.style) === JSON.stringify(nextProps.style) &&
        prevProps.addCommentDrawerVisible === nextProps.addCommentDrawerVisible
    ) {
        return true;
    } else {
        return false;
    }
};

export default memo(
    withDateFormatHandler(withNumberFormatHandler(RemittanceAdviceItemComponent)),
    arePropsEqual
);
