export enum ContentsActionTypes {
    GET_WORKFLOW_CONTENT_CONFIGURATION = '@@contents/GET_WORKFLOW_CONTENT_CONFIGURATION',
    GET_WORKFLOW_CONTENT_CONFIGURATION_SUCCESS = '@@contents/GET_WORKFLOW_CONTENT_CONFIGURATION_SUCCESS',
    GET_WORKFLOW_CONTENT_CONFIGURATION_ERROR = '@@contents/GET_WORKFLOW_CONTENT_CONFIGURATION_ERROR',
    CLEAR_WORKFLOW_CONTENT_CONFIGURATION = '@@contents/CLEAR_WORKFLOW_CONTENT_CONFIGURATION',

    GET_CONTENT_TEMPLATE_CONFIGURATION = '@@contents/GET_CONTENT_TEMPLATE_CONFIGURATION',
    GET_CONTENT_TEMPLATE_CONFIGURATION_SUCCESS = '@@contents/GET_CONTENT_TEMPLATE_CONFIGURATION_SUCCESS',
    GET_CONTENT_TEMPLATE_CONFIGURATION_ERROR = '@@contents/GET_CONTENT_TEMPLATE_CONFIGURATION_ERROR',

    GET_CONTENT_TEMPLATE = '@@contents/GET_CONTENT_TEMPLATE',
    GET_CONTENT_TEMPLATE_SUCCESS = '@@contents/GET_CONTENT_TEMPLATE_SUCCESS',
    GET_CONTENT_TEMPLATE_ERROR = '@@contents/GET_CONTENT_TEMPLATE_ERROR',

    GET_CONTENT_TEMPLATE_PREVIEW = '@@contents/GET_CONTENT_TEMPLATE_PREVIEW',
    GET_CONTENT_TEMPLATE_PREVIEW_SUCCESS = '@@contents/GET_CONTENT_TEMPLATE_PREVIEW_SUCCESS',
    GET_CONTENT_TEMPLATE_PREVIEW_ERROR = '@@contents/GET_CONTENT_TEMPLATE_PREVIEW_ERROR',

    GET_MANUAL_COMMUNICATION_TEMPLATE_OPTIONS = '@@customers/GET_MANUAL_COMMUNICATION_TEMPLATE_OPTIONS',
    GET_MANUAL_COMMUNICATION_TEMPLATE_OPTIONS_SUCCESS = '@@customers/GET_MANUAL_COMMUNICATION_TEMPLATE_OPTIONS_SUCCESS',
    GET_MANUAL_COMMUNICATION_TEMPLATE_OPTIONS_ERROR = '@@customers/GET_MANUAL_COMMUNICATION_TEMPLATE_OPTIONS_ERROR',

    SAVE_CONTENT_TEMPLATE = '@@contents/SAVE_CONTENT_TEMPLATE',
    SAVE_CONTENT_TEMPLATE_SUCCESS = '@@contents/SAVE_CONTENT_TEMPLATE_SUCCESS',
    SAVE_CONTENT_TEMPLATE_ERROR = '@@contents/SAVE_CONTENT_TEMPLATE_ERROR',

    CHANGE_CONTENT_SELECTION = '@@contents/CHANGE_CONTENT_SELECTION',
    CHANGE_PREVIEW_VERSION = '@@contents/CHANGE_PREVIEW_VERSION',
    CLEAR_CONTENT_STATES = '@@contents/CLEAR_CONTENT_STATES',
}

export interface ContentsState {
    readonly saveLoading: boolean;
    readonly workflowContents: {
        readonly errorMessages: string[];
        readonly loading: boolean;
        readonly contentConfigs: WorkflowContentConfiguration[] | null;
    }
    readonly otherContents: {
        readonly errorMessages: string[];
        readonly loading: boolean;
        readonly contentConfig: ContentTemplateConfiguration | null;
    }
    readonly manualContents: {
        readonly errorMessages: string[];
        readonly loading: boolean;
        readonly templates: ManualCommunicationTemplate[] | null;
    }
    readonly activeData: {
        loading: boolean;
        rendering: boolean;
        record: ContentTemplate | null;
        preview: {
            version: string | null,
            content: string | null
        };
        selection: ContentSelection | null;
        errorMessages: string[];
        previewErrors: string[];
    };
}

export interface ContentSelection {
    WorkflowId?: string;
    StateName?: string;
    TargetContent?: string;
    TemplateContentName?: string;
    ManualContentName?: string;
    ManualTargetContent?: string;
}

export interface ContentTemplate {
    Content: string;
    Payload: string;
    Preview: string;
    IsHtml: boolean;
    ContentType: string;
}

export interface WorkflowContentConfiguration {
    WorkflowId: string;
    States: WorkflowStateContentConfig[];
}

export interface ContentTemplateConfiguration {
    TemplateContents: TemplateContent[];
}

export interface TemplateContent {
    TemplateName: string;
    TemplateContentS3Key: string;
}

export interface WorkflowStateContentConfig {
    StateName: string;
    EmailBodyTemplateKey?: string;
    EmailSubjectTemplateKey?: string;
    LetterTemplateKey?: string;
    SMSTemplateKey?: string;
}

export interface GetContentTemplatePayload {
    TemplateKey: string;
}

export interface GetContentTemplatePreviewPayload {
    Content: string;
    Payload: string;
}

export enum TemplateType {
    WorkflowContent = 1,
    ManualContent = 2,
    Others = 3
}

export interface SaveContentTemplatePayload {
    Type: TemplateType;
    WorkflowId?: string;
    StateName?: string;
    EmailBodyContent?: string;
    EmailSubjectContent?: string;
    LetterContent?: string;
    SMSContent?: string;
    TemplateContentName?: string;
    TemplateContent?: string;
}

export interface GetManualCommunicationTemplatesRequestPayload {
    callback?: (templates: ManualCommunicationTemplate[]) => void
}

export interface ManualCommunicationTemplateFile {
    Name: string;
    Type: string;
}

export interface ManualCommunicationTemplate {
    TemplateName: string;
    FolderLocation: string;
    Files: [ManualCommunicationTemplateFile];
    SentToVip: boolean;
}