/**
 * File for the `Tasks` widget form.
 */
import { Col, Divider, Form, Input, InputNumber, Row, Select, DatePicker, Modal, TreeSelect } from 'antd';
import { filter, get, isUndefined, map, toLower, isObject, values, forEach, has, capitalize, set, isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CUSTOM_FIELD_TYPES } from '../../config/tableAndPageConstants';
import { getTaskTypeFilterOptions, getTasksWidgetFilters, getWorkflowFilterOptions } from '../../constants/tasksSortAndFilters';
import { ApplicationState } from '../../store';
import { AppCommonState } from '../../store/common/types';
import { TasksState } from '../../store/tasks/types';
import InputAutoCompleteWithButtonDashboard from '../common/InputAutoCompleteWithButtonDashboard';
import { CompanyUserRole } from '../../store/companies/types';
import { getCustomerUILabel } from '../../store/customers/sagas';
import {
    populatePopoverContainer,
    replaceInstancesOfCustomerString,
    getTranslatedText
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { customFieldIndicator, taskFieldIndicator } from '../common/FilterBar';
import SelectReadonly from '../FormComponents/SelectReadonly';
import OrganisationWidgetCommonFilters from './organisation/OrganisationWidgetCommonFilters';
import { chartWidgetDisplayTypes } from '../../constants/dashboards';
import { dateSelectOptions } from '../../constants/invoicesSortAndFilters';
import moment from 'moment-timezone';
import {
    dateFormatDDMMYYYYSlash,
    dateFormatYYYYMMDDDash,
} from '../../constants/dateFormats';
import {
    getTasksActionFilterOptionsRequestAction
} from '../../store/tasks/actions';


const { Item: FormItem } = Form;
const { Option } = Select;

interface IProps {
    widgetDetails: DynamicObject;
    getFieldDecorator: any;
    drawerRef: any;
    formDisabled?: boolean;
    customFieldsFilterList: DynamicObject[];
    isOrgView?: boolean;
    setFieldsValue: any;
    getFieldValue: any;
}

const TasksWidgetFields: React.FC<IProps> = ({
    drawerRef,
    widgetDetails,
    formDisabled,
    getFieldDecorator,
    customFieldsFilterList,
    isOrgView,
    getFieldValue,
    setFieldsValue,
}: IProps) => {
    
    const customerLabel = useSelector(getCustomerUILabel);
    const dispatch = useDispatch();

    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );

    const appState: AppCommonState = useSelector(
        (state: ApplicationState) => state.app
    );

    const organisationCompanies = useSelector(
        (app: ApplicationState) => app.organisations.companies.data
    );

    const tasksState: TasksState['activeTasks'] = useSelector(
        (state: ApplicationState) => state.tasks.activeTasks
    );

    let usingCustomerWorkflow = false;
    let usingInvoiceWorkflow = false;
    let isPaymentPlanEnabled = false;
    let supportSendNewInvoice = false;
    let supportCashAllocation = false;

    const currencyObj = Intl.NumberFormat(appState.locale, {
        style: 'currency',
        currency: appState.currencyCode,
    }).formatToParts(1111);

    const currencySign = get(
        filter(currencyObj, ['type', 'currency']),
        `0.value`
    );

    const [searchFilters, setSearchFilters] = useState<DynamicObject>({});

    const [modalFilterRequired, setModalFilterRequired] = useState<string>('');

    if (isOrgView) {
        for (const company of organisationCompanies) {
            if (!usingCustomerWorkflow) {
                usingCustomerWorkflow = get(
                    company,
                    'UsingCustomerWorkflow'
                );
            }

            if (!usingInvoiceWorkflow) {
                usingInvoiceWorkflow = !get(
                    company,
                    'UsingCustomerWorkflow'
                );
            }

            if (!isPaymentPlanEnabled) {
                isPaymentPlanEnabled = get(
                    company,
                    'CompanyPaymentPlan.IsEnabled'
                );
            }

            if (!supportSendNewInvoice) {
                supportSendNewInvoice = get(
                    company,
                    'SupportSendNewInvoice'
                );
            }

            if (!supportCashAllocation) {
                supportCashAllocation = get(
                    company,
                    'SupportCashAllocation'
                );
            }
        }
    }
    else {
        usingCustomerWorkflow = get(
            selectedUserCompany,
            'Company.UsingCustomerWorkflow'
        );

        usingInvoiceWorkflow = !get(
            selectedUserCompany,
            'Company.UsingCustomerWorkflow'
        );

        isPaymentPlanEnabled = get(
            selectedUserCompany,
            'Company.CompanyPaymentPlan.IsEnabled'
        );

        supportSendNewInvoice = get(
            selectedUserCompany,
            'Company.SupportSendNewInvoice'
        );

        supportCashAllocation = get(
            selectedUserCompany,
            'Company.SupportCashAllocation'
        );
    }

    useEffect(() => {
        getActionFilterOptions();
    }, []);

    /**
     * Function for fetching the action filter options.
     */
    const getActionFilterOptions = () => {
        dispatch(getTasksActionFilterOptionsRequestAction());
    };

    const taskTypesOptions = getTaskTypeFilterOptions(
        isPaymentPlanEnabled,
        supportSendNewInvoice,
        supportCashAllocation
    )

    const taskWorkflowOptions = getWorkflowFilterOptions(
        tasksState,
        usingCustomerWorkflow,
        usingInvoiceWorkflow,
        isPaymentPlanEnabled,
        supportCashAllocation
    )

    /**
    * Function that updates the search input filters.
    * @param filterName - name of filter item
    * @param value - string value entered
    */
    const changeSearchFilter = (filterName: string, value: any) => {
        updateSearchFiltersObject({
            [filterName]: value,
        });
    };

    /**
    * Function that updates the filter hidden value.
    * @param filterHiddenName - name of filter hidden item
    * @param filterHiddenValue - value of filter hidden value
    * @param fieldKey - key for the hidden component
    * @param value - value entered
    */
    const changeFilterHiddenValue = (
        filterHiddenName: string,
        filterHiddenValue: string,
        fieldKey: string,
        value: any) => {
        if (has(value, filterHiddenValue)) {
            set(widgetDetails, `${fieldKey}${"--"}${filterHiddenName}`, get(value, filterHiddenValue));
        }
        else {
            set(widgetDetails, `${fieldKey}${"--"}${filterHiddenName}`, undefined);
        }
    };

    /**
     * Common function for updating the serachFilters object from state.
     * @param searchFiltersObject
     */
    const updateSearchFiltersObject = (searchFiltersObject: DynamicObject) => {
        setSearchFilters({
            ...searchFilters,
            ...searchFiltersObject,
        });
    };

    /**
     * Function that will be triggered when selecting a date range.
     * @param rangeType  - From and To - based on UI mockup
     * @param filterName - name of filter
     */
    const selectDateRange = (rangeType: 'From' | 'To', filterName: string) => {
        return (dateValue: any) => {
            const searchFilterParent = get(searchFilters, filterName);
            const newSearchFilterParent = {
                ...searchFilterParent,
                [rangeType]: dateValue,
            };
            updateSearchFiltersObject({
                [filterName]: newSearchFilterParent,
            });
        };
    };

    /**
     * Function responsible for setting the days range filter values.
     * @param rangeType - Last, Next
     * @param filterName - name of filter
     * @param daysType  - From, To, or not defined
     */
    const selectDaysRange = (
        rangeType: 'Last' | 'Next',
        filterName: string,
        daysType: 'From' | 'To' | undefined = undefined
    ) => {
        return (daysValue: number | undefined) => {
            if (daysType) {
                const searchFilterParent = get(searchFilters, filterName);
                if (get(searchFilterParent, daysType)) {
                    if (rangeType === 'Last') {
                        delete searchFilterParent[daysType].Next;
                    } else {
                        delete searchFilterParent[daysType].Last;
                    }
                }
                const newSearchFilterParent = {
                    ...searchFilterParent,
                    [daysType]: {
                        [rangeType]: daysValue,
                    },
                };

                updateSearchFiltersObject({
                    [filterName]: newSearchFilterParent,
                });
            } else {
                const searchFilterParent = get(searchFilters, filterName);
                if (rangeType === 'Last') {
                    delete searchFilterParent.Next;
                } else {
                    delete searchFilterParent.Last;
                }
                const newSearchFilterParent = {
                    ...searchFilterParent,
                    [rangeType]: daysValue,
                };

                updateSearchFiltersObject({
                    [filterName]: newSearchFilterParent,
                });
            }
        };
    };

    const checkDateRangeIfCorrect = (fromDate: string, toDate: string) => {
        if (fromDate && toDate) {
            return moment(fromDate).isAfter(moment(toDate));
        } else {
            return false;
        }
    };

    /**
   * Function that sets the filter values.
   * @param filterName - name of filter item
   */
    const updateModalFilterRequired = (filterName: string) => {
        setModalFilterRequired(filterName);
    };

    const showModalError = (content: any, filterIdx: number) => {
        Modal.error({
            className: 'modal-error-left',
            title: getTranslatedText('Error'),
            content,
            okText: getTranslatedText('OK'),
        });
    };

    /**
     * Function for handling the select onChange event.
     * @param selectValue - selected value on dropdown
     * @param filter - name of the filter
     * @param fieldKey - key for the component
     * @param stateName - state name of the filter
     */
    const handleSelectOnChange = (
        selectValue: string,
        filter: any,
        fieldKey: string,
        stateName: string
    ) => {
        const newFilterValue: any = {
            value: selectValue,
        };
        if (
            selectValue ===
            dateSelectOptions.CUSTOM_DAYS_RANGE
        ) {
            const customType =
                filter.filterOptionsCustomTypes[selectValue];

            if (customType === 'all-days-range-from-to') {
                if (get(widgetDetails, `${fieldKey}${"--RangeTypeFrom"}`) === "Last") {
                    newFilterValue.From = {
                        Last: get(widgetDetails, `${fieldKey}${"--Last--From"}`)
                    }
                }
                else {
                    newFilterValue.From = {
                        Next: get(widgetDetails, `${fieldKey}${"--Next--From"}`)
                    }
                }

                if (get(widgetDetails, `${fieldKey}${"--RangeTypeTo"}`) === "Last") {
                    newFilterValue.To = {
                        Last: get(widgetDetails, `${fieldKey}${"--Last--To"}`)
                    }
                }
                else {
                    newFilterValue.To = {
                        Next: get(widgetDetails, `${fieldKey}${"--Next--To"}`)
                    }
                }
            }
            else if (customType === 'all-days-range-from-to-last') {
                newFilterValue.From = {
                    Last: get(widgetDetails, `${fieldKey}${"--Last--From"}`)
                };
                newFilterValue.To = {
                    Last: get(widgetDetails, `${fieldKey}${"--Last--To"}`)
                };
            }
            else if (customType === 'last-days-range') {
                newFilterValue.Last = get(widgetDetails, `${fieldKey}${"--Last"}`);
            }
            else if (customType === 'all-days-range') {
                if (get(widgetDetails, `${fieldKey}${"--RangeType"}`) === "Last") {
                    newFilterValue.Last = get(widgetDetails, `${fieldKey}${"--Last"}`);
                }
                else {
                    newFilterValue.Next = get(widgetDetails, `${fieldKey}${"--Next"}`);
                }
            }
            else {
                newFilterValue.Last = 1;
            }
        }

        changeSearchFilter(
            stateName,
            newFilterValue
        );
    }

    /**
     * Function to populate the select/dropdown filter.
     * @param customType - nype of filter, date-range or etc
     * @param filterName - name of filter
     * @param filterFieldKey - key for the component
     * @param filterMaxDate - optional max date for filter, can be a moment object or string
     */
    const populateSelectCustomType = (
        customType: string,
        filterName: string,
        filterFieldKey: string,
        filterMaxDate?: any
    ) => {
        if (customType === 'date-range') {
            const fromDateFieldKey = `${filterFieldKey}${"--From"}`
            const toDateFieldKey = `${filterFieldKey}${"--To"}`
            let disabledDate;
            if (filterMaxDate) {
                disabledDate = (dateValue: any) => {
                    return moment(
                        moment(dateValue).format(dateFormatYYYYMMDDDash)
                    ).isAfter(
                        moment(
                            moment(filterMaxDate).format(dateFormatYYYYMMDDDash)
                        )
                    );
                };
            }
            return (
                <Col span={12} key={filterFieldKey}>
                    <FormItem label={getTranslatedText("From")}>
                        {getFieldDecorator(fromDateFieldKey, {
                            initialValue: !isEmpty(get(widgetDetails, fromDateFieldKey)) ?
                                moment(get(widgetDetails, fromDateFieldKey)) :
                                null,
                        })(
                            <DatePicker
                                className="dashboard-datepicker"
                                format={dateFormatDDMMYYYYSlash}
                                disabledDate={disabledDate}
                                placeholder={getTranslatedText("Start Date")}
                                onChange={selectDateRange('From', filterName)}
                                getCalendarContainer={populatePopoverContainer(
                                    drawerRef
                                )}
                                allowClear={true}
                            />
                        )}
                    </FormItem>
                    <FormItem label={getTranslatedText("To")}>
                        {getFieldDecorator(toDateFieldKey, {
                            initialValue: !isEmpty(get(widgetDetails, toDateFieldKey)) ?
                            moment(get(widgetDetails, toDateFieldKey)) :
                            null,
                        })(
                            <DatePicker
                                className="dashboard-datepicker"
                                format={dateFormatDDMMYYYYSlash}
                                disabledDate={disabledDate}
                                placeholder={getTranslatedText("End Date")}
                                onChange={selectDateRange('To', filterName)}
                                getCalendarContainer={populatePopoverContainer(
                                    drawerRef
                                )}
                                allowClear={true}
                            />
                        )}
                    </FormItem>
                </Col>
            );
        } else if (customType === 'all-days-range-from-to') {
            const daysRangeType = ['Last', 'Next'];
            const rangeTypeFromFieldKey = `${filterFieldKey}${"--RangeTypeFrom"}`
            const rangeTypeToFieldKey = `${filterFieldKey}${"--RangeTypeTo"}`
            const rangeTypeSelectedFrom = has(
                searchFilters,
                `${filterName}.From.Last`
            )
                ? 'Last'
                : 'Next';

            const rangeTypeSelectedTo = has(
                searchFilters,
                `${filterName}.To.Last`
            )
                ? 'Last'
                : 'Next';

            const setRangeTypeSelectedFrom = !isUndefined(rangeTypeSelectedFrom) ?
                rangeTypeSelectedFrom :
                get(widgetDetails, rangeTypeFromFieldKey)

            const setRangeTypeSelectedTo = !isUndefined(rangeTypeSelectedTo) ?
                rangeTypeSelectedTo :
                get(widgetDetails, rangeTypeToFieldKey)

            const daysValueFrom = get(
                searchFilters,
                `${filterName}.From.${setRangeTypeSelectedFrom}`
            );
            const daysValueTo = get(
                searchFilters,
                `${filterName}.To.${setRangeTypeSelectedTo}`
            );

            return (
                <Col span={12} key={filterFieldKey}>
                    {map(['From', 'To'], (daysType: 'From' | 'To') => {
                        let typeLabel = 'Selected from';
                        let rangeTypeSelected: 'Last' | 'Next' = setRangeTypeSelectedFrom;
                        let daysValue = daysValueFrom;
                        let rangeTypeFieldKey = rangeTypeFromFieldKey;

                        if (daysType === 'To') {
                            typeLabel = 'Go to';
                            rangeTypeSelected = setRangeTypeSelectedTo;
                            daysValue = daysValueTo;
                            rangeTypeFieldKey = rangeTypeToFieldKey;
                        }

                        const daysTypeFieldKey = `${filterFieldKey}${"--"}${rangeTypeSelected}${"--"}${daysType}`

                        return (
                            <div key={daysType}>
                                <span>{getTranslatedText(typeLabel)}</span><br />
                                <div className="dashboard-div">
                                    <FormItem>
                                        {getFieldDecorator(rangeTypeFieldKey, {
                                            initialValue: rangeTypeSelected
                                        })(
                                            <SelectReadonly
                                                readOnly={formDisabled}
                                                onChange={(rangeType: 'Last' | 'Next') => {
                                                    selectDaysRange(
                                                        rangeType,
                                                        filterName,
                                                        daysType
                                                    )(1);
                                                }}
                                                placeholder={getTranslatedText("Type")}
                                                getPopupContainer={populatePopoverContainer(
                                                    drawerRef
                                                )}
                                            >
                                                {map(
                                                    daysRangeType,
                                                    (rangeType: string) => (
                                                        <Option
                                                            key={rangeType}
                                                            value={rangeType}
                                                        >
                                                            {rangeType}
                                                        </Option>
                                                    )
                                                )}
                                            </SelectReadonly>
                                        )}
                                    </FormItem>
                                    &nbsp;&nbsp;
                                    <FormItem>
                                        {getFieldDecorator(daysTypeFieldKey, {
                                            initialValue: daysValue
                                        })(
                                            <InputNumber
                                                placeholder={getTranslatedText("Count")}
                                                onChange={selectDaysRange(
                                                    rangeTypeSelected,
                                                    filterName,
                                                    daysType
                                                )}
                                                min={1}
                                            />
                                        )}
                                    </FormItem>
                                    &nbsp;&nbsp;
                                    <span>
                                        {getTranslatedText(`day${daysValue > 1 ? 's' : ''}`)}
                                    </span>
                                </div>
                            </div>
                        );
                    })
                    }
                </Col >
            );
        }
    };

    /**
     * Function for populating the task type options.
     */
    const populateTaskTypesSelectOptions = () =>
        map(taskTypesOptions, ({ label, value }: any) => {
            const labelUsed = replaceInstancesOfCustomerString(
                label,
                customerLabel,
                isOrgView
            );
            return (
                <Option key={value} value={value.toString()}>
                    {labelUsed}
                </Option>
            );
        });

    /**
    * Function for populating the invoice fields filters.
    */
    const populateTaskFieldsFilters = () => {
        return getTasksWidgetFilters(
            usingCustomerWorkflow
        ).map(
            (filter: DynamicObject) => {
                const fieldKey = `${taskFieldIndicator}${filter.filterStateName}`;
                const stateName = filter.filterStateName || filter.filterName;

                if (filter.filterElement === 'select-multiple-and-input-amount') {
                    const amountValueFieldKey = `${fieldKey}${"--AmountValue"}`
                    return (
                        <Col span={12} key={fieldKey}>
                            <FormItem label={getTranslatedText(filter.filterName)}>
                                {
                                    map(
                                        filter.filterOptions,
                                        (optionSelect: any, index: number) => {
                                            const valuesArray = get(
                                                values(optionSelect),
                                                0
                                            );
                                            const optionFieldKey = `${fieldKey}${"--"}${filter.filterOptions[index].filterOptionName}`
                                            return (
                                                <div key={index}>
                                                    <FormItem>
                                                        {getFieldDecorator(optionFieldKey, {
                                                            initialValue: get(
                                                                widgetDetails,
                                                                optionFieldKey
                                                            ),
                                                        })(
                                                            <SelectReadonly
                                                                allowClear={true}
                                                                placeholder={getTranslatedText(filter.filterOptions[index].filterPlaceholder)}
                                                                readOnly={formDisabled}
                                                                style={{ width: '100%' }}
                                                                getPopupContainer={populatePopoverContainer(
                                                                    drawerRef
                                                                )}
                                                            >
                                                                {map(
                                                                    valuesArray,
                                                                    (option: any) => {
                                                                        let value, label;
                                                                        if (
                                                                            isObject(option)
                                                                        ) {
                                                                            value = get(
                                                                                option,
                                                                                'value'
                                                                            );
                                                                            label = get(
                                                                                option,
                                                                                'label'
                                                                            );
                                                                        } else {
                                                                            value = option;
                                                                            label = option;
                                                                        }

                                                                        return (
                                                                            <Option
                                                                                key={value}
                                                                                value={
                                                                                    value
                                                                                }
                                                                            >
                                                                                {label}
                                                                            </Option>
                                                                        );
                                                                    }
                                                                )}
                                                            </SelectReadonly>
                                                        )}
                                                    </FormItem>
                                                </div>
                                            )
                                        }
                                    )
                                }
                                <FormItem>
                                    {`${currencySign} `}
                                    {getFieldDecorator(amountValueFieldKey, {
                                        initialValue: get(
                                            widgetDetails,
                                            amountValueFieldKey
                                        ) != null ? get(widgetDetails, amountValueFieldKey) : 0,
                                    })(
                                        <InputNumber
                                            placeholder={getTranslatedText("Amount")}
                                            readOnly={formDisabled}
                                        />
                                    )}
                                </FormItem>
                            </FormItem>
                        </Col >
                    );
                }
                else if (filter.filterElement === 'radio-group') {
                    return (
                        <Col span={12} key={fieldKey}>
                            <FormItem label={getTranslatedText(filter.filterName)}>
                                {getFieldDecorator(fieldKey, {
                                    initialValue: get(
                                        widgetDetails,
                                        fieldKey
                                    ),
                                })(
                                    <SelectReadonly
                                        allowClear={true}
                                        placeholder={getTranslatedText(filter.filterPlaceholder)}
                                        readOnly={formDisabled}
                                        getPopupContainer={populatePopoverContainer(
                                            drawerRef
                                        )}
                                    >
                                        {map(filter.filterOptions, ({ label, value }: any) => (
                                            <Option key={value} value={value}>
                                                {label}
                                            </Option>
                                        ))}
                                    </SelectReadonly>
                                )}
                            </FormItem>
                        </Col >
                    );
                }
                else if (filter.filterElement === 'select') {
                    const dateTypeFieldKey = `${fieldKey}${"--DateType"}`
                    const optionsObject: any = {};

                    forEach(filter.filterOptions, (option: string) => {
                        if (!optionsObject[option]) {
                            optionsObject[option] = option;
                        }
                    });

                    const selectCustomValueType = !isUndefined(searchFilters[stateName]) ?
                        get(searchFilters[stateName], 'value') :
                        get(widgetDetails, dateTypeFieldKey)

                    const selectCustomType = filter.filterOptionsCustomTypes[selectCustomValueType];

                    // Manually simulate onChange event on initial load
                    if ((selectCustomValueType === dateSelectOptions.CUSTOM_DATE_RANGE ||
                        selectCustomValueType === dateSelectOptions.CUSTOM_DAYS_RANGE) &&
                        isUndefined(searchFilters[stateName])) {
                        handleSelectOnChange(selectCustomValueType, filter, fieldKey, stateName);
                    }
                    return (
                        <Col span={12} key={dateTypeFieldKey}>
                            <FormItem label={getTranslatedText(filter.filterName)}>
                                {getFieldDecorator(dateTypeFieldKey, {
                                    initialValue: get(
                                        widgetDetails,
                                        dateTypeFieldKey
                                    ),
                                })(
                                    <SelectReadonly
                                        allowClear={true}
                                        readOnly={formDisabled}
                                        className="date-picker-select"
                                        placeholder={getTranslatedText(filter.filterPlaceholder)}
                                        onChange={(selectValue: any) => {
                                            handleSelectOnChange(selectValue, filter, fieldKey, stateName);
                                        }}
                                        getPopupContainer={populatePopoverContainer(
                                            drawerRef
                                        )}
                                    >
                                        {map(optionsObject, (option: any) => (
                                            <Option key={option} value={option}>
                                                {option}
                                            </Option>
                                        ))}
                                    </SelectReadonly>
                                )}
                            </FormItem>
                            {selectCustomType &&
                                populateSelectCustomType(
                                    selectCustomType,
                                    stateName,
                                    fieldKey,
                                    filter.filterMaxDate
                                )}
                        </Col >
                    );
                }
                else if (filter.filterElement === 'input-autocomplete') {
                    const filterNameUsed =
                        filter.filterStateName === 'Customer' &&
                            !isOrgView
                            ? capitalize(customerLabel)
                            : filter.filterName;
                    const stateName = filter.filterStateName || filter.filterName;
                    return (
                        <Col span={12} key={fieldKey}>
                            <FormItem label={getTranslatedText(filterNameUsed)}>
                                <InputAutoCompleteWithButtonDashboard
                                    readOnly={formDisabled}
                                    hasNoOkButton={true}
                                    updateField={(value: string) => {
                                        changeSearchFilter(stateName, value);
                                    }}
                                    onSelect={(value: any) => {
                                        !isUndefined(filter.filterHiddenName) &&
                                            changeFilterHiddenValue(
                                                filter.filterHiddenName,
                                                filter.filterHiddenValue,
                                                fieldKey,
                                                value);
                                    }}
                                    stateValue={searchFilters[stateName]}
                                    queryName={filter.filterQuery}
                                    queryFilterName={filter.filterNameQuery}
                                    filterField={filter.filterStateName}
                                    sortField={filter.filterSort}
                                    responseName={filter.filterResponse}
                                    labelField={filter.filterLabelField}
                                    placeholder={filter.filterPlaceholder}
                                    filterSubChecking={filter.filterSubChecking}
                                    queryFilterNameSub={filter.filterNameQuerySub}
                                    getFieldDecorator={getFieldDecorator}
                                    widgetDetails={widgetDetails}
                                    filterFieldKey={fieldKey}
                                />
                            </FormItem>
                        </Col>
                    );
                }
                else {
                    const filterNameUsed =
                        filter.filterStateName === 'Customer' &&
                            !isOrgView
                            ? capitalize(customerLabel)
                            : filter.filterName;
                    return (
                        <Col span={12} key={fieldKey}>
                            <FormItem label={getTranslatedText(filterNameUsed)}>
                                {getFieldDecorator(fieldKey, {
                                    initialValue: get(
                                        widgetDetails,
                                        fieldKey
                                    ),
                                })(
                                    <Input
                                        placeholder={getTranslatedText(filterNameUsed)}
                                        readOnly={formDisabled}
                                    />
                                )}
                            </FormItem>
                        </Col>
                    );
                }
            }
        );
    };

    /**
     * Common function for populating the custom fields filters.
     * @param customFieldType
     */
    const populateCustomFieldsFilters = (customFieldType: string) => {
        return filter(customFieldsFilterList, ['Type', customFieldType]).map(
            ({ Type, FieldName }: DynamicObject) => {
                const customFieldKey = `${customFieldIndicator}${Type}--${FieldName}`;
                return (
                    <Col span={12} key={customFieldKey}>
                        <FormItem label={getTranslatedText(FieldName)}>
                            {getFieldDecorator(customFieldKey, {
                                initialValue: get(
                                    widgetDetails,
                                    customFieldKey
                                ),
                            })(
                                <Input
                                    placeholder={getTranslatedText(FieldName)}
                                    readOnly={formDisabled}
                                />
                            )}
                        </FormItem>
                    </Col>
                );
            }
        );
    };

    /**
     * Function that populates the dropdown based on given list of options.
     */
    const populateNotificationSuccessDisplayTypeOptions = () =>
        map(chartWidgetDisplayTypes, ({ label, value }: any) => (
            <Option key={value} value={value}>
                {getTranslatedText(label)}
            </Option>
        ));


    return (
        <Row>
            <Col>
                <Row>
                    <Col span={24}>
                        <FormItem label={getTranslatedText("Filter on display type")}>
                            {getFieldDecorator('displayType', {
                                initialValue:
                                    get(widgetDetails, 'displayType') ||
                                    get(
                                        chartWidgetDisplayTypes,
                                        '1.value'
                                    ),
                            })(
                                <SelectReadonly
                                    readOnly={formDisabled}
                                    placeholder={getTranslatedText("Select Task Type")}
                                    style={{ width: '100%' }}
                                    getPopupContainer={populatePopoverContainer(
                                        drawerRef
                                    )}
                                >
                                    {populateNotificationSuccessDisplayTypeOptions()}
                                </SelectReadonly>
                            )}
                        </FormItem>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={24}>
                        <FormItem label={getTranslatedText("Filter by task types")}>
                            {getFieldDecorator('tasksTypes', {
                                initialValue:
                                    get(widgetDetails, 'tasksTypes')
                            })(
                                <SelectReadonly
                                    readOnly={formDisabled}
                                    placeholder={getTranslatedText("Select Task Type")}
                                    style={{ width: '100%' }}
                                    getPopupContainer={populatePopoverContainer(
                                        drawerRef
                                    )}
                                    mode="multiple"
                                >
                                    {populateTaskTypesSelectOptions()}
                                </SelectReadonly>
                            )}
                        </FormItem>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={24}>
                        <FormItem label={getTranslatedText("Filter by task workflow types")}>
                            {getFieldDecorator('tasksWorkflows', {
                                initialValue: !tasksState.actionFilterOptionsLoading ?
                                    get(widgetDetails, 'tasksWorkflows') : undefined
                            })(
                                <TreeSelect
                                    style={{ width: '100%' }}
                                    treeData={taskWorkflowOptions}
                                    placeholder={getTranslatedText("Select Workflow Type")}
                                    treeDefaultExpandAll={false}
                                    getPopupContainer={populatePopoverContainer(
                                        drawerRef
                                    )}
                                    treeCheckable={true}
                                    treeCheckStrictly={false}
                                />
                            )}
                        </FormItem>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col>
                        <Row>
                            <Col span={24}>
                                <h3>{getTranslatedText('Filter by task fields')}</h3>
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            {populateTaskFieldsFilters()}
                        </Row>
                    </Col>
                </Row>
                <Divider />
                {!usingCustomerWorkflow && (
                    <div>
                        <Row>
                            <Col>
                                <Row>
                                    <Col span={24}>
                                        <h3>{getTranslatedText('Filter by invoice custom fields')}</h3>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    {populateCustomFieldsFilters(
                                        CUSTOM_FIELD_TYPES.INVOICE
                                    )}
                                </Row>
                            </Col>
                        </Row>
                        <Divider />
                    </div>
                )}
                <Row>
                    <Col>
                        <Row>
                            <Col span={24}>
                                <h3>
                                    {getTranslatedText(`Filter by ${isOrgView ? 'customer' : toLower(customerLabel)} custom fields`)}
                                </h3>
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            {populateCustomFieldsFilters(
                                CUSTOM_FIELD_TYPES.CUSTOMER
                            )}
                        </Row>
                    </Col>
                </Row>
                {isOrgView && (
                    <OrganisationWidgetCommonFilters
                        widgetDetails={widgetDetails}
                        getFieldDecorator={getFieldDecorator}
                        getFieldValue={getFieldValue}
                        setFieldsValue={setFieldsValue}
                        drawerRef={drawerRef}
                        isRegional
                    />
                )}
            </Col>
        </Row>
    );
};

export default TasksWidgetFields;
