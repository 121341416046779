/**
 * File responsible for all the UI and actions for Settings>RA Customization page - Processing settings tab item - `/app/settings/ra_customization/processing-settings`.
 */

import { Form, Col, Divider, Row, Spin, Typography,Button, Input, InputNumber, Checkbox,DatePicker, Modal,Empty } from 'antd';
import { History as IHistory } from 'history';
import { get, isEmpty, isEqual } from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, { useEffect, useState, lazy, Suspense, forwardRef,Ref } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RouteLeavingGuard from '../../../../components/common/RouteLeavingGuard';
import { ApplicationState } from '../../../../store';
import { Company,CompaniesState } from '../../../../store/companies/types';
import { RAProcessingConfiguration } from '../../../../store/remittanceAdvices/types';
import {
    DynamicObject
} from '../../../../utils/commonInterfaces';
import { dateFormatDDMMMYYYYSpace, dateFormatYYYYMMDDTHHmmssSSSSSSDash } from '../../../../constants/dateFormats';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import {
    getUserCompaniesRequestAction
} from '../../../../store/companies/actions';

import {
    saveRAProcessingConfigurationRequestAction,
} from '../../../../store/remittanceAdvices/actions';

import {
    getRAProcessingConfigurationRequestAction
} from '../../../../store/remittanceAdvices/actions';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import ModalWithSpinner from '../../../../components/common/ModalWithSpinner';
import {
    getTranslatedText
} from '../../../../utils/commonFunctions';
const RACustomizationImportDetailsDrawerComponent = lazy(
    () =>
        import(
            '../../../../components/settings/RACustomizationImportDetailsDrawerComponent'
        )
);

const RACustomizationExportDetailsDrawerComponent = lazy(
    () =>
        import(
            '../../../../components/settings/RACustomizationExportDetailsDrawerComponent'
        )
);

const { Title } = Typography;
const { Item: FormItem,  } = Form;

interface IProps {
    history: typeof IHistory;
    selectedUserCompany: Company;
    form: DynamicObject;
    setFormHasChanges: () => void;
    setSaveLoading: (
        saveLoading:boolean
    ) => void;
}
const RACustomizationProcessingSettingsPage: React.FC<IProps> = forwardRef(
    (
        props: IProps,
        ref: Ref<any>
    ) => {
        const dispatch = useDispatch();

        /**
         * This is used for the Parent component wrapping this one be able to call the functions
         * inside this (save, cancel, checkForChanges).
         */
        React.useImperativeHandle(ref, () => ({
            save: () => {
                handleSave();
            },
            cancel: () => {
                handleCancel();
            },
            checkForChanges: () => {
                return checkForChanges();
            },
        }));

        const raProcessingConfigurationState = useSelector((state: ApplicationState) =>
            state.remittanceAdvices.raProcessingConfiguration
        );

        const raProcessingConfiguration: RAProcessingConfiguration = raProcessingConfigurationState.data;
        const { errorMessages, loading,saveLoading } = raProcessingConfigurationState;

        const [processingSettingsFormState, setProcessingSettingsFormState] = useState<{}>({});
        const [importDetailsDrawerValues, setImportDetailsDrawerValues] = useState<{
            visible: boolean;
        }>({
            visible: false
        });

        const [exportDetailsDrawerValues, setExportDetailsDrawerValues] = useState<{
            visible: boolean;
        }>({
            visible: false
        });

        const {
            getFieldDecorator,
            validateFields,
            resetFields
        } = props.form;

        const formFieldNames = {
            TYPE_HEADERS: "TypeHeaders",
            INVOICE_TYPE: "InvoiceType",
            CREDIT_TYPE: "CreditType",
            REBATE_TYPE: "RebateType",
            NUMBER_HEADERS: "NumberHeaders",
            INVOICE_NUMBER: "InvoiceNumber",
            CREDIT_NUMBER: "CreditNumber",
            REBATE_NUMBER: "RebateNumber",
            DESCRIPTION_HEADERS: "DescriptionHeaders",
            INVOICE_DESCRIPTION: "InvoiceDescriptionValues",
            CREDIT_DESCRIPTION: "CreditDescriptionValues",
            REBATE_DESCRIPTION: "RebateDescriptionValues",
            AMOUNT_HEADERS: "AmountHeaders",
            CUSTOMER_CODE_HEADERS: "CustomerCodeHeaders",
            CREATED_DATE_HEADERS: "CreatedDateHeaders",
            DUE_DATE_HEADERS: "DueDateHeaders",
            PAYMENT_DATE_SUMMARY: "PaymentDateSummaryFields",
            TOTAL_PAYMENT_SUMMARY: "TotalPaymentSummaryFields",
            ADJUSTMENT_AMOUNT: "AdjustmentAmount",
            ADJUSTMENT_IDENTIFIER_PREPEND: "AdjustmentIdentifierPrepend",
            ADJUSTMENT_IDENTIFIER_APPEND: "AdjustmentIdentifierAppend",
            ALLOW_HIGH_CONFIDENCE: "AllowHighConfidence"
        };

        const requiredFields = [
            "NumberHeaders",
            "AmountHeaders",
            "PaymentDateSummaryFields",
            "TotalPaymentSummaryFields"
        ];

        const dateFields = [
            "CreatedDateHeaders",
            "DueDateHeaders",
            "PaymentDateSummaryFields",
        ];

        /**
        * Function called when cancel is clicked.
        * Resets all the changes and set the data based on the one saved in redux (the original saved data).
        */
        const handleCancel = () => {
            resetFields();
            setProcessingSettingsFormState(raProcessingConfiguration);
            props.setFormHasChanges();
        };
        
        /**
         * Function called when `Save` button is clicked and will send all the changes to API.
         */
        const handleSave = () => {
            validateFields((err: DynamicObject, values: DynamicObject) => {
                if (!err) {
                    props.setSaveLoading(true); 

                    const raProcessingSettingsPayload: {
                        TypeHeaders: String,
                        InvoiceTypeValues: String,
                        CreditTypeValues: String,
                        RebateTypeValues: String,
                        NumberHeaders: String,
                        InvoiceNumberValues: String,
                        CreditNumberValues: String,
                        RebateNumberValues: String,
                        DescriptionHeaders: String,
                        InvoiceDescriptionValues: String,
                        CreditDescriptionValues: String,
                        RebateDescriptionValues: String,
                        AmountHeaders: String,
                        CustomerCodeHeaders: String,
                        CreatedDateHeaders: String,
                        DueDateHeaders: String,
                        PaymentDateSummaryFields: String,
                        TotalPaymentSummaryFields: String,
                        AdjustmentAmount: Number,
                        AdjustmentIdentifierPrepend: String,
                        AdjustmentIdentifierAppend: String,
                        AllowHighConfidence: false
                    } = {
                        TypeHeaders: get(values, formFieldNames.TYPE_HEADERS),
                        InvoiceTypeValues: get(values, formFieldNames.INVOICE_TYPE),
                        CreditTypeValues: get(values, formFieldNames.CREDIT_TYPE),
                        RebateTypeValues: get(values, formFieldNames.REBATE_TYPE),
                        NumberHeaders: get(values, formFieldNames.NUMBER_HEADERS),
                        InvoiceNumberValues: get(values, formFieldNames.INVOICE_NUMBER),
                        CreditNumberValues: get(values, formFieldNames.CREDIT_NUMBER),
                        RebateNumberValues: get(values, formFieldNames.REBATE_NUMBER),
                        DescriptionHeaders: get(values, formFieldNames.DESCRIPTION_HEADERS),
                        InvoiceDescriptionValues: get(values, formFieldNames.INVOICE_DESCRIPTION),
                        CreditDescriptionValues: get(values, formFieldNames.CREDIT_DESCRIPTION),
                        RebateDescriptionValues: get(values, formFieldNames.REBATE_DESCRIPTION),
                        AmountHeaders: get(values, formFieldNames.AMOUNT_HEADERS),
                        CustomerCodeHeaders: get(values, formFieldNames.CUSTOMER_CODE_HEADERS),
                        CreatedDateHeaders: get(values, formFieldNames.CREATED_DATE_HEADERS),
                        DueDateHeaders: get(values, formFieldNames.DUE_DATE_HEADERS),
                        PaymentDateSummaryFields: get(values, formFieldNames.PAYMENT_DATE_SUMMARY),
                        TotalPaymentSummaryFields: get(values, formFieldNames.TOTAL_PAYMENT_SUMMARY),
                        AdjustmentAmount: get(values, formFieldNames.ADJUSTMENT_AMOUNT) == null ? 0.00 : get(values, formFieldNames.ADJUSTMENT_AMOUNT),
                        AdjustmentIdentifierPrepend: get(values, formFieldNames.ADJUSTMENT_IDENTIFIER_PREPEND),
                        AdjustmentIdentifierAppend: get(values, formFieldNames.ADJUSTMENT_IDENTIFIER_APPEND),
                        AllowHighConfidence: get(values, formFieldNames.ALLOW_HIGH_CONFIDENCE)
                    }

                    submitRAPRocessingConfig(raProcessingSettingsPayload)
                }
            });
        };

        const submitRAPRocessingConfig = (raProcessingSettingsPayload: any,isImport:boolean = false) => {
            dispatch(
                saveRAProcessingConfigurationRequestAction(
                    raProcessingSettingsPayload,
                    ({ IsSuccess }: { IsSuccess: boolean }) =>
                    handleModalSaveResponse(
                        IsSuccess,
                        isImport
                    )
                )
            );
        }

        /**
         * Function responsible for showing the success/error modal after saving RA Processing settings
         * @param param0 - includes a boolean and may have an error message response from API
         */
        const handleModalSaveResponse = (IsSuccess: boolean,isImport: boolean) => {
            if (IsSuccess) {
                var content = '';
                if (isImport) {
                    content = getTranslatedText("AllowHighConfidence turned off while importing");
                } else {
                    content = getTranslatedText("Remittance advice processing settings saved successfully!");
                }

                Modal.success({
                    zIndex:9999,
                    title: getTranslatedText("Success"),
                    content: content,
                    onOk: () => {
                        fetchInitialData()
                    },
                    okText: getTranslatedText("Ok")
                });
            } else {
                Modal.error({
                    zIndex:9999,
                    title:  getTranslatedText("Error"),
                    content: getTranslatedText("Failed to save remittance advice processing settings"),
                    okText: getTranslatedText("Ok"),
                    onOk: () => {
                        fetchInitialData()
                    },
                });
                
            }
            props.setSaveLoading(false); 
        };

        /**
        * Function called when import button is clicked.
        */
        const onImportButtonClick = () => {     
            if(raProcessingConfiguration.AllowHighConfidence)
                turnOffAllowHighConfidence();
            
            setImportDetailsDrawerValues({
                visible: true
            });
        };

        /**
        * Function called when turning off AllowHighConfidence.
        */
        const turnOffAllowHighConfidence = () => {
            const raProcessingSettings = JSON.stringify(raProcessingConfiguration); 
            const raProcessingSettingsJSONParsed = JSON.parse(raProcessingSettings);

            raProcessingSettingsJSONParsed.AllowHighConfidence = false;

            submitRAPRocessingConfig(raProcessingSettingsJSONParsed,true);
        };

        /**
        * Function called when you close the import panel.
        */
        const closeImportDetailsDrawer = () => {
            setImportDetailsDrawerValues({
                visible: false
            });
        };

        /**
        * Function called when import button is clicked.
        */
        const onExportButtonClick = () => {
            setExportDetailsDrawerValues({
                visible: true
            });
        };

        /**
        * Function called when you close the import panel.
        */
        const closeExportDetailsDrawer = () => {
            setExportDetailsDrawerValues({
                visible: false
            });
        };

        /**
        * Common function for updating the form state object
        * created to minimize repitition when updating form state.
        * @param formStateObject
        */
        const updateFormStateObject = (formStateObject: {}) => {
            setProcessingSettingsFormState(formStateObject);
            props.setFormHasChanges();
        };

        /**
        * Function that updates the form state and which form field will be updated.
        * @param name - field name
        * @param valueIdentifier - string, either value or checked, or others, based on e.target.value
        */
        const updateFormState: any = (
            name: string,
            valueIdentifier: string = 'value'
        ) => {
            return (
                e: React.ChangeEvent<HTMLInputElement> | CheckboxChangeEvent
            ) => {
                const correctValue =
                    get(e, `target.${valueIdentifier}`) ||
                    get(e, `target.${valueIdentifier}`);

                updateFormStateObject({
                    ...processingSettingsFormState,
                    [name]: correctValue,
                });
            };
        };

        /**
         * Function called when checkbox is ticked.
         * @param e
         */
        const handleCheckboxClick = (
            name: string,
        ) => {
            return (
                e: React.ChangeEvent<HTMLInputElement> | CheckboxChangeEvent
            ) => {
                updateFormStateObject({
                    ...processingSettingsFormState,
                    [name]: e.target.checked,
                });
            }
        };

        /**
        * Function that checks if there are any changes to the form done.
        * Compared to the original state saved in redux.
        */
        const checkForChanges = () => {
            const stateNotEqual = !isEqual(
                raProcessingConfiguration,
                processingSettingsFormState
            );
            
            return stateNotEqual;
        };

        /**
        * Function that resets all the changes and set the data
        * equal to the one saved in redux.
        */
        const syncStateFromRedux = () => {
            updateFormStateObject(raProcessingConfiguration);
        };

        useEffect(syncStateFromRedux, [raProcessingConfiguration]);

        const fetchInitialData = () => {
            props.setSaveLoading(false); 
            dispatch(getRAProcessingConfigurationRequestAction())
        }

        useEffect(fetchInitialData, [props.selectedUserCompany]);

        const clearOnCompanyChange = () => {
            resetFields();
        };

        useEffect(clearOnCompanyChange, [props.selectedUserCompany]);

        const populateCustomizationSection = () => {
            if (!isEmpty(errorMessages)) {
                return <Empty description={get(errorMessages, 0)} />;
            }

            return <>
                <Row type="flex" align="middle">
                    <Col span={16}>
                        <Row>
                            <Col>
                                <Title level={4}>{getTranslatedText("Headers")}</Title>
                            </Col>
                            <Col className="grey">
                                {getTranslatedText("Please provide the full headers where possible, separated by a '|' symbol, ordered by least to most common. Eg. supplier invoice #|invoice#|invoice|number")}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                <Row type="flex" align="middle">
                    <Col span={16}>
                        <Row>
                            <Col span={4}>
                                <div className="mt-9 pr-2">
                                    {getTranslatedText("Type")}:
                                </div>
                            </Col>
                            <Col span={18} className="flex-i">
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(formFieldNames.TYPE_HEADERS,
                                        {
                                            initialValue: get(
                                                processingSettingsFormState,
                                                'TypeHeaders',
                                                ''
                                            ),
                                        }
                                    )(
                                        <Input
                                            placeholder={getTranslatedText("type")}
                                            onChange={updateFormState(
                                                'TypeHeaders'
                                            )}
                                        />
                                    )} 
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4}>
                                <div className="mt-9 pr-2">
                                    <span className="red">*</span>{getTranslatedText("Number")}:
                                </div>
                            </Col>
                            <Col span={18} className="flex-i">  
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(formFieldNames.NUMBER_HEADERS,
                                        {
                                            initialValue:
                                                get(
                                                    processingSettingsFormState,
                                                    'NumberHeaders',
                                                    ''
                                                ),
                                            rules: [
                                                {
                                                    required: true,
                                                    message: getTranslatedText("Number is required!"),
                                                }
                                            ],
                                        }
                                    )(
                                        <Input
                                            placeholder={getTranslatedText("supplier invoice#|invoice|number")}
                                            onChange={updateFormState(
                                                'NumberHeaders'
                                            )}
                                        />
                                    )} 
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4}>
                                <div className="mt-9 pr-2">
                                    {getTranslatedText("Description")}:
                                </div>
                            </Col>
                            <Col span={18} className="flex-i">    
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(formFieldNames.DESCRIPTION_HEADERS,
                                    {
                                        initialValue:
                                            get(
                                                processingSettingsFormState,
                                                'DescriptionHeaders',
                                                ''
                                            )
                                    })
                                    (
                                        <Input
                                            placeholder={getTranslatedText("description")}
                                            onChange={updateFormState(
                                                'DescriptionHeaders'
                                            )}
                                        />
                                    )} 
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4}>
                                <div className="mt-9 pr-2">
                                    <span className="red">*</span>{getTranslatedText("Amount")}:
                                </div>
                            </Col>
                            <Col span={18} className="flex-i">
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(formFieldNames.AMOUNT_HEADERS,
                                        {
                                            initialValue: 
                                                get(
                                                    processingSettingsFormState,
                                                    'AmountHeaders',
                                                    ''
                                                ),
                                            rules: [
                                                {
                                                    required: true,
                                                    message: getTranslatedText("Amount is required!"),
                                                }
                                            ]
                                        }
                                    )(
                                        <Input
                                            placeholder={getTranslatedText("paid amount|invoice amount|amount")}
                                            onChange={updateFormState(
                                                'AmountHeaders'
                                            )}
                                        />
                                    )} 
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4}>
                                <div className="mt-9 pr-2">
                                    {getTranslatedText("Customer Code")}:
                                </div>
                            </Col>
                            <Col span={18} className="flex-i">
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(formFieldNames.CUSTOMER_CODE_HEADERS,
                                        {
                                            initialValue:
                                                get(
                                                    processingSettingsFormState,
                                                    'CustomerCodeHeaders',
                                                    ''
                                                )
                                        }
                                    )(
                                        <Input
                                            placeholder={getTranslatedText("customer")}
                                            onChange={updateFormState(
                                                'CustomerCodeHeaders'
                                            )}
                                        />
                                    )} 
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4}>
                                <div className="mt-9 pr-2">
                                    {getTranslatedText("Created Date")}:
                                </div>
                            </Col>
                            <Col span={18} className="flex-i">
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(formFieldNames.CREATED_DATE_HEADERS,
                                        {
                                            initialValue:get(
                                                processingSettingsFormState,
                                                'CreatedDateHeaders',
                                                ''
                                            )
                                        }
                                    )
                                    (
                                        <Input
                                            placeholder={getTranslatedText("created date|invoice date")}
                                            onChange={updateFormState(
                                                'CreatedDateHeaders'
                                            )}
                                        />
                                    )} 
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4}>
                                <div className="mt-9 pr-2">
                                    {getTranslatedText("Due Date")}:
                                </div>
                            </Col>
                            <Col span={18} className="flex-i">
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(formFieldNames.DUE_DATE_HEADERS,
                                        {
                                            initialValue:get(
                                                processingSettingsFormState,
                                                'DueDateHeaders',''
                                            )
                                        }
                                    )
                                    (
                                        <Input
                                            placeholder={getTranslatedText("due date")}
                                            onChange={updateFormState(
                                                'DueDateHeaders'
                                            )}
                                        />
                                        )} 
                                </FormItem>                                          
                            </Col>
                        </Row>
                    </Col>
                    
                </Row>
                
                <Divider className="divider-thick" />
                <Row type="flex" align="middle">
                    <Col span={16}>
                        <Row>
                            <Col>
                                <Title level={4}>{getTranslatedText("Summary Fields")}</Title>
                            </Col>
                            <Col className="grey">
                                {getTranslatedText("Please provide the full headers where possible, separated by a '|' symbol, ordered by least to most common. Eg. supplier invoice #|invoice#|invoice|number")}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                <Row type="flex" align="middle">
                    <Col span={16}>
                        <Row>
                            <Col span={4}>
                                <div className="mt-9 pr-2">
                                    <span className="red">*</span>{getTranslatedText("Payment Date")}:
                                </div>
                            </Col>
                            <Col span={18} className="flex-i">
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(formFieldNames.PAYMENT_DATE_SUMMARY,
                                        {                                     
                                            initialValue:
                                                get(
                                                    processingSettingsFormState,
                                                    'PaymentDateSummaryFields',
                                                    ''
                                                )
                                            ,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: getTranslatedText("Payment Date is required!"),
                                                }
                                            ],
                                        }
                                    )(
                                        <Input
                                            placeholder={getTranslatedText("payment date|invoice date|date")}
                                            onChange={updateFormState(
                                                'PaymentDateSummaryFields'
                                            )}
                                        />
                                    )} 
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4}>
                                <div className="mt-9 pr-2">
                                    <span className="red">*</span>{getTranslatedText("Total Payment")}:
                                </div>
                            </Col>
                            <Col span={18} className="flex-i">
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(formFieldNames.TOTAL_PAYMENT_SUMMARY,
                                        {
                                            initialValue:
                                                get(
                                                    processingSettingsFormState,
                                                    'TotalPaymentSummaryFields',
                                                    ''
                                                )
                                            ,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: getTranslatedText("Total Payment is required!"),
                                                }
                                            ],
                                        }
                                    )(
                                        <Input
                                            placeholder={getTranslatedText("total payment|total paid|payment total|total")}
                                            onChange={updateFormState(
                                                'TotalPaymentSummaryFields'
                                            )}
                                        />
                                    )} 
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                    
                </Row>

                <Divider className="divider-thick" />
                <Row type="flex" align="middle">
                    <Col span={16}>
                        <Row>
                            <Col>
                                <Title level={4}>{getTranslatedText("Number Values")}</Title>
                            </Col>
                            <Col className="grey">
                                {getTranslatedText("Please provide the partial value that each piece of data should have to be identified as a particular item.")}
                                <br/>
                                {getTranslatedText("Eg. if an invoice contains \"ci\" anywhere in its number, it will be identified as such.")}
                                <br/>
                                <br/>
                                {getTranslatedText("Ensure each value is separated by a \"|\" symbol, ordered by least to most common.")}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                <Row type="flex" align="middle">
                    <Col span={16}>
                        <Row>
                            <Col span={4}>
                                <div className="mt-9 pr-2">
                                    {getTranslatedText("Invoice Number")}:
                                </div>
                            </Col>
                            <Col span={18} className="flex-i">
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(formFieldNames.INVOICE_NUMBER,
                                        {
                                            initialValue:
                                                get(
                                                    processingSettingsFormState,
                                                    'InvoiceNumberValues',
                                                    ''
                                                )
                                        }
                                    )(
                                        <Input
                                            placeholder={getTranslatedText("ri")}
                                            onChange={updateFormState(
                                                'InvoiceNumberValues'
                                            )}
                                        />
                                    )} 
                                </FormItem>     
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4}>
                                <div className="mt-9 pr-2">
                                    {getTranslatedText("Credit Number")}:
                                </div>
                            </Col>
                            <Col span={18} className="flex-i">   
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(formFieldNames.CREDIT_NUMBER,
                                        {
                                            initialValue:
                                                get(
                                                    processingSettingsFormState,
                                                    'CreditNumberValues',
                                                    ''
                                                )
                                        }
                                    )(
                                        <Input
                                            placeholder={getTranslatedText("ci")}
                                            onChange={updateFormState(
                                                'CreditNumberValues'
                                            )}
                                        />
                                    )} 
                                </FormItem>  
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4}>
                                <div className="mt-9 pr-2">
                                    {getTranslatedText("Rebate Number")}:
                                </div>
                            </Col>
                            <Col span={18} className="flex-i">
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(formFieldNames.REBATE_NUMBER,
                                        {
                                            initialValue:
                                                get(
                                                    processingSettingsFormState,
                                                    'RebateNumberValues',
                                                    ''
                                                )
                                        }
                                    )
                                    (
                                        <Input
                                            placeholder=""
                                            onChange={updateFormState(
                                                'RebateNumberValues'
                                            )}
                                        />
                                    )} 
                                </FormItem> 
                            </Col>
                        </Row>
                    </Col>
                </Row>                

                <Divider className="divider-thick" />
                <Row type="flex" align="middle">
                    <Col span={16}>
                        <Row>
                            <Col>
                                <Title level={4}>{getTranslatedText("Type Values")}</Title>
                            </Col>
                            <Col className="grey">
                                {getTranslatedText("Please provide the partial value that each piece of data should have to be identified as a particular item.")}
                                <br/>
                                {getTranslatedText("Eg. if type value contains \"inv\", It will be identified as an invoice.")}
                                <br/>
                                <br/>
                                {getTranslatedText("Ensure each value is separated by a \"|\" symbol, ordered by least to most common.")}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                <Row type="flex" align="middle">
                    <Col span={16}>
                        <Row>
                            <Col span={4}>
                                <div className="mt-9 pr-2">
                                    {getTranslatedText("Invoice Type")}:
                                </div>
                            </Col>
                            <Col span={18} className="flex-i">
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(formFieldNames.INVOICE_TYPE,
                                        {
                                            initialValue:
                                                get(
                                                    processingSettingsFormState,
                                                    'InvoiceTypeValues',
                                                    ''
                                                )
                                        }
                                    )(
                                        <Input
                                            placeholder={getTranslatedText("invoice|inv")}
                                            onChange={updateFormState(
                                                'InvoiceTypeValues'
                                            )}
                                        />
                                    )} 
                                </FormItem> 
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4}>
                                <div className="mt-9 pr-2">
                                    {getTranslatedText("Credit Type")}:
                                </div>
                            </Col>
                            <Col span={18} className="flex-i">
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(formFieldNames.CREDIT_TYPE,
                                        {
                                            initialValue:
                                                get(
                                                    processingSettingsFormState,
                                                    'CreditTypeValues',
                                                    ''
                                                )
                                        }
                                    )(
                                        <Input
                                            placeholder={getTranslatedText("credit|cre")}
                                            onChange={updateFormState(
                                                'CreditTypeValues'
                                            )}
                                        />
                                    )} 
                                </FormItem> 
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4}>
                                <div className="mt-9 pr-2">
                                    {getTranslatedText("Rebate Type")}:
                                </div>
                            </Col>
                            <Col span={18} className="flex-i">
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(formFieldNames.REBATE_TYPE,
                                        {
                                            initialValue:
                                                get(
                                                    processingSettingsFormState,
                                                    'RebateTypeValues',
                                                    ''
                                                )
                                        }
                                    )
                                    (
                                        <Input
                                            placeholder=""
                                            onChange={updateFormState(
                                                'RebateTypeValues'
                                            )}
                                        />
                                    )} 
                                </FormItem> 
                            </Col>
                        </Row>
                    </Col>
                    
                </Row>

                <Divider className="divider-thick" />
                <Row type="flex" align="middle">
                    <Col span={16}>
                        <Row>
                            <Col>
                                <Title level={4}>{getTranslatedText("Description Values")}</Title>
                            </Col>
                            <Col className="grey">
                                {getTranslatedText("Please provide the partial value that each piece of data should have to be identified as a particular item.")}
                                <br/>
                                {getTranslatedText("Eg. if the description value contains \"inv\", It will be identified as an invoice.")}
                                <br/>
                                <br/>
                                {getTranslatedText("Ensure each value is separated by a \"|\" symbol, ordered by least to most common.")}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                <Row type="flex" align="middle">
                    <Col span={16}>
                        <Row>
                            <Col span={4}>
                                <div className="mt-9 pr-2">
                                    {getTranslatedText("Invoice Description")}:
                                </div>
                            </Col>
                            <Col span={18} className="flex-i">
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(formFieldNames.INVOICE_DESCRIPTION,
                                        {
                                            initialValue:
                                                get(
                                                    processingSettingsFormState,
                                                    'InvoiceDescriptionValues',
                                                    ''
                                                )
                                        }
                                    )
                                    (
                                        <Input
                                            placeholder={getTranslatedText("invoice")}
                                            onChange={updateFormState(
                                                'InvoiceDescriptionValues'
                                            )}
                                        />
                                    )} 
                                </FormItem> 
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4}>
                                <div className="mt-9 pr-2">
                                    {getTranslatedText("Credit Description")}:
                                </div>
                            </Col>
                            <Col span={18} className="flex-i">    
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(formFieldNames.CREDIT_DESCRIPTION,
                                        {
                                            initialValue:
                                                get(
                                                    processingSettingsFormState,
                                                    'CreditDescriptionValues',
                                                    ''
                                                )
                                        }
                                    )
                                    (
                                        <Input
                                            placeholder={getTranslatedText("credit")}
                                            onChange={updateFormState(
                                                'CreditDescriptionValues'
                                            )}
                                        />
                                    )} 
                                </FormItem> 
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4}>
                                <div className="mt-9 pr-2">
                                {getTranslatedText("Rebate Description")}:
                                </div>
                            </Col>
                            <Col span={18} className="flex-i">
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(formFieldNames.REBATE_DESCRIPTION,
                                        {
                                            initialValue:

                                                get(
                                                    processingSettingsFormState,
                                                    'RebateDescriptionValues',
                                                    ''
                                                )
                                        }
                                    )
                                    (
                                        <Input
                                            placeholder={getTranslatedText("rebate")}
                                            onChange={updateFormState(
                                                'RebateDescriptionValues'
                                            )}
                                        />
                                    )} 
                                </FormItem> 
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Divider className="divider-thick" />
                <Row type="flex" align="middle">
                    <Col span={16}>
                        <Row>
                            <Col>
                                <Title level={4}>{getTranslatedText("Adjustments")}</Title>
                            </Col>
                            <Col className="grey">
                                {getTranslatedText("If an invoice amount found on a remittance advice is less than the invoice's amount owing in the IODM System, You may allow an adjustment (created as a credit) to be automatically be created for the remittance.")}
                                <br/>
                                <br/>
                                {getTranslatedText("Note: The adjustment identifier will contain the associated invoice number.")}
                                <br/>
                                <br/>
                                {getTranslatedText("In order to allow adjustment, Set the adjustment amount and identifier.")}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                <Row type="flex" align="middle">
                    <Col span={16}>
                        <Row>
                            <Col span={4}>
                                <div className="mt-9 pr-2">
                                {getTranslatedText("Adjustment Amount")}:
                                </div>
                            </Col>
                            <Col span={18} className="flex-i">
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(formFieldNames.ADJUSTMENT_AMOUNT,
                                        {
                                            initialValue:
                                                get(
                                                    processingSettingsFormState,
                                                    'AdjustmentAmount'
                                                ) || 0.0
                                            ,rules: [
                                                {
                                                    type: 'number',
                                                    validator: (_:any, value:any, callback:any) => {
                                                        if (value < 0) {
                                                            callback(getTranslatedText("The input cannot be a negative number"));
                                                        } else {
                                                            callback(); // No error
                                                        }
                                                    },
                                                },
                                            ]
                                        }
                                    )
                                    (
                                        <InputNumber
                                            type="number"
                                            style={{ width: 200 }}
                                            onChange={updateFormState(
                                                'AdjustmentAmount'
                                            )}
                                            placeholder='5.00'
                                        />
                                    )} 
                                </FormItem> 
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4}>
                                <div className="mt-9 pr-2">
                                {getTranslatedText("Adjustment Number Prepend")} :
                                </div>
                            </Col>
                            <Col span={18} className="flex-i">
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(formFieldNames.ADJUSTMENT_IDENTIFIER_PREPEND,
                                        {
                                            initialValue:
                                                get(
                                                    processingSettingsFormState,
                                                    'AdjustmentIdentifierPrepend',
                                                    ''
                                                )  
                                        }
                                    )
                                    (
                                        <Input
                                            placeholder={getTranslatedText("DA-")}
                                            onChange={updateFormState(
                                                'AdjustmentIdentifierPrepend'
                                            )}
                                        />
                                    )} 
                                </FormItem> 
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4}>
                                <div className="mt-9 pr-2">
                                {getTranslatedText("Adjustment Number Append")} :
                                </div>
                            </Col>
                            <Col span={18} className="flex-i">
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(formFieldNames.ADJUSTMENT_IDENTIFIER_APPEND,
                                        {
                                            initialValue:
                                                get(
                                                    processingSettingsFormState,
                                                    'AdjustmentIdentifierAppend',
                                                    ''
                                                )
                                        }
                                    )
                                    (
                                        <Input
                                            placeholder=""
                                            onChange={updateFormState(
                                                'AdjustmentIdentifierAppend'
                                            )}
                                        />
                                    )} 
                                </FormItem> 
                            </Col>
                        </Row>
                        <Row key="title-container" type="flex" align="middle">
                            <Col span={16}>
                                <Row>
                                    <Col className="grey">
                                        {getTranslatedText("Your adjustment will appear as: ")}   
                                        {
                                            get(
                                                processingSettingsFormState,
                                                'AdjustmentIdentifierPrepend',
                                                ''
                                            )
                                        } 
                                        {!isEmpty(get(
                                                processingSettingsFormState,
                                                'AdjustmentIdentifierPrepend'
                                            )) || !isEmpty(get(
                                                processingSettingsFormState,
                                                'AdjustmentIdentifierAppend'
                                            )) ? 12345 : ''
                                        }
                                        {get(
                                            processingSettingsFormState,
                                            'AdjustmentIdentifierAppend',
                                            ''
                                        )} 
                                                     
                                                                                  
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    
                </Row>

                <Divider className="divider-thick" />
                <Row type="flex" align="middle">
                    <Col span={16}>
                        <Row>
                            <Col>
                                <Title level={4}>{getTranslatedText("High Confidence Confirmation")}</Title>
                            </Col>
                            <Col className="grey">
                            {getTranslatedText("While off, Remittance Advice Confirmation tasks will be generated to ensure all the right details and amounts have been entered.")}
                                <br/>
                                <br/>
                                {getTranslatedText("Turning this on will allow Remittance Advices that have been automatically.")}
                                {getTranslatedText("processed to be verified immediately, Skipping the Remittance Advice Confirmation task if no errors have been found on the Remittance Advice.")}
                                <br/>
                                <br/>
                            </Col>
                            <Col>
                                <span className="red">*</span>
                                {
                                    getTranslatedText("WARNING: ENSURE TESTING HAS BEEN DONE WITH THE CURRENT CONFIG BEFORE ACTIVATING")
                                }
                                <br/>
                                <br/>
                            </Col>
                            <Col>
                                <FormItem className="f-ta-100">
                                    {getFieldDecorator(formFieldNames.ALLOW_HIGH_CONFIDENCE)
                                    (
                                        <Checkbox
                                            onChange={handleCheckboxClick('AllowHighConfidence')}
                                            checked={get(
                                                processingSettingsFormState,
                                                'AllowHighConfidence'
                                            )}
                                        >  {getTranslatedText("Allow Auto-Processing of High Confidence Remittance Advices")} </Checkbox>                              
                                    )} 
                                </FormItem> 
                            </Col>
                        </Row>

                    </Col>
                </Row>
                <br />
            </>
        };

        /**
         * Function responsible for showing the error modal after importing RA Processing settings
         * @param param0 - includes an array of required fields that doesn't have values and may have an error message response from API
         */
        const handleModalImportExceptionResponse = (exceptionFields: string[] = []) => {
            if(exceptionFields.length == 0){
                Modal.error({
                    title: getTranslatedText("Error"),
                    content: `${getTranslatedText("Failed to import remittance advice processing settings.")} ${getTranslatedText("Please check the correct JSON code syntax.")}`,
                });
            } else {   
                Modal.error({
                    title: getTranslatedText("Error"),
                    content: <div dangerouslySetInnerHTML={{__html:`${getTranslatedText("Failed to import remittance advice processing settings.")} <br/><br/>  
                    ${getTranslatedText("Validation Error")}:<br/>${exceptionFields.join('<br/>')}`}}/>
                });
            }
            
        };

        return (
            <Col span={24}>
                <RouteLeavingGuard
                    when={checkForChanges()}
                    navigate={(path: string) => props.history.push(path)}
                    shouldBlockNavigation={() => {
                        if (checkForChanges()) {
                            return true;
                        }
                        return false;
                    }}
                />
                <QueueAnim type={['right', 'left']} leaveReverse>
                <Spin
                    spinning={loading}
                    tip={getTranslatedText("Loading remittance advice processing configuration details")}
                    // size="large"
                    style={{
                        height: '80%',
                    }}
                >
                    <Row key="title-container" type="flex" align="top">
                        {
                            isEmpty(errorMessages) && 
                            <>
                                <Col span={16}>
                                    <Row>
                                        <Col>
                                            <Title level={3}>{getTranslatedText("Processing Settings")}</Title>
                                        </Col>
                                        <Col className="grey">
                                            {getTranslatedText("These settings allow you to customise how the Remittance Advice Processor identifies certain fields to map data in the IODM System. Items in the red are fields that are required and should not be left blank.")}
                                            <br/>
                                            <br/>
                                            {getTranslatedText("You may use this page to configure the processor or Import/Export your settings as JSON text.")}
                                        </Col>
                                    </Row>
                                </Col>
                                
                                <Col span={8} className="ta-right">
                                    <Button
                                        className="mr-10 w-100px"
                                        type="primary"
                                        onClick={onImportButtonClick}
                                    >
                                        {getTranslatedText("Import")}
                                    </Button>
                                    <Button
                                        className="w-100px"
                                        type="primary"
                                        onClick={onExportButtonClick}
                                        disabled={checkForChanges()}
                                    >
                                        {getTranslatedText("Export")}
                                    </Button>
                                </Col>
                            </>
                            
                        }
                        
                    </Row>
                    { 
                        populateCustomizationSection()
                    }
                </Spin>
                    
                    <br />
                    
                </QueueAnim>
                {saveLoading && (
                    <ModalWithSpinner
                        zIndex={9999}
                        modalTitle={getTranslatedText("Saving remittance advice processing settings")}
                        modalVisible={saveLoading}
                        displayMessage={getTranslatedText("Please wait while saving remittance advice processing settings. . .")}
                    />
                )}
                <Suspense fallback={null}>
                    <RACustomizationImportDetailsDrawerComponent
                        companyId={get(props.selectedUserCompany, "CompanyId")}
                        raCustomizationPage={'Processing Settings'}
                        visible={importDetailsDrawerValues.visible}
                        closeDrawer={closeImportDetailsDrawer}
                        requiredFields={requiredFields}
                        dateFields={dateFields}
                        saveRAConfigRequestAction={saveRAProcessingConfigurationRequestAction}
                        handleModalSaveResponse={handleModalSaveResponse}
                        handleModalImportExceptionResponse={handleModalImportExceptionResponse}
                    />
                </Suspense>
                <Suspense fallback={null}>
                    <RACustomizationExportDetailsDrawerComponent
                        visible={exportDetailsDrawerValues.visible}
                        closeDrawer={closeExportDetailsDrawer}
                        raCustomizationSettings={raProcessingConfiguration}
                    />
                </Suspense>
            </Col>
        );
    }
);

export default RACustomizationProcessingSettingsPage;
