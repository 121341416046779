/**
 * Component drawer for specialised reports panel.
 */

import { Drawer } from 'antd';
import React, { useRef } from 'react';
import CreateScheduledReportPanel from './CreateScheduledReportPanel';
import { getTranslatedText } from '../../utils/commonFunctions';

interface IProps {
    visible: boolean;
    onClose: () => void;
    widgetQuery: any;
}

const CreateScheduledReportDrawer: React.FC<IProps> = ({
    visible,
    onClose,
    widgetQuery
}: IProps) => {
    const drawerRef: any = useRef(null);

    return (
        <Drawer
            visible={visible}
            width={500}
            onClose={onClose}
            title={getTranslatedText("Create Scheduled Report")}
            maskClosable={false}
            closable={false}
        >
            <div id="drawer-container" ref={drawerRef}>
                <CreateScheduledReportPanel
                    containerRef={drawerRef}
                    visible={visible}
                    closePanel={onClose}
                    widgetQuery={widgetQuery}
                />
            </div>
        </Drawer>
    );
};

export default CreateScheduledReportDrawer;